import React, { Fragment } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import translations from "app/libs/i18n/translations.json";
import { useTheme } from "@mui/styles";

const Faq = ({ type }) => {
  const theme = useTheme();
  const [panelExpanded, setPanelExpanded] = useState("");
  const handleChange = (newPanel) => () => {
    setPanelExpanded(panelExpanded === newPanel ? "" : newPanel);
  };

  const getList = () => {
    const data = Object.keys(translations["en"]).filter((item) =>
      item.startsWith(`faq.${type}`)
    );
    let result = {};

    data.forEach((p) =>
      p.split(".").reduce((o, k) => (o[k] = o[k] || {}), result)
    );

    return Object.values(result.faq[type]);
  };
  const optionsList = getList();

  return (
    <Container maxWidth="lg">
      <Grid item>
        <Box py={4}>
          <Typography variant="h1" align="center" >
            <FormattedMessage id="faq.title" />
          </Typography>
        </Box>
        <Grid container>
          {optionsList.map(({ ...section }, sectionIndex) => (
            <Fragment key={sectionIndex}>
              <Box py={2}>
                <Typography variant="h5" color="primary">
                  <FormattedMessage
                    id={`faq.${type}.section${sectionIndex + 1}.title`}
                    defaultMessage={
                      translations["en"][
                        `faq.${type}.section${sectionIndex + 1}.title`
                      ]
                    }
                  />
                </Typography>
              </Box>
              {Object.values(section)
                .filter((val) => val["question"] != null)
                .map((val, index) => {
                  return (
                    <Accordion
                      key={index}
                      expanded={panelExpanded === `s${sectionIndex}q${index}`}
                      onChange={handleChange(`s${sectionIndex}q${index}`)}
                      style={{ width: "100%", marginBottom: 16 }}
                    >
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography
                          style={{ color: theme.palette.secondary.main }}
                        >
                          <FormattedMessage
                            id={`faq.${type}.section${sectionIndex + 1}.q${
                              index + 1
                            }.question`}
                            defaultMessage={
                              translations["en"][
                                `faq.${type}.section${sectionIndex + 1}.q${
                                  index + 1
                                }.question`
                              ]
                            }
                          />
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <FormattedMessage
                            id={`faq.${type}.section${sectionIndex + 1}.q${
                              index + 1
                            }.answer`}
                            defaultMessage={
                              translations["en"][
                                `faq.${type}.section${sectionIndex + 1}.q${
                                  index + 1
                                }.answer`
                              ]
                            }
                            values={{
                              a: (link) => <a href="/contact-us">{link}</a>,
                              b: (bold) => (
                                <span style={{ fontWeight: "bold" }}>
                                  {bold}
                                </span>
                              ),
                            }}
                          />
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
            </Fragment>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Faq;
