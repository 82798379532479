import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BlogViewDesktop from "./Desktop/BlogViewDesktop";
import BlogViewMobile from "./Mobile/BlogViewMobile";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import sh from "bundles/common/utils/sh";

const BlogView = ({ type = "candidate" }) => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [article, setArticle] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [squarehubId, setSquarehubId] = useState(null);
  const [similarArticles, setSimilarArticles] = useState(null);
  const params = useParams();

  const publicArticleUrl = `${window.location.origin}/${i18nLocale}/${type}s/posts/${params?.id}`;

  const fetchArticle = () => {
    sh.get(`/blog_post/${params.id}`, {
      authenticity_token: authenticityToken,
    })
      .then((res) => {
        if (res?.data) {
          setArticle(res?.data);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setNotFound(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchFourSimilarArticles = () => {
    sh.get("four_similar_articles", {
      params: {
        quantity: 4,
        authenticity_token: authenticityToken,
      },
    })
      .then((res) => {
        setSimilarArticles(res.data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getSquarehubId = () => {
    sh.get(`/${i18nLocale}/find_squarehub_id`)
      .then((response) => {
        if (response?.data?.id) {
          setSquarehubId(response.data.id);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchArticle();
    fetchFourSimilarArticles();
    getSquarehubId();
  }, []);

  return (
    <>
      {isDesktop
        ?
        <BlogViewDesktop
          type={type}
          article={article}
          squarehubId={squarehubId}
          publicArticleUrl={publicArticleUrl}
          similarArticles={similarArticles}
          notFound={notFound}
        />
        :
        <BlogViewMobile
          type={type}
          article={article}
          squarehubId={squarehubId}
          publicArticleUrl={publicArticleUrl}
          similarArticles={similarArticles}
          notFound={notFound}
        />
      }
    </>
  );
};

export default BlogView;
