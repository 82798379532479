import React from "react";
import { useSelector } from "react-redux";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { TextField, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import translations from "app/libs/i18n/translations.json";
import { useState } from "react";
import { useEffect } from "react";
import { useIntl } from "react-intl";

const filter = createFilterOptions();

const SkillSelect = ({
  selectedValues,
  category,
  label,
  disabled,
  onChange,
  allowAddingCustomSkills = false
}) => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const theme = useTheme();
  const intl = useIntl();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const optionsList = Object.entries(translations[i18nLocale]).filter((elem) =>
    elem[0].includes(`skills_list.${category}`)
  );

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (optionsList?.length || selectedValues?.length) {
      let baseOptions = optionsList.map((option) => {
        const splited = option[0].split(".");
        return { id: splited[splited.length - 1], label: option[1] };
      });

      let customOptions =
        currentRecruiter?.company?.skills
          ?.filter((s) => s.category == category)
          .map((s) => {
            return {
              id: s.name,
              label: s.name,
              company_id: currentRecruiter?.company_id,
            };
          }) || [];

      let createdOptions = currentRecruiter
        ? selectedValues
          .filter(
            (v) =>
              !baseOptions.map((option) => option.id).includes(v) &&
              !customOptions.map((option) => option.id).includes(v)
          )
          .map((v) => {
            return {
              id: v,
              label: v,
              company_id: currentRecruiter?.company_id,
            };
          })
        : [];
      setOptions(
        [...customOptions, ...createdOptions, ...baseOptions].sort((a, b) => {
          let aLabel = a.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          let bLabel = b.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          if (aLabel < bLabel) {
            return -1;
          } else if (aLabel > bLabel) {
            return 1;
          }
          return 0;
        })
      );
    }
  }, [optionsList?.length, selectedValues?.length]);

  return (
    <Autocomplete
      multiple
      disableClearable
      disabled={disabled}
      options={options.filter((option) => !selectedValues.includes(option.id))}
      getOptionLabel={(option) => option.label}
      value={options.filter((option) => selectedValues.includes(option.id))}
      onChange={(event, newValue) => onChange(category, newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={intl.formatMessage({ id: "select" })}
          color={currentRecruiter ? "secondary" : "primary"}
          onKeyDown={(e) => {
            if (e.key === "Backspace") {
              e.stopPropagation();
            }
          }}
        />
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue === option.id);
        if (inputValue !== "" && !isExisting && currentRecruiter && allowAddingCustomSkills) {
          filtered.push({
            id: inputValue,
            label: `${intl.formatMessage({ id: "add" })} "${inputValue}"`,
            company_id: currentRecruiter.company_id,
          });
        }

        return filtered;
      }}
      renderTags={() => {}}
      style={{ height: "64px", width: isDesktop ? "440px" : "100%" }}
    />
  );
};

export default SkillSelect;
