import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import validator from "validator";
import {
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  FormControlLabel,
  Switch,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import sh from "bundles/common/utils/sh";
import { replaceCurrentCandidate } from "bundles/Candidate/constants/candidateConstants";
import ConfirmModal from "bundles/common/components/ConfirmModal";

const EditExperienceModal = ({
  experience,
  closeModal,
  completionCallback,
}) => {
  const isEdit = experience != null;
  const currentCandidate = useSelector(state => state.current_candidate);
  const authenticityToken = useSelector(state => state.authenticity_token);

  const intl = useIntl();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const useStyles = makeStyles(() => ({
    modalContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: "white",
      borderRadius: "8px",
      padding: isDesktop ? "32px 64px" : "32px",
      minWidth: isDesktop ? "600px" : "unset",
      width: isDesktop ? "unset" : "calc(100% - 32px) !important",
      height: isDesktop ? "unset" : "fit-content",
    },
    modalBox: {},
    headerBox: {
      textAlign: "center",
      marginBottom: "32px",
    },
    inputBox: {
      marginBottom: isDesktop ? "24px" : "16px",
    },
  }));
  const classes = useStyles();

  const initialValues = {
    id: experience?.id,
    title: experience?.title || "",
    description: experience?.description || "",
    company: experience?.company || "",
    location: experience?.location || "",
    start_date: experience?.start_date,
    end_date: experience?.end_date,
  };
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [stillActive, setStillActive] = useState(
    (experience?.id && !experience?.end_date) || false
  );

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );
    let end_date_must_be_higher = (
      <FormattedMessage id="form_validation.end_date_must_be_higher"></FormattedMessage>
    );

    if ("title" in fieldValues) {
      temp.title = fieldValues.title ? "" : required;
    }

    if ("company" in fieldValues) {
      temp.company = fieldValues.company ? "" : required;
    }

    if ("location" in fieldValues) {
      temp.location = fieldValues.location ? "" : required;
    }

    if ("start_date" in fieldValues) {
      temp.start_date = fieldValues.start_date ? "" : required;
    }

    if ("end_date" in fieldValues) {
      if (values.start_date) {
        if (
          values.end_date &&
          !validator.isAfter(fieldValues.end_date, values.start_date)
        ) {
          temp.end_date = end_date_must_be_higher;
        } else if (!stillActive && !fieldValues.end_date) {
          temp.end_date = required;
        } else {
          temp.end_date = "";
        }
      }
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every(x => x === "");
  };

  const handleStillActive = () => {
    if (!stillActive) {
      let newValues = { ...values, end_date: "" };
      setValues(newValues);
    }
    setStillActive(!stillActive);
  };

  const handleInputValue = e => {
    const { name, value } = e.target;
    let newValues = { ...values, [name]: value };
    setValues(newValues);
    validate({ [name]: value });
  };

  const handleSubmit = e => {
    setLoading(true);
    e.preventDefault();
    if (validate(values)) {
      let experience = {
        candidate_id: currentCandidate.id,
        id: values.id,
        title: values.title,
        description: values.description,
        company: values.company,
        location: values.location,
        start_date: values.start_date,
        end_date: stillActive ? null : values.end_date,
      };

      if (values.id) {
        sh.put(`experience/${experience.id}`, {
          authenticity_token: authenticityToken,
          experience,
        })
          .then(() => {
            replaceCurrentCandidate(dispatch, authenticityToken, () => {
              closeModal();
              enqueueSnackbar(
                intl.formatMessage({ id: "settings.save_success" }),
                {
                  variant: "success",
                }
              );
            });
          })
          .catch(err => {
            console.error(err);
            enqueueSnackbar(
              intl.formatMessage({ id: "settings.save_failed" }),
              { variant: "error" }
            );
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        sh.post("experience", {
          authenticity_token: authenticityToken,
          experience,
        })
          .then(() => {
            replaceCurrentCandidate(dispatch, authenticityToken, () => {
              closeModal();
              enqueueSnackbar(
                intl.formatMessage({ id: "settings.save_success" }),
                {
                  variant: "success",
                }
              );
              if (completionCallback) completionCallback();
            });
          })
          .catch(err => {
            console.error(err);
            enqueueSnackbar(
              intl.formatMessage({ id: "settings.save_failed" }),
              { variant: "error" }
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setLoading(false);
    }
  };

  const handleDelete = e => {
    e.preventDefault();
    setIsConfirmModalOpen(true);
  };

  const handleDeleteConfirmed = e => {
    e.preventDefault();
    sh.delete(`experience/${experience.id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        authenticity_token: authenticityToken,
        experience: experience,
      },
    })
      .then(() => {
        replaceCurrentCandidate(dispatch, authenticityToken, () => {
          closeModal();
          enqueueSnackbar(intl.formatMessage({ id: "settings.save_success" }), {
            variant: "success",
          });
        });
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar(intl.formatMessage({ id: "settings.save_failed" }), {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box className={classes.modalContainer}>
      <form className={classes.modalBox}>
        <Box className={classes.headerBox}>
          <Typography variant="h4">
            <FormattedMessage
              id={isEdit ? "settings.edit_xp" : "settings.add_xp"}
            />
          </Typography>
        </Box>
        <Box className={classes.inputBox}>
          <TextField
            size="small"
            fullWidth
            required
            inputProps={{ maxLength: 58 }}
            InputLabelProps={{ required: false }}
            value={values.title}
            type="text"
            name="title"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            variant="outlined"
            label={<FormattedMessage id="experience.title" />}
            {...(errors["title"] && {
              error: true,
              helperText: errors["title"],
            })}
          />
          <Box width={isDesktop ? "100%" : "100%"}>
            <Typography align="right">
              {values?.title?.length}/58{" "}
              {intl.formatMessage({ id: "characters" })}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.inputBox}>
          <TextField
            size="small"
            fullWidth
            required
            InputLabelProps={{ required: false }}
            value={values.company}
            type="text"
            name="company"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            variant="outlined"
            label={<FormattedMessage id="experience.company" />}
            {...(errors["company"] && {
              error: true,
              helperText: errors["company"],
            })}
          />
        </Box>
        <Box className={classes.inputBox}>
          <TextField
            size="small"
            fullWidth
            required
            InputLabelProps={{ required: false }}
            value={values.location}
            type="text"
            name="location"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            variant="outlined"
            label={<FormattedMessage id="experience.location" />}
            {...(errors["location"] && {
              error: true,
              helperText: errors["location"],
            })}
          />
        </Box>
        <Box className={classes.inputBox}>
          <FormControlLabel
            size="small"
            control={
              <Switch checked={stillActive} onChange={handleStillActive} />
            }
            label={
              <Typography variant="body2" style={{ whiteSpace: "nowrap" }}>
                <FormattedMessage id="experience.active" />
              </Typography>
            }
          />
        </Box>
        <Box
          className={classes.inputBox}
          style={{ display: "flex", columnGap: "8px" }}
        >
          <TextField
            size="small"
            name="start_date"
            value={values.start_date}
            type="date"
            variant="outlined"
            label={<FormattedMessage id="experience.start_date" />}
            fullWidth
            onBlur={handleInputValue}
            onChange={handleInputValue}
            InputLabelProps={{
              shrink: true,
            }}
            {...(errors["start_date"] && {
              error: true,
              helperText: errors["start_date"],
            })}
          />
          <TextField
            size="small"
            name="end_date"
            value={values.end_date}
            disabled={stillActive}
            type="date"
            variant="outlined"
            label={<FormattedMessage id="experience.end_date" />}
            fullWidth
            onBlur={handleInputValue}
            onChange={handleInputValue}
            InputLabelProps={{
              shrink: true,
            }}
            {...(errors["end_date"] && {
              error: true,
              helperText: errors["end_date"],
            })}
          />
        </Box>
        <Box className={classes.inputBox} style={{ display: "flex" }}>
          <TextField
            size="small"
            name="description"
            variant="outlined"
            value={values.description}
            label={
              <Typography variant="body2" style={{ whiteSpace: "nowrap" }}>
                <FormattedMessage id="experience.description" />
              </Typography>
            }
            onBlur={handleInputValue}
            onChange={handleInputValue}
            inputProps={{ maxLength: 2300 }}
            fullWidth
            multiline
            rows={isDesktop ? 6 : 4}
            {...(errors["description"] && {
              error: true,
              helperText: errors["description"],
            })}
          />
        </Box>
        <Box width={isDesktop ? "100%" : "100%"}>
          <Typography align="right">
            {values?.description?.length}/2300{" "}
            {intl.formatMessage({ id: "characters" })}
          </Typography>
        </Box>
        <Grid
          container
          spacing={isDesktop ? 4 : 2}
          className={classes.inputBox}
        >
          <Grid item xs={12} md={6}>
            <Button
              type="submit"
              fullWidth
              variant="rounded"
              color="primaryContained"
              disabled={loading}
              loading={loading}
              onClick={handleSubmit}
            >
              <FormattedMessage id="save" />
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            {isEdit ? (
              <Button
                fullWidth
                variant="rounded"
                color="dangerOutlined"
                disabled={loading}
                loading={loading}
                onClick={handleDelete}
              >
                <FormattedMessage id="delete" />
              </Button>
            ) : (
              <Button
                fullWidth
                variant="rounded"
                color="secondaryOutlined"
                onClick={closeModal}
              >
                <FormattedMessage id="cancel" />
              </Button>
            )}
          </Grid>
        </Grid>
      </form>

      <ConfirmModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        handleConfirm={handleDeleteConfirmed}
        labelId="delete_experience_confirmation"
      />
    </Box>
  );
};

export default EditExperienceModal;
