import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import JobOfferEdit from "./JobOfferEdit";
import sh from "bundles/common/utils/sh";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import wipImg from "pictures/wip.png";
import { trackJobOfferCreation } from "bundles/common/utils/marketing";
import { useBlocker } from "bundles/common/hooks/useBlocker";

const JobOfferCreation = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const dispatch = useDispatch();
  const railsEnv = useSelector((state) => state.railsEnv);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const queryJobOffer = queryParams.get("jobOffer");
  const draftMode = queryParams.get("draftMode") || false;

  const isEdit = queryJobOffer != null;

  const [jobOffer, setJobOffer] = useState(
    (currentRecruiter?.company?.job_offers || []).find(
      (offer) => offer.id == queryJobOffer
    ) || {}
  );
  const [skills, setSkills] = useState(jobOffer?.skills || []);

  const showWarning =
    !draftMode &&
    !jobOffer?.id &&
    currentRecruiter != null &&
    !currentRecruiter["active_licence_credit?"];

  const [isBeingSaved, setIsBeingSaved] = useState(false);
  const [activeBlocker, setActiveBlocker] = useState(
    !showWarning && !isBeingSaved
  );

  const fetchJobOffer = () => {
    sh.get(`recruiter/job_offers/${queryJobOffer}`, {
      authenticity_token: authenticityToken,
    })
      .then((res) => {
        setJobOffer(res.data?.data);
        setSkills(res.data.data.skills);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleJobOfferSave = (withPublish = false) => {
    let job_offer = {
      description: jobOffer?.description,
      responsibilities: jobOffer?.responsibilities,
      profile: jobOffer?.profile,
      offer: jobOffer?.offer,
      offer_language: jobOffer?.offer_language,
      company_industries: jobOffer?.company_industries,
      job_title: jobOffer?.job_title,
      job_statuses: jobOffer?.job_statuses,
      locations: jobOffer?.locations,
      monthly_offer: jobOffer?.monthly_offer,
      start_date: jobOffer?.start_date,
      end_date: jobOffer?.end_date,
      offer_status: jobOffer?.offer_status || "draft",
      offer_advantages: jobOffer?.offer_advantages,
      external_application: jobOffer.external_application,
      external_application_url: jobOffer.external_application_url,
      days_validity: parseInt(jobOffer.days_validity),
      abroad: jobOffer?.abroad,
    };

    if (jobOffer?.id) {
      sh.put(`job_offer/${jobOffer.id}`, {
        authenticity_token: authenticityToken,
        job_offer,
        skills,
        with_publish: withPublish,
      })
        .then((res) => {
          handleSuccessfulSave(res, true, withPublish);
        })
        .catch((err) => {
          handleFailedSave(err);
        });
    } else {
      sh.post("job_offer", {
        authenticity_token: authenticityToken,
        job_offer: job_offer,
        skills: skills,
        with_publish: withPublish,
      })
        .then((res) => {
          if (railsEnv === "production") trackJobOfferCreation();
          handleSuccessfulSave(res, true, withPublish);
        })
        .catch((err) => {
          handleFailedSave(err);
        });
    }
  };

  const showSnackbar = (message, variant, autoHideDuration) => {
    enqueueSnackbar(message, {
      variant: variant,
      autoHideDuration: autoHideDuration,
    });
  };

  const handleSuccessfulSave = (res, created, isPublishing) => {
    let status;
    if (res.data.result.publish) {
      if (res.data.result.publish_error) {
        const messageId = created
          ? "flash.success.job_offer_creation_without_publish"
          : "flash.success.job_offer_edition_without_publish";
        showSnackbar(
          intl.formatMessage({
            id: messageId,
          }),
          "warning",
          15000
        );

        status = "draft";
      } else {
        const messageId = created
          ? "flash.success.job_offer_creation_with_publish"
          : "flash.success.job_offer_edition_with_publish";
        showSnackbar(
          intl.formatMessage({
            id: messageId,
          }),
          "success",
          2000
        );
        updateCurrentRecruiter(res.data.recruiter);
        status = "online";
      }
    } else {
      const messageId = created
        ? "flash.success.job_offer_creation"
        : "flash.success.job_offer_edit";
      showSnackbar(
        intl.formatMessage({
          id: messageId,
        }),
        "success",
        2000
      );
      status = res.data.result.job_offer.offer_status;
    }

    if ((!jobOffer.status || jobOffer.status == "draft") && isPublishing) {
      status = "online";
    }

    setIsBeingSaved(true);
    setTimeout(() => {
      window.location.href = `${window.location.origin}/${i18nLocale}/recruiter/job_offers?selectedTab=${status}`;
    }, 500);
  };

  const updateCurrentRecruiter = useCallback(
    (recruiter) => {
      dispatch({ type: "UPDATE_CURRENT_RECRUITER", payload: recruiter });
    },
    [dispatch]
  );

  const handleFailedSave = (err) => {
    enqueueSnackbar(
      intl.formatMessage({
        id: "flash.unknown",
      }),
      { variant: "error" }
    );
  };

  useBlocker(() => {}, activeBlocker);

  useEffect(() => {
    if (queryJobOffer) {
      fetchJobOffer();
    }
  }, [queryJobOffer]);

  useEffect(() => {
    if (isBeingSaved) {
      setActiveBlocker(false);
    }
  }, [isBeingSaved]);
  return (
    <Container maxWidth="lg">
      <Grid
        container
        spacing={4}
        style={{ textAlign: "center", marginBottom: "32px" }}
      >
        <Grid item xs={12} style={{ marginTop: "16px", marginBottom: "16px" }}>
          <Typography variant="h2">
            <FormattedMessage id="recruiter.create_new_job_title" />
          </Typography>
        </Grid>

        {isEdit || !showWarning ? (
          <Grid item xs={12}>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{
                  paddingLeft: isDesktop ? "64px" : "0px",
                  paddingRight: isDesktop ? "64px" : "0px",
                }}
              >
                <JobOfferEdit
                  jobOffer={jobOffer}
                  setJobOffer={setJobOffer}
                  skills={skills}
                  setSkills={setSkills}
                  saveJobOffer={handleJobOfferSave}
                  setActiveBlocker={setActiveBlocker}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  style={{ marginTop: "16px", marginBottom: "32px" }}
                >
                  <FormattedMessage id="credits_box.no_job_offer_credits" />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <img
                  src={wipImg}
                  style={{ maxWidth: "280px", marginTop: "16px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="rounded"
                  color="primaryOutlined"
                  style={{
                    width: "240px",
                    marginTop: "16px",
                    marginBottom: "16px",
                  }}
                  onClick={() =>
                    (window.location.href = `/${i18nLocale}/recruiter/pricing`)
                  }
                >
                  <FormattedMessage id="credits_box.add_credits" />
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="rounded"
                  color="primaryContained"
                  style={{ width: "240px" }}
                  onClick={() =>
                    (window.location.href = `/${i18nLocale}/recruiter/job_offer_creation?draftMode=true`)
                  }
                >
                  <FormattedMessage id="job_offer.create_draft" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default JobOfferCreation;
