import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Button, Container, Grid } from "@mui/material";
import sh from "bundles/common/utils/sh";
import { handleLinkedinShare, handleCopyClick } from "bundles/common/utils/utils";
import CompanyView from "bundles/common/components/CompanyView";
import BackdropScreen from "bundles/common/components/BackdropScreen";
import { FormattedMessage, useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import { FacebookShareButton } from "react-share";
import Tooltip from "@material-ui/core/Tooltip";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

const CompanyPage = () => {
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const intl = useIntl();

  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const redirectTo = query.get("redirectTo");

  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState({});
  const [jobOffers, setJobOffers] = useState([]);
  const [projects, setProjects] = useState([]);

  const [jobCount, setJobCount] = useState(0);

  const fetchCompany = () => {
    setLoading(true);
    sh.get(
      `${i18nLocale}/company/${params?.id || currentRecruiter?.company?.id}`
    )
      .then((res) => {
        setCompany(res.data);
        setJobOffers(
          res?.data?.job_offers
            ?.filter(
              (j) => j.offer_status == "online" || j.offer_status == "closed"
            )
            .sort((a, b) => {
              // Compare offer_status in descending order
              if (a.offer_status < b.offer_status) return 1;
              if (a.offer_status > b.offer_status) return -1;

              // If offer_status is the same, compare published_at in descending order
              if (a.published_at < b.published_at) return 1;
              if (a.published_at > b.published_at) return -1;

              // If both offer_status and published_at are equal, no change in order
              return 0;
            })
        );
        setJobCount(
          res?.data?.job_offers?.filter((j) => j.offer_status == "online")
            ?.length || 0
        );
        setProjects(res.data.projects);
      })
      .catch((err) => {
        window.location.href = window.location.origin;
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const goBack = () => {
    if (redirectTo == "recruiters") {
      window.location.href = `/${i18nLocale}/recruiters`;
    } else if (redirectTo == "companiesIndex") {
      window.location.href = buildCompanyIndexUrl();
    } else {
      window.location.href = `/${i18nLocale}`;
    }
  };

  const buildCompanyIndexUrl = () => {
    let queryText = query.get("queryText");
    let selectedIndustries = query.get("selectedIndustries");
    let currentPage = query.get("page");
    let baseUrl = currentRecruiter?.id
      ? `/${i18nLocale}/trusted_companies`
      : `/${i18nLocale}/companies`;
    if (queryText && queryText != "") {
      baseUrl += `?queryText=${queryText}`;
    }
    if (selectedIndustries && selectedIndustries != "") {
      if (queryText && queryText != "") {
        baseUrl += "&";
      } else {
        baseUrl += "?";
      }
      baseUrl += `selectedIndustries=${selectedIndustries}`;
    }
    if (currentPage && currentPage != "") {
      if (
        (queryText && queryText != "") ||
        (selectedIndustries && selectedIndustries != "")
      ) {
        baseUrl += "&";
      } else {
        baseUrl += "?";
      }
      baseUrl += `currentPage=${currentPage}`;
    }

    return baseUrl;
  };

  const buttonLabel = () => {
    if (redirectTo === "recruiters") {
      return "company_card.back_to_landing";
    } else if (redirectTo === "companiesIndex") {
      return "company_card.back_to_companies";
    } else {
      return "company_card.back_to_landing";
    }
  };

  const publicCompanyUrl = `${window.location.origin}/${i18nLocale}/companies/${company?.encoded_name}/${params?.id}`;

  useEffect(() => {
    fetchCompany();
  }, []);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2} style={{ paddingBottom: "64px" }}>
        <BackdropScreen open={loading} />
        <Grid item xs={12} style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Grid item xs={6} style={{ flex: 1 }}>
            <Button
              size="small"
              variant="rounded"
              color="primaryOutlined"
              style={{ width: "200px", marginTop: "16px", marginBottom: "8px" }}
              onClick={() => goBack()}
            >
              <FormattedMessage id={buttonLabel()} />
            </Button>
          </Grid>

          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flex: 1,
              alignItems: "center",
            }}
          >
            <Box onClick={() => handleLinkedinShare(publicCompanyUrl)} style={{ cursor: "pointer", marginRight: "4px" }}>
              <LinkedInIcon style={{ fill: "#0077B5" }} size={32} round={true} />
            </Box>
            <Box style={{ marginRight: "4px" }}>
              <FacebookShareButton url={publicCompanyUrl}>
                <FacebookIcon style={{ fill: "#1877F2" }} size={32} round={true} />
              </FacebookShareButton>
            </Box>
            <Tooltip title={<FormattedMessage id="settings.copy_link" />}>
              <FileCopyOutlinedIcon style={{ cursor: "pointer" }} onClick={() => handleCopyClick(publicCompanyUrl, intl, enqueueSnackbar)} />
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <CompanyView
            company={company}
            jobCount={jobCount}
            jobOffers={jobOffers}
            projects={projects}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CompanyPage;
