import React from "react";
import { Dialog, Box, useMediaQuery } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import Authentication from "./Authentication";

const AuthenticationModal = ({
  isOpen,
  closeModal,
  customScope = "",
  section = "login",
  singleJob = true,
  addResumeLogin = false,
  addFavoriteLogin = false,
  isSpontaneous = false,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const useStyles = makeStyles(() => ({
    authenticationModal: {
      "& .MuiDialog-container": {
        "& .MuiDialog-paper": {
          margin: "16px !important",
          borderRadius: "30px",
        },
      },
    },
    modalContainer: {
      justifyContent: "center",
      maxWidth: "450px",
      borderRadius: "30px",
      padding: isDesktop ? "36px" : "32px",
    },
    modalBox: {
      height: "min-content",
    },
    headerBox: {
      textAlign: "center",
      marginBottom: "32px",
    },
    inputBox: {
      marginBottom: "16px",
    },
  }));
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.authenticationModal}
    >
      <Box className={classes.modalContainer}>
        <Box className={classes.modalBox}>
          <Authentication
            onActionFinished={closeModal}
            customScope={customScope}
            section={section}
            singleJob={singleJob}
            addResumeLogin={addResumeLogin}
            addFavoriteLogin={addFavoriteLogin}
            isSpontaneous={isSpontaneous}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default AuthenticationModal;
