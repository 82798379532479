import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import sh from "bundles/common/utils/sh";
import { replaceCurrentCandidate } from "bundles/Candidate/constants/candidateConstants";
import Preferences from "icons/preferences.svg";

const Unsubscribe = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const currentCandidate = useSelector((state) => state.current_candidate);
  const i18nLocale = useSelector((state) => state.i18nLocale);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();

  const unsubscribe = (token, emailDeliveryId) => {
    sh.put("candidate/unsubscribe", {
      authenticity_token: authenticityToken,
      data: {
        token: token,
        email_delivery_id: emailDeliveryId,
      },
    })
      .then(() => {
        if (currentCandidate?.id) {
          replaceCurrentCandidate(dispatch, authenticityToken, () => {});
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("Oops... Something went wrong", { variant: "error" });
      });
  };

  useEffect(() => {
    let token = searchParams.get("token");
    let emailDeliveryId = searchParams.get("email_delivery_id");
    if (token) {
      searchParams.delete("token");
      searchParams.delete("email_delivery_id");
      setSearchParams(searchParams);
      unsubscribe(token, emailDeliveryId);
    } else {
      window.location.href = `${window.location.origin}/${i18nLocale}`;
    }
  }, []);

  return (
    <Container
      maxWidth="lg"
      style={{
        minHeight: "inherit",
        paddingTop: "32px",
        paddingBottom: "32px",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          rowGap: "32px",
          minHeight: "inherit",
        }}
      >
        <Box>
          <Typography variant="body1bold">
            <FormattedMessage id="notification_preferences.unsubscribe_success" />
          </Typography>
        </Box>
        <Box style={{ textAlign: "center" }}>
          <img
            style={{ width: isDesktop ? "60%" : "100%" }}
            src={Preferences}
          />
        </Box>
        <Box>
          {currentCandidate?.id ? (
            <Button
              variant="rounded"
              color="primaryContained"
              onClick={() => {
                navigate({
                  pathname: `/${i18nLocale}/candidate/settings`,
                  search: "?selectedSection=notifications",
                });
              }}
            >
              <FormattedMessage id="nav.my_profile" />
            </Button>
          ) : (
            <Button
              variant="rounded"
              color="primaryContained"
              onClick={() => {
                window.location.href = `${window.location.origin}/${i18nLocale}`;
              }}
            >
              <FormattedMessage id="go_homepage" />
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Unsubscribe;
