import React, { useRef } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Box, useMediaQuery, useTheme } from "@mui/material";
import Pagination from "@mui/lab/Pagination";
import JobOfferCard from "bundles/Candidate/components/JobOfferCard";

const useStyles = makeStyles(() => ({
  scrollArea: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

const JobOfferList = ({
  offers,
  activeOffer,
  setPage,
  page,
  pageCount,
  handleOfferClick,
  columnPerRow = 1,
  redirect = false,
  logoHoverAndRedirect = false,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const topElementRef = useRef(null);

  const scrollToTop = () => {
    topElementRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    scrollToTop();
  };

  return (
    <Grid
      container
      spacing={2}
      style={{ marginBottom: "16px", marginTop: "16px" }}
      id="candidate-list-container"
    >
      <Grid item xs={12}>
        <Grid
          {...(isDesktop && {
            style: {
              WebkitOverflow: "auto",
              overflow: "auto",
              height: "fit-content",
              paddingBottom: "32px",
              overflowX: "hidden",
            },
          })}
          container
          spacing={3}
          alignItems="stretch"
          justifyContent="center"
          className={classes.scrollArea}
        >
          {offers?.length > 0 &&
            offers.map((offer, i) => (
              <Grid
                item
                xs={12 / columnPerRow}
                key={`job-${i}`}
                style={{
                  paddingTop: i == 0 && columnPerRow == 1 ? "8px" : "16px",
                  paddingBottom: "0px",
                }}
                ref={i === 0 ? topElementRef : null}
              >
                <JobOfferCard
                  offer={offer}
                  active={activeOffer && activeOffer.id === offer.id}
                  key={offer.id}
                  redirect={redirect}
                  onClick={handleOfferClick}
                  logoHoverAndRedirect={logoHoverAndRedirect}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
      {setPage && (
        <Grid item xs={12}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            alignContent="center"
          >
            <Box mt={2}>
              <Pagination
                size="large"
                onChange={handlePageChange}
                count={pageCount}
                page={page}
                variant="outlined"
                color="secondary"
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default JobOfferList;
