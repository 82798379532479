import React from "react";
import { useTheme, makeStyles } from "@mui/styles";
import { Box, Chip, Grid, Typography } from "@mui/material";
import { useIntl } from "react-intl";

const ChipSelection = ({
  values,
  maxSelection,
  backgroundColor,
  color,
  size = "small",
  category = "",
  onDelete,
}) => {
  const intl = useIntl();
  const isLarge = size != "small";
  const theme = useTheme();
  const useStyles = makeStyles(() => ({
    container: {
      border: `1px solid ${theme.palette.common.sh_grey}`,
      borderRadius: "4px",
      width: "100%",
      height: isLarge ? "120px" : "56px",
      display: "flex",
      alignItems: isLarge ? "start" : "center",
      paddingTop: isLarge ? "8px" : "0px",
      paddingBottom: isLarge ? "8px" : "0px",
      paddingLeft: "8px",
      paddingRight: "8px",
      overflowX: isLarge ? "hidden" : "auto",
      flexWrap: "wrap",
    },
    chip: {
      height: "48px",
      borderRadius: "30px",
      margin: "4px",
      backgroundColor: `${backgroundColor} !important`,
      color: `${color} !important`,
      "& > .MuiChip-deleteIcon": {
        color: `${color} !important`,
      },
    },
  }));
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box className={classes.container}>
          {values?.map((value, i) => {
            console.log(values, value);
            return (
              <Chip
                key={`${value.label}`}
                label={value?.label}
                className={classes.chip}
                style={{
                  marginLeft: i > 0 ? "8px" : "0px",
                }}
                onDelete={() => onDelete(value)}
              />
            );
          })}
        </Box>
      </Grid>
      {maxSelection && (
        <Grid item xs={12} align="right">
          <Typography>
            {values?.length || 0} / {maxSelection}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ChipSelection;
