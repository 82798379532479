import React, { useState } from "react";
import { Box, Select, InputBase, MenuItem } from "@mui/material";
import { makeStyles, withStyles, useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import sh from "bundles/common/utils/sh";

const useStyles = makeStyles(() => ({
  notActiveStyle: {
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)",
    color: "rgba(42, 51, 78, 0.5)",
    fontWeight: 400,
  },
  activeStyle: {
    fontWeight: "bolder",
    border: "1px solid #000",
    backgroundColor: "inherit",
    padding: "5px 10px",
    color: "#000",
  },
}));

export default function SelectLanguage({ initLocale = "fr", inline = false }) {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const currentCandidate = useSelector((state) => state.current_candidate);
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const [locale, setLocale] = useState(i18nLocale || initLocale);
  const theme = useTheme();
  const classes = useStyles();

  const CustomSelect = withStyles(() => ({
    root: {
      fontSize: 14,
      borderRadius: 5,
      border: "1px solid #000",
      "&:focus": { backgroundColor: "#00000000" },
      zIndex: 1,
      justifyContent: "center",
      padding: "5px 10px!important",
    },
    icon: {
      // color: "white",
      display: "none",
    },
  }))(Select);

  const handleMobileLanguageSelection = (language) => {
    handleLanguageSelection(language);
  };

  const handleDesktopLanguageSelection = (event) => {
    const language = event.target.value;
    handleLanguageSelection(language);
  };

  const redirectToPreferredLocale = (language) => {
    if (window) {
      let path = window.location?.pathname?.split("/");
      let oldLocale = path?.[1];
      if (languages.includes(oldLocale)) {
        window.location.href = `${window.location.origin}/${language}/${path
          .slice(2)
          .join("/")}${window.location.search}`;
      }
    }
  };

  const handleLanguageSelection = (language) => {
    setLocale(language);
    if (currentRecruiter) {
      sh.put("recruiter", {
        authenticity_token: authenticityToken,
        recruiter: {
          id: currentRecruiter.id,
          preferred_locale: language,
        }
      }).then(() => {
        redirectToPreferredLocale(language);
      }).catch((err) => {
        console.error(err);
      });;
    } else if (currentCandidate) {
      sh.put("candidate", {
        authenticity_token: authenticityToken,
        candidate: {
          id: currentCandidate.id,
          preferred_locale: language,
        }
      }).then(() => {
        redirectToPreferredLocale(language);
      }) .catch((err) => {
        console.error(err);
      });;
    } else {
      redirectToPreferredLocale(language);
    }
  };

  const languages = ["fr", "en", "nl"];
  return inline ? (
    <Box
      style={{ display: "flex", width: "100%", justifyContent: "space-evenly" }}
    >
      {languages.map((language) => {
        return (
          <Box
            key={`key-${language}`}
            style={{
              textTransform: "uppercase",
              backgroundColor: theme.palette.common.white,
              padding: "4px 12px",
              border:
                language == i18nLocale
                  ? `1px solid ${theme.palette.secondary.main}`
                  : "",
              borderRadius: "5px",
              cursor: "pointer",
              fontWeight: language === i18nLocale ? "900" : "400",
            }}
            onClick={() => handleMobileLanguageSelection(language)}
          >
            {language}
          </Box>
        );
      })}
    </Box>
  ) : (
    <CustomSelect
      id="language-select"
      value={locale}
      onChange={handleDesktopLanguageSelection}
      // IconComponent={ExpandMoreIcon}
      input={
        <InputBase style={{ textTransform: "uppercase", fontWeight: "700" }} />
      }
    >
      {languages.map((language) => (
        <MenuItem
          value={language}
          key={language}
          className={
            language === locale ? classes.activeStyle : classes.notActiveStyle
          }
          style={{
            justifyContent: "center",
            textTransform: "uppercase",
            margin: "0 10px",
            marginBottom: "10px",
            borderRadius: 5,
            fontSize: 14,
          }}
          disableGutters
        >
          {language}
        </MenuItem>
      ))}
    </CustomSelect>
  );
}
