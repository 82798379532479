import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Grid, Typography, Box, Divider, Button, Modal } from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import TrainingView from "bundles/common/components/TrainingView";
import EditTrainingModal from "./EditTrainingModal";
import { sortDateRange } from "bundles/common/utils/utils";

const Training = ({ completionCallback }) => {
  const currentCandidate = useSelector((state) => state.current_candidate);
  const sortedTrainings = sortDateRange(currentCandidate?.trainings);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedTraining, setSelectedTraining] = useState(null);

  const handleNewTrainingClicked = (e) => {
    e.preventDefault();
    setSelectedTraining(null);
    setIsEditModalOpen(true);
  };

  const handleTrainingClicked = (training) => {
    setSelectedTraining(training);
    setIsEditModalOpen(true);
  };

  return (
    <Grid container style={{ height: "100%" }}>
      <Grid item xs={12} style={{ marginBottom: "16px" }}>
        <Typography variant="h4">
          <FormattedMessage id="settings.precise_training" />
        </Typography>
      </Grid>
      {sortedTrainings?.length > 0 && (
        <Grid item xs={12}>
          {sortedTrainings?.map((training, i) => (
            <Box key={`training-${i}`}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <Box>
                      <TrainingView
                        training={training}
                        editable={true}
                        onEdit={handleTrainingClicked}
                      />
                      <Divider
                        style={{ marginBottom: "16px", marginTop: "16px" }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Grid>
      )}
      <Grid item xs={12} textAlign="center">
        <Button
          variant="rounded"
          color="primaryContained"
          style={{ marginTop: "8px" }}
          onClick={handleNewTrainingClicked}
        >
          <ControlPointIcon style={{ marginRight: "8px" }} />
          <FormattedMessage id="settings.add_training" />
        </Button>
      </Grid>

      <Modal
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
        }}
      >
        <EditTrainingModal
          closeModal={() => setIsEditModalOpen(false)}
          training={selectedTraining}
          completionCallback={completionCallback}
        />
      </Modal>
    </Grid>
  );
};

export default Training;
