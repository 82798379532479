import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Container,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import Pagination from "@mui/lab/Pagination";
import PaperBox from "bundles/common/components/PaperBox";
import CompanyCard from "bundles/Recruiter/components/CompanyCard";
import MobileAdvancedFilteringCompany from "./MobileAdvancedFilteringCompany";
import MobileChurchBar from "bundles/common/components/Mobile/MobileChurchBar";
import BackdropScreen from "bundles/common/components/BackdropScreen";

const CompanyIndexMobile = ({
  count,
  page,
  queryText,
  setQueryText,
  getCompanyUrl,
  fetchCompanies,
  selectedIndustries,
  setSelectedIndustries,
  clearAdvancedFilters,
  loading,
  companies,
  pageCount,
  setPage,
}) => {
  const intl = useIntl();

  const CompanyCount = () => (
    <PaperBox>
      <Typography variant="h3">
        <b>
          {intl.formatMessage(
            { id: "company_search.n_results" },
            { result: count }
          )}{" "}
        </b>
      </Typography>
    </PaperBox>
  );

  return (
    <Container maxWidth="lg">
      <Grid container spacing={4} style={{ marginBottom: "50px" }}>
        <Grid
          item
          xs={12}
          justifyContent="center"
          style={{ marginTop: "32px" }}
        >
          <Typography
            align="center"
            variant="h1"
            style={{ fontFamily: "Work Sans", fontSize: "24px", fontWeight: "bold" }}
          >
            <b>
              <FormattedMessage id="company_index.companies" />
            </b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <>
            <MobileChurchBar
              queryText={queryText}
              setQueryText={setQueryText}
              fetchRecords={fetchCompanies}
              placeholder="landing_candidate.searched_jobs"
            />
            <MobileAdvancedFilteringCompany
              selectedIndustries={selectedIndustries}
              setSelectedIndustries={setSelectedIndustries}
              clearAdvancedFilters={clearAdvancedFilters}
            />
          </>
        </Grid>
        {loading && (
          <Grid item xs={12}>
            <BackdropScreen open={loading} />
          </Grid>
        )}
        <Grid item xs={12} md={3} >
          <CompanyCount />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            {companies.map((company) => (
              <Grid
                item
                xs={12}
                key={`${company.id}--companie`}
              >
                <CompanyCard
                  company={company}
                  onClick={() => {
                    window.location.href = getCompanyUrl(company);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        {setPage && (
          <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              alignContent="center"
            >
              <Box mt={2}>
                <Pagination
                  size="large"
                  onChange={(e, page) => setPage(page)}
                  count={pageCount}
                  page={page}
                  variant="outlined"
                  color="secondary"
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default CompanyIndexMobile;
