import React, { useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/styles";
import { Box, useMediaQuery } from "@mui/material";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const SignupRecruiter = ({ redirectTo }) => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [step, setStep] = useState(1);
  const [recruiterData, setRecruiterData] = useState({});
  const [companyData, setCompanyData] = useState({});

  const nextStep = () => {
    if (step === 3 && (query.get("redirectTo") || redirectTo)) {
      let redirectUrl =
        query.get("redirectTo") ||
        `${window.location.origin}/${i18nLocale}/${redirectTo}`;

      window.location.href = redirectUrl;
    } else {
      setStep(step + 1);
    }
  };

  const redirectDashboard = () => {
    let redirectUrl =
      query.get("redirectTo") ||
      `${window.location.origin}/${i18nLocale}/${redirectTo}`;

    let shouldRedirect = query.get("redirectTo") || redirectTo;
    window.location.href = shouldRedirect
      ? redirectUrl
      : `${window.location.origin}/${i18nLocale}/recruiter`;
  };

  const onSignUpFinished = () => {
    enqueueSnackbar(
      intl.formatMessage({
        id: "devise.signup.welcome_to_squarehub",
      }),
      {
        variant: "success",
        autoHideDuration: 1500,
        onExit: redirectDashboard,
      }
    );
  };

  return (
    <Box
      style={{
        width: isDesktop ? "500px" : "100%",
        height: "100%",
        background: "#FFFFFF",
        padding: isDesktop ? "64px" : "16px",
        paddingTop: isDesktop ? "48px" : "16px",
        paddingBottom: isDesktop ? "48px" : "16px",
        overflow: "auto",
        color: theme.palette.secondary.main,
      }}
    >
      {step === 1 && (
        <StepOne setRecruiterData={setRecruiterData} nextStep={nextStep} />
      )}
      {step === 2 && (
        <StepTwo
          recruiterData={recruiterData}
          setRecruiterData={setRecruiterData}
          setCompanyData={setCompanyData}
          nextStep={nextStep}
        />
      )}
      {step === 3 && (
        <StepThree companyData={companyData} nextStep={onSignUpFinished} />
      )}
    </Box>
  );
};

export default SignupRecruiter;
