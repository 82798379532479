import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import {
  Box,
  Typography,
  Button,
  Dialog,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import sh from "bundles/common/utils/sh";
import PictureDropzone from "bundles/common/components/PictureDropzone";
import ImageCropper from "bundles/common/components/ImageCropper";
import { pictureRatios } from "bundles/common/utils/constants";
import { uploadPicture } from "bundles/common/utils/uploadProfilePicture";
import { replaceCurrentRecruiter } from "bundles/Recruiter/constants/recruiterConstants";
import { useDropzone } from 'react-dropzone';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Close as CloseIcon } from "@mui/icons-material";

const ProjectMediaFields = ({ project, closeModal, handleBack }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const intl = useIntl();
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [coverPicture, setCoverPicture] = useState(null);
  const [coverPictureUrl, setCoverPictureUrl] = useState(project?.cover_picture_url);
  const [generalInfoErrors, setGeneralInfoErrors] = useState({});
  const [isCropModalOpened, setIsCropModalOpened] = useState(false);
  const [isCropped, setIsCropped] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleManualCrop = () => {
    setIsCropModalOpened(true);
  };

  useEffect(() => {
    if (coverPicture && typeof coverPicture !== "string") {
      uploadPicture(
        coverPicture,
        project.id,
        "projects",
        "cover_picture",
        authenticityToken
      )
        .then((res) => {
          setCoverPictureUrl(res.project.cover_picture_url);
          if (isCropped) {
            setIsCropped(false);
            setIsCropModalOpened(false);
            enqueueSnackbar(
              intl.formatMessage({ id: "project.cover_picture_uploaded" }),
              {
                variant: "success",
              }
            );
          } else {
            setIsCropModalOpened(true);
          }
          replaceCurrentRecruiter(dispatch, authenticityToken);
        })
        .catch((err) => {
          enqueueSnackbar(intl.formatMessage({ id: "settings.save_failed" }), {
            variant: "error",
          });
        });
    }
  }, [coverPicture]);

  const [galleryPictures, setGalleryPictures] = useState(project?.gallery_pictures_records || []);
  const [newGalleryPictures, setNewGalleryPictures] = useState([]);
  const [justUploadedPictures, setJustUploadedPictures] = useState([]);

  const handleGalleryPicturesChange = (files) => {
    const newPicturesCount = files.length + galleryPictures.length;
    const remainingSlots = Math.max(0, 13 - newPicturesCount);

    if (remainingSlots === 0) {
      enqueueSnackbar(
        intl.formatMessage({ id: "project.12_pictures_max" }),
        { variant: "error" }
      );
      return
    }
    const newPictures = files.filter((file) => !justUploadedPictures.includes(file));
    setNewGalleryPictures(newPictures);
  };

  useEffect(() => {
    const uploadGalleryPictures = async () => {
      let uploadedCount = 0;
      const totalPictures = newGalleryPictures.length;

      for (const picture of newGalleryPictures) {
        try {
          const res = await uploadPicture(
            picture,
            project.id,
            "projects",
            "gallery_pictures",
            authenticityToken
          );
          setGalleryPictures(res.project.gallery_pictures_records);
          setJustUploadedPictures((prevPictures) => [...prevPictures, picture]);
          replaceCurrentRecruiter(dispatch, authenticityToken);
          uploadedCount++;

          if (uploadedCount === totalPictures) {
            enqueueSnackbar(
              intl.formatMessage({ id: "project.gallery_picture_uploaded" }),
              {
                variant: "success",
              }
            );
          }
        } catch (err) {
          enqueueSnackbar(intl.formatMessage({ id: "settings.save_failed" }), {
            variant: "error",
          });
        }
      }
    };

    if (newGalleryPictures.length > 0) {
      uploadGalleryPictures();
    }
  }, [newGalleryPictures]);

  const handlePictureDelete = (picture) => {
    const params = {
      project,
      authenticity_token: authenticityToken,
      picture: {
        id: picture.id,
      },
      attachment: {
        field_name: "gallery_pictures",
      },
    };
    sh.put("/purge_gallery_picture", params)
      .then(() => {
        setGalleryPictures((prevPictures) =>
          prevPictures.filter((p) => p.picture !== picture)
        );
        replaceCurrentRecruiter(dispatch, authenticityToken);
      })
  };

  const closeModalandNotification = () => {
    closeModal();
    enqueueSnackbar(
      intl.formatMessage({ id: "settings.save_success" }),
      {
        variant: "success",
      }
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: [".png", ".jpeg", ".jpg", ".svg+xml"],
    onDrop: (acceptedFiles) => {
      handleGalleryPicturesChange(acceptedFiles);
    },
    maxFiles: 12,
  });

  return (
    <>
      <Box style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%"}}>
        <Box style={{ display: "flex", gap: "40px", margin: "24px 0" }}>
          <Box style={{ width: "50%" }}>
            <Typography variant="h5" style={{ marginBottom: "16px" }}>
              <FormattedMessage id="settings.cover_picture" />
            </Typography>
            <PictureDropzone
              handleFileInput={(file) => {
                setCoverPicture(file);
                setGeneralInfoErrors({
                  ...generalInfoErrors,
                  cover_picture: "",
                });
              }}
              filesLimit={1}
              maxFileSize={2048000}
              backgroundSize="cover"
              pictureUrl={coverPictureUrl}
              width="100%"
              height="140px"
              type="recruiter"
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="body3" textAlign="right">
                  <FormattedMessage id="settings.recommended_size" />{" "}
                  1000x350
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="textUnderline"
                  onClick={handleManualCrop}
                  size="xsmall"
                  style={{ padding: "0" }}
                >
                  <Typography variant="body3" textAlign="right">
                    <FormattedMessage id="image_cropper.title" />
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
          <Box style={{ width: "50%" }}>
            <Typography variant="h5" style={{ marginBottom: "16px" }}>
              <FormattedMessage id="project.gallery" />
            </Typography>
            <Box style={{ display: "flex", columnGap: "8px" }}>
              <Box style={{ width: "20%" }}>
                <Box {...getRootProps()} display="flex" alignItems="center">
                  <input {...getInputProps()} disabled={galleryPictures.length === 12} />
                  {galleryPictures.length < 12 && (
                    <AddCircleIcon
                      fontSize="large"
                      sx={{
                        color: theme.palette.secondary.main,
                        fontSize: 50,
                        cursor: 'pointer',
                       }}
                    />
                  )}
                </Box>
                {galleryPictures.length === 12 && (
                  <FormattedMessage id="project.maximum_pictures_reached" />
                )}
              </Box>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "20px",
                  flexDirection: galleryPictures.length === 0 ? "column" : "row",
                  justifyContent: galleryPictures.length === 0 ? "center" : "flex-start",
                  marginLeft: galleryPictures.length === 0 ? "-40px" : "0px"
                }}
              >
                {galleryPictures.length === 0 ? (
                  <Typography variant="body1">
                    <FormattedMessage id="project.add_gallery_pictures" />
                  </Typography>
                ) : (
                  galleryPictures.map((record, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          position: "relative",
                          flexBasis: "20%",
                          height: "80px",
                          "& img": {
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          },
                          "& .delete-button": {
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                            background: "rgba(255, 255, 255, 0.8)",
                            borderRadius: "50%",
                            width: "14px",
                            height: "14px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            color: "rgba(0, 0, 0, 0.6)"
                          },
                        }}
                      >
                        <img src={record.url} alt={`Gallery Picture ${index}`} />
                        <Box
                          className="delete-button"
                          onClick={() => handlePictureDelete(record.picture)}
                        >
                          <CloseIcon sx={{ fontSize: "16px" }} />
                        </Box>
                      </Box>
                    );
                  })
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Grid container spacing={isDesktop ? 4 : 2}>
          <Grid item xs={12} md={6}>
            <Button onClick={handleBack}>
              <FormattedMessage id="back" />
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="submit"
                fullWidth={false}
                variant="rounded"
                color="secondaryContained"
                disabled={loading}
                loading={loading}
                onClick={closeModalandNotification}
                sx={{ width: '50%' }}
              >
                <FormattedMessage id="save" />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={isCropModalOpened}
        onClose={() => setIsCropModalOpened(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth="xl"
        maxWidth
      >
        <ImageCropper
          ratio={pictureRatios.coverPicture}
          src={coverPictureUrl}
          filename={
            coverPictureUrl ? coverPictureUrl.split("filename=")[1] || `cover_${project?.title}` : `cover_${project?.title}`
          }
          closeModal={() => setIsCropModalOpened(false)}
          handleNewImg={(img, callback) => {
            setIsCropped(true);
            setCoverPicture(img[0]);
            setGeneralInfoErrors({
              ...generalInfoErrors,
              cover_picture: "",
            });
            if (callback) {
              setTimeout(() => {
                callback();
              }, 1500);
            }
          }}
        />
      </Dialog>
    </>
  );
};

export default ProjectMediaFields;
