import React, { forwardRef, useState } from "react";
import { SnackbarContent } from "notistack";
import { Box, Button, Typography, TextField, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import PersonIcon from "@mui/icons-material/Person";

const useStyles = makeStyles(() => ({
  root: {
    "@media (min-width:600px)": {
      width: "601px !important",
      height: "90px !important",
    },
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    border: "1px solid #2A334E",
    padding: "8px 16px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
  },
  typography: {
    color: "#2A334E",
  },
  whiteInput: {
    "& .MuiInputBase-input": {
      color: "#2A334E",
      fontSize: "14px",
      width: "140px",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#2A334E",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#2A334E",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: "#2A334E",
    },
    "& .MuiInputLabel-root": {
      color: "#2A334E",
      fontSize: "14px",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#2A334E",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#2A334E",
      },
      "&:hover fieldset": {
        borderColor: "#2A334E",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#2A334E",
      },
    },
  },
  whiteIcon: {
    color: "#2A334E",
  },
  button: {
    minWidth: 0,
    borderRadius: "30px",
    padding: "5px 10px",
    borderColor: "#2A334E",
    height: "32px",
    color: "#fff",
  },
  upperLine: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  percentage: {
    color: "#079344",
  },
}));

const CandidateQuestion = forwardRef(
  ({ id, message, questionData, onSelectValue }, ref) => {
    const classes = useStyles();
    const [numberInput, setNumberInput] = useState("");
    const [isSending, setIsSending] = useState(false);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    const handleSend = () => {
      if (!isSending && numberInput !== "") {
        setIsSending(true);
        onSelectValue(numberInput, id);
      }
    };

    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleSend();
      }
    };

    const handleInputChange = (e) => {
      const inputValue = e.target.value;
      setNumberInput(inputValue);
    };

    return (
      <SnackbarContent ref={ref} className={classes.root}>
        <Box
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <Box className={classes.upperLine}>
            <Typography className={classes.typography} fontWeight="bold">
              {message}
            </Typography>
            <Typography className={classes.percentage}>
              <Box display="flex" alignItems="center">
                +{questionData.percentage}%
                <PersonIcon style={{ marginLeft: "5px" }} />
              </Box>
            </Typography>
          </Box>
          {questionData.type === "boxSelect" && (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                columnGap: "15px",
              }}
            >
              {questionData.questionValues.map((value, index) => (
                <Button
                  key={index}
                  onClick={() => onSelectValue(value.value, id)}
                  variant="outlined"
                  className={classes.button}
                >
                  <Typography
                    className={classes.typography}
                    style={{
                      textTransform: "none",
                      fontSize: isDesktop ? "14px" : "8px",
                    }}
                  >
                    {value.label}
                  </Typography>
                </Button>
              ))}
            </Box>
          )}{" "}
          {questionData.type === "numberInput" && (
            <Box style={{ display: "flex", justifyContent: "flex-start" }}>
              <TextField
                value={numberInput}
                label={questionData.inputLabel}
                className={classes.whiteInput}
                InputProps={{
                  inputProps: { min: 0, max: 100 },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        className={classes.whiteIcon}
                        onClick={handleSend}
                        disabled={isSending}
                      >
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                type="number"
                variant="outlined"
                size="small"
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
            </Box>
          )}
        </Box>
      </SnackbarContent>
    );
  }
);

CandidateQuestion.displayName = "CandidateQuestion";

export default CandidateQuestion;
