import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  List,
  ListItem,
  Checkbox,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { FormattedMessage } from "react-intl";
import {
  companyIndustryMap,
} from "bundles/common/utils/constants";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "32px 24px",
  },
  item: {
    cursor: "pointer",
    alignItems: "center",
    padding: "0px 0px 0px 16px !important",
  },
  checkboxes: {
    padding: "0px",
  },
  filterTitle: {
    color: theme.palette.common.blue,
  },
}));

const AdvancedFilteringCompany = ({
  selectedIndustries,
  setSelectedIndustries,
  clearAdvancedFilters,
}) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleIndustries = (industry) => {
    if (selectedIndustries.includes(industry)) {
      setSelectedIndustries(selectedIndustries.filter((a) => a != industry));
    } else {
      setSelectedIndustries(selectedIndustries.concat(industry));
    }
  };

  const onDelete = (filter, filterType) => {
    if (filterType == "industry") {
      setSelectedIndustries(selectedIndustries.filter((a) => a != filter));
    }
  };

  const hasActiveFilters =
    selectedIndustries.length > 0;

  return (
    <Box id="sh-advanced-filtering">
      <Box display="flex" justifyContent="center">
        <Button
          variant="textUnderline"
          style={{
            marginTop: "8px",
            marginBottom: "4px",
          }}
          onClick={() => setExpanded(!expanded)}
        >
          <Box style={{ display: "flex", alignItems: "end" }}>
            <FormattedMessage id="job_search.advanced_search" />
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
        </Button>
      </Box>
      {expanded && (
        <Box display="flex">
          <Grid container component={Paper} className={classes.container}>
            <Grid item xs={12}>
              <Typography variant="body1bold" className={classes.filterTitle}>
                <b>
                  <FormattedMessage id="candidate.desired_sector" />
                </b>
              </Typography>
              <List style={{ display: "flex", paddingTop: "25px" }}>
                {companyIndustryMap.map((industry) => {
                  return (
                    <ListItem
                      key={industry}
                      className={classes.item}
                      onClick={() => handleIndustries(industry)}
                    >
                      <Checkbox
                        edge="start"
                        checked={selectedIndustries.includes(industry)}
                        className={classes.checkboxes}
                      />
                      <Typography>
                        <FormattedMessage
                          id={`company.company_industry.${industry}`}
                        />
                      </Typography>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                disabled={!hasActiveFilters}
                variant="textUnderline"
                style={{
                  marginTop: "8px",
                  marginBottom: "4px",
                }}
                onClick={() => clearAdvancedFilters()}
              >
                <FormattedMessage id="job_search.clear_filters" />
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      {!expanded && hasActiveFilters && (
        <Box>
          <Box display="flex" columnGap="8px" alignItems="center">
            {selectedIndustries.map((industry) => {
              return (
                <Chip
                  key={`${industry}`}
                  label={
                    <FormattedMessage
                      id={`company.company_industry.${industry}`}
                    />
                  }
                  className={classes.chip}
                  style={{}}
                  onDelete={() => onDelete(industry, "industry")}
                />
              );
            })}
            <Button
              variant="textUnderline"
              onClick={() => clearAdvancedFilters()}
            >
              <FormattedMessage id="job_search.clear_filters" />
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AdvancedFilteringCompany;
