import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import moment from "moment";
import {
  Grid,
  Typography,
  Paper,
  Button,
  Box,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { makeStyles, useTheme } from "@mui/styles";
import SkillView from "bundles/Recruiter/components/SkillView";
import MetricsGrid from "bundles/common/components/Desktop/MetricsGridDesktop";
import HTML from "bundles/common/components/HTML";
import {
  getOfferStatus,
  truncate,
  annualyRemuneration,
  handleLinkedinShare,
  handleCopyClick,
} from "bundles/common/utils/utils";
import ConfirmModal from "bundles/common/components/ConfirmModal";
import sh from "bundles/common/utils/sh";
import { formatJobOfferLocation, getDuration } from "../../common/utils/utils";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { FacebookShareButton } from "react-share";

const useStyles = makeStyles(theme => ({
  header: {
    padding: theme.spacing(2),
    zIndex: 0,
    margin: "auto",
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: "10px 10px 0px 0px !important",
    minHeight: "56px !important",
  },
  content: {
    padding: "16px 32px !important",
    margin: "auto !important",
    borderRadius: "0px 0px 10px 10px !important",
  },
  statusBox: {
    textAlign: "center !important",
    color: theme.palette.common.white,
  },
}));

const TextBlock = ({ titleId, text }) => {
  return (
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        <b>
          <FormattedMessage id={titleId} />
        </b>
      </Typography>
      <HTML html={text} />
    </Grid>
  );
};

const JobOfferSummary = ({
  jobOffer,
  skills,
  isEdit = false,
  saveJobOffer = null,
  showNavigationMessage = () => {},
}) => {
  const authenticityToken = useSelector(state => state.authenticity_token);
  const i18nLocale = useSelector(state => state.i18nLocale);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  const hard_skills = skills?.filter(skill => skill.category == "hard");
  const soft_skills = skills?.filter(skill => skill.category == "soft");
  const languages = skills?.filter(skill => skill.category == "lang");

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const getCustomOfferStatus = () => {
    let message = getOfferStatus(jobOffer, 1000);
    return (
      <Typography className={`${classes.statusBox}`}>
        <FormattedMessage id={message.id} values={message.values} />
      </Typography>
    );
  };

  const handleEditClicked = () => {
    if (isEdit && saveJobOffer) {
      saveJobOffer(false);
    } else {
      window.location.href = `/${i18nLocale}/recruiter/job_offer_creation?jobOffer=${jobOffer.id}`;
    }
  };

  const handleDuplicateClicked = () => {
    sh.get(`recruiter/duplicate_job_offers/${jobOffer.id}`, {
      authenticity_token: authenticityToken,
    })
      .then(res => {
        window.location.href = `/${i18nLocale}/recruiter/job_offer_creation?jobOffer=${res.data.data.id}`;
      })
      .catch(() => {
        enqueueSnackbar(
          intl.formatMessage({
            id: "flash.unknown",
          }),
          { variant: "error" }
        );
      });
  };

  const handleDeleteClicked = () => {
    setIsConfirmModalOpen(true);
  };

  const handleDeleteConfirmed = () => {
    showNavigationMessage(false);
    if (jobOffer.id) {
      let target =
        jobOffer.offer_status == "" || jobOffer.offer_status == "draft"
          ? "draft"
          : "closed";

      let action = target == "draft" ? "deleted" : "closed";
      sh.delete(`job_offer/${jobOffer.id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          authenticity_token: authenticityToken,
        },
      })
        .then((response) => {
          const days_left = response.data.days_left;
          sh.post(`job_offers/${jobOffer.id}/notify_admins_about_deletion`, {
            authenticity_token: authenticityToken,
          }).then(() => {
            enqueueSnackbar(`Your ad has been succesfully ${action}. Your credit is still available for ${days_left} day(s).`, {
              variant: "success",
            });
            setTimeout(() => {
              window.location.href = `/${i18nLocale}/recruiter/job_offers?selectedTab=${target}`;
            }, 1200);
          }).catch(() => {
            enqueueSnackbar(
              intl.formatMessage({
                id: "flash.unknown",
              }),
              { variant: "error" }
            );
          });
        })
        .catch(() => {
          enqueueSnackbar(
            intl.formatMessage({
              id: "flash.unknown",
            }),
            { variant: "error" }
          );
        });
    } else {
      setTimeout(() => {
        window.location.href = `/${i18nLocale}/recruiter/job_offers?selectedTab=online`;
        enqueueSnackbar("Your ad has been succesfully deleted", {
          variant: "success",
        });
      }, 100);
    }
  };

  const handlePublishClicked = () => {
    if (saveJobOffer) saveJobOffer(true);
  };

  const publicJobUrl = `${window.location.origin}/${i18nLocale}/jobs/${jobOffer?.encoded_job_title}/${jobOffer?.id}`;

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          component={Paper}
          elevation={0}
          className={classes.header}
        >
          <Grid container justifyContent={isDesktop ? "flex-start" : "center"}>
            <Grid item style={{ flexGrow: 1 }}>
              {getCustomOfferStatus()}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          component={Paper}
          elevation={0}
          className={classes.content}
        >
          <Grid container style={{ paddingBottom: "32px", textAlign: "left" }}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={8}>
                  <Typography
                    variant="subtitle2"
                    style={{
                      fontSize: "24px",
                      fontFamily: "Work Sans Bold",
                      color: theme.palette.text.blue,
                    }}
                  >
                    <b>{jobOffer?.job_title}</b>
                  </Typography>
                  {jobOffer?.locations?.map(location => {
                    return (
                      <Typography
                        key={Object.values(location).join("-")}
                        variant="subtitle2"
                        style={{
                          fontSize: "16px",
                          fontFamily: "Work Sans Bold",
                          color: theme.palette.text.blue,
                          textTransform: "capitalize",
                        }}
                      >
                        <b>{formatJobOfferLocation(location)}</b>
                      </Typography>
                    );
                  })}
                </Grid>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    flex: 1,
                    alignItems: "center",
                  }}
                >
                  {jobOffer.offer_status !== "draft" && (
                    <>
                      <Box
                        onClick={() => handleLinkedinShare(publicJobUrl)}
                        style={{ cursor: "pointer", marginRight: "4px" }}
                      >
                        <LinkedInIcon
                          style={{ fill: "#0077B5" }}
                          size={32}
                          round={true}
                        />
                      </Box>
                      <Box style={{ marginRight: "4px" }}>
                        <FacebookShareButton url={publicJobUrl}>
                          <FacebookIcon
                            style={{ fill: "#1877F2" }}
                            size={32}
                            round={true}
                          />
                        </FacebookShareButton>
                      </Box>
                      <Tooltip
                        title={<FormattedMessage id="settings.copy_link" />}
                      >
                        <FileCopyOutlinedIcon
                          style={{ cursor: "pointer", marginRight: "16px" }}
                          onClick={() =>
                            handleCopyClick(publicJobUrl, intl, enqueueSnackbar)
                          }
                        />
                      </Tooltip>
                    </>
                  )}

                  {jobOffer.offer_status !== "draft" &&
                    jobOffer.view_count != null &&
                    jobOffer.view_count > 0 && (
                      <Grid
                        md={4}
                        container
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                      >
                        <Tooltip
                          placement="left"
                          title={
                            <FormattedMessage
                              id="job_offer_summary.view_count_candidates"
                              values={{ people: jobOffer.view_count }}
                            />
                          }
                        >
                          <Box
                            style={{
                              display: "flex",
                              columnGap: "4px",
                              alignItems: "center",
                              cursor: "default",
                            }}
                          >
                            <VisibilityIcon />
                            {jobOffer.view_count}
                          </Box>
                        </Tooltip>
                      </Grid>
                    )}
                </Box>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ marginBottom: "32px", marginTop: "32px" }}
            >
              <MetricsGrid
                titleOne="job_offer.company_industry"
                textOne={
                  jobOffer?.company_industries?.length > 0
                    ? truncate(
                        jobOffer.company_industries
                          .map(industry =>
                            intl.formatMessage({
                              id: `company.company_industry.${industry}`,
                            })
                          )
                          .join(", "),
                        12
                      )
                    : ""
                }
                titleTwo={
                  jobOffer?.end_date
                    ? "job_offer.duration"
                    : "job_offer.start_date"
                }
                textTwo={
                  jobOffer?.end_date ? (
                    getDuration(jobOffer.start_date, jobOffer.end_date)
                  ) : jobOffer?.start_date ? (
                    moment(jobOffer.start_date)
                      .locale("fr")
                      .format("l")
                  ) : (
                    <FormattedMessage id="undefined" />
                  )
                }
                titleThree="job_offer.job_status"
                textThree={
                  jobOffer?.job_statuses?.length > 0 ? (
                    <Tooltip
                      title={jobOffer?.job_statuses
                        .map(status =>
                          intl.formatMessage({
                            id: `candidate.status_map.${status}_hover`,
                          })
                        )
                        .join(", ")}
                      style={{ cursor: "pointer" }}
                      placement="bottom"
                    >
                      <span>
                        {jobOffer?.job_statuses
                          .map(status =>
                            intl.formatMessage({
                              id: `candidate.status_map.${status}`,
                            })
                          )
                          .join(", ")}
                      </span>
                    </Tooltip>
                  ) : (
                    <FormattedMessage id="undefined" />
                  )
                }
                titleFour="job_offer.monthly_offer"
                textFour={annualyRemuneration(jobOffer.monthly_offer)}
              />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: "32px" }}>
              <Grid container spacing={3}>
                {/* Description */}
                {jobOffer?.description && (
                  <TextBlock
                    titleId="job_offer.description"
                    text={jobOffer.description}
                  />
                )}

                {/* Responsibilities */}
                {jobOffer?.responsibilities && (
                  <TextBlock
                    titleId="job_offer_creation.responsibilities_label"
                    text={jobOffer.responsibilities}
                  />
                )}

                {/* Profile */}
                {jobOffer?.profile && (
                  <TextBlock
                    titleId="job_offer.profile"
                    text={jobOffer.profile}
                  />
                )}

                {/* Offer */}
                {jobOffer?.offer && (
                  <TextBlock titleId="job_offer.offer" text={jobOffer.offer} />
                )}

                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    rowGap: "16px",
                    columnGap: "16px",
                  }}
                >
                  {jobOffer?.offer_advantages?.advantages?.map(
                    (value, index) => (
                      <Box
                        key={`advantages-${index}`}
                        style={{ display: "flex" }}
                      >
                        <CheckCircleOutlineIcon
                          color="primary"
                          style={{ marginRight: "4px" }}
                        />
                        <FormattedMessage
                          id={`job_offer_edit.advantages.${value}`}
                        />
                      </Box>
                    )
                  )}
                  {jobOffer?.offer_advantages?.schedules?.map(
                    (value, index) => (
                      <Box
                        key={`schedules-${index}`}
                        style={{ display: "flex" }}
                      >
                        <CheckCircleOutlineIcon
                          color="primary"
                          style={{ marginRight: "4px" }}
                        />
                        <FormattedMessage
                          id={`job_offer_edit.schedules.${value}`}
                        />
                      </Box>
                    )
                  )}
                  {jobOffer?.offer_advantages?.environments?.map(
                    (value, index) => (
                      <Box
                        key={`environments-${index}`}
                        style={{ display: "flex" }}
                      >
                        <CheckCircleOutlineIcon
                          color="primary"
                          style={{ marginRight: "4px" }}
                        />
                        <FormattedMessage
                          id={`job_offer_edit.environment.${value}`}
                        />
                      </Box>
                    )
                  )}
                  {jobOffer?.offer_advantages?.primes?.map((value, index) => (
                    <Box key={`primes-${index}`} style={{ display: "flex" }}>
                      <CheckCircleOutlineIcon
                        color="primary"
                        style={{ marginRight: "4px" }}
                      />
                      <FormattedMessage id={`job_offer_edit.primes.${value}`} />
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Grid>

            {/* Skills */}
            {skills?.length > 0 && (
              <Grid item xs={12} style={{ paddingBottom: "64px" }}>
                <Grid container spacing={3}>
                  {hard_skills?.length > 0 && (
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        <b>
                          <FormattedMessage id="candidate.profile.skills.hard" />
                        </b>
                      </Typography>
                      <Box
                        display="flex"
                        style={{
                          columnGap: "8px",
                          rowGap: "8px",
                          flexWrap: "wrap",
                        }}
                      >
                        {hard_skills.map((value, index) => (
                          <Box key={`hard-${index}`}>
                            <SkillView
                              value={value}
                              category="hard"
                              locale={i18nLocale}
                              level={false}
                            />
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  )}

                  {soft_skills?.length > 0 && (
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        <b>
                          <FormattedMessage id="candidate.profile.skills.soft" />
                        </b>
                      </Typography>
                      <Box
                        display="flex"
                        style={{
                          columnGap: "8px",
                          rowGap: "8px",
                          flexWrap: "wrap",
                        }}
                      >
                        {soft_skills.map((value, index) => (
                          <Box key={`soft-${index}`}>
                            <SkillView
                              level={false}
                              value={value}
                              category="soft"
                              locale={i18nLocale}
                            />
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  )}

                  {languages?.length > 0 && (
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        <b>
                          <FormattedMessage id="candidate.profile.skills.lang" />
                        </b>
                      </Typography>
                      <Box
                        display="flex"
                        style={{
                          columnGap: "8px",
                          rowGap: "8px",
                          flexWrap: "wrap",
                        }}
                      >
                        {languages.map((value, index) => (
                          <Box key={`lang-${index}`}>
                            <SkillView
                              value={value}
                              category="lang"
                              locale={i18nLocale}
                            />
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}

            {(jobOffer.offer_status == "" ||
              jobOffer.offer_status == "draft") && (
              <>
                <Grid
                  item
                  xs={12}
                  md={6}
                  display="flex"
                  justifyContent="center"
                >
                  <Button
                    variant="rounded"
                    color="primaryContained"
                    style={{ width: isDesktop ? "84%" : "100%" }}
                    onClick={handlePublishClicked}
                  >
                    <FormattedMessage id="job_offer.publish" />
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  display="flex"
                  justifyContent="center"
                >
                  <Button
                    variant="rounded"
                    color="primaryOutlined"
                    style={{
                      width: isDesktop ? "84%" : "100%",
                      marginTop: isDesktop ? "0px" : "16px",
                    }}
                    onClick={handleEditClicked}
                  >
                    {isEdit ? (
                      <FormattedMessage id="job_offer.save" />
                    ) : (
                      <FormattedMessage id="job_offer.edit" />
                    )}
                  </Button>
                </Grid>
                <Grid item xs={12} align="center">
                  <Button
                    variant="text"
                    style={{
                      marginTop: "24px",
                    }}
                    onClick={handleDeleteClicked}
                  >
                    <FormattedMessage id="job_offer.delete_draft" />
                  </Button>
                </Grid>
              </>
            )}

            {jobOffer.offer_status == "online" && (
              <>
                <Grid
                  item
                  xs={12}
                  md={6}
                  display="flex"
                  justifyContent="center"
                >
                  <Button
                    variant="rounded"
                    color="primaryContained"
                    style={{ width: isDesktop ? "84%" : "100%" }}
                    onClick={handleEditClicked}
                  >
                    {isEdit ? (
                      <FormattedMessage id="job_offer.save" />
                    ) : (
                      <FormattedMessage id="job_offer.edit" />
                    )}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  display="flex"
                  justifyContent="center"
                >
                  <Button
                    variant="rounded"
                    color="primaryOutlined"
                    style={{
                      width: isDesktop ? "84%" : "100%",
                      marginTop: isDesktop ? "0px" : "16px",
                    }}
                    onClick={handleDeleteClicked}
                  >
                    <FormattedMessage id="job_offer.soft_delete" />
                  </Button>
                </Grid>
              </>
            )}

            {jobOffer.offer_status == "closed" && (
              <Grid item xs={12} display="flex" justifyContent="center">
                <Button
                  variant="rounded"
                  color="primaryContained"
                  style={{
                    width: isDesktop ? "84%" : "100%",
                    marginTop: isDesktop ? "0px" : "16px",
                  }}
                  onClick={handleDuplicateClicked}
                >
                  <FormattedMessage id="job_offer.repost" />
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        handleConfirm={handleDeleteConfirmed}
        labelId={
          jobOffer.offer_status == "online"
            ? "close_offer_confirmation"
            : "delete_offer_confirmation"
        }
      />
    </>
  );
};

export default JobOfferSummary;
