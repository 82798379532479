import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { makeStyles, useTheme, withStyles } from "@mui/styles";
import {
  Toolbar,
  AppBar,
  Drawer,
  Divider,
  List,
  ListItem,
  Button,
  IconButton,
  Box,
  Hidden,
  Typography,
  Collapse,
  Menu,
  MenuItem,
  Badge,
  useMediaQuery,
  Link as MuiLink,
} from "@mui/material";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import Logo from "icons/logo.svg";
import LogoWhite from "icons/logoWhite.svg";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import BusinessIcon from "@mui/icons-material/Business";
import LoginIcon from "@mui/icons-material/Login";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Person2Icon from "@mui/icons-material/Person2";
import FeedIcon from "@mui/icons-material/Feed";
import { PermIdentity, Search } from "@mui/icons-material";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import {
  desktopAuthenticatedSubmenus,
  mobileAuthenticatedSubmenus,
  mobileUnauthMenus,
} from "bundles/common/utils/candidates/navbar";
import SelectLanguage from "./SelectLanguage";
import sh from "bundles/common/utils/sh";
import ProfilePicture from "../common/components/ProfilePicture";

export default function MainAppBar({ children }) {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const currentCandidate = useSelector((state) => state.current_candidate);
  const authenticity_token = useSelector((state) => state.authenticity_token);
  const navigate = useNavigate();
  const theme = useTheme();

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 0,
    },
    logo: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: theme.palette.text.blue,
      textTransform: "none",
    },
    drawer: {
      width: "240px !important",
      borderTopRightRadius: "30px",
      borderBottomRightRadius: "30px",
    },
    drawerHeader: {
      height: "100px",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    rightHeader: {
      marginRight: "16px",
    },
    headerTitle: {
      fontWeight: 600,
    },
    drawerItem: {
      justifyContent: "left",
      columnGap: "4px",
    },
    divider: {
      margin: "8px 16px !important",
    },
    recruiterButton: {
      fontWeight: "bolder",
      marginLeft: "16px !important",
    },
  }));

  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [submenus, setSubmenus] = useState(
    matches ? desktopAuthenticatedSubmenus : mobileAuthenticatedSubmenus
  );
  const [submenusUpdateCounter, setSubmenusUpdateCounter] = useState(0);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsDrawerOpen(open);
  };

  const handleSubmenuClicked = (submenu) => {
    let tempSubmenus = submenus;
    let tempSubmenu = tempSubmenus.find((s) => s.code == submenu.code);
    tempSubmenus.forEach((s) => {
      if (s.code != submenu.code) s.isOpen = false;
    });
    tempSubmenu.isOpen = !tempSubmenu.isOpen;
    setSubmenus(tempSubmenus);
    setSubmenusUpdateCounter(submenusUpdateCounter + 1);
  };

  const handleRedirectClick = (link, popupState = null) => {
    if (link.logout) return logout(link.target);

    if (matches && popupState) {
      popupState.close();
    } else {
      setIsDrawerOpen(false);
    }

    if (currentCandidate) {
      return navigate({
        pathname: `/${i18nLocale}/${link.target}`,
        search: link.search,
      });
    } else {
      window.location.href = `/${i18nLocale}/${link.target}`;
    }
  };

  const LogoIcon = (props) => {
    return (
      <MuiLink
        href={currentCandidate ? `/${i18nLocale}/candidate` : `/${i18nLocale}/`}
      >
        <Box
          component="img"
          id="logo-squarehub"
          alt="Squarehub"
          width="100%"
          height="auto"
          maxWidth={200}
          {...props}
          src={props.white ? LogoWhite : Logo}
        />
      </MuiLink>
    );
  };

  const logout = (target = null) => {
    sh.delete(`${i18nLocale}/candidates/sign_out`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        authenticity_token,
      },
    })
      .then(() => {
        if (window)
          window.location.replace(
            `/${i18nLocale}/${typeof target == "string" ? target : ""}`
          );
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {});
  };

  const UserMenu = ({ classes }) => {
    const popupState = usePopupState({
      variant: "popover",
      popupId: "demoMenu",
    });

    return (
      <Box>
        <IconButton
          disableRipple={true}
          className={classes.iconButton}
          aria-label="menu-button"
          {...bindTrigger(popupState)}
        >
          <ProfilePicture
            rounded
            picture={currentCandidate?.profile_picture_url}
            initials={
              currentCandidate?.first_name[0] +
              (currentCandidate?.last_name
                ? currentCandidate?.last_name[0]
                : "")
            }
          />{" "}
          <ExpandMoreIcon />
        </IconButton>
        <Menu {...bindMenu(popupState)}>
          <MenuItem
            className={classes.userMenuItem}
            onClick={() => handleRedirectClick({ target: "" }, popupState)}
          >
            <HomeOutlinedIcon style={{ marginRight: "8px" }} />
            <Typography variant="body2bold">
              <FormattedMessage id="menu.home" />
            </Typography>
          </MenuItem>
          <MenuItem
            className={classes.userMenuItem}
            onClick={() =>
              handleRedirectClick({ target: "candidate/settings" }, popupState)
            }
          >
            <Person2Icon
              style={{
                marginRight: "8px",
              }}
            />
            <Typography variant="body2bold">
              <FormattedMessage id="profile.my_settings" />
            </Typography>
          </MenuItem>
          <MenuItem
            className={classes.userMenuItem}
            onClick={() =>
              handleRedirectClick(
                {
                  target: "candidate/job_offers",
                  search: "?onlyFavorites=true",
                },
                popupState
              )
            }
          >
            <Badge
              badgeContent={currentCandidate?.favorite_offers?.length || 0}
              color="secondary"
              style={{ marginRight: "8px" }}
            >
              <FavoriteBorderIcon />
            </Badge>
            <Typography variant="body2bold">
              <FormattedMessage id="nav.candidate.favorite_jobs" />
            </Typography>
          </MenuItem>
          <Divider className={classes.divider} />
          {desktopAuthenticatedSubmenus.map((submenu) => {
            return (
              <Box key={`key-${submenu.titleId}-${Math.random()}`}>
                <Typography variant="body2bold" style={{ marginLeft: "16px" }}>
                  <FormattedMessage id={submenu.titleId} />
                </Typography>
                <Box style={{ marginTop: "8px" }}>
                  {submenu.links.map((link) => {
                    return (
                      <MenuItem
                        key={`${link.titleId}-${Math.random()}`}
                        className={classes.userMenuItem}
                        onClick={() => {
                          handleRedirectClick(link, popupState);
                        }}
                      >
                        {link.icon && link.icon()}
                        <Typography
                          variant="body2"
                          style={{ marginLeft: "8px" }}
                        >
                          <FormattedMessage id={link.titleId} />
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Box>
                <Divider className={classes.divider} />
              </Box>
            );
          })}
          {currentCandidate && (
            <Box style={{ marginTop: "16px", marginBottom: "16px" }}>
              <SelectLanguage initLocale={i18nLocale} inline={true} />
            </Box>
          )}
          <Divider className={classes.divider} />
          {currentCandidate && (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={logout}
                className={classes.userMenuItem}
                style={{
                  textAlign: "center",
                }}
              >
                <FormattedMessage id="logout" />
              </Button>
            </Box>
          )}
        </Menu>
      </Box>
    );
  };

  const CustomUserMenu = withStyles(() => ({
    iconButton: {
      "&:hover": {
        backgroundColor: "unset",
        opacity: 0.84,
      },
    },
    userMenuItem: {
      width: "240px !important",
      "&:hover": {
        backgroundColor: `${theme.palette.text.blue} !important`,
        color: `${theme.palette.common.white} !important`,
      },
    },
    divider: {
      margin: "8px 16px !important",
    },
  }))(UserMenu);

  const CandidateAvatar = () => {
    return <CustomUserMenu />;
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color={matches ? "transparent" : "inherit"}
        elevation={0}
        style={{
          height: matches ? "inherit" : "68px",
          justifyContent: "center",
          backgroundColor: theme.palette.common.white,
        }}
      >
        <Toolbar style={{ paddingRight: matches ? "16px" : "4px" }}>
          {matches && (
            <Box flexGrow={1}>
              <LogoIcon />
            </Box>
          )}
          <Hidden mdDown>
            {currentCandidate ? (
              <>
                <Button
                  className={classes.menuButton}
                  variant="text"
                  style={{ marginRight: "16px" }}
                  href={`/${i18nLocale}/candidate/job_offers?selectedTab=my_jobs`}
                >
                  <FormattedMessage id="job_search.my_jobs" />
                  {currentCandidate.unclosed_job_applications_count &&
                  parseInt(currentCandidate.unclosed_job_applications_count) >
                    0 ? (
                      <Box
                        style={{
                          textAlign: "right",
                          flexGrow: 1,
                          marginLeft: "4px",
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "auto",
                            textAlign: "center",
                            width: "16px",
                            height: "16px",
                            borderRadius: "50%",
                            color: theme.palette.common.white,
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          <Typography variant="body4">
                            <b>
                              {currentCandidate.unclosed_job_applications_count}
                            </b>
                          </Typography>
                        </Box>
                      </Box>
                    ) : null}
                </Button>
                <Button
                  variant="text"
                  style={{ marginRight: "16px" }}
                  href={`/${i18nLocale}/candidate/settings?selectedSection=resume_generator`}
                >
                  <FormattedMessage id="menu.resume_generator" />
                  <Box
                    style={{
                      position: "absolute",
                      top: 5,
                      right: -10,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "30px",
                      height: "15px",
                      borderRadius: "10px",
                      color: theme.palette.common.white,
                      backgroundColor: theme.palette.primary.main,
                    }}
                  >
                    <Typography variant="body4">
                      <b>New</b>
                    </Typography>
                  </Box>
                </Button>
                <Button
                  size="medium"
                  variant="text"
                  style={{ marginRight: "16px" }}
                  href={`/${i18nLocale}/companies`}
                >
                  <FormattedMessage id="menu.all_companies" />
                </Button>
                <Button
                  className={classes.menuButton}
                  variant="text"
                  href={`/${i18nLocale}/candidate/posts`}
                >
                  <FormattedMessage id="menu.blog_posts" />
                </Button>
                <Button
                  size="small"
                  variant="rounded"
                  color="primaryContained"
                  style={{ marginRight: "16px", textTransform: "uppercase" }}
                  href={`/${i18nLocale}/candidate/job_offers`}
                >
                  <FormattedMessage id="menu.all_jobs" />
                </Button>
                <CandidateAvatar />
              </>
            ) : (
              <>
                <Button
                  className={classes.menuButton}
                  variant="text"
                  href={`/${i18nLocale}/candidates/posts`}
                >
                  <FormattedMessage id="menu.blog_posts" />
                </Button>
                <Button
                  variant="text"
                  href={`/${i18nLocale}/recruiters`}
                  className={classes.menuButton}
                >
                  <FormattedMessage id="recruiter.recruiter_space" />
                </Button>
                <Button
                  className={classes.recruiterButton}
                  variant="rounded"
                  size="small"
                  color="primaryOutlined"
                  style={{ minWidth: "150px" }}
                  href={`/${i18nLocale}/candidates/sign_up`}
                >
                  <FormattedMessage id="signup" />
                </Button>
                <Button
                  className={classes.recruiterButton}
                  variant="rounded"
                  size="small"
                  color="primaryContained"
                  style={{ marginRight: "16px", minWidth: "150px" }}
                  href={`/${i18nLocale}/candidates/sign_in`}
                >
                  <FormattedMessage id="signin" />
                </Button>
                <SelectLanguage initLocale={i18nLocale} />
              </>
            )}
            <Box mx={1} />
          </Hidden>
          <Hidden lgUp>
            <IconButton
              edge="end"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          {!matches && (
            <Box
              flexGrow={1}
              style={{ marginLeft: "-32px", textAlign: "center" }}
            >
              <LogoIcon />
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mobile navbar">
        <Hidden lgUp implementation="css">
          <Drawer
            anchor="left"
            open={isDrawerOpen}
            onClose={toggleDrawer(false)}
            classes={{ paper: classes.drawer }}
          >
            <Box className={classes.drawerHeader}>
              <IconButton onClick={toggleDrawer(false)}>
                <ArrowBackIosIcon
                  style={{ color: "white", marginLeft: "8px" }}
                />
              </IconButton>
              <Box className={classes.rightHeader}>
                {currentCandidate ? (
                  <Box display="flex" alignItems="center" columnGap="8px">
                    <Box
                      display="flex"
                      flexDirection="column"
                      textAlign="right"
                    >
                      <Typography variant="h7">Bienvenue</Typography>
                      <Typography
                        variant="body3"
                        className={classes.headerTitle}
                      >
                        {currentCandidate?.first_name}{" "}
                        {currentCandidate?.last_name}
                      </Typography>
                    </Box>
                    <img
                      src={currentCandidate?.profile_picture_url}
                      style={{ width: "50px", borderRadius: "4px" }}
                    />
                  </Box>
                ) : (
                  <Box textAlign="right">
                    <LogoIcon white={true} style={{ width: "140px" }} />
                    <Typography
                      variant="body3"
                      style={{ textTransform: "uppercase" }}
                    >
                      <FormattedMessage id="candidate.candidate_space" />
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <List>
              {currentCandidate ? (
                <>
                  <ListItem
                    button
                    className={classes.drawerItem}
                    onClick={() => handleRedirectClick({ target: "" })}
                  >
                    <HomeOutlinedIcon style={{ marginRight: "8px" }} />
                    <Typography variant="body2bold">
                      <FormattedMessage id="menu.home" />
                    </Typography>
                  </ListItem>
                  <ListItem
                    button
                    className={classes.drawerItem}
                    onClick={() =>
                      handleRedirectClick({ target: "candidate/settings" })
                    }
                  >
                    <PermIdentity style={{ marginRight: "8px" }} />
                    <Typography variant="body2bold">
                      <FormattedMessage id="nav.my_settings" />
                    </Typography>
                  </ListItem>
                  <ListItem
                    button
                    className={classes.drawerItem}
                    onClick={() => {
                      if (window)
                        window.location.href = `/${i18nLocale}/candidate/job_offers?selectedTab=find_a_job`;
                    }}
                  >
                    <Search style={{ marginRight: "8px" }} />
                    <Typography variant="body2bold">
                      <FormattedMessage id="nav.candidate.find_job" />
                    </Typography>
                  </ListItem>
                  <ListItem
                    button
                    className={classes.drawerItem}
                    style={{ display: "flex" }}
                    onClick={() =>
                      handleRedirectClick({ target: "candidate/companies" })
                    }
                  >
                    <BusinessIcon style={{ marginRight: "8px" }} />
                    <Typography variant="body2bold">
                      <FormattedMessage id="nav.candidate.companies" />
                    </Typography>
                  </ListItem>
                  <ListItem
                    button
                    className={classes.drawerItem}
                    style={{ display: "flex" }}
                    onClick={() => {
                      if (window)
                        window.location.href = `/${i18nLocale}/candidate/settings?selectedSection=resume_generator`;
                    }}
                  >
                    <CreateNewFolderIcon style={{ marginRight: "8px" }} />
                    <Typography variant="body2bold">
                      <FormattedMessage id="nav.candidate.resume_generator" />
                    </Typography>
                    <Box
                      style={{
                        position: "absolute",
                        top: 5,
                        right: 80,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "30px",
                        height: "15px",
                        borderRadius: "10px",
                        color: theme.palette.common.white,
                        backgroundColor: theme.palette.primary.main,
                      }}
                    >
                      <Typography variant="body4">
                        <b>New</b>
                      </Typography>
                    </Box>
                  </ListItem>
                  <ListItem
                    button
                    className={classes.drawerItem}
                    style={{ display: "flex" }}
                    onClick={() => {
                      if (window)
                        window.location.href = `/${i18nLocale}/candidate/job_offers?selectedTab=my_jobs`;
                    }}
                  >
                    <PermIdentity style={{ marginRight: "8px" }} />
                    <Typography variant="body2bold">
                      <FormattedMessage id="nav.candidate.my_jobs" />
                    </Typography>
                    {currentCandidate.unclosed_job_applications_count &&
                    parseInt(currentCandidate.unclosed_job_applications_count) >
                      0 ? (
                        <Box
                          style={{
                            textAlign: "right",
                            flexGrow: 1,
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginLeft: "auto",
                              textAlign: "center",
                              width: "16px",
                              height: "16px",
                              borderRadius: "50%",
                              color: theme.palette.common.white,
                              backgroundColor: theme.palette.primary.main,
                            }}
                          >
                            <Typography variant="body3">
                              {currentCandidate.unclosed_job_applications_count}
                            </Typography>
                          </Box>
                        </Box>
                      ) : null}
                  </ListItem>
                  <ListItem
                    button
                    className={classes.drawerItem}
                    onClick={() => {
                      if (window)
                        window.location.href = `/${i18nLocale}/candidate/posts`;
                    }}
                  >
                    <FeedIcon style={{ marginRight: "8px" }} />
                    <Typography variant="body2bold">
                      <FormattedMessage id="menu.blog_posts" />
                    </Typography>
                  </ListItem>
                  <Divider className={classes.divider} />
                  {submenus.map((submenu) => {
                    return (
                      <Box key={`key-${submenu.titleId}`}>
                        <ListItem
                          className={classes.drawerItem}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          onClick={() => handleSubmenuClicked(submenu)}
                        >
                          <Typography variant="body2bold">
                            <FormattedMessage id={submenu.titleId} />
                          </Typography>
                          {submenu.isOpen ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </ListItem>
                        <Collapse
                          in={submenu.isOpen}
                          timeout="auto"
                          unmountOnExit
                        >
                          {submenu.links.map((link) => {
                            return (
                              <ListItem
                                key={`${link.titleId}-${Math.random()}`}
                                className={`${classes.drawerItem} ${classes.drawerSubItem}`}
                                onClick={() => handleRedirectClick(link)}
                              >
                                {link.icon && link.icon()}
                                <Typography
                                  variant="body2"
                                  style={{ marginLeft: "8px" }}
                                >
                                  <FormattedMessage id={link.titleId} />
                                </Typography>
                              </ListItem>
                            );
                          })}
                        </Collapse>
                      </Box>
                    );
                  })}
                </>
              ) : (
                <>
                  <ListItem
                    button
                    className={classes.drawerItem}
                    onClick={() => {
                      if (window)
                        window.location.href = `/${i18nLocale}/candidates/sign_in`;
                    }}
                  >
                    <LoginIcon
                      style={{
                        width: "18px",
                        height: "18px",
                        marginRight: "8px",
                      }}
                    />
                    <Typography variant="body1bold">
                      <FormattedMessage id="signin" />
                    </Typography>
                  </ListItem>
                  <ListItem
                    button
                    className={classes.drawerItem}
                    onClick={() => {
                      if (window)
                        window.location.href = `/${i18nLocale}/candidates/sign_up`;
                    }}
                  >
                    <Person2Icon
                      style={{
                        width: "18px",
                        height: "18px",
                        marginRight: "8px",
                      }}
                    />
                    <Typography variant="body1bold">
                      <FormattedMessage id="signup" />
                    </Typography>
                  </ListItem>
                  <Divider className={classes.divider} />
                  {mobileUnauthMenus.map((link) => {
                    return (
                      <ListItem
                        key={`${link.titleId}-${Math.random()}`}
                        className={`${classes.drawerItem} ${classes.drawerSubItem}`}
                        onClick={() => handleRedirectClick(link)}
                      >
                        {link.icon && link.icon()}
                        <Typography
                          variant="body2"
                          style={{ marginLeft: "8px" }}
                        >
                          <FormattedMessage id={link.titleId} />
                        </Typography>
                      </ListItem>
                    );
                  })}
                </>
              )}
              <Divider className={classes.divider} />
              <ListItem
                className={classes.drawerItem}
                style={{ marginBottom: "8px" }}
              >
                <SelectLanguage initLocale={i18nLocale} inline={true} />
              </ListItem>
              <Divider className={classes.divider} />
              {currentCandidate && (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={logout}
                    className={classes.drawerItem}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <FormattedMessage id="logout" />
                  </Button>
                </Box>
              )}
            </List>
          </Drawer>
        </Hidden>
      </nav>
      {children}
    </div>
  );
}
