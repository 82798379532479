import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  useMediaQuery,
  AppBar,
  Tabs,
  Tab,
} from "@mui/material";
import { useTheme, makeStyles } from "@mui/styles";
import { lighten } from "@material-ui/core/styles";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/system";
import SwipeableViews from "react-swipeable-views";
import Architecture from "icons/architecture.svg";
import Engineering from "icons/engineering.svg";
import Construction from "icons/tools.svg";
import PropTypes from "prop-types";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

const AvailableJobs = () => {
  const theme = useTheme();
  const intl = useIntl();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const i18nLocale = useSelector((state) => state.i18nLocale);

  const useStyles = makeStyles((theme) => ({
    create_account_hover: {
      "&:hover": {
        backgroundColor: `${theme.palette.secondary.main} !important`,
        color: "white !important",
      },
    },
    inactiveTab: {
      "&:hover": {
        backgroundColor: `${lighten(theme.palette.secondary.main, 0.2)} !important`,
        color: "white !important",
      },
    },
  }));
  const classes = useStyles();

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    color: "white",
    backgroundColor: `${theme.palette.secondary.main} !important`,
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper": {
      color: "white",
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const getJobList = (fieldName) => {
    const jobList = intl.formatMessage({
      id: `landing_recruiter.fields.${fieldName}_job_list`,
    });
    const jobNames = jobList
      .replace(/<\/li><li>/g, ", ")
      .replace(/<\/?li>|<\/?ul>/g, "");
    return jobNames;
  };

  const tabNames = [
    { name: "architecture", index: 0 },
    { name: "engineering", index: 1 },
    { name: "construction", index: 2 },
  ];

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            sx={{
              padding: 3,
              backgroundColor: theme.palette.background.default,
            }}
          >
            <Typography>
              <FormattedMessage
                id={`landing_recruiter.fields.${
                  tabNames.find((tab) => tab.index === index).name
                }_job_list`}
                values={{
                  li: (chunks) => (
                    <li
                      key={chunks}
                      style={{
                        listStyle: "none",
                        display: "flex",
                        alignItems: "baseline",
                        fontFamily: "Work Sans Bold",
                        fontSize: "18px",
                        fontWeight: 400,
                        lineHeight: "32px",
                        letterSpacing: "0.4px",
                        textAlign: "left",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          fontSize: "20px",
                          marginRight: "8px",
                          color: "black",
                        }}
                      >
                        •
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: "bold" }}
                      >
                        {chunks}
                      </Typography>
                    </li>
                  ),
                  ul: (chunks) => (
                    <ul
                      key={chunks}
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, 1fr)",
                        gridGap: "12px",
                      }}
                    >
                      {chunks}
                    </ul>
                  ),
                }}
              />
            </Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <>
      <Container maxWidth="lg">
        <Grid
          container
          style={{
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Box
            style={{
              paddingRight: isDesktop ? "32px" : "0px",
              width: "100%",
              paddingBottom: isDesktop ? "56px" : "24px",
              paddingTop: "16px",
            }}
          >
            <Typography
              variant="h2"
              style={{
                fontFamily: "Work Sans Bold",
                color: theme.palette.secondary.main,
                fontSize: isDesktop ? "36px" : "32px",
              }}
            >
              <FormattedMessage id="landing_recruiter.fields.header" />
            </Typography>
          </Box>
          <Box
            style={{ width: "80%", paddingBottom: isDesktop ? "48px" : "24px" }}
          >
            <Typography
              variant="subtitle1"
              style={{
                fontFamily: "Work Sans Bold",
                fontWeight: 400,
                fontSize: isDesktop ? "16px" : "14px",
              }}
            >
              <FormattedMessage id="landing_recruiter.fields.sub_header" />
            </Typography>
          </Box>
          {!isDesktop ? (
            <Box>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleAccordionChange("panel1")}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>
                    {intl.formatMessage({
                      id: "company.company_industry.architecture",
                    })}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ fontSize: "14px", textAlign: "left" }}>
                    {getJobList("architecture")}.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleAccordionChange("panel2")}
              >
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <Typography>
                    {intl.formatMessage({
                      id: "company.company_industry.engineering",
                    })}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ fontSize: "14px", textAlign: "left" }}>
                    {getJobList("engineering")}.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleAccordionChange("panel3")}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography>
                    {intl.formatMessage({
                      id: "company.company_industry.construction",
                    })}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography style={{ fontSize: "14px", textAlign: "left" }}>
                    {getJobList("construction")}.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          ) : (
            <Box style={{ width: "100%" }}>
              <AppBar position="static">
                <Tabs
                  TabIndicatorProps={{
                    sx: {
                      height: "6px",
                    },
                  }}
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                  style={{ backgroundColor: theme.palette.secondary.main }}
                >
                  <Tab
                    className={value !== 0 ? classes.inactiveTab : ""}
                    label={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={Architecture}
                          alt="architecture icon"
                          width="24"
                          height="24"
                          style={{ marginRight: "8px" }}
                        />
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bold" }}
                        >
                          {intl.formatMessage({
                            id: "company.company_industry.architecture",
                          })}
                        </Typography>
                      </Box>
                    }
                    {...a11yProps(0)}
                    style={{ borderRight: "0.4px solid grey" }}
                  />
                  <Tab
                    className={value !== 1 ? classes.inactiveTab : ""}
                    label={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={Engineering}
                          alt="engineering icon"
                          width="24"
                          height="24"
                          style={{ marginRight: "8px" }}
                        />
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bold" }}
                        >
                          {intl.formatMessage({
                            id: "company.company_industry.engineering",
                          })}
                        </Typography>
                      </Box>
                    }
                    {...a11yProps(1)}
                    style={{ borderRight: "0.4px solid grey" }}
                  />
                  <Tab
                    className={value !== 2 ? classes.inactiveTab : ""}
                    label={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={Construction}
                          alt="construction icon"
                          width="24"
                          height="24"
                          style={{ marginRight: "8px" }}
                        />
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bold" }}
                        >
                          {intl.formatMessage({
                            id: "company.company_industry.construction",
                          })}
                        </Typography>
                      </Box>
                    }
                    {...a11yProps(2)}
                  />
                </Tabs>
              </AppBar>
              <SwipeableViews
                containerStyle={{
                  transition:
                    "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
                }}
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction} />
                <TabPanel value={value} index={1} dir={theme.direction} />
                <TabPanel value={value} index={2} dir={theme.direction} />
              </SwipeableViews>
            </Box>
          )}
          <Button
            variant="rounded"
            color="secondaryOutlined"
            href={`/${i18nLocale}/recruiters/sign_up`}
            style={{
              width: "275px",
              marginTop: isDesktop ? "48px" : "32px",
            }}
            className={classes.create_account_hover}
          >
            <FormattedMessage id="landing_recruiter.fields.button" />
          </Button>
        </Grid>
      </Container>
    </>
  );
};

export default AvailableJobs;
