import React from "react";
import { Box, Typography } from "@mui/material";

const Godview = ({ message }) => {
  return (
    <Box
      style={{
        transform: "translateX(-50%)",
        position: "fixed",
        zIndex: 999999,
        backgroundColor: "#fafafa",
        border: "2px solid #FFCC00",
        padding: "16px",
        borderRadius: "4px",
        left: "50%",
        top: "8px",
      }}
    >
      <Typography variant="body1">{message}</Typography>
    </Box>
  );
};

export default Godview;
