import React from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Container } from "@mui/material";
import { useTheme } from "@mui/styles";
import translations from "app/libs/i18n/translations.json";
import MainAppBar from "bundles/Layouts/MainAppBar";
import Login from "bundles/Devise/Login/components/Login";
import ForgottenPassword from "bundles/Devise/Password/components/ForgottenPassword";
import ChangePassword from "bundles/common/components/ChangePassword";
import ResendConfirmation from "bundles/common/components/ResendConfirmation";
import Crane from "icons/crane.svg";

const LoginContainer = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const locale = i18nLocale;
  const messages = translations[locale];
  const theme = useTheme();

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <BrowserRouter>
        <MainAppBar />
        <Container
          maxWidth="lg"
          className={theme.background}
          style={{
            backgroundImage: `url(${Crane})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "right center",
            height: "calc(100% - 64px)",
          }}
        >
          <Routes>
            <Route
              path={`/${i18nLocale}/candidates/password/new`}
              element={<ForgottenPassword userType="candidates" />}
            />
            <Route
              path={`/${i18nLocale}/candidates/password/edit?reset_password_token`}
              element={<ChangePassword userType="candidates" />}
            />
            <Route
              path={`/${i18nLocale}/candidates/password/edit`}
              element={<ChangePassword userType="candidates" />}
            />
            <Route
              path={`/${i18nLocale}/candidates/confirmation/new`}
              element={<ResendConfirmation userType="candidates" />}
            />
            <Route
              path={`/${i18nLocale}/candidates/sign_in`}
              element={<Login />}
            />
            <Route
              path={`/${i18nLocale}`}
              element={<Navigate to={`/${i18nLocale}/candidates/sign_in`} />}
            />
          </Routes>
        </Container>
      </BrowserRouter>
    </IntlProvider>
  );
};

export default LoginContainer;
