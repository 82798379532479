import WorkSans from "WorkSans-VariableFont_wght.ttf";
import MontserratRegular from "Montserrat-Regular.ttf";
import createBreakpoints from "@mui/system/createTheme/createBreakpoints";
import { variants as buttonVariants } from "./mui_components/button";

const montserrat = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  src: `
  local('Montserrat'),
  local('Montserrat-Regular'),
  url(${MontserratRegular}) format('truetype')
`,
};

const workSans = {
  fontFamily: "Work Sans",
  fontWeight: 300,
  src: `local('WorkSans'), url(${WorkSans}) format('truetype')`,
};

const workSansBold = {
  fontFamily: "Work Sans Bold",
  src: `local('WorkSans'), url(${WorkSans}) format('truetype')`,
};

const breakpoints = createBreakpoints({});

const theme = {
  typography: {
    fontFamily: "Work Sans, Roboto, Helvetica, Arial, sans-serif",
    emphasis: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontSize: "64px",
      fontWeight: 600,
      lineHeight: "75px",
      [breakpoints.down("lg")]: {
        fontSize: "48px",
      },
    },
    h1: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontSize: "36px",
      fontWeight: 400,
      [breakpoints.down("lg")]: {
        fontSize: "18px",
      },
    },
    h2: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontSize: "28px",
      fontWeight: 400,
      letterSpacing: "-0.3333333432674408px",
      color: "#2A334E",
      [breakpoints.down("lg")]: {
        fontSize: "16px",
      },
    },
    h3: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontSize: "24px",
      fontWeight: 400,
      [breakpoints.down("lg")]: {
        fontSize: "14px",
      },
    },
    h4: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontSize: "22px",
      fontWeight: 400,
      letterSpacing: "-0.33px",
      lineHeight: 1.195,
      [breakpoints.down("lg")]: {
        fontSize: "12px",
      },
    },
    h5: {
      wordBreak: "normal",
      fontFamily: "Work Sans Bold",
      fontSize: "18px",
      fontWeight: 400,
      letterSpacing: "-0.33px",
      [breakpoints.down("lg")]: {
        fontSize: "10px",
      },
    },
    h6: {
      wordBreak: "normal",
      fontFamily: "Work Sans Bold",
      fontSize: "16px",
      fontWeight: 400,
      letterSpacing: "-0.33px",
      [breakpoints.down("lg")]: {
        fontSize: "10px",
      },
    },
    h7: {
      wordBreak: "normal",
      fontFamily: "Work Sans Bold",
      fontSize: "14px",
      fontWeight: 400,
      letterSpacing: "-0.33px",
      [breakpoints.down("lg")]: {
        fontSize: "10px",
      },
    },
    h8: {
      wordBreak: "normal",
      fontFamily: "Work Sans Bold",
      fontSize: "12px",
      fontWeight: 400,
      letterSpacing: "-0.33px",
      [breakpoints.down("lg")]: {
        fontSize: "10px",
      },
    },
    body1: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontSize: "16px",
      fontWeight: 300,
      letterSpacing: "-0.3333333432674408px",
      [breakpoints.down("lg")]: {
        fontSize: "14px",
      },
    },
    body1bold: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontStyle: "normal",
      fontSize: "16px",
      fontWeight: 700,
      letterSpacing: "-0.3333333432674408px",
      [breakpoints.down("lg")]: {
        fontSize: "14px",
      },
    },
    body2: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: "0.15000000596046448px",
      [breakpoints.down("lg")]: {
        fontSize: "12px",
      },
    },
    body2bold: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 600,
      letterSpacing: "0.15000000596046448px",
      [breakpoints.down("lg")]: {
        fontSize: "12px",
      },
    },
    body3: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: "0.05000000596046448px",
    },
    body3bold: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 600,
      letterSpacing: "0.05000000596046448px",
    },
    body4: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: "0.15000000596046448px",
    },
    subtitle0: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontSize: "48px",
      fontWeight: 400,
      lineHeight: "56px",
    },
    subtitle1: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: 1.2,
      letterSpacing: "0.15000000596046448px",
    },
    subtitle2: {
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontSize: "16px",
      fontWeight: 300,
      letterSpacing: "-0.33px",
      color: "#11111180",
    },
    caption: {
      display: "block",
      wordBreak: "normal",
      fontFamily: "Work Sans",
      fontSize: "11px",
      fontWeight: 400,
      letterSpacing: "0px",
      lineHeight: "14.08px",
      color: "#00000080",
    },
  },
  palette: {
    common: {
      black: "#000",
      white: "#fff",
      blue: "#2A334E",
      sh_lightgrey: "rgba(0, 0, 0, 0.06)",
      grey_background: "rgba(255, 255, 255, 0.15)",
      sh_grey: "#B2B2B2",
      main: "#079344",
      blue_grey: "#D3E0EA",
    },
    background: { paper: "#fff", default: "#F9FAFF" },
    warning: { main: "#FAC403" },
    primary: {
      lighter: "rgba(81, 197, 113, 0.4)",
      light: "rgba(81, 197, 113, 1)",
      main: "#079344",
      dark: "rgba(0, 100, 25, 1)",
      smoke: "rgba(7, 147, 68, 0.04)",
      contrastText: "#fff",
    },
    secondary: {
      lighter: "#3e4c7440",
      light: "#3e4c74",
      main: "#2A334E",
      dark: "#1b2132",
      smoke: "#eef0f6",
      contrastText: "#fff",
    },
    error: {
      smoke: "rgba(248, 128, 120, 0.1)",
      light: "rgba(248, 128, 120, 1)",
      main: "#f44336",
      dark: "rgba(227, 27, 12, 1)",
      contrastText: "#fff",
    },
    text: {
      blue: "#2A334E",
      light_blue: "#94A3D0",
      light_green: "#C0DED6",
      white: "rgba(255, 255, 255, 0.75)",
      dark: "rgba(0, 0, 0)",
      primary: "rgba(0, 0, 0, 0.75)",
      placeholder: "rgba(0, 0, 0, 0.625)",
      green: "rgba(7, 147, 68, 1)",
      secondary: "rgba(0, 0, 0, 0.5)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
      warning: "#E4A11B",
    },
    linkedin: {
      primary: "#0e76a8",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": workSans,
        fallback: [
          { "@font-face": workSansBold },
          { "@font-face": montserrat },
        ],
      },
    },
    MuiButton: {
      variants: buttonVariants,
    },
  },
};
export default theme;
