import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/styles";
import {
  Hidden,
  List,
  ListItem,
  Grid,
  Box,
  Link,
  IconButton,
  Typography,
  useMediaQuery,
  Modal,
} from "@mui/material";
import Logo from "icons/logo.svg";
import { BrowserRouter } from "react-router-dom";
import PricingModal from "bundles/LandingPageRecruiter/components/PricingModal";
import { Facebook, LinkedIn, Instagram } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

function SocialMedias() {
  const theme = useTheme();

  return (
    <Grid item xs={12}>
      <Box mb={2}>
        <IconButton
          aria-label="Facebook"
          href="https://www.facebook.com/squarehubeu"
          target={"_blank"}
        >
          <Facebook
            fontSize="medium"
            style={{ color: theme.palette.text.blue }}
          ></Facebook>
        </IconButton>
        <IconButton
          aria-label="LinkedIn"
          href="https://www.linkedin.com/company/squarehub-eu/"
          target={"_blank"}
        >
          <LinkedIn
            fontSize="medium"
            style={{ color: theme.palette.text.blue }}
          ></LinkedIn>
        </IconButton>
        <IconButton
          aria-label="Instagram"
          href="https://www.instagram.com/squarehub.eu/"
          target={"_blank"}
        >
          <Instagram
            fontSize="medium"
            style={{ color: theme.palette.text.blue }}
          ></Instagram>
        </IconButton>
      </Box>
    </Grid>
  );
}

function FooterLink({ href, messageId }) {
  return (
    <Grid item>
      <Box mx={1}>
        <Typography align="center" variant="body2" gutterBottom>
          <Link href={href} style={{ color: "white" }} underline="hover">
            <FormattedMessage align="center" id={messageId} />
          </Link>
        </Typography>
      </Box>
    </Grid>
  );
}

export default function Footer({ setFooterRendered }) {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const currentCandidate = useSelector((state) => state.current_candidate);
  const currentRecruiter = useSelector((state) => state.current_recruiter);

  const isCandidatePage =
    (currentCandidate || !window.location.pathname.includes("recruiter")) &&
    !currentRecruiter;

  const isRecruiterPage =
    (currentRecruiter || location.pathname.includes("recruiter")) &&
    !currentCandidate;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  let listItemPosition = isDesktop ? "left" : "center";

  const [showModal, setShowModal] = useState(false);

  const connected = currentCandidate || currentRecruiter;
  const userType = currentCandidate ? "candidate" : "recruiter";

  function getUrl(target) {
    return connected
      ? `/${i18nLocale}/${userType}/${target}`
      : `/${i18nLocale}/${target}`;
  }

  function FooterLinks() {
    return (
      <>
        <FooterLink
          messageId="footer.terms_of_use.terms_of_use"
          href={getUrl("terms-of-use")}
        />
        {!isCandidatePage && (
          <FooterLink
            messageId="footer.general_sales_conditions.general_sales_conditions"
            href={getUrl("general-sales-conditions")}
          />
        )}
        <FooterLink
          messageId="footer.privacy_policy.privacy_policy"
          href={getUrl("privacy-policy")}
        />
        <FooterLink
          messageId="footer.site_map.site_map"
          href={getUrl("site-map")}
        />
      </>
    );
  }

  useEffect(() => {
    if (setFooterRendered) {
      setFooterRendered(true);
    }
  }, []);

  return (
    <BrowserRouter>
      <Grid container align="center" id="client-sh-footer">
        <Grid item xs={12}>
          <Grid container style={{ backgroundColor: "white" }}>
            <Grid item xs={12} md={4}>
              <Box
                my={4}
                component="img"
                id="logo-squarehub"
                alt="Squarehub"
                width="100%"
                height="auto"
                maxWidth={250}
                src={Logo}
              />
              <Hidden smDown>
                <SocialMedias />
              </Hidden>
            </Grid>

            {/* CANDIDATES */}
            {isDesktop && isCandidatePage && connected && (
              <Grid item xs={12} md={2}>
                <Box my={2}>
                  <List>
                    <ListItem style={{ justifyContent: listItemPosition }}>
                      <Typography variant="h4">
                        <b>
                          <FormattedMessage id="menu.candidates" />
                        </b>
                      </Typography>
                    </ListItem>
                    <ListItem style={{ justifyContent: listItemPosition }}>
                      <Link
                        href={`/${i18nLocale}/candidate/settings`}
                        color="textPrimary"
                        underline="hover"
                      >
                        <FormattedMessage id="profile.my_settings" />
                      </Link>
                    </ListItem>
                    <ListItem style={{ justifyContent: listItemPosition }}>
                      <Link
                        href={`/${i18nLocale}/candidate/job_offers`}
                        color="textPrimary"
                        underline="hover"
                      >
                        <FormattedMessage id="job_search.my_jobs" />
                      </Link>
                    </ListItem>
                  </List>
                </Box>
              </Grid>
            )}

            {/* CANDIDATE UNAUTHENTICATED */}
            {isDesktop && isCandidatePage && (
              <Grid item xs={12} md={2}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginTop: "24px",
                  }}
                >
                  <List disablePadding>
                    <ListItem style={{ justifyContent: listItemPosition, padding: 0 }}>
                      <Typography variant="h4" component="div">
                        <b>
                          <FormattedMessage id="menu.recruiters" />
                        </b>
                      </Typography>
                    </ListItem>
                    <ListItem style={{ justifyContent: listItemPosition, padding: 0 }}>
                      <Link
                        href={`/${i18nLocale}/recruiters`}
                        color="textPrimary"
                        underline="hover"
                      >
                        <FormattedMessage id="footer.go_to_recruiter" />
                      </Link>
                    </ListItem>
                  </List>
                </Box>
              </Grid>
            )}

            {/* RECRUITERS */}
            {isDesktop && currentRecruiter && (
              <Grid item xs={12} md={2}>
                <Box my={2}>
                  <List>
                    <ListItem style={{ justifyContent: listItemPosition }}>
                      <Typography variant="h4">
                        <b>
                          <FormattedMessage id="menu.recruiters" />
                        </b>
                      </Typography>
                    </ListItem>
                    <ListItem style={{ justifyContent: listItemPosition }}>
                      <Link
                        href={`/${i18nLocale}/recruiter/job_offers`}
                        color="textPrimary"
                        underline="hover"
                      >
                        <FormattedMessage id="menu.jobs" />
                      </Link>
                    </ListItem>
                    <ListItem style={{ justifyContent: listItemPosition }}>
                      <Link
                        href={`/${i18nLocale}/recruiter/job_offer_creation`}
                        color="textPrimary"
                        underline="hover"
                      >
                        <FormattedMessage id="menu.post_job_offer" />
                      </Link>
                    </ListItem>
                    <ListItem style={{ justifyContent: listItemPosition }}>
                      <Link
                        href={`/${i18nLocale}/recruiter/find_candidate`}
                        color="textPrimary"
                        underline="hover"
                      >
                        <FormattedMessage id="candidate_search.find_candidate" />
                      </Link>
                    </ListItem>
                  </List>
                </Box>
              </Grid>
            )}

            {/* RECRUITERS UNAUTHENTICATED */}
            {isDesktop && !currentRecruiter &&(
              <Grid item xs={12} md={2}>
                <Box my={1}>
                  <List>
                    <ListItem style={{ justifyContent: listItemPosition }}>
                      <Typography variant="h4">
                        <b>
                          <FormattedMessage id="recruiter.recruiter" />
                        </b>
                      </Typography>
                    </ListItem>
                    <ListItem style={{ justifyContent: listItemPosition }}>
                      <Link
                        href={`/${i18nLocale}/recruiter/job_offer_creation`}
                        color="textPrimary"
                        underline="hover"
                      >
                        <FormattedMessage id="job_offer.post_new_job" />
                      </Link>
                    </ListItem>
                    <ListItem style={{ justifyContent: listItemPosition }}>
                      <Link
                        href={`/${i18nLocale}/recruiters/sign_up`}
                        color="textPrimary"
                        underline="hover"
                      >
                        <FormattedMessage id="landing_recruiter.account.create_account" />
                      </Link>
                    </ListItem>
                    {isRecruiterPage && !currentRecruiter && (
                      <>
                        <ListItem style={{ justifyContent: listItemPosition }}>
                          <Link
                            href="#"
                            onClick={() => setShowModal(true)}
                            color="textPrimary"
                            underline="hover"
                          >
                            <FormattedMessage id="footer.download_prices" />
                          </Link>
                          <Modal
                            open={showModal}
                            onClose={() => setShowModal(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <PricingModal
                              closeModal={() => setShowModal(false)}
                            />
                          </Modal>
                        </ListItem>
                      </>
                    )}
                  </List>
                </Box>
              </Grid>
            )}

            {isDesktop && (
              <Grid item xs={12} md={2}>
                <Box my={1}>
                  <List>
                    <ListItem style={{ justifyContent: listItemPosition }}>
                      <Typography variant="h4">
                        <b>
                          <FormattedMessage id="footer.help" />
                        </b>
                      </Typography>
                    </ListItem>
                    {connected ? (
                      <>
                        <ListItem style={{ justifyContent: listItemPosition }}>
                          <Link
                            href={`/${i18nLocale}/${
                              currentCandidate ? "candidate" : "recruiter"
                            }/FAQ`}
                            style={{ fontStyle: "Work Sans" }}
                            color="textPrimary"
                            underline="hover"
                          >
                            <FormattedMessage id="footer.faq" />
                          </Link>
                        </ListItem>
                        <ListItem style={{ justifyContent: listItemPosition }}>
                          <Link
                            href={getUrl("contact-us")}
                            style={{ fontStyle: "Work Sans" }}
                            color="textPrimary"
                            underline="hover"
                          >
                            <FormattedMessage id="contact_us" />
                          </Link>
                        </ListItem>
                      </>
                    ) : (
                      <ListItem style={{ justifyContent: listItemPosition }}>
                        <Link
                          href={`/${i18nLocale}${
                            window.location.pathname.includes("recruiters")
                              ? "/recruiters"
                              : ""
                          }/faq`}
                          style={{ fontStyle: "Work Sans" }}
                          color="textPrimary"
                          underline="hover"
                        >
                          <FormattedMessage id="footer.faq" />
                        </Link>
                      </ListItem>
                    )}
                    {isRecruiterPage && !currentRecruiter && (
                      <>
                        <ListItem style={{ justifyContent: listItemPosition }}>
                          <Link
                            href="https://calendly.com/demo-squarehub/quick-demo-30-min"
                            color="textPrimary"
                            underline="hover"
                          >
                            <FormattedMessage id="landing_recruiter.banner.button" />
                          </Link>
                        </ListItem>
                      </>
                    )}
                    {isRecruiterPage && currentRecruiter && (
                      <>
                        <ListItem style={{ justifyContent: listItemPosition }}>
                          <Link
                            href={`/${i18nLocale}/recruiter/pricing`}
                            color="textPrimary"
                            underline="hover"
                          >
                            <FormattedMessage id="footer.pricing" />
                          </Link>
                        </ListItem>
                      </>
                    )}
                    {/* <ListItem style={{ justifyContent: listItemPosition }}>
                      <Link
                        href={getUrl("join-us")}
                        style={{ fontStyle: "Work Sans" }}
                        color="textPrimary"
                      >
                        <FormattedMessage id="join_squarehub" />
                      </Link>
                    </ListItem>
                    <ListItem style={{ justifyContent: listItemPosition }}>
                      <FormattedMessage id="footer.who_are_we" />
                    </ListItem>
                    <ListItem style={{ justifyContent: listItemPosition }}>
                      <FormattedMessage id="footer.press_partners" />
                    </ListItem> */}
                  </List>
                </Box>
              </Grid>
            )}

            {/* GO TO CANDIDATE */}
            {isDesktop && isRecruiterPage && (
              <Grid item xs={12} md={2}>
                <Box my={1}>
                  <List>
                    <ListItem style={{ justifyContent: listItemPosition }}>
                      <Typography variant="h4">
                        <b>
                          <FormattedMessage id="menu.candidates" />
                        </b>
                      </Typography>
                    </ListItem>
                    <ListItem style={{ justifyContent: listItemPosition }}>
                      <Link
                        href={`/${i18nLocale}/candidates/sign_in`}
                        color="textPrimary"
                        underline="hover"
                      >
                        <FormattedMessage id="nav.recruiter.go_to_candidate" />
                      </Link>
                    </ListItem>
                  </List>
                </Box>
              </Grid>
            )}

            <Hidden mdUp>
              <Grid item xs={12}>
                <Box>
                  <Typography variant="body2" style={{ marginBottom: "16px" }}>
                    <FormattedMessage id="join_social_medias" />
                  </Typography>
                  <SocialMedias />
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container style={{ backgroundColor: theme.palette.text.blue }}>
            {/* Mobile footer links */}
            <Hidden mdUp>
              <Grid item xs={12}>
                <Box my={2} p={1}>
                  <FooterLinks />
                </Box>
              </Grid>
            </Hidden>

            <Grid item xs={12} md={4}>
              <Box py={1}>
                <Typography
                  align="center"
                  style={{
                    color: "white",
                    fontStyle: "italic",
                  }}
                  variant="body3"
                  gutterBottom
                >
                  &copy; Squarehub {new Date().getFullYear()}.{" "}
                  <FormattedMessage id="footer.all_rights_reserved" />.
                </Typography>
              </Box>
            </Grid>

            {/* Desktop footer links */}
            <Hidden smDown>
              <Grid item md={8}>
                <Box display="flex" p={1} justifyContent="flex-end" mr={6}>
                  <FooterLinks />
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    </BrowserRouter>
  );
}
