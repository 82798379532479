import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import validator from "validator";
import { useSnackbar } from "notistack";
import { useTheme, makeStyles } from "@mui/styles";
import { Box, TextField, Button, Typography, Grid, useMediaQuery } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import sh from "bundles/common/utils/sh";
import { replaceCurrentRecruiter } from "bundles/Recruiter/constants/recruiterConstants.js";

const Invitation = ({ onClose }) => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const companyId = useSelector((state) => state.current_recruiter.company.id);
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const useStyles = makeStyles(() => ({
    modalContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: "white",
      borderRadius: "8px",
      padding: isDesktop ? "32px 64px" : "32px",
      minWidth: isDesktop ? "600px" : "unset",
      width: isDesktop ? "unset" : "calc(100% - 32px) !important",
      height: isDesktop ? "unset" : "fit-content",
    },
    headerBox: {
      textAlign: "center",
      marginBottom: "32px",
    },
    inputBox: {
      marginBottom: isDesktop ? "24px" : "16px",
    },
  }));
  const classes = useStyles();

  const [errors, setErrors] = useState({});

  const [recruiterData, setRecruiterData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    company_id: companyId
  });

  const validate = (fieldValues = recruiterData) => {
    let temp = { ...errors };
    let required = <FormattedMessage id="settings.invitations.required" />;
    let notAnEmail = <FormattedMessage id="settings.invitations.not_email" />;

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : required;
      if (fieldValues.email && !validator.isEmail(fieldValues.email))
        temp.email = notAnEmail;
    }

    if ("first_name" in fieldValues) {
      temp.first_name = fieldValues.first_name ? "" : required;
    }

    if ("last_name" in fieldValues) {
      temp.last_name = fieldValues.last_name ? "" : required;
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRecruiterData({
      ...recruiterData,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
    case "ALREADY_TEAMMATE":
      return intl.formatMessage({ id: "settings.invitations.already_teammate" });
    case "ALREADY_INVITED":
      return intl.formatMessage({ id: "settings.invitations.already_invited" });
    case "EXISTS_DIFFERENT_COMPANY":
      return intl.formatMessage({ id: "settings.invitations.exists_different_company" });
    default:
      return intl.formatMessage({ id: "settings.invitations.inviting_error" });
    }
  };

  const handleSubmit = () => {
    if (validate()) {
      sh.post(`/${i18nLocale}/recruiters/invitation`, {
        authenticity_token: authenticityToken,
        recruiter: recruiterData
      })
        .then(() => {
          replaceCurrentRecruiter(dispatch, authenticityToken);
          enqueueSnackbar(intl.formatMessage({ id: "settings.invitations.invitation_sent" }), {
            variant: "success",
          });
          onClose();
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            const errorCode = error.response.data.code;
            const errorMessage = getErrorMessage(errorCode);
            enqueueSnackbar(errorMessage, { variant: "error" });
          } else {
            enqueueSnackbar(intl.formatMessage({ id: "settings.invitations.inviting_error" }), {
              variant: "error",
            });
          }
        });
    }
  };


  return (
    <Box className={classes.modalContainer}>
      <Box className={classes.headerBox}>
        <Typography variant="h4">
          <FormattedMessage id="settings.invitations.title" />
        </Typography>
      </Box>
      <Box className={classes.inputBox}>
        <TextField
          value={recruiterData.email}
          onChange={handleInputChange}
          label={<FormattedMessage id="settings.invitations.recruiter_email" />}
          variant="outlined"
          fullWidth
          name="email"
          error={!!errors.email}
          helperText={errors.email ? <FormattedMessage id="settings.invitations.email_error" /> : ""}
        />
      </Box>
      <Box className={classes.inputBox}>
        <TextField
          value={recruiterData.first_name}
          onChange={handleInputChange}
          label={<FormattedMessage id="settings.invitations.first_name" />}
          variant="outlined"
          fullWidth
          name="first_name"
          error={!!errors.first_name}
          helperText={errors.first_name}
        />
      </Box>
      <Box className={classes.inputBox}>
        <TextField
          value={recruiterData.last_name}
          onChange={handleInputChange}
          label={<FormattedMessage id="settings.invitations.last_name" />}
          variant="outlined"
          fullWidth
          name="last_name"
          error={!!errors.last_name}
          helperText={errors.last_name}
        />
      </Box>
      <Grid container spacing={isDesktop ? 4 : 2} className={classes.inputBox}>
        <Grid item xs={12}>
          <Button onClick={handleSubmit} fullWidth variant="rounded" color="primaryContained">
            <FormattedMessage id="settings.invitations.send_invitation" />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Invitation;
