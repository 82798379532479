import { useLocation, useNavigate } from "react-router-dom";
import sh from "bundles/common/utils/sh";
import { useSelector } from "react-redux";

// Event snippet for Inscription SH conversion page In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button.
function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof url != "undefined") {
      window.location = url;
    }
  };
  gtag("event", "conversion", {
    send_to: "AW-10868281309/5SsrCI7Z66sDEN2fs74o",
    event_callback: callback,
  });
  return false;
}

// Event triggers for Download pricing Event to pixel trackers
export function trackDownloadPricing() {
  if (typeof gtag !== "undefined")
    gtag("event", "conversion", {
      send_to: "AW-10868281309/XH9vCMPT0IMYEN2fs74o",
    });
  if (typeof fbq !== "undefined") fbq("trackCustom", "Download Pricing");
}

// Event triggers for Order Event to pixel trackers
export function trackOrder() {
  if (typeof gtag !== "undefined")
    gtag("event", "conversion", {
      send_to: "AW-10868281309/ijuWCKWirIgYEN2fs74o",
    });
  if (typeof fbq !== "undefined") fbq("trackCustom", "Credit sold");
}

// Event triggers for JobOfferCreation Event to pixel trackers
export function trackJobOfferCreation() {
  if (typeof gtag !== "undefined")
    gtag("event", "conversion", {
      send_to: "AW-10868281309/dfoVCI-L0oMYEN2fs74o",
      value: 295.0,
      currency: "EUR",
      transaction_id: "",
    });
  if (typeof fbq !== "undefined") fbq("trackCustom", "Job Offer Creation");
}

// Event triggers for JobOfferApplication Event to pixel trackers
export function trackJobOfferApplication() {
  if (typeof gtag !== "undefined")
    gtag("event", "conversion", {
      send_to: "AW-10868281309/L4JMCJKL0oMYEN2fs74o",
      value: 0.0,
    });
  if (typeof fbq !== "undefined") fbq("trackCustom", "Job Offer Application");
  if (window && typeof window.lintrk !== "undefined") {
    window.lintrk("track", { conversion_id: 10945050 });
  }
}

// Function that triggers events to 3 tags (google, facebook and linkedin)
export function trackCandidateSignup() {
  if (typeof gtag !== "undefined") gtag_report_conversion();
  if (typeof fbq !== "undefined") fbq("trackCustom", "Inscription Candidat SH");
  if (window && typeof window.lintrk !== "undefined")
    window.lintrk("track", { conversion_id: 8587642 });
}

export function activeTrackingScripts() {
  return (
    typeof fbq !== "undefined" &&
    typeof fbq.getState !== "undefined" &&
    typeof lintrk !== "undefined" &&
    typeof gtag !== "undefined" &&
    !!google_tag_data?.ics?.entries?.ad_storage?.update
  );
}
async function updateCandidate(candidateId, authenticity_token) {
  // Call setTimeout to call functions at the end of callback queue
  setTimeout(() => {
    trackCandidateSignup();
    sh.put("candidate", {
      authenticity_token,
      candidate: {
        id: candidateId,
        active_tracking_scripts: activeTrackingScripts(),
      },
    });
  }, 500);
}

export const trackSignup = async () => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);
  const authenticity_token = useSelector((state) => state.authenticity_token);
  const candidateId = useSelector((state) => state.current_candidate?.id);

  if (!queryParams.has("signup") || queryParams.get("signup") !== "true")
    return;

  await updateCandidate(candidateId, authenticity_token).then(() => {
    queryParams.delete("signup");
    navigate({
      pathname: pathname,
      search: queryParams.toString(),
      replace: true,
    });
  });
};
