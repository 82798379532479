import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { useIntl } from "react-intl";
import { useDropzone } from "react-dropzone";
import { useSnackbar } from "notistack";
import { formatFileSize } from "../utils/utils";
import { Box, Typography, IconButton } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";

const FileDropzone = ({
  handleFileInput,
  filesLimit,
  initialFiles,
  handleFileDelete,
  deleteJustImportedFile,
  fileName,
  type,
  label,
  ...props
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      border: `1px dashed ${theme.palette.text.hint}`,
      "&:hover": {
        border: `1px dashed ${theme.palette.text.placeholder}`,
      },
      minHeight: props.minHeight || "80px !important",
      height: props.height || "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: theme.spacing(2),
      cursor: "pointer",
    },
    uploadIcon: {
      marginBottom: theme.spacing(1),
    },
    fileName: {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
    },
    closeButton: {
      marginLeft: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
  }));

  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const [uploadedFileName, setUploadedFileName] = useState("");
  const [isFileDeleted, setIsFileDeleted] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    multiple: filesLimit > 1,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        let rejectionReasons = fileRejections.map((f) => f.errors[0]?.code);

        if (rejectionReasons.includes("file-too-large")) {
          let rejectedFile = fileRejections.find(
            (f) => f.errors[0]?.code === "file-too-large"
          ).file;
          enqueueSnackbar(
            intl.formatMessage(
              { id: "devise.signup.drop_reject" },
              {
                fileName: rejectedFile.path,
                sizeLimit: formatFileSize(12582912),
              }
            )
          );
        }

        if (rejectionReasons.includes("file-invalid-type")) {
          enqueueSnackbar(
            intl.formatMessage({ id: "devise.signup.drop_invalid_type" })
          );
        }

        return;
      }

      setUploadedFileName(fileName);
      handleFileInput(acceptedFiles);
    },
  });

  const handleDelete = (event) => {
    event.stopPropagation();
    setUploadedFileName("");
    setIsFileDeleted(true);
    if (handleFileDelete) {
      handleFileDelete();
    }
    if (deleteJustImportedFile) {
      deleteJustImportedFile();
    }
  };

  useEffect(() => {
    if (
      fileName &&
      !uploadedFileName &&
      initialFiles &&
      initialFiles.length > 0
    ) {
      setUploadedFileName(fileName);
    }
  }, [fileName, uploadedFileName, initialFiles]);

  useEffect(() => {
    if (isFileDeleted) {
      setUploadedFileName("");
      setIsFileDeleted(false);
    }
  }, [isFileDeleted]);

  return (
    <Box {...getRootProps()} className={classes.root}>
      {label && (
        <Typography variant="body1" gutterBottom>
          {label}
        </Typography>
      )}
      <input {...getInputProps()} disabled={filesLimit === 0} />
      <CloudUploadIcon
        className={classes.uploadIcon}
        style={{ color: type === "candidate" ? theme.palette.primary.main : theme.palette.secondary.main }}
        fontSize="large"
      />
      {uploadedFileName && (
        <Typography variant="body2" className={classes.fileName}>
          {uploadedFileName}
          <IconButton
            className={classes.closeButton}
            aria-label="Close"
            onClick={handleDelete}
          >
            <CloseIcon />
          </IconButton>
        </Typography>
      )}
    </Box>
  );
};

export default FileDropzone;
