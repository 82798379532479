import React, { useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { Grid, Box } from "@mui/material";
// import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const SignupRecruiter = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const [step, setStep] = useState(2);
  const [recruiterData, setRecruiterData] = useState({});
  const [companyData, setCompanyData] = useState({});

  const nextStep = () => {
    if (step === 3 && query.get("redirectTo")) {
      window.location.href = query.get("redirectTo");
    } else {
      setStep(step + 1);
    }
  };

  const redirectDashboard = () => {
    let redirectTo = query.get("redirectTo");
    window.location.href = redirectTo
      ? `${window.location.origin}/${redirectTo}`
      : `${window.location.origin}/${i18nLocale}/recruiter`;
  };

  const onSignUpFinished = () => {
    enqueueSnackbar(
      intl.formatMessage({
        id: "devise.signup.welcome_to_squarehub",
      }),
      {
        variant: "success",
        autoHideDuration: 1500,
        onExit: redirectDashboard,
      }
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} md={7}>
        <Box my={4} style={{ padding: "32px" }}>
          {/* {step === 1 && <StepOne nextStep={nextStep} />} */}
          {step === 2 && (
            <StepTwo setRecruiterData={setRecruiterData} nextStep={nextStep} />
          )}
          {step === 3 && (
            <StepThree
              recruiterData={recruiterData}
              setRecruiterData={setRecruiterData}
              setCompanyData={setCompanyData}
              nextStep={nextStep}
            />
          )}
          {step === 4 && (
            <StepFour companyData={companyData} nextStep={onSignUpFinished} />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default SignupRecruiter;
