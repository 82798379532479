import React from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
import translations from "app/libs/i18n/translations.json";
// import configureStore from '../store/helloWorldStore';
// import HelloWorldContainer from '../containers/HelloWorldContainer';
import LandingPageRecruiter from "../components/LandingPageRecruiter";
// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
import MainAppBarRecruiter from "bundles/Layouts/MainAppBarRecruiter";
import Footer from "bundles/Layouts/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Faq from "bundles/common/components/Faq";
import ScrollToTop from "bundles/common/utils/scrollToTop";
import Blog from "bundles/common/components/Blog";
import BlogView from "bundles/common/components/BlogView";
import CompanyIndex from "bundles/Candidate/components/CompanyIndex";

const LandingPageRecruiterApp = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const theme = useTheme();
  const locale = i18nLocale;
  const messages = translations[locale];

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <BrowserRouter>
        <MainAppBarRecruiter />
        <Box
          className={theme.background}
          style={{ minHeight: "calc(100vh - 248px)" }}
        >
          <ScrollToTop />
          <Routes>
            <Route
              path={`/${i18nLocale}/recruiters/FAQ`}
              element={<Faq type="recruiter" />}
            />
            <Route
              path={`/${i18nLocale}/recruiters/posts/:id`}
              element={<BlogView type="recruiter" />}
            />
            <Route
              path={`/${i18nLocale}/recruiters/posts/:blogTitle/:id`}
              element={<BlogView type="recruiter" />}
            />
            <Route
              path={`/${i18nLocale}/recruiters/posts`}
              element={<Blog type="recruiter" />}
            />
            <Route
              path={`/${i18nLocale}/trusted_companies`}
              element={<CompanyIndex />}
            />
            <Route
              path={`/${i18nLocale}/recruiters`}
              element={<LandingPageRecruiter />}
            />
          </Routes>
        </Box>
      </BrowserRouter>
      <Footer />
    </IntlProvider>
  );
};

export default LandingPageRecruiterApp;
