import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
  useMediaQuery,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useTheme } from "@mui/styles";
import Autocomplete from "react-google-autocomplete";
import { useSelector } from "react-redux";
import sh from "bundles/common/utils/sh";
import { replaceCurrentRecruiter } from "bundles/Recruiter/constants/recruiterConstants";

const ProjectFormFields = ({ project, classes, handleBack, handleNext, currentRecruiter }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const intl = useIntl();
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const initialValues = {
    id: project?.id,
    location: project?.location || "",
    client_name: project?.client_name || "",
    status: project?.status || "",
    end_date: project?.end_date
  };

  const [values, setValues] = useState(initialValues);
  const [status, setStatus] = useState(project?.status || "");

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    let newValues = { ...values, [name]: value };
    setValues(newValues);
    validate({ [name]: value });

    if (name === 'status') {
      setStatus(value);
    }
  };

  const handleSaveFormFields = () => {
    setLoading(true);
    if (validate(values)) {
      let project_data = {
        id: project.id,
        company_id: currentRecruiter.company.id,
        status: status,
        location: values.location,
        client_name: values.client_name,
        end_date: values.end_date,
      };

      sh.put(`projects/${project_data.id}`, {
        authenticity_token: authenticityToken,
        project_data,
      })
        .then(() => {
          replaceCurrentRecruiter(dispatch, authenticityToken, () => { });
        })
        .catch((err) => {
          console.error(err);
          enqueueSnackbar(
            intl.formatMessage({ id: "settings.save_failed" }),
            { variant: "error" }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };


  const validate = (fieldValues = values) => {
    const today = new Date();
    const endDate = new Date(fieldValues.end_date);
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );

    if ("status" in fieldValues) {
      temp.status = fieldValues.status ? "" : required;
    }

    if ("location" in fieldValues) {
      temp.location = fieldValues.location ? "" : required;
    }

    if ("end_date" in fieldValues) {
      if (fieldValues.status === "finished") {
        if (endDate > today) {
          temp.end_date = (
            <FormattedMessage id="project.validation.end_date" />
          );
        } else {
          temp.end_date = fieldValues.end_date ? "" : required;
        }
      } else {
        temp.end_date = "";
      }
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const onPlacesSelect = (place) => {
    if (place.address_components) {
      let newValues = {
        ...values,
        location: place.formatted_address
      };

      setValues(newValues);
    }
  };

  const handleNextAndSubmit = () => {
    const isValid = validate(values);
    if (isValid) {
      handleNext();
      handleSaveFormFields();
    }
  };

  const isEndDateOptional = status === "in_progress" || status === "planned";

  useEffect(() => {
    if (values.end_date) {
      validate({ end_date: values.end_date });
    }
  }, [values.status, values.end_date]);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <Box style={{ display: "flex", justifyContent: 'center', flexGrow: 1 }}>
          <Box style={{ display: "flex", gap: "40px", alignItems: "center", width: '100%' }}>
            <Box style={{ width: "50%" }}>
              <Box className={classes.inputBox} style={{ marginBottom: '50px', height: '60px' }} >
                <TextField
                  size="small"
                  fullWidth
                  required
                  InputLabelProps={{ required: false }}
                  name="location"
                  variant="outlined"
                  color="secondary"
                  label={intl.formatMessage({
                    id: "project.location",
                  })}
                  id="location_autocomplete"
                  placeholder={intl.formatMessage({
                    id: "devise.signup.search_city_or_zipcode",
                  })}
                  InputProps={{
                    inputComponent: ({ ...props }) => (
                      <Autocomplete
                        /* eslint-disable no-undef */
                        apiKey={process.env.GOOGLE_AUTOCOMPLETE_API_KEY}
                        language={i18nLocale}
                        {...props}
                        onPlaceSelected={onPlacesSelect}
                        defaultValue={values.location}
                      />
                    ),
                  }}
                  {...(errors["location"] && {
                    error: true,
                    helperText: errors["location"],
                  })}
                />
              </Box>
              <Box className={classes.inputBox} style={{ height: '60px' }}>
                <TextField
                  size="small"
                  fullWidth
                  required
                  InputLabelProps={{ required: false }}
                  value={values.client_name}
                  type="text"
                  name="client_name"
                  onBlur={handleInputValue}
                  onChange={handleInputValue}
                  variant="outlined"
                  color="secondary"
                  label={<FormattedMessage id="project.client_name" />}
                  {...(errors["client_name"] && {
                    error: true,
                    helperText: errors["client_name"],
                  })}
                />
              </Box>
            </Box>
            <Box style={{ width: "50%" }}>
              <Box className={classes.inputBox} style={{ marginBottom: '50px', height: '60px' }} >
                <FormControl
                  fullWidth
                  size="small"
                  variant="outlined"
                  {...(errors["status"] && {
                    error: true,
                    helperText: errors["status"],
                  })}
                >
                  <InputLabel
                    id="status-label"
                    htmlFor="status"
                    className={classes.inputLabel}
                  >
                    <FormattedMessage id="project.status" />
                  </InputLabel>
                  <Select
                    id="status"
                    name="status"
                    labelId="status-label"
                    value={status}
                    color="secondary"
                    onChange={handleInputValue}
                    label={
                      <FormattedMessage id="project.status" />
                    }
                  >
                    <MenuItem value="planned">
                      <FormattedMessage id="project.status_values.planned" />
                    </MenuItem>
                    <MenuItem value="in_progress">
                      <FormattedMessage id="project.status_values.in_progress" />
                    </MenuItem>
                    <MenuItem value="finished">
                      <FormattedMessage id="project.status_values.finished" />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box className={classes.inputBox} style={{ height: '60px' }}>
                <TextField
                  size="small"
                  name="end_date"
                  value={values.end_date}
                  type="date"
                  variant="outlined"
                  color="secondary"
                  label={
                    <>
                      <FormattedMessage id="project.end_date" />
                      {isEndDateOptional && <span> (<FormattedMessage id="project.end_date_optional" />)</span>}
                    </>
                  }
                  fullWidth
                  onBlur={handleInputValue}
                  onChange={handleInputValue}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...(errors["end_date"] && {
                    error: true,
                    helperText: errors["end_date"],
                  })}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={handleBack}>
            <FormattedMessage id="back" />
          </Button>
          <Button
            variant="rounded"
            color="secondaryContained"
            onClick={handleNextAndSubmit}>
            <FormattedMessage id="next" />
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ProjectFormFields;
