import React, { useState } from "react";
import PricingModal from "bundles/LandingPageRecruiter/components/PricingModal";
import {
  Grid,
  Typography,
  Box,
  Paper,
  useMediaQuery,
  Modal,
} from "@mui/material";
import { useTheme, makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import Slider from "react-slick";
import styled from "styled-components";

const PricingDetails = ({ onClickBusiness, onClickPremium }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const useStyles = makeStyles((theme) => ({
    container: {
      width: "100% !important",
      marginLeft: "0px !important",
    },
    dots: {
      bottom: "-18px",
      position: "relative",
      "& li.slick-active button::before": {
        color: theme.palette.secondary.main,
      },
      "& li": {
        "& button::before": {
          fontSize: theme.typography.pxToRem(14),
          color: theme.palette.secondary.lighter,
          opacity: 1,
        },
      },
    },
  }));
  const classes = useStyles();
  const settings = {
    arrows: false,
    dots: true,
    className: "slick-list",
    // dotsClass: `slick-dots ${classes.dots}`,
    appendDots: (dots) => (
      <>
        <div className={`slick-dots ${classes.dots}`}>
          <ul style={{ padding: 0 }}>{dots}</ul>
        </div>
      </>
    ),
    infinite: false,
    adaptiveHeight: true,
    initialSlide: 1,
  };

  const prices = [
    {
      name: "Basic",
      title: "landing_recruiter.prices.basic2024.title",
      number_annonce: "landing_recruiter.prices.basic2024.number_annonce",
      bulletpoints: "landing_recruiter.prices.basic2024.bulletpoints",
      annonce: "landing_recruiter.prices.basic2024.annonce",
      nickname: "basic_2024",
    },
    {
      name: "Business",
      title: "landing_recruiter.prices.business2024.title",
      number_annonce: "landing_recruiter.prices.business2024.number_annonce",
      bulletpoints: "landing_recruiter.prices.business2024.bulletpoints",
      annonce: "landing_recruiter.prices.business2024.annonce",
      offerBulletpoints:
        "landing_recruiter.prices.business2024.offer_bulletpoints",
      nickname: "business_2024",
      popular: true,
    },
    {
      name: "Premium",
      title: "landing_recruiter.prices.premium2024.title",
      number_annonce: "landing_recruiter.prices.premium2024.number_annonce",
      bulletpoints: "landing_recruiter.prices.premium2024.bulletpoints",
      annonce: "landing_recruiter.prices.premium2024.annonce",
      offerBulletpoints:
        "landing_recruiter.prices.premium2024.offer_bulletpoints",
      nickname: "premium_2024",
    },
  ];

  const StyledSlider = styled(Slider)`
    & .slick-list {
      height: inherit;

      & .slick-track {
        display: flex !important;
        height: 100%;

        & .slick-slide {
          height: 450px;
          & div {
            height: 250px;
          }
        }
      }
    }
  `;

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Grid
        container
        spacing={isDesktop ? 12 : 2}
        display={isDesktop ? "flex" : "block"}
        justifyContent="center"
        className={classes.container}
        style={{ marginBottom: "12px" }}
      >
        {isDesktop ? (
          prices.map((price, index) => (
            <Grid
              item
              md={4}
              xs={12}
              key={price.title + index}
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                marginTop: index == 1 ? "-48px" : "0px",
              }}
            >
              {price.popular && (
                <Box position="relative" mt={0}>
                  <Box
                    top="-35px"
                    color="white"
                    width="378px"
                    height="48px"
                    bgcolor={theme.palette.primary.main}
                    pt={1}
                    style={{
                      borderTopLeftRadius: "4px",
                      borderTopRightRadius: "4px",
                      marginLeft: "0px",
                      marginRight: "0px",
                    }}
                  >
                    <Typography
                      align="center"
                      variant="h6"
                      style={{ marginTop: "4px", fontWeight: "bold" }}
                    >
                      <b>
                        <FormattedMessage id="landing_recruiter.prices.popular" />
                      </b>
                    </Typography>
                  </Box>
                </Box>
              )}
              <Paper
                component={Box}
                p={3}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "440px",
                  width: "378px",
                  padding: "0px",
                  marginLeft: index == 0 ? "auto" : "0px",
                  marginRight: index == 0 ? "0px" : "auto",
                  borderTopLeftRadius: price.popular ? "0px" : "4px",
                  borderTopRightRadius: price.popular ? "0px" : "4px",
                }}
              >
                <Box py={1}>
                  <Typography
                    variant="h4"
                    align="center"
                    style={{ paddingTop: "24px", paddingBottom: "10px", fontFamily: "Work Sans Bold" }}
                  >
                    <b>
                      <FormattedMessage id={price.title} />
                    </b>
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "left",
                    flexDirection: "column",
                    height: "100%",
                    padding: "5px",
                  }}
                >
                  <Box py={1}>
                    <FormattedMessage
                      id={price.bulletpoints}
                      values={{
                        li: (chunks) => (
                          <li
                            key={chunks}
                            style={{
                              listStyle: "none",
                              display: "flex",
                              marginBottom: "8px",
                              textAlign: "left",
                            }}
                          >
                            <Typography
                              variant="body2"
                              style={{
                                marginRight: "5px",
                                marginLeft: "8px",
                                color: theme.palette.primary.main,
                              }}
                            >
                              ✓
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{ textAlign: "left" }}
                            >
                              {chunks}
                            </Typography>
                          </li>
                        ),
                      }}
                    />
                    <Box style={{ marginTop: "32px" }}>
                      {price.offerBulletpoints && (
                        <FormattedMessage
                          id={price.offerBulletpoints}
                          values={{
                            li: (chunks) => (
                              <li
                                key={chunks}
                                style={{
                                  listStyle: "none",
                                  display: "flex",
                                  alignItems: "baseline",
                                  marginBottom: "8px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  style={{
                                    marginRight: "5px",
                                    marginLeft: "8px",
                                    color: theme.palette.primary.main,
                                  }}
                                >
                                  ✓
                                </Typography>
                                <Typography variant="body2">
                                  {chunks}
                                  <b
                                    style={{
                                      marginLeft: "8px",
                                      color: theme.palette.primary.main,
                                    }}
                                  >
                                    <FormattedMessage id="landing_recruiter.prices.offer" />
                                  </b>
                                </Typography>
                              </li>
                            ),
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                  {/* <Box
                    py={1}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <Button
                      type="button"
                      variant="rounded"
                      color="primaryOutlined"
                      style={{ width: "200px" }}
                      onClick={() => {
                        if (price.name == "Business") {
                          onClickBusiness();
                        } else {
                          onClickPremium();
                        }
                      }}
                    >
                      <FormattedMessage id="landing_recruiter.prices.selection_button" />
                    </Button>
                  </Box> */}
                </Box>
              </Paper>
            </Grid>
          ))
        ) : (
          <StyledSlider {...settings}>
            {prices.map((price, index) => {
              return (
                <Grid
                  key={`carousel-mobile-${index}`}
                  item
                  xs={12}
                  style={{ height: "inherit" }}
                >
                  <Paper
                    component={Box}
                    p={3}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "auto",
                      padding: "20px",
                      overflow: "hidden",
                    }}
                  >
                    {price.popular && (
                      <Box position="relative" style={{ height: "5px" }}>
                        <Box
                          position="absolute"
                          top="-40px"
                          color="white"
                          bgcolor={theme.palette.primary.main}
                          pt={1}
                          style={{
                            transform: "rotate(45deg)",
                            top: "-1px",
                            right: "-76px",
                            width: "54%",
                            height: "auto",
                          }}
                        >
                          <Typography
                            align="center"
                            variant="h5"
                            style={{
                              paddingBottom: "1px",
                              borderTopLeftRadius: "4px",
                              borderTopRightRadius: "4px",
                            }}
                          >
                            <b>
                              <FormattedMessage id="landing_recruiter.prices.popular" />
                            </b>
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    <Box py={1} height="auto" component="span">
                      <Typography variant="h2" align="center" style={{ fontWeight: "bolder" }}>
                        <b>
                          <FormattedMessage id={price.title} />
                        </b>
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "column",
                        height: "100%",
                        padding: "16px",
                      }}
                    >
                      <Box py={1}>
                        <FormattedMessage
                          id={price.bulletpoints}
                          values={{
                            li: (chunks) => (
                              <li
                                key={chunks}
                                style={{
                                  listStyle: "none",
                                  display: "flex",
                                  alignItems: "baseline",
                                  marginBottom: "4px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  style={{
                                    marginRight: "8px",
                                    color: theme.palette.primary.main,
                                  }}
                                >
                                  ✓
                                </Typography>
                                <Typography variant="body2">{chunks}</Typography>
                              </li>
                            ),
                          }}
                        />
                        <Box style={{ marginTop: "35px" }}>
                          {price.offerBulletpoints && (
                            <FormattedMessage
                              id={price.offerBulletpoints}
                              values={{
                                li: (chunks) => (
                                  <li
                                    key={chunks}
                                    style={{
                                      listStyle: "none",
                                      display: "flex",
                                      alignItems: "baseline",
                                      marginBottom: "4px",
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      style={{
                                        marginRight: "8px",
                                        color: theme.palette.primary.main,
                                      }}
                                    >
                                      ✓
                                    </Typography>
                                    <Typography variant="body2">
                                      {chunks}
                                      <b
                                        style={{
                                          marginLeft: "8px",
                                          color: theme.palette.primary.main,
                                        }}
                                      >
                                        <FormattedMessage id="landing_recruiter.prices.offer" />
                                      </b>
                                    </Typography>
                                  </li>
                                ),
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                      {/* <Box
                        py={1}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Button
                          type="button"
                          variant="rounded"
                          color="primaryOutlined"
                          style={{ width: "80%" }}
                          onClick={() => {
                            if (price.name == "Business") {
                              onClickBusiness();
                            } else {
                              onClickPremium();
                            }
                          }}
                        >
                          <FormattedMessage id="landing_recruiter.prices.selection_button" />
                        </Button>
                      </Box> */}
                    </Box>
                  </Paper>
                </Grid>
              );
            })}
          </StyledSlider>
        )}
      </Grid>
      <Grid
        container
        spacing={isDesktop ? 2 : 4}
        style={{ paddingLeft: "16px", paddingRight: "16px" }}
      ></Grid>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PricingModal closeModal={() => setShowModal(false)} />
      </Modal>
    </>
  );
};

export default PricingDetails;
