import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  IconButton,
  useMediaQuery,
  useTheme, } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import CloseIcon from "@mui/icons-material/Close";

const GalleryCarousel = ({ project }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [carouselOpen, setCarouselOpen] = useState(false);
  const [selectedPictureIndex, setSelectedPictureIndex] = useState(0);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!carouselOpen) return;

      if (event.key === "ArrowLeft") {
        setSelectedPictureIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : project?.gallery_pictures_records.length - 1
        );
      } else if (event.key === "ArrowRight") {
        setSelectedPictureIndex((prevIndex) =>
          prevIndex < project?.gallery_pictures_records.length - 1 ? prevIndex + 1 : 0
        );
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [carouselOpen, project?.gallery_pictures_records.length]);

  const handlePictureClick = (index) => {
    setSelectedPictureIndex(index);
    setCarouselOpen(true);
  };

  const handleCarouselClose = () => {
    setCarouselOpen(false);
  };

  const galleryPicturesToDisplay = isDesktop ? project?.gallery_pictures_records : project?.small_gallery_pictures_records;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          columnGap: isDesktop ? "10px" : "40px",
          rowGap: "16px",
        }}
      >
        {galleryPicturesToDisplay?.map((picture, index) => (
          <Box
            key={index}
            aria-label={`${project?.title || ""} ${index + 1}`}
            sx={{
              width: isDesktop ? "calc(33.33% - 8px)" : "calc(40% - 8px)",
              height: "115px",
              backgroundImage: `url(${picture.url})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              cursor: "pointer",
            }}
            onClick={() => handlePictureClick(index)}
          ></Box>
        ))}
      </Box>

      <Dialog
        open={carouselOpen}
        onClose={handleCarouselClose}
        fullWidth
      >
        <Box sx={{ position: "relative", height: isDesktop ? "400px" : "300px" }}>
          <Carousel
            autoPlay={false}
            animation="slide"
            navButtonsAlwaysVisible
            index={selectedPictureIndex}
            indicators={false}
          >
            {project?.gallery_pictures_records?.map((picture, index) => (
              <Box key={index} sx={{ width: "100%", height: isDesktop ? "400px" : "300px", position: "relative" }}>
                <img
                  src={picture.url}
                  alt={`${project?.title || ""} ${index + 1}`}
                  style={{ width: "100%", height: "100%", objectFit: "cover", filter: "blur(32px)" }}
                />
                <img
                  src={picture.url}
                  alt={`${project?.title || ""} ${index + 1}`}
                  style={{ width: "100%", height: "100%", objectFit: "contain", position: "absolute", top: 0, left: 0 }}
                />
              </Box>
            ))}
          </Carousel>
          <IconButton
            sx={{
              position: "absolute",
              top: "16px",
              right: "16px",
              zIndex: 1000,
            }}
            onClick={handleCarouselClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Dialog>
    </Box>
  );
};

export default GalleryCarousel;
