import React from "react";
import { FormattedMessage } from "react-intl";
import { LoadingButton } from "@mui/lab";
import { Box, CircularProgress } from "@mui/material";
import { pulses } from "bundles/common/utils/utils";

const ShLoadingButton = ({
  type = "button",
  size,
  width,
  color,
  variant,
  pulsating,
  defaultLabelId,
  loading,
  loadingLabelId,
  showFinishedState,
  isSuccessful,
  successLabelId,
  isFailed,
  failLabelId,
  onClick,
}) => {
  const pulseClass = pulses();

  const staleLabel = () => {
    if (showFinishedState && isSuccessful) {
      return <FormattedMessage id={successLabelId} />;
    } else if (showFinishedState && isFailed) {
      return <FormattedMessage id={failLabelId} />;
    } else {
      return <FormattedMessage id={defaultLabelId} />;
    }
  };

  return (
    <LoadingButton
      type={type}
      size={size}
      style={{ minWidth: width }}
      className={pulsating ? pulseClass.pulse : ""}
      color={color}
      variant={variant}
      onClick={onClick}
      loading={loading}
      loadingIndicator={
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Box style={{ minWidth: "24px", display: "flex" }}>
            <CircularProgress color="inherit" size={16} />
          </Box>
          <Box style={{ whiteSpace: "nowrap" }}>
            <FormattedMessage id={loadingLabelId} />
          </Box>
        </Box>
      }
    >
      {staleLabel()}
    </LoadingButton>
  );
};

export default ShLoadingButton;
