import React, { useCallback } from "react";
import {
  Grid,
  Typography,
  Box,
  Paper,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useTheme, makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import ProfilePicture from "bundles/common/components/ProfilePicture";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useSelector, useDispatch } from "react-redux";
import sh from "bundles/common/utils/sh";
import { truncate } from "bundles/common/utils/utils";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    padding: "8px 8px",
    height: "100%",
    cursor: "pointer",
  },

  cardContainerActive: {
    border: `2px solid ${theme.palette.common.sh_grey}`,
    padding: "6px 6px",
    boxShadow: "0px 1px 1px -1px rgb(0 0 0 / 20%)",
  },
}));

const NewCandidateCard = ({
  candidate,
  onClickOnCandidate,
  active,
  favorite = false,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();

  const availabilityColors = {
    looking: theme.palette.primary.main,
    listening: theme.palette.secondary.main,
    currently_employed: theme.palette.common.sh_grey,
  };

  const isFavorite =
    currentRecruiter?.favorite_candidate_ids?.includes(candidate?.id) ||
    favorite;

  const updateCurrentRecruiter = useCallback(
    (recruiter) => {
      dispatch({ type: "UPDATE_CURRENT_RECRUITER", payload: recruiter });
    },
    [dispatch]
  );

  const handleFavorite = (e) => {
    e.stopPropagation();

    let currentFavorites;
    if (isFavorite) {
      currentFavorites = currentRecruiter?.favorite_candidate_ids?.filter(
        (favorite) => favorite != `${candidate?.id}`
      );
    } else {
      currentFavorites = currentRecruiter?.favorite_candidate_ids?.concat([
        candidate?.id,
      ]);
    }

    sh.put("update_favorite_candidates", {
      authenticity_token: authenticityToken,
      favorite_candidates: currentFavorites,
    }).then((res) => {
      if (res.data) {
        updateCurrentRecruiter(res.data);
      }
    });
  };

  return (
    <>
      <Box
        style={{
          position: "relative",
          top: "10px",
          left: "94px",
        }}
      >
        {candidate?.availability && (
          <Box
            style={{
              height: "fit-content",
              textAlign: "center",
              display: "flex",
              justifyContent: "start",
            }}
          >
            <Typography
              variant="body3"
              style={{
                backgroundColor: availabilityColors[candidate?.availability],
                color: theme.palette.common.white,
                borderRadius: "30px",
                padding: "2px 8px",
              }}
            >
              <FormattedMessage id={`candidate.${candidate?.availability}`} />
            </Typography>
          </Box>
        )}
      </Box>
      <Grid
        container
        component={Paper}
        className={`${classes.cardContainer} ${
          active ? classes.cardContainerActive : ""
        }`}
        onClick={() => onClickOnCandidate(candidate)}
      >
        <Grid item xs={12} className={classes.paperUp}>
          <Box
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box style={{ display: "flex", columnGap: "16px" }}>
              <Box style={{ paddingLeft: "8px" }}>
                <ProfilePicture
                  rounded
                  picture={candidate?.profile_picture_url}
                  picturesmall={candidate?.small_profile_picture_url}
                  size={70}
                  initials={
                    candidate?.first_name[0] +
                    (candidate?.last_name ? candidate?.last_name[0] : "")
                  }
                  alt={`${candidate?.first_name} ${candidate?.last_name}`}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box style={{ paddingTop: "8px" }}>
                  <Typography variant="body1bold">
                    {truncate(candidate?.job_title, isDesktop ? 36 : 24)}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2bold">
                    {candidate?.first_name} {candidate?.last_name}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body3">
                    {candidate?.region ? (
                      <FormattedMessage id={`regions.${candidate.region}`} />
                    ) : (
                      candidate?.city
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box display="flex" alignItems="center">
              <IconButton
                aria-label="edit-personal-info"
                onClick={handleFavorite}
              >
                {isFavorite ? (
                  <FavoriteIcon
                    style={{
                      color: theme.palette.text.blue,
                      width: "32px",
                      height: "32px",
                    }}
                  />
                ) : (
                  <FavoriteBorderIcon
                    style={{
                      color: theme.palette.text.blue,
                      width: "32px",
                      height: "32px",
                    }}
                  />
                )}
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default NewCandidateCard;
