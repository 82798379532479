import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import sh from "bundles/common/utils/sh";
import CardSelect from "bundles/common/components/CardSelect";
import { replaceCurrentCandidate } from "bundles/Candidate/constants/candidateConstants";
import {
  availabilities,
  companyIndustryMap,
  candidateStatus,
} from "bundles/common/utils/constants";

const YourSearch = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const currentCandidate = useSelector((state) => state.current_candidate);
  const dispatch = useDispatch();
  const theme = useTheme();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const initialFormValues = {
    availability: currentCandidate.availability || "",
    desired_sector: currentCandidate.desired_sector || "",
    candidate_status: currentCandidate.candidate_status || "",
    desired_job_title: currentCandidate.desired_job_title || ""
  };

  const [hardSkills, setHardSkills] = useState(
    currentCandidate.hard_skills || []
  );
  const [softSkills, setSoftSkills] = useState(
    currentCandidate.soft_skills || []
  );
  const [languageSkills, setLanguageSkills] = useState(
    currentCandidate.language_skills || []
  );
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleCardSelected = (name, value) => {
    handleChange(name, value);
  };

  const handleChange = (name, value) => {
    let newValues = { ...values, [name]: value };
    setValues(newValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const hard_skills_temp = hardSkills.filter((skill) => !!skill.name);
    const soft_skills_temp = softSkills.filter((skill) => !!skill.name);
    const languages_temp = languageSkills.filter((skill) => !!skill.name);

    sh.post(`/update_skills/${currentCandidate.id}`, {
      authenticity_token: authenticityToken,
      skills: [...hard_skills_temp, ...soft_skills_temp, ...languages_temp],
    })
      .then(() => {
        sh.put("candidate", {
          authenticity_token: authenticityToken,
          candidate: { ...values, id: currentCandidate.id },
        })
          .then((res) => {
            replaceCurrentCandidate(dispatch, authenticityToken, () => {});
            enqueueSnackbar(
              intl.formatMessage(
                { id: "candidate.updated_profile" },
                { percentage: res?.data.profile_completion }
              ),
              {
                variant: "success",
              }
            );
          })
          .catch((err) => {
            console.error(err);
            enqueueSnackbar("Oops... Something went wrong", {
              variant: "error",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setHardSkills(hard_skills_temp);
        setSoftSkills(soft_skills_temp);
        setLanguageSkills(languages_temp);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: "8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {isDesktop && (
            <Typography variant="h4">
              <FormattedMessage id="settings.your_search" />
            </Typography>
          )}
          <Button
            disabled={loading}
            type="submit"
            variant="rounded"
            color="primaryContained"
            style={{ width: isDesktop ? "144px" : "100%" }}
          >
            <FormattedMessage id="save" />
          </Button>
        </Grid>

        {/* About */}
        <Grid item xs={12}>
          <Box style={{ marginTop: "0px", marginBottom: "32px" }}>
            <TextField
              variant="outlined"
              label={intl.formatMessage({ id: "candidate.desired_job_title" })}
              value={values.desired_job_title}
              onChange={(e) => handleChange("desired_job_title", e.target.value)}
            />
          </Box>
          <Box style={{ marginTop: "0px", marginBottom: "8px" }}>
            <Typography variant="h5">
              <FormattedMessage id="settings.you_are" />
            </Typography>
          </Box>
          <CardSelect
            justifyContent="space-between"
            color="primary"
            fieldName="availability"
            selected={values.availability}
            values={availabilities.map((availability) => {
              return {
                code: availability,
                label: intl.formatMessage({
                  id: `candidate.${availability}`,
                }),
              };
            })}
            onChange={handleCardSelected}
          />
          {errors["availability"] && (
            <p
              className="MuiFormHelperText-root MuiFormHelperText-sizeSmall Mui-error"
              style={{ color: "#f44336" }}
            >
              {errors["availability"]}
            </p>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box style={{ marginTop: "0px", marginBottom: "8px" }}>
            <Typography variant="h5">
              <FormattedMessage id="settings.of_a_job_in" />
            </Typography>
          </Box>
          <CardSelect
            justifyContent="space-between"
            color="primary"
            fieldName="desired_sector"
            selected={values.desired_sector}
            values={companyIndustryMap.map((industry) => {
              return {
                code: industry,
                label: intl.formatMessage({
                  id: `company.company_industry.${industry}`,
                }),
              };
            })}
            onChange={handleCardSelected}
          />
          {errors["desired_sector"] && (
            <p
              className="MuiFormHelperText-root MuiFormHelperText-sizeSmall Mui-error"
              style={{ color: "#f44336" }}
            >
              {errors["desired_sector"]}
            </p>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box style={{ marginTop: "0px", marginBottom: "8px" }}>
            <Typography variant="h5">
              <FormattedMessage id="settings.with_the_status_of" />
            </Typography>
          </Box>
          <CardSelect
            justifyContent="space-between"
            color="primary"
            fieldName="candidate_status"
            selected={values.candidate_status}
            values={candidateStatus.map((status) => {
              return {
                code: status,
                label: intl.formatMessage({
                  id: `candidate.status_map.${status}`,
                }),
              };
            })}
            onChange={handleCardSelected}
          />
          {errors["candidate_status"] && (
            <p
              className="MuiFormHelperText-root MuiFormHelperText-sizeSmall Mui-error"
              style={{ color: "#f44336" }}
            >
              {errors["candidate_status"]}
            </p>
          )}
        </Grid>

        <Grid item xs={12} style={{ marginTop: "32px" }}>
          <Divider />
        </Grid>

        {/* Save button */}
        <Grid item xs={12} textAlign="center">
          <Button
            disabled={loading}
            type="submit"
            variant="rounded"
            color="primaryContained"
            style={{
              width: isDesktop ? "144px" : "100%",
              marginTop: "32px",
              marginBottom: "8px",
            }}
          >
            <FormattedMessage id="save" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default YourSearch;
