import React from "react";
import MobileLandingPage from "./MobileLandingPage";
import DesktopLandingPage from "./DesktopLandingPage";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const LandingPage = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      {isDesktop ? <DesktopLandingPage /> : <MobileLandingPage />}
    </>
  );
};

export default LandingPage;
