import React from "react";
import { Grid, Typography } from "@mui/material";
import PasswordEdit from "bundles/common/components/PasswordEdit";
import { FormattedMessage } from "react-intl";

const Password = () => {
  return (
    <Grid container>
      <Grid item xs={12} style={{ marginBottom: "16px" }}>
        <Typography variant="h4">
          <FormattedMessage id="settings.change_password" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <PasswordEdit userType="recruiter" />
      </Grid>
    </Grid>
  );
};

export default Password;
