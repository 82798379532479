import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import sh from "bundles/common/utils/sh";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GalleryCarousel from "./GalleryCarousel";

const ProjectPage = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { state } = useLocation();

  const authenticityToken = useSelector((state) => state.authenticity_token);
  const params = useParams();
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const currentRecruiter = useSelector((state) => state.current_recruiter);

  const [project, setProject] = useState(state?.project);

  const [projectCompany, setProjectCompany] = useState();

  const fetchProject = () => {
    sh.get(`/projects/${params.id}`, {
      authenticity_token: authenticityToken,
    })
      .then((res) => {
        if (res?.data) {
          setProject(res.data?.project);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
      });
  };

  const fetchCompany = (companyId) => {
    sh.get(`/company/${companyId}`, {
      authenticity_token: authenticityToken,
    })
      .then((res) => {
        if (res?.data) {
          setProjectCompany(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (!project?.id) {
      fetchProject();
    }
  }, []);

  useEffect(() => {
    if (project?.company_id) {
      fetchCompany(project.company_id);
    }
  }, [project]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "16px",
      }}
    >
      <Container maxWidth={isDesktop ? "lg" : "md"}>
        <Box
          sx={{
            borderRadius: "16px",
            border: "1px solid #e0e0e0",
            marginBottom: "16px",
            backgroundColor: "white",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: isDesktop ? "350px" : "200px",
              position: "relative",
              marginBottom: "16px",
              overflow: "hidden",
            }}
          >
            <img
              src={project?.cover_picture_url}
              srcSet={`${project?.cover_picture_url} 1x, ${project?.small_cover_picture_url} 0.5x`}
              alt={project?.title || "Project Cover"}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
            <Button
              variant="rounded"
              color="primaryContained"
              href={currentRecruiter ? `/${i18nLocale}/recruiter/company` : `/${i18nLocale}/companies/${projectCompany?.encoded_name}/${project?.company_id}`}
              sx={{
                position: "absolute",
                bottom: "16px",
                right: "16px",
                ...(isDesktop && { fontSize: "inherit", width: "25%" }),
                ...(isDesktop || { fontSize: "12px", padding: "4px 10px" }),
              }}
            >
              <FormattedMessage id="settings.company" />
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              marginBottom: "16px",
              padding: isDesktop ? "32px 64px" : "16px 32px",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: isDesktop ? "row" : "column",
              }}
            >
              <Box sx={{ width: isDesktop ? "50%" : "100%" }}>
                <Typography
                  variant="h1"
                  style={{
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    marginBottom: "8px",
                    fontFamily: "Montserrat, sans-serif",
                    fontSize: isDesktop ? "28px" : "14px"
                  }}
                >
                  {project?.title}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    marginBottom: "18px",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  {projectCompany?.name}
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: isDesktop ? "42px" : "24px",
                  }}
                >
                  <LocationOnIcon sx={{ marginRight: "8px" }} />
                  <Typography variant="body1">{project?.location}</Typography>
                </Box>
              </Box>
              <Box sx={{ width: isDesktop ? "50%" : "100%" }}>
                <TableContainer sx={{ border: "1px solid rgba(0, 0, 0, 0.6)", borderRadius: "8px", marginBottom: "32px" }}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            borderRight: "1px solid rgba(0, 0, 0, 0.6)",
                            textAlign: "center",
                            padding: "12px 0",
                            width: "33%",
                          }}
                        >
                          <Box style={{ marginBottom: "6px" }}>
                            <Typography variant="h7" style={{ color: "grey" }}>
                              <FormattedMessage id="project.end_date_year" />
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="h5">
                              <strong style={{ color: theme.palette.secondary.main }}>
                                {project?.end_date ? new Date(project.end_date).getFullYear() : <FormattedMessage id="undefined" />}
                              </strong>
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderRight: "1px solid rgba(0, 0, 0, 0.6)",
                            textAlign: "center",
                            padding: "12px 0",
                            width: "33%",
                          }}
                        >
                          <Box style={{ marginBottom: "6px" }}>
                            <Typography variant="h7" style={{ color: "grey" }}>
                              <FormattedMessage id="project.status_only" />
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="h5">
                              <strong style={{ color: theme.palette.secondary.main }}>
                                <FormattedMessage id={`project.status_values.${project?.status}`} />
                              </strong>
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            padding: "12px 0",
                            width: "33%",
                          }}
                        >
                          <Box style={{ marginBottom: "6px" }}>
                            <Typography variant="h7" style={{ color: "grey" }}>
                              <FormattedMessage id="project.client_only" />
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="h5">
                              <strong style={{ color: theme.palette.secondary.main }}>
                                {project?.client_name || <FormattedMessage id="undefined" />}
                              </strong>
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: isDesktop ? "row" : "column",
                  justifyContent: "space-between"
                }}
              >
                <Box sx={{ width: isDesktop ? "50%" : "100%" }}>
                  <Typography variant="h6" style={{ fontWeight: "bold", marginBottom: "16px", fontSize: isDesktop ? "inherit" : "14px" }}>
                    <FormattedMessage id="project.only_description" />
                  </Typography>
                  <Typography variant="body1">
                    <div dangerouslySetInnerHTML={{ __html: project?.description }} />
                  </Typography>
                </Box>
                <Box sx={{ width: isDesktop ? "50%" : "100%" }}>
                  <Typography variant="h6" style={{ fontWeight: "bold", marginBottom: "16px", fontSize: isDesktop ? "inherit" : "14px" }}>
                    <FormattedMessage id="project.gallery_only" />
                  </Typography>

                  {project?.gallery_pictures_records.length === 0 ? (
                    <Typography>
                      <FormattedMessage id="project.no_picture" />
                    </Typography>
                  ) : (
                    <GalleryCarousel project={project} />
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: theme.spacing(6),
              }}
            >
              <Button
                variant="rounded"
                color="secondaryOutlined"
                href={currentRecruiter ? `/${i18nLocale}/recruiter/company` : `/${i18nLocale}/companies/${projectCompany?.encoded_name}/${project?.company_id}`}
                sx={{
                  ...(isDesktop && { fontSize: "inherit", width: "25%" }),
                  ...(isDesktop || { fontSize: "12px", padding: "4px 10px" }),
                }}
              >
                <FormattedMessage id="project.more_projects" />
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ProjectPage;
