import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Button, Box, Typography, CircularProgress } from "@mui/material";
import BackdropScreen from "bundles/common/components/BackdropScreen";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import ZoomIn from "@mui/icons-material/ZoomIn";
import ZoomOut from "@mui/icons-material/ZoomOut";
import { useTheme } from "@mui/styles";

const ImageCropper = ({ src, filename, ratio, handleNewImg }) => {
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(true);
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState(null);
  const [zoomValue, setZoomValue] = useState(0);

  const getCropData = () => {
    let croppedData = cropper?.getCroppedCanvas();
    if (croppedData) {
      setIsLoading(true);
      setCropData(croppedData.toDataURL());
    } else {
      setIsLoading(false);
      enqueueSnackbar(intl.formatMessage({ id: "failed_snack" }), {
        variant: "error",
      });
    }
  };

  const urltoFile = (url, filename, mimeType) => {
    return fetch(url, { mode: "no-cors" })
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  const handleZoom = (e) => {
    let value = e.target.value;
    if (value != zoomValue) {
      let newZoom = value - zoomValue >= 0 ? 0.1 : -0.1;
      setZoomValue(value);
      cropper.zoom(newZoom);
    }
  };

  useEffect(() => {
    if (cropData != "#") {
      urltoFile(cropData, filename, cropData.split(";")[0]?.split(":")[1]).then(
        function (file) {
          handleNewImg([file], () => {
            setIsLoading(false);
          });
        }
      );
    }
  }, [cropData]);

  return (
    <Box
      style={{
        height: "100%",
        width: "100%",
        padding: "32px",
        display: "flex",
      }}
    >
      <Box style={{ width: "100%", marginRight: "32px" }}>
        <Typography
          variant="h2"
          style={{ textAlign: "center", marginBottom: "32px" }}
        >
          <FormattedMessage id="image_cropper.title" />
        </Typography>
        <Box style={{ width: "100%" }}>
          <BackdropScreen open={isLoading} />
          <Cropper
            style={{ height: 400, width: "100%" }}
            aspectRatio={ratio}
            preview=".cropPreview"
            src={src}
            viewMode={1}
            dragMode="none"
            guides={true}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            modal={false}
            highlight={false}
            center={true}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            zoomOnTouch={false}
            zoomOnWheel={false}
            autoCropArea={1}
            onInitialized={(instance) => {
              setIsLoading(false);
              setCropper(instance);
            }}
          />
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "32px",
            }}
          >
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              style={{ width: "50%" }}
            >
              <ZoomOut />
              <Slider
                color="secondary"
                aria-label="zoom"
                value={zoomValue}
                onChange={handleZoom}
                step={0.1}
                marks
                min={-1}
                max={1}
              />
              <ZoomIn />
            </Stack>
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "32px",
            marginBottom: "16px",
            columnGap: "32px",
          }}
        >
          <Button
            variant="rounded"
            color="secondaryContained"
            onClick={getCropData}
            disabled={isLoading}
            style={{ width: "240px" }}
          >
            <Box justifyContent="center">
              {isLoading && (
                <>
                  <CircularProgress
                    size={16}
                    style={{ color: theme.palette.common.white }}
                  />
                  <FormattedMessage id="image_cropper.saving" />
                </>
              )}
              {!isLoading && <FormattedMessage id="image_cropper.save" />}
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ImageCropper;
