import React from "react";
import { Provider } from "react-redux";

import appStore from "bundles/common/store/appStore";
import RecruiterContainer from "bundles/Recruiter/container/RecruiterContainer";
import SquarehubTheme from "bundles/Layouts/SquarehubTheme";
import { SnackbarProvider } from "notistack";

const RecruiterApp = (props, railsContext) => {
  return (
    <Provider store={appStore(Object.assign(railsContext, props))}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <SquarehubTheme>
          <RecruiterContainer {...props} />
        </SquarehubTheme>
      </SnackbarProvider>
    </Provider>
  );
};

export default RecruiterApp;
