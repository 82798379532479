import React from "react";
import { useIntl } from "react-intl";
import { buttonList } from "../utils/constants";
import { FormHelperText, Grid } from "@mui/material";
import SunEditor from "suneditor-react";

export default function RichTextEditor({ error, helperText, ...props }) {
  const intl = useIntl();

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{ border: error ? "1px solid red" : "" }}
        align="left"
      >
        <SunEditor
          setDefaultStyle="font-family: 'Work Sans';"
          setOptions={{
            buttonList,
            pasteTagsBlacklist: "span",
            attributesBlacklist: {
              all: "style",
            },
            placeholder:
              props.placeholder ||
              intl?.formatMessage({ id: "enter_text_editor" }),
          }}
          height={props.height || "200px"}
          width="100%"
          borderRadius="30px"
          {...props}
        />
      </Grid>
      {error && <FormHelperText error> {helperText} </FormHelperText>}
    </Grid>
  );
}
