import React from "react";

import { makeStyles } from "@mui/styles";
import { Box, Avatar, Grid } from "@mui/material";

const ProfilePicture = ({
  picture,
  picturesmall,
  initials,
  rounded,
  size = 32,
  alt = "profile-picture",
  ...boxProps
}) => {
  const useStyles = makeStyles((theme) => ({
    profilePicture: {
      height: `${size}px !important`,
      width: `${size}px !important`,
      objectFit: rounded && size > 32 ? "cover" : "contain",
      borderRadius: rounded ? "50%" : "4px",
    },
    avatar: {
      height: `${size}px !important`,
      width: `${size}px !important`,
      fontSize: 15,
      color: "white",
      backgroundColor: theme.palette.text.blue,
      borderRadius: rounded ? "50% !important" : null,
    },
    profilePictureContainer: {
      // minWidth: size + 16,
      // maxWidth: size + 16,
      display: "flex",
      justifyContent: "center",
    },
  }));

  const classes = useStyles();

  return (
    <Grid className={classes.profilePictureContainer} item>
      {picture ? (
        <Box
          component="img"
          src={picture}
          srcSet={`${picture} 1x, ${picturesmall} 0.5x`}
          alt={alt}
          className={classes.profilePicture}
          {...boxProps}
        />
      ) : (
        <Avatar variant="square" className={classes.avatar}>
          <b>{initials}</b>
        </Avatar>
      )}
    </Grid>
  );
};

export default ProfilePicture;
