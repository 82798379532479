import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import translations from "app/libs/i18n/translations.json";

const SkillView = ({ value, category, level = false }) => {
  const useStyles = makeStyles((theme) => ({
    skill: {
      borderTopLeftRadius: "30px",
      borderBottomLeftRadius: "30px",
      borderTopRightRadius: level ? "0px" : "30px",
      borderBottomRightRadius: level ? "0px" : "30px",

      padding: "8px 16px",
      textAlign: "center",
    },
    hardSkill: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    softSkill: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    langSkill: {
      backgroundColor: theme.palette.common.sh_grey,
      color: "#fff",

      display: "flex",
    },
    skillLevel: {
      borderTopRightRadius: "30px",
      borderBottomRightRadius: "30px",
      padding: "8px 16px",
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.text.secondary,
    },
    typo: {
      fontSize: "16px",
      whiteSpace: "nowrap",
    },
  }));
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Box display="flex">
      <Box className={`${classes.skill} ${classes[`${category}Skill`]}`}>
        <Box>
          <Typography className={classes.typo}>
            <FormattedMessage
              id={
                !value?.company_id
                  ? intl.formatMessage({
                      id: `skills_list.${category}.${value?.name}`,
                      defaultMessage: value?.name
                    })
                  : value?.name
              }
            />
          </Typography>
        </Box>
      </Box>
      {level && <Box className={classes.skillLevel}>{value.level}</Box>}
    </Box>
  );
};

export default SkillView;
