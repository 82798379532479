// Locales
export const localLanguages = [
  { value: "fr", label: "French", longValue: "french" },
  { value: "en", label: "English", longValue: "english" },
  { value: "nl", label: "Dutch", longValue: "dutch" },
];
export const languages = localLanguages.map((local) => local.longValue);
export const countryMap = {
  andorra: "ad",
  albania: "al",
  austria: "at",
  "bosnia and herzegovina": "ba",
  belgium: "be",
  bulgaria: "bg",
  belarus: "by",
  switzerland: "ch",
  cyprus: "cy",
  "czech republic": "cz",
  germany: "de",
  denmark: "dk",
  estonia: "ee",
  spain: "es",
  finland: "fi",
  france: "fr",
  "united kingdom": "gb",
  guernsey: "gg",
  gibraltar: "gi",
  greece: "gr",
  croatia: "hr",
  hungary: "hu",
  ireland: "ie",
  iceland: "is",
  italy: "it",
  jersey: "je",
  liechtenstein: "li",
  lithuania: "lt",
  luxembourg: "lu",
  latvia: "lv",
  monaco: "mc",
  moldova: "md",
  montenegro: "me",
  "north macedonia": "mk",
  malta: "mt",
  netherlands: "nl",
  norway: "no",
  poland: "pl",
  portugal: "pt",
  romania: "ro",
  serbia: "rs",
  russia: "ru",
  sweden: "se",
  slovenia: "si",
  slovakia: "sk",
  "san marino": "sm",
  ukraine: "ua",
  kosovo: "xk",
};

// Candidate

function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

export const availability = ["candidate.available", "candidate.unavailable"];
export const availabilities = ["looking", "listening", "currently_employed"];
export const experience = [
  { id: 1, label: "0-2", value: range(0, 2) },
  { id: 2, label: "3-5", value: range(3, 5) },
  { id: 3, label: "6-10", value: range(6, 10) },
  { id: 4, label: "11-20", value: range(11, 20) },
  { id: 5, label: "21+", value: range(21, 100) },
];

export const offerStatuses = [
  { code: "fixedterm", tooltip: true },
  { code: "permanent", tooltip: true },
  { code: "interim", tooltip: false },
  { code: "freelance", tooltip: false },
  { code: "internship", tooltip: false }
];

export const status_map = {
  "candidate.status_map.independent": null,
  "candidate.status_map.employee": [
    "candidate.status_map.fixedterm",
    "candidate.status_map.permanent",
    "candidate.status_map.trainee",
  ],
  "candidate.status_map.workman": [
    "candidate.status_map.fixedterm",
    "candidate.status_map.permanent",
  ],
  "candidate.status_map.unemployed": null,
  "candidate.status_map.student": [
    "candidate.status_map.bachelor",
    "candidate.status_map.master",
    "candidate.status_map.training",
  ],
};

export const candidateStatus = [
  "independent",
  "employee",
  "workman",
  "student",
];

// Company

export const companyIndustryMap = [
  "architecture",
  "engineering",
  "construction",
  "administration",
];

export const hashCompanySize = [
  {
    label: "1 to 5",
    value: "one_to_five",
  },
  {
    label: "6 to 10",
    value: "six_to_ten",
  },
  {
    label: "11 to 20",
    value: "eleven_to_twenty",
  },
  {
    label: "21 to 50",
    value: "twenty_one_to_fifty",
  },
  {
    label: "51 to 100",
    value: "fifty_one_to_hundred",
  },
  {
    label: "101 to 200",
    value: "hundred_one_to_two_hundred",
  },
  {
    label: "200+",
    value: "two_hundred_plus",
  },
];

export const companySizeMap = hashCompanySize.map((element) => element.value);

export const companyExternalTools = ["platform", "agency"];

// Job offer

export const jobStatuses = ["permanent", "fixedterm", "interim", "freelance", "internship"];
export const jobOfferStatus = ["draft", "online", "closed"];

export const be_regions = [
  "antwerp",
  "limburg",
  "east_flanders",
  "flemish_brabant",
  "west_flanders",
  "walloon_brabant",
  "hainaut",
  "liege",
  "be_luxembourg",
  "province_of_namur",
  "brussels",
];

export const lu_regions = [
  "diekirch",
  "clervaux",
  "redange",
  "vianden",
  "wiltz",
  "grevenmacher",
  "echternach",
  "remich",
  "luxembourg",
  "capellen",
  "esch_sur_alzette",
  "mersch",
];

export const fr_regions = [
  "auvergne_rhone_alpes",
  "bourgogne_franche_comte",
  "brittany",
  "centre_val_de_loire",
  "corsica",
  "grand_est",
  "hauts_de_france",
  "ile_de_france",
  "normandy",
  "nouvelle_aquitaine",
  "occitanie",
  "pays_de_la_loire",
  "provence_alpes_cote_dazur"
];

export const regions = be_regions.concat(lu_regions).concat(fr_regions);

// Errors

export const getResponseMessageId = (res) => {
  return res || "flash.unknown";
};

// SunEditor

export const buttonList = [
  ["undo", "redo"],
  ["bold", "underline", "italic"],
  ["removeFormat"],
  ["horizontalRule", "list"],
];

export const pricingInformations = [
  {
    name: "Essential",
    title: "landing_recruiter.prices.essential.title",
    number_annonce: "landing_recruiter.prices.essential.number_annonce",
    bulletpoints: "landing_recruiter.prices.essential.bulletpoints",
    annonce: "landing_recruiter.prices.essential.annonce",
    offerBulletpoints: "landing_recruiter.prices.essential.offer_bulletpoints",
    nickname: "essential_january_2023",
  },
  {
    name: "Premium",
    title: "landing_recruiter.prices.premium2023.title",
    number_annonce: "landing_recruiter.prices.premium2023.number_annonce",
    bulletpoints: "landing_recruiter.prices.premium2023.bulletpoints",
    annonce: "landing_recruiter.prices.premium2023.annonce",
    offerBulletpoints:
      "landing_recruiter.prices.premium2023.offer_bulletpoints",
    nickname: "premium_2023",
    popular: true,
  },
  {
    name: "Business",
    title: "landing_recruiter.prices.business2023.title",
    number_annonce: "landing_recruiter.prices.business2023.number_annonce",
    bulletpoints: "landing_recruiter.prices.business2023.bulletpoints",
    annonce: "landing_recruiter.prices.business2023.annonce",
    nickname: "february_business_2023",
  },
  {
    name: "Premium",
    title: "landing_recruiter.prices.premium2023.title",
    number_annonce: "landing_recruiter.prices.premium2023.number_annonce",
    bulletpoints: "landing_recruiter.prices.premium2023.bulletpoints",
    annonce: "landing_recruiter.prices.premium2023.annonce",
    offerBulletpoints:
      "landing_recruiter.prices.premium2023.offer_bulletpoints",
    nickname: "february_premium_2023",
    popular: true,
  },
  {
    name: "Basic",
    title: "landing_recruiter.prices.basic2024.title",
    number_annonce: "landing_recruiter.prices.basic2024.number_annonce",
    bulletpoints: "landing_recruiter.prices.basic2024.bulletpoints",
    annonce: "landing_recruiter.prices.basic2024.annonce",
    nickname: "basic_2024",
  },
  {
    name: "Business",
    title: "landing_recruiter.prices.business2024.title",
    number_annonce: "landing_recruiter.prices.business2024.number_annonce",
    bulletpoints: "landing_recruiter.prices.business2024.bulletpoints",
    annonce: "landing_recruiter.prices.business2024.annonce",
    offerBulletpoints:
      "landing_recruiter.prices.business2024.offer_bulletpoints",
    nickname: "business_2024",
    popular: true,
  },
  {
    name: "Premium",
    title: "landing_recruiter.prices.premium2024.title",
    number_annonce: "landing_recruiter.prices.premium2024.number_annonce",
    bulletpoints: "landing_recruiter.prices.premium2024.bulletpoints",
    annonce: "landing_recruiter.prices.premium2024.annonce",
    offerBulletpoints:
      "landing_recruiter.prices.premium2024.offer_bulletpoints",
    nickname: "premium_2024",
  },
];

export const jobOfferAdvantages = {
  advantages: [
    "company_car",
    "meal_vouchers",
    "fuel_card",
    "group_insurance",
    "mobility_plan",
    "phone",
    "trainings",
    "hospital_insurance",
  ],
  environment: [
    "company_restaurant",
    "open_space",
    "parking",
    "daycare",
    "team_building",
    "kitchen",
  ],
  schedules: ["homeworking", "remote_working", "on_site", "flexible_holiday"],
  primes: [
    "end_of_year_bonus",
    "starting_bonus",
    "thirteenth_month",
    "pension_plan",
    "bonus",
  ],
};

export const pictureRatios = {
  coverPicture: 3.5,
  logo: 1,
  profilePicture: 1,
};
