import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, TextField, InputAdornment, Button } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  queryInput: {
    backgroundColor: theme.palette.common.white,
    height: "48px",
    paddingLeft: "16px",
    borderRadius: "30px",
    boxShadow: "0px 1px 1px 0px #00000010",
  },
}));

const MobileChurchBar = ({
  queryText,
  setQueryText,
  fetchRecords,
  placeholder,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const isRecruiter = currentRecruiter != null;

  return (
    <Box>
      <TextField
        style={{ width: "100%", height: "100%" }}
        id="landing_recruiter.searched_candidates"
        value={queryText}
        placeholder={intl.formatMessage({ id: placeholder })}
        onChange={(e) => setQueryText(e.target.value)}
        InputProps={{
          className: classes.queryInput,
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlined />
            </InputAdornment>
          ),
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            fetchRecords();
          }
        }}
        variant="standard"
      />
      <Button
        fullWidth
        variant="rounded"
        color={isRecruiter ? "secondaryContained" : "primaryContained"}
        className={classes.searchButton}
        onClick={() => fetchRecords()}
        style={{ marginTop: "12px" }}
      >
        <FormattedMessage id="search" />
      </Button>
    </Box>
  );
};

export default MobileChurchBar;
