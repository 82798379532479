import React from "react";
import JobApplicationCardDesktop from "./Desktop/JobApplicationCardDesktop";
import JobApplicationCardMobile from "./Mobile/JobApplicationCardMobile";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const JobApplicationCard = ({ jobApplication, onClick}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      {isDesktop
        ?
        <JobApplicationCardDesktop jobApplication={jobApplication} onClick={onClick}/>
        :
        <JobApplicationCardMobile jobApplication={jobApplication} onClick={onClick}/>
      }
    </>
  );
};

export default JobApplicationCard;
