import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import sh from "bundles/common/utils/sh";

const landingBotConfigs = {
  en: process.env.LANDING_BOT_RECRUITER_EN,
  fr: process.env.LANDING_BOT_RECRUITER_FR,
  nl: process.env.LANDING_BOT_RECRUITER_NL,
};
const useStyles = makeStyles((theme) => ({
  bot: {
    height: "100%",
    width: "100%",
    "& div": {
      "& iframe": {
        borderRadius: "30px",
        boxShadow: `${theme.palette.secondary.lighter} 0px 8px 24px`,
      },
    },
  },
}));

export default function LandingBot() {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const containerRef = useRef(null);
  const classes = useStyles();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  var landbot;

  const logUserIn = (email, encryptedPassword) => {
    sh.post(`/${i18nLocale}/recruiters/sign_in`, {
      authenticityToken,
      recruiter: {
        email: email,
        encrypted_password: encryptedPassword,
      },
    })
      .then(() => {
        window.location.href = `/${i18nLocale}/recruiter`;
      })
      .catch((err) => {
        if (err.response?.status == 401) {
          enqueueSnackbar(
            intl.formatMessage({
              id: "devise.login.unauthorized",
            }),
            { variant: "error" }
          );
        }
      });
  };

  const landbotCallback = () => {
    let encryptedPassword = JSON.parse(
      window.authResponse.replaceAll("'", '"')
    )["password"];
    logUserIn(window.email, encryptedPassword);
  };

  useEffect(() => {
    window.landbotCallback = landbotCallback;
    landbot = new Landbot.Container({
      container: containerRef.current,
      configUrl: landingBotConfigs[i18nLocale],
      customData: {
        authenticity_token: authenticityToken,
      },
    });

    return () => {
      landbot.destroy();
    };
  }, [containerRef]);

  return <Box className={`MyLandbot ${classes.bot}`} ref={containerRef} />;
}
