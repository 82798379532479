import React from "react";
import { Grid, Card, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import Authentication from "bundles/Candidate/components/Authentication";

const Signup = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card
          style={{
            width: isDesktop ? "440px" : "100%",
            padding: isDesktop ? "25px" : "16px",
            borderRadius: "8px",
            marginTop: isDesktop ? "32px" : "24px",
            marginBottom: isDesktop ? "64px" : "24px",
          }}
        >
          <Authentication
            onActionFinished={() => window.location.reload()}
            section={"sign_up"}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default Signup;
