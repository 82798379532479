import React from "react";
import { Tabs, Tab } from "@mui/material";
import { styled } from "@mui/styles";

export const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    variant="fullWidth"
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: theme.palette.common.white,
  },

  "& .MuiTabs-flexContainer": {
    height: "56px",
    borderTopLeftRadius: "4px",
  },
}));

export const StyledTab = styled((props) => <Tab {...props} />)(() => ({
  color: "#FFF",
  "&.Mui-selected": {
    color: "#fff",
  },
  fontWeight: "bold",
}));
