// Actions
import sh from "bundles/common/utils/sh";

export const REPLACE_CURRENT_CANDIDATE = "REPLACE_CURRENT_CANDIDATE";

export const replaceCurrentCandidate = (
  dispatch,
  authenticity_token,
  successCallback = () => {}
) => {
  sh.get("current_candidate", {
    authenticity_token,
  })
    .then((res) => {
      dispatch({
        type: REPLACE_CURRENT_CANDIDATE,
        payload: res.data,
      });
      successCallback();
    })
    .catch((err) => {
      console.log(err);
    });
};
