import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Grid,
  Typography,
  Box,
  Button,
  Paper,
  TextField,
  Link,
} from "@mui/material";
import { useTheme, makeStyles } from "@mui/styles";
import FileDropzone from "bundles/common/components/FileDropzone";
import ShLoadingButton from "bundles/common/components/ShLoadingButton";
import { truncate } from "bundles/common/utils/utils";
import Interview from "icons/interview.svg";
import ApplicationSub from "icons/applicationSubmitted.svg";

const ApplicationDialogMobile = ({
  jobOffer,
  setIsApplying,
  jobApplication = null,
  onCompanyClicked,
  handleInputValue={handleInputValue},
  values={values},
  handleSubmit={handleSubmit},
  errors={errors},
  setErrors={setErrors},
  loading={loading},
  setResume={setResume},
  initialFiles={initialFiles},
  handleNewCVDelete={handleNewCVDelete},
  candidate={candidate},
}) => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const theme = useTheme();
  const useStyles = makeStyles((theme) => ({
    modalContainer: {
      marginLeft: "16px",
      marginRight: "16px",
      height: "calc(100% - 32px)",
      overflow: "hidden",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    modalBox: {
      maxHeight: "100%",
      display: "flex",
    },
    applicationAction: {
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
      padding: "8px",
      maxHeight: "100%",
      overflow: "auto",
    },
    jobOfferSummary: {
      borderLeft: `2px solid ${theme.palette.common.sh_lightgrey}`,
      maxHeight: "100%",
      overflow: "auto",
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
      display: "flex",
    },
  }));
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Box className={classes.modalContainer}>
      <Box className={classes.modalBox}>
        <Grid container component={Paper}>
          <Grid
            item
            md={6}
            sm={7}
            xs={12}
            className={classes.applicationAction}
          >
            {candidate?.id &&
              candidate?.id === jobApplication?.candidate_id && (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <Box>
                  <Typography
                    variant="h4"
                    style={{
                      fontWeight: "bold",
                      marginTop: "16px",
                    }}
                  >
                    <FormattedMessage id="applicant.application_submitted" />
                  </Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    padding: "40px",
                  }}
                >
                  <img
                    src={ApplicationSub}
                    alt="Application submitted"
                    width="60%"
                  />
                </Box>
                <Box>
                  <Typography style={{ fontWeight: "bold" }} component="span">
                    <FormattedMessage id="applicant.application_sent" />
                  </Typography>{" "}
                  <Link onClick={onCompanyClicked}>
                    <Typography
                      component="span"
                      style={{
                        color: theme.palette.primary.main,
                        fontWeight: "bold",
                      }}
                    >
                      {truncate(jobOffer?.company?.name, 40)}
                    </Typography>
                  </Link>
                </Box>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    textAlign="center"
                  >
                    <Button
                      disabled={loading}
                      variant="rounded"
                      color="primaryContained"
                      style={{
                        minWidth: "100%",
                        marginTop: "24px",
                        marginBottom: "8px",
                      }}
                      href={`${window.location.origin}/${i18nLocale}/candidate/job_offers?selectedTab=my_jobs`}
                    >
                      <Box justifyContent="center">
                        <FormattedMessage id="application.checkout_applications" />
                      </Box>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    textAlign="center"
                  >
                    <Button
                      variant="rounded"
                      color="primaryOutlined"
                      style={{
                        minWidth: "100%",
                        marginTop: "16px",
                        marginBottom: "32px",
                      }}
                      href={`${window.location.origin}/${i18nLocale}/candidate/job_offers`}
                    >
                      <Typography>
                        <FormattedMessage id="application.more_jobs" />
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
            {candidate?.id &&
              candidate?.id !== jobApplication?.candidate_id && (
              <form
                onSubmit={handleSubmit}
                style={{
                  height: "100%",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Box
                    display={"flex"}
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ flexGrow: 0, rowGap: "32px" }}
                  >
                    <Typography
                      align={"center"}
                      variant="h2"
                      style={{ marginTop: "16px" }}
                    >
                      <b>
                        <FormattedMessage id="application.finalize_your_application" />
                      </b>
                    </Typography>
                    <img src={Interview} alt="Message sent" width="180px" />
                  </Box>
                  <Box
                    style={{
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      style={{
                        width: "100%",
                        marginBottom: "8px",
                      }}
                    >
                      <b>
                        <FormattedMessage id="application.cover_letter" /> :
                      </b>
                    </Box>
                    <TextField
                      name="cover_letter"
                      variant="outlined"
                      value={values.cover_letter}
                      placeholder={intl.formatMessage({
                        id: "application.cover_letter",
                      })}
                      onBlur={handleInputValue}
                      onChange={handleInputValue}
                      fullWidth
                      multiline
                      disabled={jobApplication != null}
                      rows={6}
                      {...(errors["cover_letter"] && {
                        error: true,
                        helperText: errors["cover_letter"],
                      })}
                    />

                    <Box
                      style={{
                        marginTop: "24px",
                        marginBottom: "8px"
                      }}
                    >
                      <b>
                        <FormattedMessage id="application.resume" /> :
                      </b>
                    </Box>
                    <FileDropzone
                      height="150px"
                      dropzoneProps={{ disabled: jobApplication != null }}
                      handleFileInput={(file) => {
                        setResume(file);
                        setErrors({ ...errors, resume: "" });
                      }}
                      filesLimit={1}
                      dropzoneTextId="devise.signup.drag_and_drop_zone"
                      initialFiles={
                        initialFiles
                      }
                      deleteJustImportedFile={handleNewCVDelete}
                      fileName={`${candidate.first_name} ${candidate.last_name} - CV.pdf`}
                      type="candidate"
                    />
                    <Typography
                      align="left"
                      variant="body1"
                      style={{ color: theme.palette.error.main }}
                    >
                      {errors?.resume}
                    </Typography>
                  </Box>
                  <Grid
                    container
                    justifyContent="space-between"
                    style={{ flexGrow: 0 }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={6}
                      textAlign="center"
                      style={{ marginTop: "24px" }}
                    >
                      <ShLoadingButton
                        type="submit"
                        size="large"
                        width="100%"
                        color="primaryContained"
                        variant="rounded"
                        onClick={() => {}}
                        loading={loading}
                        pulsating={false}
                        showFinishedState={true}
                        isSuccessful={jobApplication?.id}
                        defaultLabelId="job_search.apply_to_job"
                        loadingLabelId="job_search.applying"
                        successLabelId="job_search.applied_successfully"
                        failLabelId="job_search.applied_failed"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      textAlign="center"
                    >
                      <Button
                        variant="rounded"
                        color="primaryOutlined"
                        style={{
                          minWidth: "100%",
                          marginTop: "16px",
                          marginBottom: "32px",
                        }}
                        onClick={() => setIsApplying(false)}
                      >
                        <Typography>
                          <FormattedMessage id="cancel" />
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ApplicationDialogMobile;
