import createDOMPurify from "dompurify";
import React from "react";

const Dompurify = createDOMPurify(window);

export default function HTML({ html, className, h2 = false, blacklistedTags = [] }) {
  const baseWhitelistedTags = [
    "a",
    "b",
    "i",
    "p",
    "u",
    "br",
    "em",
    "hr",
    "li",
    "ul",
    "ol",
    "div",
    "strong",
    "span"
  ];

  const WHITELISTED_TAGS = h2 ? [...baseWhitelistedTags, "h2"] : baseWhitelistedTags;

  Dompurify.setConfig({
    IN_PLACE: true, // In place mode for faster sanitization,
    ALLOWED_TAGS: WHITELISTED_TAGS.filter(
      (tag) => !blacklistedTags.includes(tag)
    ), // Only allow tags specified in the whitelist above
    ADD_ATTR: ["target"], // Allow elements with the target attribute
    FORBID_ATTR: ["style"],
  });

  return (
    <div
      style={{ wordBreak: "break-word" }}
      className={className}
      dangerouslySetInnerHTML={{
        __html: Dompurify.sanitize(html),
      }}
    />
  );
}
