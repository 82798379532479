import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import CompanyIndexDesktop from "./Desktop/CompanyIndexDesktop";
import CompanyIndexMobile from "./Mobile/CompanyIndexMobile";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import sh from "bundles/common/utils/sh";

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const CompanyIndex = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const query = useQuery();
  const [companies, setCompanies] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(
    query.get("currentPage") ? parseInt(query.get("currentPage")) : 1
  );
  const [count, setCount] = useState(0);
  const [selectedIndustries, setSelectedIndustries] = useState(
    query.get("selectedIndustries") && query.get("selectedIndustries") != ""
      ? query.get("selectedIndustries")?.split(",")
      : []
  );
  const [loading, setLoading] = useState(false);
  const [queryText, setQueryText] = useState(
    history?.location?.state?.queryText || query.get("queryText") || ""
  );
  const authenticityToken = useSelector((state) => state.authenticity_token);

  useEffect(() => {
    fetchCompanies({
      page,
      query: queryText,
      filters: createFilters(),
    });
  }, [page, queryText, selectedIndustries]);

  const fetchCompanies = ({
    page = 1,
    query = queryText,
    filters = createFilters(),
    callback,
  } = {}) => {
    setLoading(true);
    sh.get("/search/companies", {
      params: {
        authenticity_token: authenticityToken,
        query,
        page,
        filters,
      },
    })
      .then((res) => {
        setCompanies(res.data?.data.companies);
        setPageCount(res.data?.pagy?.pages);
        setCount(res?.data?.pagy?.count);
        callback && callback(res.data?.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createFilters = () => {
    let filters = {};
    if (selectedIndustries?.length > 0)
      Object.assign(filters, { company_industry: selectedIndustries });
    return filters;
  };

  const clearAdvancedFilters = () => {
    setSelectedIndustries([]);
  };

  const getCompanyUrl = (company) => {
    let baseUrl = `${window.location.origin}/${i18nLocale}/companies/${company.encoded_name}/${company.id}?redirectTo=companiesIndex`;
    if (queryText) {
      baseUrl += `&queryText=${queryText}`;
    }
    if (selectedIndustries.length > 0) {
      baseUrl += `&selectedIndustries=${selectedIndustries}`;
    }
    if (page > 1) {
      baseUrl += `&page=${page}`;
    }
    return baseUrl;
  };

  return (
    <>
      {isDesktop
        ?
        <CompanyIndexDesktop
          count={count}
          page={page}
          queryText={queryText}
          setQueryText={setQueryText}
          fetchCompanies={fetchCompanies}
          selectedIndustries={selectedIndustries}
          setSelectedIndustries={setSelectedIndustries}
          clearAdvancedFilters={clearAdvancedFilters}
          loading={loading}
          companies={companies}
          setPage={setPage}
          pageCount={pageCount}
          getCompanyUrl={getCompanyUrl}
        />
        :
        <CompanyIndexMobile
          count={count}
          page={page}
          queryText={queryText}
          setQueryText={setQueryText}
          fetchCompanies={fetchCompanies}
          selectedIndustries={selectedIndustries}
          setSelectedIndustries={setSelectedIndustries}
          clearAdvancedFilters={clearAdvancedFilters}
          loading={loading}
          companies={companies}
          setPage={setPage}
          pageCount={pageCount}
          getCompanyUrl={getCompanyUrl}
        />
      }
    </>
  );
};

export default CompanyIndex;
