import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Modal,
  useMediaQuery,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/styles";
import SetPasswordModal from "bundles/common/components/SetPasswordModal";
import MetricsBar from "./MetricsBar";
import JobOffersSummary from "./Dashboard/JobOffersSummary";
import CVBox from "./Dashboard/CVBox";
import CreditsBox from "./Dashboard/CreditsBox";
import CandidateRecommendationBot from "./Dashboard/CandidateRecommendationBot";
import ProfileBox from "./Dashboard/ProfileBox";
import Poll from "bundles/common/components/Poll";
import sh from "bundles/common/utils/sh";

const Recruiter = () => {
  const currentRecruiter = useSelector(state => state.current_recruiter);
  const authenticityToken = useSelector(state => state.authenticity_token);
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [activePoll, setActivePoll] = useState(null);
  const [shouldShowPoll, setShouldShowPoll] = useState(false);
  const [isSetPasswordModalOpened, setIsSetPasswordModalOpened] = useState(
    currentRecruiter?.password_generated
  );

  const fetchActivePoll = () => {
    sh.get("/active_poll", {
      params: {
        authenticity_token: authenticityToken,
      },
    })
      .then(res => {
        setActivePoll(res.data.active_poll);
        setTimeout(() => setShouldShowPoll(!!res.data.active_poll), 1000);
      })
      .catch(err => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (currentRecruiter) {
      fetchActivePoll();
    }
  }, []);

  return (
    <Container maxWidth="lg">
      <Grid
        container
        justifyContent="center"
        alignItems="self-start"
        spacing={4}
        style={{ marginBottom: "48px" }}
      >
        <Grid item xs={12} style={{ marginTop: isDesktop ? "16px" : "32px" }}>
          <Typography align="center" variant="h2" gutterBottom>
            <b>
              <FormattedMessage
                id="candidate.welcome"
                values={{ name: currentRecruiter.full_name }}
              />
            </b>
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "16px" }}>
          <MetricsBar />
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ marginBottom: isDesktop ? "48px" : "32px" }}
            >
              <JobOffersSummary />
            </Grid>
            <Grid item xs={12}>
              <CVBox />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ marginBottom: isDesktop ? "48px" : "32px" }}
            >
              <ProfileBox />
            </Grid>
            <Grid item xs={12}>
              <CreditsBox />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {activePoll && shouldShowPoll && <Poll poll={activePoll} />}
      <Modal
        open={isSetPasswordModalOpened}
        onClose={() => {}}
        aria-labelledby="set-password-modal-title"
        aria-describedby="set-password-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
        }}
      >
        <SetPasswordModal
          closeModal={() => setIsSetPasswordModalOpened(false)}
          userType="recruiter"
        />
      </Modal>
      <CandidateRecommendationBot />
    </Container>
  );
};

export default Recruiter;
