import React, { useReducer } from "react";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  TextField,
  Button,
  Divider,
  Box,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import sh from "bundles/common/utils/sh";
import { useSnackbar } from "notistack";
import Crane from "icons/crane.svg";
import MessageSent from "icons/message_sent.svg";
import validator from "validator";
import { reducerFn } from "../../common/store/appStore";
import { useSelector } from "react-redux";

const ContactUs = () => {
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const theme = useTheme();
  const currentCandidate = useSelector((state) => state.current_candidate);
  const currentRecruiter = useSelector((state) => state.current_recruiter);

  const isAuthenticated = currentCandidate || currentRecruiter;

  const [{ values, errors, loading, isSuccess }, setState] = useReducer(
    reducerFn,
    {
      values: {
        email: "",
        subject: "",
        content: "",
      },
      errors: {},
      loading: false,
      isSuccess: false,
    }
  );

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );
    let not_an_email = (
      <FormattedMessage id="form_validation.not_an_email"></FormattedMessage>
    );

    if ("email" in fieldValues && !isAuthenticated) {
      temp.email = fieldValues.email ? "" : required;
      if (fieldValues.email) {
        if (!validator.isEmail(fieldValues.email)) temp.email = not_an_email;
        else {
          temp.email = "";
        }
      }
    }

    if ("subject" in fieldValues) {
      temp.subject = fieldValues.subject ? "" : required;
    }
    if ("content" in fieldValues) {
      temp.content = fieldValues.content ? "" : required;
    }

    setState({
      errors: { ...temp },
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;

    setState({
      values: { ...values, [name]: value },
    });
    validate({ [name]: value });
  };

  const handleSubmit = (e) => {
    setState({
      loading: true,
    });
    e.preventDefault();
    if (validate(values)) {
      let params = {
        subject: values.subject,
        content: values.content,
      };
      if (!isAuthenticated) params["email"] = values.email;

      sh.post("contact-us", { data: params })
        .then(() => {
          setState({
            isSuccess: true,
            loading: false,
          });
        })
        .catch(() => {
          setState({
            loading: false,
          });
          enqueueSnackbar(
            intl.formatMessage({
              id: "flash.unknown",
            }),
            { variant: "error" }
          );
        });
    } else {
      setState({
        loading: false,
      });
    }
  };

  return (
    <Grid container justifyContent="center">
      <Container
        maxWidth="lg"
        className={theme.background}
        style={{
          height: "100vh",
          backgroundImage: `url(${Crane})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "right center",
        }}
      >
        <Grid item xs={12} md={6}>
          <Box my={4} />
          <Card
            style={{
              minHeight: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center ",
              padding: 20,
            }}
          >
            {isSuccess ? (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  rowGap: "64px",
                }}
              >
                <img
                  src={MessageSent}
                  alt="Message sent"
                  width="240px"
                  style={{ marginLeft: "-60px" }}
                />
                <Typography variant="h4" align="center">
                  <FormattedMessage id="landing_candidate.contact_form.contact_success" />
                </Typography>
              </Grid>
            ) : (
              <form onSubmit={handleSubmit}>
                <CardHeader
                  disableTypography
                  title={
                    <Typography variant="h2" align="center">
                      <FormattedMessage id="contact_us_page.title" />
                    </Typography>
                  }
                ></CardHeader>
                <CardContent>
                  <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography align="center" variant="h4">
                        <b>
                          <FormattedMessage id="landing_recruiter.contact_form.your_question"></FormattedMessage>
                        </b>
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      {!isAuthenticated && (
                        <TextField
                          fullWidth
                          required
                          type="email"
                          margin="normal"
                          variant="outlined"
                          onBlur={handleInputValue}
                          onChange={handleInputValue}
                          name="email"
                          {...(errors["email"] && {
                            error: true,
                            helperText: errors["email"],
                          })}
                          label={<FormattedMessage id="devise.signup.email" />}
                        />
                      )}
                      <TextField
                        fullWidth
                        required
                        type="text"
                        margin="normal"
                        variant="outlined"
                        onBlur={handleInputValue}
                        onChange={handleInputValue}
                        name="subject"
                        {...(errors["subject"] && {
                          error: true,
                          helperText: errors["subject"],
                        })}
                        label={
                          <FormattedMessage id="landing_candidate.contact_form.subject" />
                        }
                      />
                      <TextField
                        required
                        multiline
                        margin="normal"
                        minRows={4}
                        onBlur={handleInputValue}
                        onChange={handleInputValue}
                        name="content"
                        label={
                          <FormattedMessage id="landing_candidate.contact_form.your_question" />
                        }
                        {...(errors["content"] && {
                          error: true,
                          helperText: errors["content"],
                        })}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} md={4}>
                      <Button
                        fullWidth
                        type="submit"
                        variant="rounded"
                        color="primaryContained"
                        disabled={loading}
                      >
                        <Box justifyContent="center">
                          {loading ? (
                            <CircularProgress size={16} color="secondary">
                              <FormattedMessage id="send" />
                            </CircularProgress>
                          ) : (
                            <FormattedMessage id="send" />
                          )}
                        </Box>
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </form>
            )}
          </Card>
        </Grid>
      </Container>
    </Grid>
  );
};

export default ContactUs;
