import { Grid, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

const TextBlock = ({ titleId, textId }) => {
  return (
    <Grid item xs={12}>
      {titleId && (
        <Typography variant="h6" gutterBottom>
          <b>
            <FormattedMessage id={titleId} />
          </b>
        </Typography>
      )}
      {textId && (
        <Typography variant="body2" style={{ whiteSpace: "pre-wrap" }}>
          <FormattedMessage
            id={textId}
            values={{
              b: (chunks) => <b>{chunks}</b>,
            }}
          />
        </Typography>
      )}
    </Grid>
  );
};

export default TextBlock;
