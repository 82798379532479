import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import PasswordEdit from "bundles/common/components/PasswordEdit";
import Welcome from "welcome.svg";

const SetPasswordModal = ({ closeModal, userType = "candidate" }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const useStyles = makeStyles(() => ({
    modalContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: "white",
      borderRadius: "8px",
      padding: isDesktop ? "32px 64px" : "32px",
      minWidth: isDesktop ? "600px" : "unset",
      width: isDesktop ? "360px" : "calc(100% - 32px) !important",
      height: isDesktop ? "440px" : "fit-content",
    },
    modalBox: {},
    headerBox: {
      textAlign: "center",
      marginBottom: "16px",
    },
    inputBox: {
      marginBottom: isDesktop ? "24px" : "16px",
    },
  }));
  const classes = useStyles();

  return (
    <Box className={classes.modalContainer}>
      <Box className={classes.modalBox}>
        <Box className={classes.headerBox}>
          <Typography variant="h2">
            <FormattedMessage
              id="landing_candidate.welcome_html"
              values={{
                b: (chunks) => <b>{chunks}</b>,
              }}
            />
          </Typography>
          <img src={Welcome} style={{ width: "100px", marginTop: "16px" }} />
        </Box>
        <Box className={classes.inputBox}>
          <FormattedMessage id="settings.choose_password" />:
          <PasswordEdit userType={userType} onFinished={closeModal} />
        </Box>
      </Box>
    </Box>
  );
};

export default SetPasswordModal;
