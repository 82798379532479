import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Button,
  Checkbox,
  Typography,
  ListItemText,
  MenuItem,
  Modal,
  IconButton,
  Divider,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import SelectRegion from "../../../common/components/SelectRegion";
import {
  companyIndustryMap,
  offerStatuses,
  languages,
} from "bundles/common/utils/constants";
import { truncate } from "../../../common/utils/utils";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "32px 24px",
  },
  item: {
    cursor: "pointer",
    alignItems: "center",
    padding: "0px 0px 4px 16px",
  },
  checkboxes: {
    padding: "0px",
  },
  filterTitle: {
    color: theme.palette.common.blue,
  },
  modal: {
    overflow: "auto",
    width: "100%",
    height: "100%",
    backgroundColor: `${theme.palette.common.white} !important`,
  },
  modalContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.common.white,
  },
  modalHeader: {
    height: "68px",
    backgroundColor: theme.palette.primary.main,
  },
  modalBox: {},
  category: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 8px",
  },
  badge: {
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: "16px",
    width: "16px",
  },
  menuItem: {
    height: "24px",
    "&.Mui-selected": {
      backgroundColor: "white !important",
    },
  },
}));

const MobileAdvancedFiltering = ({
  selectedRegions,
  selectedLanguages,
  selectedIndustries,
  selectedJobStatuses,
  onlyFavorites,
  setSelectedRegions,
  setSelectedLanguages,
  setSelectedIndustries,
  setSelectedJobStatuses,
  setOnlyFavorites,
  clearAdvancedFilters,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const [currentExpanded, setCurrentExpanded] = useState(null);
  const query = useQuery();

  useEffect(() => {
    const presetRegions = query.get("selectedRegion");
    const presetIndustries = query.get("selectedSector");

    if (presetRegions) setSelectedRegions(presetRegions.split(","));
    if (presetIndustries) setSelectedIndustries(presetIndustries.split(","));
  }, [query, setSelectedRegions, setSelectedIndustries]);

  const handleLanguages = (lang) => {
    if (selectedLanguages.includes(lang)) {
      setSelectedLanguages(selectedLanguages.filter((a) => a != lang));
    } else {
      setSelectedLanguages(selectedLanguages.concat(lang));
    }
  };

  const handleIndustries = (industry) => {
    if (selectedIndustries.includes(industry)) {
      setSelectedIndustries(selectedIndustries.filter((a) => a != industry));
    } else {
      setSelectedIndustries(selectedIndustries.concat(industry));
    }
  };

  const handleJobStatuses = (status) => {
    if (selectedJobStatuses.includes(status)) {
      setSelectedJobStatuses(selectedJobStatuses.filter((a) => a != status));
    } else {
      setSelectedJobStatuses(selectedJobStatuses.concat(status));
    }
  };

  const onModalCloses = () => {
    setCurrentExpanded(null);
    setExpanded(false);
  };

  const handleCategoryExpansion = (category) => {
    return currentExpanded == category
      ? setCurrentExpanded(null)
      : setCurrentExpanded(category);
  };

  const totalFilters =
    (selectedLanguages.length || 0) +
    (selectedIndustries.length || 0) +
    (selectedJobStatuses.length || 0) +
    (selectedRegions.length || 0) +
    (onlyFavorites ? 1 : 0);

  const hasActiveFilters = totalFilters > 0;

  useEffect(() => {
    if (!expanded) setCurrentExpanded(null);
  }, [expanded]);

  return (
    <Box id="sh-advanced-filtering">
      <Box display="flex" justifyContent="center">
        <Button
          variant="textUnderline"
          style={{
            marginTop: "8px",
            marginBottom: hasActiveFilters ? "0px" : "4px",
          }}
          onClick={() => setExpanded(!expanded)}
        >
          <Box>
            <FormattedMessage id="job_search.advanced_search" />
            {"  "}
            {hasActiveFilters && <span>({totalFilters})</span>}
          </Box>
        </Button>
      </Box>

      {hasActiveFilters && (
        <Box textAlign="center">
          <Button
            variant="text"
            style={{
              padding: "0px",
              marginBottom: "4px",
              height: "fit-content",
            }}
            onClick={clearAdvancedFilters}
          >
            <Typography variant="body3">
              <FormattedMessage id="job_search.clear_filters" />
            </Typography>
          </Button>
        </Box>
      )}

      <Modal
        open={expanded}
        onClose={onModalCloses}
        disableAutoFocus={true}
        className={classes.modal}
        hideBackdrop={true}
      >
        <Box className={classes.modalContainer}>
          <Box className={classes.modalHeader}>
            <IconButton
              aria-label="af-go-back"
              onClick={() => setExpanded(false)}
              style={{
                top: "14px",
                left: "16px",
                position: "absolute",
              }}
            >
              <ArrowCircleLeftIcon
                fontSize="medium"
                style={{ color: "white" }}
              ></ArrowCircleLeftIcon>
            </IconButton>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{ height: "100%" }}
            >
              <Typography
                variant="h2"
                style={{ color: theme.palette.common.white, fontWeight: 600 }}
              >
                <FormattedMessage id="job_search.advanced_search" />
              </Typography>
            </Box>
          </Box>
          <Box className={classes.modalBox}>
            {/* Regions */}
            <Box>
              <Box
                className={classes.category}
                onClick={() => handleCategoryExpansion("region")}
              >
                <Box>
                  <Box>
                    <Typography
                      variant="body1bold"
                      color="secondary"
                      style={{ display: "flex", alignItems: "baseline" }}
                    >
                      <FormattedMessage id="candidate_search.regions"/>
                      {selectedRegions.length > 0 && (
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "8px",
                            textAlign: "center",
                            width: "16px",
                            height: "16px",
                            borderRadius: "50%",
                            color: theme.palette.common.white,
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          <Typography variant="body3">
                            {selectedRegions.length}
                          </Typography>
                        </Box>
                      )}
                    </Typography>
                  </Box>

                  {selectedRegions.length > 0 && (
                    <Box>
                      <Typography>
                        {truncate(
                          selectedRegions
                            .map((r) => {
                              return intl.formatMessage({
                                id: `regions.${r}`,
                              });
                            })
                            .join(", "),
                          40
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box>
                  {currentExpanded == "region" ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </Box>
              </Box>
              {currentExpanded == "region" && (
                <Box>
                  <SelectRegion
                    required
                    multiple
                    labelId="landing_recruiter.searched_location"
                    value={selectedRegions}
                    handleInputValue={(values) => setSelectedRegions(values)}
                    displayEmpty={true}
                    list={true}
                  />
                </Box>
              )}
              <Divider />
            </Box>

            {/* Languages */}
            <Box>
              <Box
                className={classes.category}
                onClick={() => handleCategoryExpansion("lang")}
              >
                <Box>
                  <Box>
                    <Typography
                      variant="body1bold"
                      color="secondary"
                      style={{ display: "flex", alignItems: "baseline" }}
                    >
                      <FormattedMessage id="languages.languages"/>
                      {selectedLanguages.length > 0 && (
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "8px",
                            textAlign: "center",
                            width: "16px",
                            height: "16px",
                            borderRadius: "50%",
                            color: theme.palette.common.white,
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          <Typography variant="body3">
                            {selectedLanguages.length}
                          </Typography>
                        </Box>
                      )}
                    </Typography>
                  </Box>

                  {selectedLanguages.length > 0 && (
                    <Box>
                      <Typography>
                        {truncate(
                          selectedLanguages
                            .map((a) => {
                              return intl.formatMessage({
                                id: `languages.${a}`,
                              });
                            })
                            .join(", "),
                          40
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box>
                  {currentExpanded == "lang" ? <ExpandLess /> : <ExpandMore />}
                </Box>
              </Box>
              {currentExpanded == "lang" && (
                <Box>
                  {languages.map((lang) => {
                    return (
                      <MenuItem
                        onClick={() => handleLanguages(lang)}
                        key={lang}
                        value={lang}
                        className={`${classes.menuItem} ${classes.menuItemOption}`}
                      >
                        <Checkbox checked={selectedLanguages?.includes(lang)} />
                        <ListItemText
                          primary={intl.formatMessage({
                            id: `languages.${lang}`,
                          })}
                        />
                      </MenuItem>
                    );
                  })}
                </Box>
              )}
              <Divider />
            </Box>

            {/* Sectors */}
            <Box>
              <Box
                className={classes.category}
                onClick={() => handleCategoryExpansion("sectors")}
              >
                <Box>
                  <Box>
                    <Typography
                      variant="body1bold"
                      color="secondary"
                      style={{ display: "flex", alignItems: "baseline" }}
                    >
                      <FormattedMessage id="candidate_search.sectors"/>
                      {selectedIndustries.length > 0 && (
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "8px",
                            textAlign: "center",
                            width: "16px",
                            height: "16px",
                            borderRadius: "50%",
                            color: theme.palette.common.white,
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          <Typography variant="body3">
                            {selectedIndustries.length}
                          </Typography>
                        </Box>
                      )}
                    </Typography>
                  </Box>

                  {selectedIndustries.length > 0 && (
                    <Box>
                      <Typography>
                        {truncate(
                          selectedIndustries
                            .map((a) => {
                              return intl.formatMessage({
                                id: `company.company_industry.${a}`,
                              });
                            })
                            .join(", "),
                          40
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box>
                  {currentExpanded == "sectors" ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </Box>
              </Box>
              {currentExpanded == "sectors" && (
                <Box>
                  {companyIndustryMap.map((sector) => {
                    return (
                      <MenuItem
                        onClick={() => handleIndustries(sector)}
                        key={sector}
                        value={sector}
                        className={`${classes.menuItem} ${classes.menuItemOption}`}
                      >
                        <Checkbox
                          checked={selectedIndustries?.includes(sector)}
                        />
                        <ListItemText
                          primary={intl.formatMessage({
                            id: `company.company_industry.${sector}`,
                          })}
                        />
                      </MenuItem>
                    );
                  })}
                </Box>
              )}
              <Divider />
            </Box>

            {/* Offer status */}
            <Box>
              <Box
                className={classes.category}
                onClick={() => handleCategoryExpansion("status")}
              >
                <Box>
                  <Box>
                    <Typography
                      variant="body1bold"
                      color="secondary"
                      style={{ display: "flex", alignItems: "baseline" }}
                    >
                      <FormattedMessage id="candidate.status"/>
                      {selectedJobStatuses.length > 0 && (
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "8px",
                            textAlign: "center",
                            width: "16px",
                            height: "16px",
                            borderRadius: "50%",
                            color: theme.palette.common.white,
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          <Typography variant="body3">
                            {selectedJobStatuses.length}
                          </Typography>
                        </Box>
                      )}
                    </Typography>
                  </Box>

                  {selectedJobStatuses.length > 0 && (
                    <Box>
                      <Typography>
                        {truncate(
                          selectedJobStatuses
                            .map((status) => {
                              return intl.formatMessage({
                                id: `candidate.status_map.${status}`,
                              });
                            })
                            .join(", "),
                          40
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box>
                  {currentExpanded == "status" ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </Box>
              </Box>
              {currentExpanded == "status" && (
                <Box>
                  {offerStatuses.map((status) => {
                    return (
                      <MenuItem
                        onClick={() => handleJobStatuses(status.code)}
                        key={status.code}
                        value={status.code}
                        className={`${classes.menuItem} ${classes.menuItemOption}`}
                      >
                        <Checkbox
                          checked={selectedJobStatuses?.includes(status.code)}
                        />
                        <ListItemText
                          primary={intl.formatMessage({
                            id: `candidate.status_map.${status.code}`,
                          })}
                        />
                      </MenuItem>
                    );
                  })}
                </Box>
              )}
              <Divider />
            </Box>

            {/* Favorites */}
            <Box>
              <Box
                className={classes.category}
                style={{
                  paddingTop: "12px",
                  paddingBottom: "12px",
                  paddingRight: "0px",
                }}
                onClick={() => setOnlyFavorites(!onlyFavorites)}
              >
                <Box style={{ flexGrow: 1 }}>
                  <Box style={{ flexGrow: 1 }}>
                    <Typography
                      variant="body1bold"
                      color="secondary"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormattedMessage id="candidate_search.favorites" />
                      <Checkbox checked={onlyFavorites} />
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Divider />
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default MobileAdvancedFiltering;
