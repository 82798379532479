import React from "react";
import { Box, Grid, Typography, Container, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";

const GeneralSalesConditions = () => {
  const theme = useTheme();
  const useStyles = makeStyles({
    block: {
      marginTop: "24px",
    },
    title: {
      marginBottom: "8px",
      color: theme.palette.primary.main,
    },
    subtitle: {
      marginTop: "16px",
      marginBottom: "8px",
    },
    paragraph: {
      marginBottom: "4px",
    },
    list: {
      marginLeft: "8px",
    },
  });
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box my={2}>
            <Typography variant="h1" align="center">
              <b>
                <FormattedMessage id="footer.general_sales_conditions.general_sales_conditions" />
              </b>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.block}>
          <Typography className={classes.title}>
            <b>
              <FormattedMessage id="footer.general_sales_conditions.application_field_title" />
            </b>
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.application_field_html_1" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.application_field_html_2" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.application_field_html_3" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.application_field_html_4" />
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.block}>
          <Typography className={classes.title}>
            <b>
              <FormattedMessage id="footer.general_sales_conditions.description_title" />
            </b>
          </Typography>
          <Typography className={classes.subtitle}>
            <b>
              <FormattedMessage id="footer.general_sales_conditions.description_subtitle_1" />
            </b>
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.description_html_1_1" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.description_html_1_2" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.description_html_1_3" />
          </Typography>
          <Typography className={classes.list}>
            <FormattedMessage
              id="footer.general_sales_conditions.description_html_1_3_list"
              values={{
                li: (chunks) => (
                  <Typography>
                    <li key={chunks}>{chunks}</li>
                  </Typography>
                ),
              }}
            />
          </Typography>

          <Typography className={classes.subtitle}>
            <b>
              <FormattedMessage id="footer.general_sales_conditions.description_subtitle_2" />
            </b>
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.description_html_2_1" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.description_html_2_2" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.description_html_2_3" />
          </Typography>
          <Typography className={classes.list}>
            <FormattedMessage
              id="footer.general_sales_conditions.description_html_2_3_list"
              values={{
                li: (chunks) => (
                  <Typography>
                    <li key={chunks}>{chunks}</li>
                  </Typography>
                ),
              }}
            />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.description_html_2_4" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.description_html_2_5" />
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.block}>
          <Typography className={classes.title}>
            <b>
              <FormattedMessage id="footer.general_sales_conditions.obligation_title" />
            </b>
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.obligation_html_1" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.obligation_html_2" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.obligation_html_3" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.obligation_html_4" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.obligation_html_5" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.obligation_html_6" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.obligation_html_7" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.obligation_html_8" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.obligation_html_9" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.obligation_html_10" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.obligation_html_11" />
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.block}>
          <Typography className={classes.title}>
            <b>
              <FormattedMessage id="footer.general_sales_conditions.conclusion_title" />
            </b>
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.conclusion_html" />
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.block}>
          <Typography className={classes.title}>
            <b>
              <FormattedMessage id="footer.general_sales_conditions.remuneration_title" />
            </b>
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.remuneration_html_1" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.remuneration_html_2" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.remuneration_html_3" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.remuneration_html_4" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.remuneration_html_5" />
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.block}>
          <Typography className={classes.title}>
            <b>
              <FormattedMessage id="footer.general_sales_conditions.copyright_title" />
            </b>
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.copyright_html_1" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.copyright_html_2" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.copyright_html_3" />
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.block}>
          <Typography className={classes.title}>
            <b>
              <FormattedMessage id="footer.general_sales_conditions.act_of_god_title" />
            </b>
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.act_of_god_html_1" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.act_of_god_html_2" />
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.block}>
          <Typography className={classes.title}>
            <b>
              <FormattedMessage id="footer.general_sales_conditions.privacy_title" />
            </b>
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.privacy_html_1" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.privacy_html_2" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.privacy_html_3" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.privacy_html_4" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.privacy_html_5" />
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.block}>
          <Typography className={classes.title}>
            <b>
              <FormattedMessage id="footer.general_sales_conditions.diverse_title" />
            </b>
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.diverse_html" />
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.block}>
          <Typography className={classes.title}>
            <b>
              <FormattedMessage id="footer.general_sales_conditions.court_title" />
            </b>
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.court_html_1" />
          </Typography>
          <Typography className={classes.paragraph}>
            <FormattedMessage id="footer.general_sales_conditions.court_html_2" />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box my={4} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default GeneralSalesConditions;
