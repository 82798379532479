import React from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Container } from "@mui/material";
import { useTheme } from "@mui/styles";
import translations from "app/libs/i18n/translations.json";
import MainAppBarRecruiter from "bundles/Layouts/MainAppBarRecruiter";
import SignupRecruiter from "bundles/Devise/SignupRecruiter/components/SignupRecruiter";
import Crane from "icons/crane-recruiter.svg";

const SignupRecruiterContainer = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const locale = i18nLocale;
  const messages = translations[locale];
  const theme = useTheme();

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <BrowserRouter>
        <MainAppBarRecruiter />
        <Container
          className={theme.background}
          style={{
            height: "100vh",
            backgroundImage: `url(${Crane})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "right center",
          }}
        >
          <Routes>
            <Route
              path={`/${i18nLocale}/recruiters/sign_up`}
              element={<SignupRecruiter />}
            />
            <Route
              path={`/${i18nLocale}`}
              element={<Navigate to={`/${i18nLocale}/recruiters/sign_up`} />}
            />
          </Routes>
        </Container>
      </BrowserRouter>
    </IntlProvider>
  );
};

export default SignupRecruiterContainer;
