import ReactOnRails from "react-on-rails";

import HelloWorldApp from "bundles/HelloWorld/startup/HelloWorldApp";
import LandingPageApp from "bundles/LandingPage/startup/LandingPageApp";
import LandingPageRecruiterApp from "bundles/LandingPageRecruiter/startup/LandingPageRecruiterApp";
import CandidateApp from "bundles/Candidate/startup/CandidateApp";
import RecruiterApp from "bundles/Recruiter/startup/RecruiterApp";
import LoginApp from "bundles/Devise/Login/startup/LoginApp";
import PasswordApp from "bundles/Devise/Password/startup/PasswordApp";
import LoginRecruiterApp from "bundles/Devise/LoginRecruiter/startup/LoginRecruiterApp";
import SignupApp from "bundles/Devise/Signup/startup/SignupApp";
import SignupRecruiterApp from "bundles/Devise/SignupRecruiter/startup/SignupRecruiterApp";

import appStore from "bundles/common/store/appStore";

ReactOnRails.register({
  HelloWorldApp,
  LandingPageApp,
  LandingPageRecruiterApp,
  CandidateApp,
  RecruiterApp,
  SignupApp,
  SignupRecruiterApp,
  LoginApp,
  LoginRecruiterApp,
  PasswordApp,
});

ReactOnRails.registerStore({
  appStore,
});
