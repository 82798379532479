import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Grid,
  Typography,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import TextBlock from "./TextBlock";

const Cookies = () => {
  const intl = useIntl();

  function createData1(name, input1) {
    return { name, input1 };
  }

  const rows1 = [
    createData1(
      intl.formatMessage({ id: "footer.cookies.grid1_row1_cell2" }),
      intl.formatMessage({ id: "footer.cookies.grid1_row2_cell2" }),
    ),
    createData1(
      intl.formatMessage({ id: "footer.cookies.grid1_row1_cell3" }),
      intl.formatMessage({ id: "footer.cookies.grid1_row2_cell3" }),
    ),
    createData1(
      intl.formatMessage({ id: "footer.cookies.grid1_row1_cell4" }),
      intl.formatMessage({ id: "footer.cookies.grid1_row2_cell4" }),
    ),
  ];

  function createData2(name, input1, input2) {
    return { name, input1, input2 };
  }

  const rows2 = [
    createData2(
      intl.formatMessage({ id: "footer.cookies.grid2_row1_cell2" }),
      intl.formatMessage({ id: "footer.cookies.grid2_row2_cell2" }),
      intl.formatMessage({ id: "footer.cookies.grid2_row3_cell2" }),
    ),
    createData2(
      intl.formatMessage({ id: "footer.cookies.grid2_row1_cell3" }),
      intl.formatMessage({ id: "footer.cookies.grid2_row2_cell3" }),
      intl.formatMessage({ id: "footer.cookies.grid2_row3_cell3" }),
    ),
    createData2(
      intl.formatMessage({ id: "footer.cookies.grid2_row1_cell4" }),
      intl.formatMessage({ id: "footer.cookies.grid2_row2_cell4" }),
      intl.formatMessage({ id: "footer.cookies.grid2_row3_cell4" }),
    ),
  ];

  const rows3 = [
    createData2(
      intl.formatMessage({ id: "footer.cookies.grid3_row1_cell2" }),
      intl.formatMessage({ id: "footer.cookies.grid3_row2_cell2" }),
      intl.formatMessage({ id: "footer.cookies.grid3_row3_cell2" }),
    ),
  ];

  const rows4 = [
    createData2(
      intl.formatMessage({ id: "footer.cookies.grid4_row1_cell2" }),
      intl.formatMessage({ id: "footer.cookies.grid4_row2_cell2" }),
      intl.formatMessage({ id: "footer.cookies.grid4_row3_cell2" }),
    ),
    createData2(
      intl.formatMessage({ id: "footer.cookies.grid4_row1_cell3" }),
      intl.formatMessage({ id: "footer.cookies.grid4_row2_cell3" }),
      intl.formatMessage({ id: "footer.cookies.grid4_row3_cell3" }),
    ),
    createData2(
      intl.formatMessage({ id: "footer.cookies.grid4_row1_cell4" }),
      intl.formatMessage({ id: "footer.cookies.grid4_row2_cell4" }),
      intl.formatMessage({ id: "footer.cookies.grid4_row3_cell4" }),
    ),
    createData2(
      intl.formatMessage({ id: "footer.cookies.grid4_row1_cell5" }),
      intl.formatMessage({ id: "footer.cookies.grid4_row2_cell5" }),
      intl.formatMessage({ id: "footer.cookies.grid4_row3_cell5" }),
    ),
    createData2(
      intl.formatMessage({ id: "footer.cookies.grid4_row1_cell6" }),
      intl.formatMessage({ id: "footer.cookies.grid4_row2_cell6" }),
      intl.formatMessage({ id: "footer.cookies.grid4_row3_cell6" }),
    ),
  ];

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box my={2}>
            <Typography variant="h1" align="center">
              <b>
                <FormattedMessage id="footer.cookies.cookies" />
              </b>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.cookies.last_updated_title"
            textId="footer.cookies.last_updated_html"
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.cookies.what_are_cookies_title"
            textId="footer.cookies.what_are_cookies_html"
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.cookies.how_why_cookies_title"
            textId="footer.cookies.how_why_cookies_html"
          />
        </Grid>
        <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "auto" }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ borderRight: "1px solid #ccc" }}>
                    <FormattedMessage id="footer.cookies.grid1_row1_cell1" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="footer.cookies.grid1_row2_cell1" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows1.map((row) => (
                  <TableRow
                    key={row.name}
                  >
                    <TableCell component="th" scope="row" sx={{ borderRight: "1px solid #ccc" }}>
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.input1}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <TextBlock
              titleId="footer.cookies.tiers_title"
              textId="footer.cookies.tiers_html" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <TextBlock
              titleId="footer.cookies.necessary_cookies_title"
              textId="footer.cookies.necessary_cookies_html" />
          </Box>
        </Grid>
        <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "auto" }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ borderRight: "1px solid #ccc" }}>
                    <FormattedMessage id="footer.cookies.grid2_row1_cell1" />
                  </TableCell>
                  <TableCell style={{ borderRight: "1px solid #ccc" }}>
                    <FormattedMessage id="footer.cookies.grid2_row2_cell1" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="footer.cookies.grid2_row3_cell1" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows2.map((row) => (
                  <TableRow
                    key={row.name}
                  >
                    <TableCell component="th" scope="row" sx={{ borderRight: "1px solid #ccc" }}>
                      {row.name}
                    </TableCell>
                    <TableCell align="left" sx={{ borderRight: "1px solid #ccc" }}>{row.input1}</TableCell>
                    <TableCell align="left" style={{ width: "100px" }}>{row.input2}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <TextBlock
              titleId="footer.cookies.performance_cookies_title"
              textId="footer.cookies.performance_cookies_html" />
          </Box>
        </Grid>
        <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "auto" }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ borderRight: "1px solid #ccc" }}>
                    <FormattedMessage id="footer.cookies.grid2_row1_cell1" />
                  </TableCell>
                  <TableCell style={{ borderRight: "1px solid #ccc" }}>
                    <FormattedMessage id="footer.cookies.grid2_row2_cell1" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="footer.cookies.grid2_row3_cell1" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows3.map((row) => (
                  <TableRow
                    key={row.name}
                  >
                    <TableCell component="th" scope="row" sx={{ borderRight: "1px solid #ccc" }}>
                      {row.name}
                    </TableCell>
                    <TableCell align="left" sx={{ borderRight: "1px solid #ccc" }}>{row.input1}</TableCell>
                    <TableCell align="left" style={{ width: "100px" }}>{row.input2}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <TextBlock
              titleId="footer.cookies.targeting_cookies_title"
              textId="footer.cookies.targeting_cookies_html" />
          </Box>
        </Grid>
        <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "auto" }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ borderRight: "1px solid #ccc" }}>
                    <FormattedMessage id="footer.cookies.grid2_row1_cell1" />
                  </TableCell>
                  <TableCell style={{ borderRight: "1px solid #ccc" }}>
                    <FormattedMessage id="footer.cookies.grid2_row2_cell1" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="footer.cookies.grid2_row3_cell1" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows4.map((row) => (
                  <TableRow
                    key={row.name}
                  >
                    <TableCell component="th" scope="row" sx={{ borderRight: "1px solid #ccc" }}>
                      {row.name}
                    </TableCell>
                    <TableCell align="left" sx={{ borderRight: "1px solid #ccc" }}>{row.input1}</TableCell>
                    <TableCell align="left" style={{ width: "100px" }}>{row.input2}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <TextBlock
              titleId="footer.cookies.targeting_cookies_title"
              textId="footer.cookies.targeting_cookies_html" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <TextBlock
              titleId="footer.cookies.about_cookies_title"
              textId="footer.cookies.about_cookies_sub_title" />
            <TextBlock
              textId="footer.cookies.about_cookies_html" />
            <TextBlock
              titleId="footer.cookies.about_cookies_sub_title2"
              textId="footer.cookies.about_cookies_html2" />
            <TextBlock
              textId="footer.cookies.about_cookies_sub_title3" />
            <TextBlock
              textId="footer.cookies.about_cookies_html3" />
            <TextBlock
              titleId="footer.cookies.about_cookies_sub_title4"
              textId="footer.cookies.about_cookies_html4"/>
            <TextBlock
              titleId="footer.cookies.about_cookies_sub_title5"
              textId="footer.cookies.about_cookies_html5" />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Cookies;
