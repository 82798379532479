import { REPLACE_CURRENT_CANDIDATE } from "../../Candidate/constants/candidateConstants";
import { REPLACE_CURRENT_RECRUITER } from "../../Recruiter/constants/recruiterConstants";

export const initialState = {};

const UPDATE_CURRENT_RECRUITER = "UPDATE_CURRENT_RECRUITER";
const UPDATE_CURRENT_CANDIDATE = "UPDATE_CURRENT_CANDIDATE";

export default function railsContextReducer(state = initialState, action) {
  switch (action.type) {
  case REPLACE_CURRENT_CANDIDATE:
    return { ...state, current_candidate: action?.payload };
  case REPLACE_CURRENT_RECRUITER:
    return { ...state, current_recruiter: action?.payload };
  case UPDATE_CURRENT_RECRUITER:
    return { ...state, current_recruiter: action?.payload };
  case UPDATE_CURRENT_CANDIDATE:
    return { ...state, current_candidate: action?.payload };
  default:
    return state;
  }
}
