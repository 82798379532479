import React from "react";
import {
  Grid,
  useMediaQuery,
  Box,
  Dialog,
  Slide,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { Close } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogScreen = ({ open, onClose, maxWidth, children }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      TransitionComponent={Transition}
      onClose={onClose}
      fullScreen={!isDesktop}
      style={{ marginTop: isDesktop ? 0 : 50 }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <Grid item xs={12} dir="rtl">
        <Box m={1}>
          <IconButton style={{ position: "fixed" }} onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </Grid>
      {children}
    </Dialog>
  );
};

export default DialogScreen;
