import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import BlogDesktop from "./Desktop/BlogDesktop";
import BlogMobile from "./Mobile/BlogMobile";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import sh from "bundles/common/utils/sh";

const Blog = ({ type = "recruiter" }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const [articles, setArticles] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("all");
  const [squarehubId, setSquarehubId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("all");

  const fetchArticles = () => {
    setLoading(true);
    const categoryFilter = selectedCategory !== "all" ? { category: selectedCategory } : {};

    sh.get("search/blog_posts", {
      authenticity_token: authenticityToken,
      params: {
        page: page,
        filters: {
          resource_type: type,
          ...(selectedLanguage !== "all" && { preferred_locale: selectedLanguage }),
          ...categoryFilter,
        },
      },
    })
      .then((res) => {
        if (res?.data) {
          setArticles(res.data.data?.blog_posts);
          setPageCount(res.data.pagy?.pages);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    fetchArticles();
    setPage(1);
  }, [type, selectedCategory, selectedLanguage]);

  useEffect(() => {
    fetchArticles();
  }, [page]);

  useEffect(() => {
    sh.get(`/${i18nLocale}/find_squarehub_id`)
      .then((response) => {
        if (response?.data?.id) setSquarehubId(response.data.id);
      })
      .catch((err) => console.error(err));
  }, [i18nLocale]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [page]);

  return (
    <>
      {isDesktop
        ?
        <BlogDesktop
          type={type}
          handleCategoryChange={handleCategoryChange}
          selectedCategory={selectedCategory}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          loading={loading}
          articles={articles}
          pageCount={pageCount}
          page={page}
          squarehubId={squarehubId}
          setPage={setPage}
        />
        :
        <BlogMobile
          type={type}
          handleCategoryChange={handleCategoryChange}
          selectedCategory={selectedCategory}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          loading={loading}
          articles={articles}
          pageCount={pageCount}
          page={page}
          squarehubId={squarehubId}
          setPage={setPage}
        />
      }
    </>
  );
};

export default Blog;
