import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  CircularProgress,
  capitalize,
  useTheme,
  useMediaQuery,
} from "@mui/material";

const ProfileBox = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const currentCompany = currentRecruiter.company;

  const intl = useIntl();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        component={Paper}
        elevation={0}
        style={{
          padding: "32px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box style={{ display: "flex" }}>
          <Box style={{ marginRight: "32px" }}>
            <Box position="relative" display="inline-flex">
              <CircularProgress
                variant="determinate"
                thickness={2}
                value={100}
                style={{
                  height: "110px",
                  width: "110px",
                  zIndex: 1,
                  color: "#E3E3E3",
                }}
              />
              <CircularProgress
                variant="determinate"
                value={currentCompany?.company_page_completion || 0}
                thickness={2}
                style={{
                  height: "110px",
                  width: "110px",
                  zIndex: 2,
                  position: "absolute",
                  left: 0,
                  color: theme.palette.secondary.main,
                }}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {currentCompany?.logo_url && (
                  <img
                    src={currentCompany?.logo_url}
                    style={{ width: "70px" }}
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography variant="h4" style={{ marginBottom: "8px" }}>
              {currentCompany?.name}
            </Typography>
            {currentCompany?.city && (
              <Typography variant="body2">
                {capitalize(currentCompany?.city)}
              </Typography>
            )}
            {currentCompany?.company_industry && (
              <Typography variant="body2">
                {capitalize(currentCompany?.company_industry)}
              </Typography>
            )}
            <Box display="flex" style={{ marginTop: "8px" }}>
              <Typography
                variant="body1"
                style={{
                  marginRight: "4px",
                  color: theme.palette.secondary.main,
                }}
              >
                <b>{currentCompany?.company_page_completion || 0}%</b>
              </Typography>
              <Typography variant="body1">
                <b>{intl.formatMessage({ id: "candidate.completed" })}</b>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Button
          variant="contained"
          color="secondary"
          style={{
            height: "48px",
            borderRadius: "30px",
            marginTop: "16px",
            marginLeft: "auto",
            marginRight: "auto",
            width: isDesktop ? "80%" : "100%",
          }}
          href={`/${i18nLocale}/recruiter/settings?selectedSection=company`}
        >
          <FormattedMessage id="profile_box.my_company_page" />
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProfileBox;
