import React, { Fragment, useState } from "react";
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  TextField,
  Button,
  Link,
  Divider,
  Box,
  CircularProgress,
} from "@mui/material";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import sh from "bundles/common/utils/sh";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/styles";

const ResendConfirmation = ({ userType }) => {
  const authenticity_token = useSelector((state) => state.authenticity_token);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const initialFormValues = {
    email: "",
  };

  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : required;
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (validate(values)) {
      sh.post(`/${i18nLocale}/${userType}/confirmation`, {
        authenticity_token,
        [userType.slice(0, -1)]: {
          email: values.email,
        },
      })
        .then(() => {
          setIsSuccess(true);
        })
        .catch(() => {
          enqueueSnackbar(
            intl.formatMessage({
              id: "flash.unknown",
            }),
            { variant: "error" }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Box my={4} />
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              disableTypography
              title={
                <Typography variant="h4" align="center">
                  <FormattedMessage
                    id="devise.confirmation.email_confirmation"
                    values={{
                      b: (chunks) => <b>{chunks}</b>,
                    }}
                  />
                </Typography>
              }
            ></CardHeader>
            <CardContent>
              <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Typography align="center">
                    <b>
                      <FormattedMessage id="devise.confirmation.resend_confirmation_instructions"></FormattedMessage>
                    </b>
                  </Typography>
                </Grid>
                {!isSuccess ? (
                  <Fragment>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">
                        <FormattedMessage id="devise.confirmation.confirmation_instructions" />
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        type="email"
                        margin="normal"
                        variant="outlined"
                        onBlur={handleInputValue}
                        onChange={handleInputValue}
                        name="email"
                        {...(errors["email"] && {
                          error: true,
                          helperText: errors["email"],
                        })}
                        label={<FormattedMessage id="devise.signup.email" />}
                      />
                    </Grid>
                  </Fragment>
                ) : (
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <FormattedMessage id="devise.confirmation.confirmation_success" />
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <CardActions>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    color="primary"
                    {...(userType == "recruiters" &&
                      !loading &&
                      !isSuccess && {
                      style: {
                        backgroundColor: theme.palette.text.blue,
                        color: theme.palette.text.white,
                      },
                    })}
                    type="submit"
                    variant="contained"
                    disabled={loading || isSuccess}
                  >
                    <Box justifyContent="center">
                      {loading && (
                        <CircularProgress size={16} color="secondary">
                          <FormattedMessage id="send" />
                        </CircularProgress>
                      )}
                      {!loading && <FormattedMessage id="send" />}
                    </Box>
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Box mb={2}>
                    <Typography variant="body2" align="center">
                      <Link
                        href={`/${i18nLocale}/${userType}/sign_in`}
                        color="primary"
                        underline="always"
                      >
                        <FormattedMessage id="devise.password.back_to_login" />
                      </Link>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </form>
      </Grid>
    </Grid>
  );
};

export default ResendConfirmation;
