import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import ApplicationDialogDesktop from "./Desktop/ApplicationDialogDesktop";
import ApplicationDialogMobile from "./Mobile/ApplicationDialogMobile";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { uploadResume } from "bundles/common/utils/uploadResume";
import { replaceCurrentCandidate } from "bundles/Candidate/constants/candidateConstants";
import sh from "bundles/common/utils/sh";
import { trackJobOfferApplication } from "bundles/common/utils/marketing";
import { useSnackbar } from "notistack";

const ApplicationDialog = ({
  jobOffer,
  setJobOffer,
  setIsApplying,
  jobApplication,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const intl = useIntl();
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const candidate = useSelector((state) => state.current_candidate) || {};
  const railsEnv = useSelector((state) => state.railsEnv);

  const initialFormValues = {
    cover_letter: jobApplication?.cover_letter || "",
  };

  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [resume, setResume] = useState();
  const handleInputValue = (e) => {
    const { name, value } = e.target;
    let newValues = { ...values, [name]: value };
    setValues(newValues);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (resume?.length > 0) {
      let resumeUrl = resume[0];
      if (resumeUrl instanceof File) {
        const presignedFileParams = await uploadResume(resumeUrl);
        resumeUrl = presignedFileParams.blob_signed_id;
      }

      sh.post("/job_application", {
        authenticity_token: authenticityToken,
        job_offer_id: jobOffer.id,
        cover_letter: values.cover_letter,
        resume_url: resumeUrl,
      })
        .then((res) => {
          setTimeout(() => {
            if (railsEnv === "production") trackJobOfferApplication();
            setJobOffer(res.data);
            setLoading(false);
            replaceCurrentCandidate(dispatch, authenticityToken, () => {});
            enqueueSnackbar(
              intl.formatMessage({ id: "applicant.application_submitted" }),
              {
                variant: "success",
              }
            );
          }, 1000);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          let message = candidate.whitelisted ? "settings.save_failed" : "settings.must_be_whitelisted";
          enqueueSnackbar(intl.formatMessage({ id: message }), {
            variant: "error",
          });
        });
    } else {
      if (!resume?.length > 0) {
        setErrors({
          ...errors,
          resume: <FormattedMessage id="form_validation.document_required" />,
        });
      }
      setLoading(false);
    }
  };

  const onCompanyClicked = () => {
    navigate(`/${i18nLocale}/candidate/company/${jobApplication.company.id}`);
  };

  const [initialFiles, setInitialFiles] = useState([]);

  useEffect(() => {
    const files = [];
    if (jobApplication?.resume_url) {
      files.push(jobApplication.resume_url);
    } else if (candidate?.resume_url) {
      files.push(candidate.resume_url);
    }
    setInitialFiles(files);
    setResume(files);
  }, [jobApplication, candidate]);

  const handleNewCVDelete = () => {
    setResume();
  };

  return (
    <>
      {isDesktop
        ?
        <ApplicationDialogDesktop
          jobOffer={jobOffer}
          setJobOffer={setJobOffer}
          setIsApplying={setIsApplying}
          jobApplication={jobApplication}
          candidate={candidate}
          onCompanyClicked={onCompanyClicked}
          values={values}
          handleInputValue={handleInputValue}
          handleSubmit={handleSubmit}
          errors={errors}
          setErrors={setErrors}
          loading={loading}
          resume={resume}
          setResume={setResume}
          initialFiles={initialFiles}
          handleNewCVDelete={handleNewCVDelete}
        />
        :
        <ApplicationDialogMobile
          jobOffer={jobOffer}
          setJobOffer={setJobOffer}
          setIsApplying={setIsApplying}
          jobApplication={jobApplication}
          candidate={candidate}
          onCompanyClicked={onCompanyClicked}
          values={values}
          handleInputValue={handleInputValue}
          handleSubmit={handleSubmit}
          errors={errors}
          setErrors={setErrors}
          loading={loading}
          resume={resume}
          setResume={setResume}
          initialFiles={initialFiles}
          handleNewCVDelete={handleNewCVDelete}
        />
      }
    </>
  );
};

export default ApplicationDialog;
