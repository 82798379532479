import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import {
  Grid,
  Typography,
  Box,
  Divider,
  Button,
  Modal,
  useMediaQuery,
} from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ExperienceView from "bundles/common/components/ExperienceView";
import EditExperienceModal from "./EditExperienceModal";
import { sortDateRange } from "bundles/common/utils/utils";
import { useTheme } from "@mui/styles";

const Experience = ({ completionCallback }) => {
  const currentCandidate = useSelector((state) => state.current_candidate);
  const sortedExperiences = sortDateRange(currentCandidate?.experiences);

  const theme = useTheme();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);

  const handleNewExperienceClicked = (e) => {
    e.preventDefault();
    setSelectedExperience(null);
    setIsEditModalOpen(true);
  };

  const handleExperienceClicked = (experience) => {
    setSelectedExperience(experience);
    setIsEditModalOpen(true);
  };

  return (
    <Grid container style={{ height: "100%" }}>
      <Grid item xs={12} style={{ marginBottom: "16px" }}>
        <Typography variant="h4">
          <FormattedMessage id="settings.precise_experience" />
        </Typography>
      </Grid>
      {sortedExperiences?.length > 0 && (
        <Grid item xs={12}>
          {sortedExperiences?.map((exp, i) => (
            <Box key={`experience-${i}`}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <Box>
                      <ExperienceView
                        experience={exp}
                        editable={true}
                        onEdit={handleExperienceClicked}
                      />
                      <Divider
                        style={{ marginBottom: "16px", marginTop: "16px" }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Grid>
      )}
      <Grid item xs={12} textAlign="center">
        <Button
          variant="rounded"
          color="primaryContained"
          style={{ marginTop: "8px" }}
          onClick={handleNewExperienceClicked}
        >
          <ControlPointIcon style={{ marginRight: "8px" }} />
          <FormattedMessage id="settings.add_experience" />
        </Button>
      </Grid>

      <Modal
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <EditExperienceModal
          closeModal={() => setIsEditModalOpen(false)}
          experience={selectedExperience}
          completionCallback={completionCallback}
        />
      </Modal>
    </Grid>
  );
};

export default Experience;
