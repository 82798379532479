import React, { useState } from "react";
import { Typography, Box, IconButton, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import EditIcon from "@mui/icons-material/Edit";

const trainingView = ({ training, onEdit, editable = false }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [isHovered, setIsHovered] = useState(false);

  const onEditClicked = (e) => {
    e.preventDefault();
    if (onEdit) onEdit(training);
  };

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "space-between",
          marginBottom: "8px",
          minHeight: "26px",
        }}
      >
        <Box>
          <Typography
            variant="body1bold"
            style={{ color: theme.palette.text.dark }}
          >
            {training?.institution}
          </Typography>
          {editable && isHovered && isDesktop && (
            <IconButton
              aria-label="edit-personal-info"
              onClick={onEditClicked}
              style={{
                padding: "0px",
                marginLeft: "8px",
              }}
            >
              <EditIcon style={{ width: "24px", height: "24px" }} />
            </IconButton>
          )}
        </Box>
        <Box>
          <Typography
            variant="body2"
            style={{ color: theme.palette.text.dark }}
          >
            {new Date(training?.start_date)?.getFullYear()} -{" "}
            {training?.end_date ? (
              new Date(training?.end_date).getFullYear()
            ) : (
              <FormattedMessage id="today" />
            )}
          </Typography>
        </Box>
      </Box>

      <Box style={{ marginBottom: "2px" }}>
        <Typography variant="body2" style={{ color: theme.palette.text.dark }}>
          {training?.diploma}
        </Typography>
      </Box>

      <Box style={{ marginBottom: "8px" }}>
        <Typography
          variant="body2"
          style={{ color: theme.palette.text.secondary }}
        >
          {training?.location}
        </Typography>
      </Box>

      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography
            variant="body2"
            style={{ color: theme.palette.text.primary }}
          >
            {training?.honors}
          </Typography>
        </Box>
        {editable && !isDesktop && (
          <Box>
            <IconButton
              aria-label="edit-personal-info"
              onClick={onEditClicked}
              style={{
                padding: "0px",
                marginLeft: "8px",
              }}
            >
              <EditIcon style={{ width: "24px", height: "24px" }} />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default trainingView;
