import React from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import translations from "app/libs/i18n/translations.json";
import { Container } from "@mui/material";
import { useTheme } from "@mui/styles";
import MainAppBar from "bundles/Layouts/MainAppBar";
import Crane from "icons/crane.svg";
import MainAppBarRecruiter from "bundles/Layouts/MainAppBarRecruiter";
import ForgottenPassword from "./../components/ForgottenPassword";
import ChangePassword from "bundles/common/components/ChangePassword";
import ResendConfirmation from "bundles/common/components/ResendConfirmation";
import PasswordApp from "../startup/PasswordApp";

const PasswordContainer = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const locale = i18nLocale;
  const messages = translations[locale];
  const theme = useTheme();

  const isRecruiter = window.location.pathname.includes("recruiter");

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <BrowserRouter>
        {isRecruiter ? <MainAppBarRecruiter /> : <MainAppBar />}
        <Container
          maxWidth="lg"
          className={theme.background}
          style={{
            backgroundImage: `url(${Crane})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "right center",
          }}
        >
          <Routes>
            <Route
              path={`/${i18nLocale}/candidates/password/new`}
              element={<ForgottenPassword userType="candidates" />}
            />
            <Route
              path={`/${i18nLocale}/candidates/password/edit`}
              element={<ChangePassword userType="candidates" />}
            />
            <Route
              path={`/${i18nLocale}/candidates/confirmation/new`}
              element={<ResendConfirmation userType="candidates" />}
            />

            <Route
              path={`/${i18nLocale}/recruiters/password/new`}
              element={<ForgottenPassword userType="recruiters" />}
            />
            <Route
              path={`/${i18nLocale}/recruiters/password/edit`}
              element={<ChangePassword userType="recruiters" />}
            />
            <Route path={`/${i18nLocale}/`} component={PasswordApp} />
          </Routes>
        </Container>
      </BrowserRouter>
    </IntlProvider>
  );
};

export default PasswordContainer;
