import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import sh from "bundles/common/utils/sh";
import WorkIcon from "icons/work.svg";
import EnvelopeIcon from "icons/envelope.svg";
import ProfilePicture from "bundles/common/components/ProfilePicture";

const MetricsBar = () => {
  const theme = useTheme();
  const intl = useIntl();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const useStyles = makeStyles({
    container: {
      height: "100%",
    },
    item: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: isDesktop ? "16px" : "8px",
      paddingBottom: isDesktop ? "16px" : "8px",

      "&:hover": {
        backgroundColor: "#FCFCFC",
        cursor: "pointer",
      },
    },
    box: {
      flexGrow: 1,
      textAlign: "left",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: isDesktop ? "unset" : "96px",
    },
    divider: {
      height: isDesktop ? "100%" : "calc(100% + 24px) !important",
      marginTop: isDesktop ? "auto" : "-8px !important",
      marginBottom: isDesktop ? "auto" : "-16px !important",
    },
    dividerHorizontal: {
      width: "100%",
      marginBottom: "16px !important",
    },
    img: {
      marginTop: isDesktop ? "12px" : "4px",
      width: isDesktop ? "44px" : "24px",
    },
  });
  const classes = useStyles();
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const currentCandidate = useSelector((state) => state.current_candidate);

  const [onlineJobsCount, setOnlineJobsCount] = useState(0);

  const handleCardClicked = (cardValue) => {
    switch (cardValue) {
    case "online_jobs":
      window.location.href = `/${i18nLocale}/candidate/job_offers`;
      break;
    case "pending_applications":
      window.location.href = `/${i18nLocale}/candidate/job_offers?selectedTab=my_jobs`;
      break;
    case "profile":
      window.location.href = `/${i18nLocale}/candidate/settings`;
      break;
    }
  };

  useEffect(() => {
    sh.get("/online_job_offers_count", {}).then((res) => {
      setOnlineJobsCount(res.data.online_jobs_count);
    });
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} component={Paper} elevation={1}>
        <Grid container className={classes.container}>
          <Grid
            item
            xs={6}
            md={4}
            className={classes.item}
            onClick={() => handleCardClicked("pending_applications")}
          >
            <Box className={classes.box}>
              <Typography variant="h2" color="primary" style={{ fontSize: isDesktop ? "28px" : "16px"}}>
                <b>{currentCandidate?.unclosed_job_applications_count}</b>
              </Typography>
              <Typography variant="h5" textAlign="center" style={{ fontSize: isDesktop ? "18px" : "14px"}}>
                <b>
                  <FormattedMessage id="metrics_bar.open_applications" />
                </b>
              </Typography>
              <img src={EnvelopeIcon} className={classes.img} />
            </Box>
            <Divider orientation="vertical" className={classes.divider} />
          </Grid>
          <Grid
            item
            xs={6}
            md={4}
            className={classes.item}
            style={{ flexDirection: isDesktop ? "row" : "column" }}
            onClick={() => handleCardClicked("online_jobs")}
          >
            <Box className={classes.box}>
              <Typography variant="h2" color="primary" style={{ fontSize: isDesktop ? "28px" : "16px"}}>
                <b>{onlineJobsCount}</b>
              </Typography>
              <Typography variant="h5" style={{ fontSize: isDesktop ? "18px" : "14px"}}>
                <b>
                  <FormattedMessage id="metrics_bar.online_jobs" />
                </b>
              </Typography>
              <img src={WorkIcon} className={classes.img} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            className={classes.item}
            style={{ flexDirection: isDesktop ? "row" : "column" }}
            onClick={() => handleCardClicked("profile")}
          >
            <Divider
              orientation={isDesktop ? "vertical" : "horizontal"}
              className={
                isDesktop ? classes.divider : classes.dividerHorizontal
              }
            />
            <Box className={classes.box}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Box style={{ marginRight: "16px", marginLeft: "16px" }}>
                  <Box position="relative" display="inline-flex">
                    <CircularProgress
                      variant="determinate"
                      thickness={2}
                      value={100}
                      style={{
                        height: "110px",
                        width: "110px",
                        zIndex: 1,
                        color: "#E3E3E3",
                      }}
                    />
                    <CircularProgress
                      variant="determinate"
                      value={currentCandidate?.profile_completion}
                      thickness={2}
                      style={{
                        height: "110px",
                        width: "110px",
                        zIndex: 2,
                        position: "absolute",
                        left: 0,
                      }}
                    />
                    <Box
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      position="absolute"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        color="textSecondary"
                      >
                        <ProfilePicture
                          rounded
                          size="100"
                          picture={currentCandidate?.profile_picture_url}
                          initials={
                            currentCandidate?.first_name[0] +
                            (currentCandidate?.last_name
                              ? currentCandidate?.last_name[0]
                              : "")
                          }
                          alt={`${currentCandidate?.first_name} ${currentCandidate?.last_name}`}
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Typography variant="h4" style={{ marginBottom: "8px" }}>
                    <FormattedMessage id="onboarding.my_profile" />
                  </Typography>
                  <Box
                    display="flex"
                    style={{ marginTop: "8px", alignItems: "center" }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        marginRight: "4px",
                        color: theme.palette.primary.main,
                      }}
                    >
                      <b>{currentCandidate?.profile_completion}%</b>
                    </Typography>
                    <Typography variant="body1">
                      <b>
                        <FormattedMessage id="onboarding.completed" />
                      </b>
                    </Typography>
                  </Box>
                  <Box textAlign="left">
                    <Typography variant="body2">
                      {currentCandidate?.profile_completion < 100
                        ? intl.formatMessage({
                          id: "candidate.complete_onboarding_title",
                        })
                        : intl.formatMessage({
                          id: "candidate.completed_onboarding_title",
                        })}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {currentCandidate?.profile_completion < 100 && (
                <Box style={{ marginTop: "8px" }}>
                  <Button variant="rounded" color="primaryContained">
                    <FormattedMessage id="onboarding.complete_profile" />
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MetricsBar;
