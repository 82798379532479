import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";
import {
  Box,
  Button,
  Card,
  Container,
  Link,
  Typography,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import HTML from "bundles/common/components/HTML";
import FourBlogJobOffers from "bundles/Candidate/components/FourBlogJobOffers";
import BlogPostCard from "bundles/common/components/BlogPostCard";
import Logo from "icons/logo.svg";
import moment from "moment";
import NotFound from "icons/notFound.svg";
import { handleLinkedinShare, getFirstPathParam } from "bundles/common/utils/utils";
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";
import FacebookIcon from "facebook-blue-background.svg";
import LinkedinIcon from "linkedin-blue-background.svg";
import MailIcon from "mail-blue-background.svg";
import WhatsappIcon from "whatsapp-blue-background.svg";

const BlogViewDesktop = ({
  type = "candidate",
  article,
  squarehubId,
  publicArticleUrl,
  similarArticles,
  notFound,
}) => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const theme = useTheme();
  const useStyles = makeStyles(() => ({
    card: {
      padding: "24px 32px",
      marginTop: "32px",
      marginBottom: "32px",
      boxShadow: "none !important",
      borderRadius: "16px !important"
    },
    logo: { width: "60px", objectFit: "contain" },
    coverImage: {
      width: "100%",
      height: "400px",
      objectFit: "cover",
      borderRadius: "8px",
      marginTop: "32px",
      marginBottom: "32px",
    },
    logoAndSocialMedia: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "36px",
    },
    socialMediaBox: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: "8px",
    },
    blogContent: {
      padding: "0px 50px",
      color: theme.palette.secondary.main,
      "& h2": {
        fontSize: "24px",
        color: theme.palette.secondary.main,
      },
    },
    notFoundBox: { display: "flex", alignItems: "center", padding: "32px" },
  }));
  const classes = useStyles();

  return (
    <>
      <Helmet>
        {article?.id && (
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "NewsArticle",
              headline: article.title,
              image: [article.logo_url || `${window.location.origin}${Logo}`],
              datePublished: article.created_at,
              author: (typeof article?.authors == "string"
                ? JSON.parse(article?.authors)
                : article.authors
              ).map((author) => {
                return {
                  "@type": author.type,
                  name: author.name,
                  url: author.url,
                };
              }),
            })}
          </script>
        )}
      </Helmet>
      <Container maxWidth="md">
        <Card className={classes.card}>
          {article?.id && (
            <>
              <Typography
                variant="h1"
                color="secondary"
                style={{
                  textAlign: "center",
                  fontSize: "48px",
                  fontWeight: "bold",
                }}
              >
                {article.title}
              </Typography>
              <img
                src={article.cover_picture_url || NotFound}
                alt={article.title}
                className={classes.coverImage}
              />
              <Box className={classes.logoAndSocialMedia}>
                <Box style={{ display: "flex", justifyContent: "space-between", gap: "16px" }}>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <img src={Logo} alt="Squarehub" className={classes.logo} />
                  </Box>
                  <Box style={{ textAlign: "left" }}>
                    <Typography variant="body2" color="primary" style={{ cursor: "pointer", marginBottom: "4px" }}>
                      <Link
                        href={`/companies/squarehub/${squarehubId}`}
                        sx={{
                          color: "inherit",
                          textDecoration: "inherit",
                          ":hover": {
                            color: "#035d2a"
                          }
                        }}
                      >
                        Squarehub
                      </Link>
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      <FormattedMessage id="blog.updated_at" /> {moment(article.updated_at).format("DD.MM.YY")}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.socialMediaBox}>
                  <EmailShareButton
                    subject={article.title}
                    body={"Check out this article on Squarehub:"}
                    url={publicArticleUrl}
                  >
                    <img src={MailIcon} alt="Mail Icon" />
                  </EmailShareButton>
                  <FacebookShareButton url={publicArticleUrl}>
                    <img src={FacebookIcon} alt="Facebook Icon" />
                  </FacebookShareButton>
                  <Box onClick={() => handleLinkedinShare(publicArticleUrl)} style={{ cursor: "pointer" }}>
                    <img src={LinkedinIcon} alt="Linkedin Icon" />
                  </Box>
                  <WhatsappShareButton url={publicArticleUrl}>
                    <img src={WhatsappIcon} alt="Whatsapp Icon" />
                  </WhatsappShareButton>
                </Box>
              </Box>
              <Box className={classes.blogContent}>
                <HTML html={article.content} h2={true} />
              </Box>
            </>
          )}
          {notFound && (
            <Box className={classes.notFoundBox}>
              <img src={NotFound} alt="Not Found" />
            </Box>
          )}
        </Card>
      </Container>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          width: "80%",
          margin: "auto"
        }}
      >
        <Typography
          variant="h2"
          color="secondary"
          style={{
            paddingTop: "36px",
            paddingBottom: "48px",
            fontSize: "36px",
            textAlign: "left",
            width: "100%"
          }}
        >
          <FormattedMessage id="blog.similar_articles" />
        </Typography>

        <Box
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: "32px",
            marginBottom: "20px",
            maxWidth: "lg",
          }}
        >
          {similarArticles?.map((post) => (
            <BlogPostCard
              post={post}
              key={`post-${post.id}`}
              companyId={squarehubId}
            />
          ))}
        </Box>
        <Box style={{ textAlign: "center", margin: "24px auto" }}>
          <Button
            variant="rounded"
            color="primaryContained"
            style={{ minWidth: "280px" }}
            href={`/${i18nLocale}/${getFirstPathParam()}/posts`}
          >
            <FormattedMessage id="blog.all_articles" />
          </Button>
        </Box>
      </Box>
      {type === "candidate" &&
        <Box style={{ marginBottom: "48px" }}>
          <FourBlogJobOffers />
        </Box>
      }
    </>
  );
};

export default BlogViewDesktop;
