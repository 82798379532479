import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import JobApplicationIndexDesktop from "./Desktop/JobApplicationIndexDesktop";
import JobApplicationIndexMobile from "./Mobile/JobApplicationIndexMobile";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useIntl } from "react-intl";
import sh from "bundles/common/utils/sh";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";

const JobApplicationIndex = ({ setLoading, setSelectedTab }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const authenticity_token = useSelector((state) => state.authenticity_token);
  const currentCandidate = useSelector((state) => state.current_candidate);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const querySelectedTab = queryParams.get("selectedTab");
  const intl = useIntl();

  const [jobApplications, setJobApplications] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [selectedApplication, setSelectedApplication] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedScope, setSelectedScope] = useState(
    querySelectedTab ? querySelectedTab : "all"
  );

  const fetchJobApplications = (page = 1, status = selectedStatus) => {
    setLoading(true);
    sh.get("job_application", {
      authenticity_token,
      params: {
        page,
        status,
      },
    })
      .then((res) => {
        setPageCount(res.data?.pagy?.pages);
        setJobApplications(res.data?.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const scopes = [
    {
      code: "all",
      label: intl.formatMessage({ id: "application.all_candidat" }),
    },
    {
      code: "pending",
      label: intl.formatMessage({ id: "application.pending" }),
    },
    {
      code: "open",
      label: intl.formatMessage({ id: "application.open" }),
    },
    {
      code: "closed",
      label: intl.formatMessage({ id: "application.closed" }),
    },
  ];

  const handleApplicationClick = (jobApplication) => {
    setSelectedApplication(jobApplication);
    setIsDialogOpen(true);
  };

  const handleScopeChange = (name, value) => {
    let newScope = scopes.find((scope) => scope.code == value)?.code;
    if (newScope) {
      setSelectedStatus(newScope);
    }
  };

  useEffect(() => {
    fetchJobApplications(page, selectedStatus);
  }, [selectedStatus, page]);

  useEffect(() => {
    if (currentCandidate) {
      fetchJobApplications(page, selectedStatus);
    } else {
      setSelectedTab("find_a_job");
    }
  }, [selectedStatus, page, currentCandidate]);

  useEffect(() => {
    setSelectedScope(querySelectedTab || "all");
  }, [querySelectedTab]);

  return (
    <>
      {isDesktop
        ?
        <JobApplicationIndexDesktop
          jobApplications={jobApplications}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          selectedApplication={selectedApplication}
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          selectedStatus={selectedStatus}
          scopes={scopes}
          handleScopeChange={handleScopeChange}
          handleApplicationClick={handleApplicationClick}
        />
        :
        <JobApplicationIndexMobile
          jobApplications={jobApplications}
          page={page}
          setPage={setPage}
          pageCount={pageCount}
          selectedApplication={selectedApplication}
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          scopes={scopes}
          handleApplicationClick={handleApplicationClick}
        />
      }
    </>
  );
};

export default JobApplicationIndex;
