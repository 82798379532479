import React from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Container } from "@mui/material";
import { useTheme } from "@mui/styles";
import translations from "app/libs/i18n/translations.json";
import LoginRecruiter from "bundles/Devise/LoginRecruiter/components/LoginRecruiter";
import AcceptInvitation from "bundles/Devise/LoginRecruiter/components/AcceptInvitation";
import MainAppBarRecruiter from "bundles/Layouts/MainAppBarRecruiter";
import ForgottenPassword from "bundles/common/components/ForgottenPassword";
import ChangePassword from "bundles/common/components/ChangePassword";
import ResendConfirmation from "bundles/common/components/ResendConfirmation";
import Crane from "icons/crane-recruiter.svg";

const LoginReruiterContainer = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const locale = i18nLocale;
  const messages = translations[locale];
  const theme = useTheme();
  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <BrowserRouter>
        <MainAppBarRecruiter />
        <Container
          maxWidth="lg"
          className={theme.background}
          style={{
            height: "100vh",
            backgroundImage: `url(${Crane})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "right center",
          }}
        >
          <Routes>
            <Route
              path={`/${i18nLocale}/recruiters/password/new`}
              element={<ForgottenPassword userType="recruiters" />}
            />
            <Route
              path={`/${i18nLocale}/recruiters/password/edit`}
              element={<ChangePassword userType="recruiters" />}
            />
            <Route
              path={`/${i18nLocale}/recruiters/confirmation/new`}
              element={<ResendConfirmation userType="recruiters" />}
            />
            <Route
              path={`/${i18nLocale}/recruiters/invitation/accept`}
              element={<AcceptInvitation />}
            />
            <Route
              path={`/${i18nLocale}/recruiters/sign_in`}
              element={<LoginRecruiter />}
            />
            <Route
              path={`/${i18nLocale}`}
              element={<Navigate to={`/${i18nLocale}/recruiters/sign_in`} />}
            />
          </Routes>
        </Container>
      </BrowserRouter>
    </IntlProvider>
  );
};

export default LoginReruiterContainer;
