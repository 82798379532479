import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Pagination from "@mui/lab/Pagination";
import {
  Box,
  Container,
  Link,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import BlogCard from "bundles/common/components/BlogCard";
import BlogPostCard from "bundles/common/components/BlogPostCard";
import FourBlogJobOffers from "bundles/Candidate/components/FourBlogJobOffers";
import BackdropScreen from "bundles/common/components/BackdropScreen";
import ArticleSvg from "articles.svg";

const BlogDesktop = ({
  type = "recruiter",
  handleCategoryChange,
  selectedCategory,
  selectedLanguage,
  setSelectedLanguage,
  loading,
  articles,
  setPage,
  pageCount,
  page,
  squarehubId,
}) => {
  const intl = useIntl();
  const useStyles = makeStyles((theme) => ({
    coloredHeader: {
      paddingTop: "24px",
      paddingBottom: "24px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: theme.palette.secondary.main,
    },
    container: { marginTop: "32px" },
    breadcrumbLink: { color: `${theme.palette.common.white} !important` },
    breadcrumbActive: { color: `${theme.palette.text.white} !important` },
    languageFormControl: {
      width: "200px",
      "&:before": { borderColor: theme.palette.common.white},
      "&:after": { borderColor: theme.palette.common.white},
      "& .MuiInputLabel-root": { color: theme.palette.common.white},
      "& .Mui-focused": { color: `${theme.palette.common.white} !important` },
    },
    languageSelect: {
      "& .MuiSelect-select": { color: theme.palette.common.white},
      "& .MuiSelect-iconOutlined": { color: theme.palette.common.white},
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.common.white} !important`,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.text.white} !important`,
      },
    },
    mobileTitle: {
      fontSize: "24px !important",
      fontWeight: "inherit",
      textAlign: "left",
    },
    mobileLinkContainer: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "10px",
      marginTop: "24px",
      marginBottom: "20px",
    },
    mobileLinkButton: {
      backgroundColor: `${theme.palette.secondary.main} !important`,
      color: "white !important",
      padding: "10px",
      textAlign: "center",
      borderRadius: "4px",
    },
  }));

  const classes = useStyles();

  return (
    <Box style={{ backgroundColor: "white" }}>
      <Box className={classes.coloredHeader}>
        <Box style={{ display: "flex", alignItems: "center", paddingLeft: "86px" }}>
          <Link
            onClick={() => handleCategoryChange("all")}
            sx={{
              textDecoration: "none",
              color: "white",
              "&:hover": { textDecoration: "none" },
              marginRight: "24px",
              cursor: "pointer",
              fontWeight: selectedCategory === "all" ? "bold" : "normal"
            }}
          >
            <FormattedMessage id="blog.categories.all" />
          </Link>
          {type !== "recruiter" ? (
            <>
              <Link
                onClick={() => handleCategoryChange("career_advise")}
                sx={{
                  textDecoration: "none",
                  color: "white",
                  "&:hover": { textDecoration: "none" },
                  marginRight: "24px",
                  cursor: "pointer",
                  fontWeight: selectedCategory === "career_advise" ? "bold" : "normal"
                }}
              >
                <FormattedMessage id="blog.categories.advices" />
              </Link>
              <Link
                onClick={() => handleCategoryChange("interviews_and_cv")}
                sx={{
                  textDecoration: "none",
                  color: "white",
                  "&:hover": { textDecoration: "none" },
                  marginRight: "24px",
                  cursor: "pointer",
                  fontWeight: selectedCategory === "interviews_and_cv" ? "bold" : "normal"
                }}
              >
                <FormattedMessage id="blog.categories.interviews_and_cv" />
              </Link>
              <Link
                onClick={() => handleCategoryChange("candidate_actuality")}
                sx={{
                  textDecoration: "none",
                  color: "white",
                  "&:hover": { textDecoration: "none" },
                  marginRight: "24px",
                  cursor: "pointer",
                  fontWeight: selectedCategory === "candidate_actuality" ? "bold" : "normal"
                }}
              >
                <FormattedMessage id="blog.categories.actualities" />
              </Link>
            </>
          ) : (
            <>
              <Link
                onClick={() => handleCategoryChange("how_to_recruit")}
                sx={{
                  textDecoration: "none",
                  color: "white",
                  "&:hover": { textDecoration: "none" },
                  marginRight: "24px",
                  cursor: "pointer",
                  fontWeight: selectedCategory === "how_to_recruit" ? "bold" : "normal"
                }}
              >
                <FormattedMessage id="blog.categories.how_to_recruit" />
              </Link>
              <Link
                onClick={() => handleCategoryChange("human_resources")}
                sx={{
                  textDecoration: "none",
                  color: "white",
                  "&:hover": { textDecoration: "none" },
                  marginRight: "24px",
                  cursor: "pointer",
                  fontWeight: selectedCategory === "human_resources" ? "bold" : "normal"
                }}
              >
                <FormattedMessage id="blog.categories.human_resources" />
              </Link>
              <Link
                onClick={() => handleCategoryChange("recruiter_actuality")}
                sx={{
                  textDecoration: "none",
                  color: "white",
                  "&:hover": { textDecoration: "none" },
                  marginRight: "24px",
                  cursor: "pointer",
                  fontWeight: selectedCategory === "recruiter_actuality" ? "bold" : "normal"
                }}
              >
                <FormattedMessage id="blog.categories.actualities" />
              </Link>
            </>
          )}

        </Box>
        <Box style={{ paddingRight: "86px"}}>
          <FormControl variant="outlined" className={classes.languageFormControl}>
            <InputLabel id="posts-select-label-id">
              <FormattedMessage id="languages.languages" />
            </InputLabel>
            <Select
              value={selectedLanguage}
              labelId="posts-select-label-id"
              id="posts-select-label-id"
              className={classes.languageSelect}
              onChange={(e) => setSelectedLanguage(e?.target?.value)}
              input={<OutlinedInput label={intl.formatMessage({ id: "languages.languages" })} />}
            >
              <MenuItem value="all"><FormattedMessage id="languages.all_languages" /></MenuItem>
              <MenuItem value="nl"><FormattedMessage id="languages.dutch" /></MenuItem>
              <MenuItem value="fr"><FormattedMessage id="languages.french" /></MenuItem>
              <MenuItem value="en"><FormattedMessage id="languages.english" /></MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Container maxWidth="lg" className={classes.container}>
        <Box style={{ paddingBottom: "32px" }}>
          <BackdropScreen open={loading} />
          {articles?.length > 0 && !loading && (
            <Box>
              {page === 1 && (
                <>
                  <Typography variant="h1" color="secondary" style={{ marginTop: "48px", marginBottom: "48px", fontSize: "48px" }}>
                    <FormattedMessage id="menu.blog_posts" />
                  </Typography>
                  <BlogCard article={articles[0]} type={type} companyId={squarehubId} />
                  <Typography variant="h2" style={{ fontSize: "36px", fontWeight: "600", margin: "32px 0"}}>
                    <FormattedMessage id="blog.most_recent" />
                  </Typography>
                </>
              )}
              <Box>
                <Box style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "20px", margin: "0 0 20px 0"}}>
                  {articles.map((post) => (
                    <BlogPostCard post={post} key={`post-${post.id}`} companyId={squarehubId} />
                  ))}
                </Box>
                {pageCount > 1 && (
                  <Pagination size="large" count={pageCount} page={page} onChange={(e, newPage) => setPage(newPage)} style={{ justifyContent: "center", display: "flex", marginTop: "32px"}} />
                )}
              </Box>
            </Box>
          )}
          {articles?.length <= 0 && !loading && (
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "120px"}}>
              <Box style={{ marginBottom: "32px" }}>
                <Typography variant="h6">
                  <FormattedMessage id="blog.no_articles" />
                </Typography>
              </Box>
              <img src={ArticleSvg} style={{ width: "400px"}} />
            </Box>
          )}
        </Box>
      </Container>
      {!loading && type === "candidate" && <FourBlogJobOffers />}
    </Box>
  );
};

export default BlogDesktop;
