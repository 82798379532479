import React from "react";
import { Tabs, Tab, useMediaQuery } from "@mui/material";
import { useTheme, makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";

const TabSwitch = ({
  textLeft,
  textRight,
  leftValue,
  rightValue,
  setValue,
  value,
  onClick,
}) => {
  const currentCandidate = useSelector((state) => state.current_candidate);
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const handleChange = (event, newValue) => {
    if (setValue) setValue(newValue);
  };

  const tabsStyles = makeStyles({
    root: {
      width: "fit-content",
      borderRadius: "30px",
      boxShadow: "0 0 0 1px black",
      backgroundColor: "#ffff",
    },
    indicator: {
      display: "none",
    },
    centered: {
      alignItems: "center",
      justifyContent: "center",
    },
  });

  const tabItemStyles = makeStyles({
    root: {
      position: "relative",
      display: "block",
      borderRadius: "30px !important",
      textAlign: "center",
      transition: "all .5s",
      padding: "10px 15px",
      color: "#555555",
      height: "auto",
      opacity: "1",
      margin: "5px 5px",
      float: "none",
    },
    leftButton: {
      minWidth: isDesktop ? "200px !important" : "80px !important",
      margin: "4px 4px !important",
      "&$selected": {
        "&, &:hover": {
          color: "#FFFFFF !important",
          backgroundColor: currentRecruiter
            ? theme.palette.secondary.main
            : theme.palette.primary.main,
        },
      },
    },
    rightButton: {
      minWidth: isDesktop ? "200px !important" : "80px !important",
      margin: "4px 4px !important",
      "&$selected": {
        "&, &:hover": {
          color: "#FFFFFF !important",
          backgroundColor: currentCandidate
            ? theme.palette.primary.main
            : theme.palette.secondary.main,
        },
      },
    },
    selected: {},
    wrapper: {
      lineHeight: "24px",
      textTransform: "uppercase",
      fontSize: "12px",
      fontWeight: "500",
      position: "relative",
      display: "block",
      color: "inherit",
    },
  });

  const tabItemClasses = tabItemStyles();

  //TODO: Update state when changing mode OR Switch page
  return (
    <Tabs
      value={value}
      classes={tabsStyles()}
      onChange={handleChange}
      onClick={onClick}
    >
      <Tab
        className={`${tabItemClasses.leftButton} ${tabItemClasses.root} ${
          tabItemClasses.wrapper
        } ${value === leftValue ? tabItemClasses.selected : ""}`}
        label={textLeft}
        value={leftValue}
      />
      <Tab
        className={`${tabItemClasses.rightButton} ${tabItemClasses.root} ${
          tabItemClasses.wrapper
        } ${value === rightValue ? tabItemClasses.selected : ""}`}
        label={textRight}
        value={rightValue}
      />
    </Tabs>
  );
};

export default TabSwitch;
