import React from "react";
import {
  Grid,
  Typography,
  Box,
  Card,
  CardActionArea,
  Link,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import ProfilePicture from "bundles/common/components/ProfilePicture";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  formatJobOfferLocations,
  getDenialReasons,
  truncate,
} from "../../utils/utils";

const JobApplicationCardMobile = ({ jobApplication, onClick }) => {
  const theme = useTheme();
  const jobOffer = jobApplication?.job_offer;
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const navigate = useNavigate();

  const redirectCompanyPage = () => {
    navigate(`/${i18nLocale}/candidate/company/${jobApplication.company.id}`);
  };

  return (
    <Grid item xs={12} style={{ borderRadius: "12px" }}>
      <Card>
        <CardActionArea onClick={onClick}>
          <Box m={2}>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <ProfilePicture
                  picture={jobApplication?.company?.logo_url}
                  rounded
                  size={40}
                  initials={jobApplication?.company?.name[0]}
                  borderRadius="5%"
                />
              </Grid>
              {jobOffer != null ? (
                <Grid item xs={5} md={4}>
                  <Typography variant="h4">
                    <b>
                      {jobOffer ? (
                        jobOffer.job_title
                      ) : (
                        <FormattedMessage id="spontaneous" />
                      )}
                    </b>
                  </Typography>

                  <Typography variant="subtitle2">
                    <b>
                      {formatJobOfferLocations(jobOffer?.locations).join(", ")}
                    </b>
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={5} md={4}>
                  <Typography variant="h5">
                    <b>
                      <FormattedMessage id="spontaneous" />
                    </b>
                  </Typography>
                </Grid>
              )}
              <Grid item xs={4} style={{ display: "flex", justifyContent: "center"}}>
                <Link onClick={redirectCompanyPage}>
                  <Typography align="left">
                    {truncate(jobApplication?.company?.name, 40)}
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={1}>
                {jobApplication?.status == "pending" && (
                  <Typography
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      color: theme.palette.text.warning,
                    }}
                  >
                    <Tooltip
                      title={<FormattedMessage id="application.pending" />}
                      style={{ cursor: "pointer" }}
                      aria-label="add"
                      placement="right"
                    >
                      <PendingActionsIcon />
                    </Tooltip>
                  </Typography>
                )}
                {jobApplication?.status == "open" && (
                  <Typography
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      color: theme.palette.text.green,
                    }}
                  >
                    <Tooltip
                      title={<FormattedMessage id="application.open" />}
                      style={{ cursor: "pointer" }}
                      aria-label="add"
                      placement="right"
                    >
                      <CheckCircleOutlineIcon />
                    </Tooltip>
                  </Typography>
                )}
                {jobApplication?.status == "closed" && (
                  <Typography
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      color: theme.palette.error.main,
                    }}
                  >
                    <Tooltip
                      title={getDenialReasons(
                        jobApplication.denial_reasons,
                        i18nLocale
                      )}
                      style={{ cursor: "pointer" }}
                      aria-label="add"
                      placement="right"
                    >
                      <HighlightOffIcon />
                    </Tooltip>
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default JobApplicationCardMobile;
