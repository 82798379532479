import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Grid,
  Typography,
  Paper,
  Box,
  Link,
  IconButton,
  Tooltip,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import SkillView from "bundles/Recruiter/components/SkillView";
import MetricsGrid from "bundles/common/components/Desktop/MetricsGridDesktop";
import HTML from "bundles/common/components/HTML";
import ProfilePicture from "bundles/common/components/ProfilePicture";
import {
  truncate,
  annualyRemuneration,
  handleLinkedinShare,
  handleCopyClick,
} from "bundles/common/utils/utils";
import { formatJobOfferLocation } from "bundles/common/utils/utils";
import ShLoadingButton from "bundles/common/components/ShLoadingButton";
import { formatJobDuration } from "bundles/common/utils/utils";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { FacebookShareButton } from "react-share";

const useStyles = makeStyles(theme => ({
  content: {
    padding: "16px 32px !important",
    borderRadius: "10px !important",
    marginTop: "15px !important",
  },
  statusBox: {
    textAlign: "center !important",
    color: theme.palette.common.white,
  },
}));

const TextBlock = ({ titleId, text }) => {
  return (
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom>
        <b>
          <FormattedMessage id={titleId} />
        </b>
      </Typography>
      <HTML html={text} />
    </Grid>
  );
};

const JobOfferSummaryDesktop = ({
  jobOffer,
  isApplyModal,
  company,
  handleApply,
  handleFavorite,
  isFavorite,
  publicJobUrl,
  hard_skills,
  soft_skills,
  isEasyApplying,
  languages,
  candidateApplication,
  enqueueSnackbar,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const i18nLocale = useSelector(state => state.i18nLocale);
  const intl = useIntl();

  useEffect(() => {
    if (isApplyModal) {
      console.log("Modal is active");
    }
  }, [isApplyModal]);

  function ApplyButton() {
    return (
      <Grid item xs={12} textAlign="center" style={{ marginTop: "16px" }}>
        <ShLoadingButton
          size="large"
          width="240px"
          color="primaryContained"
          variant="rounded"
          onClick={handleApply}
          loading={isEasyApplying}
          pulsating={!isEasyApplying}
          showFinishedState={true}
          isSuccessful={candidateApplication?.id}
          defaultLabelId="job_search.apply_to_job"
          loadingLabelId="job_search.applying"
          successLabelId="job_search.applied_successfully"
          failLabelId="job_search.applied_failed"
        />
      </Grid>
    );
  }

  return (
    <Grid container>
      {isApplyModal ? (
        ""
      ) : (
        <Grid item xs={12} md={true}>
          <Grid container>
            <Grid item xs={12}>
              <Grid
                container
                component={Paper}
                justifyContent="center"
                textAlign="left"
                style={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.common.white,
                  borderRadius: 10,
                }}
              >
                <Grid item xs={12}>
                  <Box px={3} my={1}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      <FormattedMessage
                        id="job_offer.apply_job_candidate"
                        values={{
                          candidate: jobOffer?.job_applications_count || 0,
                        }}
                      />
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        component={Paper}
        elevation={1}
        className={classes.content}
      >
        {/* Header */}
        <Grid item xs={12}>
          <Grid container spacing={3} align={"left"} alignItems="stretch">
            <Grid item xs={12} md={4}>
              <ProfilePicture
                picture={company?.logo_url}
                size={150}
                initials={company?.name?.[0]}
                alt={`Logo ${company?.name}`}
                px={2}
                py={2}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              display="flex"
              flexDirection="column"
              justifyContent="space-evenly"
            >
              <Grid container>
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    <Typography variant="h4">
                      <b>{truncate(jobOffer?.job_title, 60)}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      aria-label="edit-personal-info"
                      onClick={handleFavorite}
                      style={{ marginTop: "-28px" }}
                    >
                      {isFavorite ? (
                        <FavoriteIcon
                          style={{
                            color: theme.palette.text.blue,
                            width: "32px",
                            height: "32px",
                          }}
                        />
                      ) : (
                        <FavoriteBorderIcon
                          style={{
                            color: theme.palette.text.blue,
                            width: "32px",
                            height: "32px",
                          }}
                        />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1bold">
                    <Link
                      href={`/${i18nLocale}/companies/${company?.encoded_name}/${company?.id}`}
                    >
                      <b>{truncate(company?.name, 40)}</b>
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {jobOffer?.locations?.map(location => {
                    return (
                      <Box key={Object.values(location).join("-")}>
                        <Typography
                          variant="body3"
                          style={{
                            color: theme.palette.text.blue,
                            textTransform: "capitalize",
                          }}
                        >
                          <b>{formatJobOfferLocation(location)}</b>
                        </Typography>
                      </Box>
                    );
                  })}
                </Grid>
                <Grid item xs={12}>
                  <Box
                    style={{ display: "flex", width: "100%", marginTop: "8px" }}
                  >
                    <Box
                      onClick={() => handleLinkedinShare(publicJobUrl)}
                      style={{ cursor: "pointer" }}
                    >
                      <LinkedInIcon
                        style={{ fill: "#0077B5", marginRight: "4px" }}
                        size={32}
                        round={true}
                      />
                    </Box>
                    <FacebookShareButton url={publicJobUrl}>
                      <FacebookIcon
                        style={{ fill: "#1877F2", marginRight: "4px" }}
                        size={32}
                        round={true}
                      />
                    </FacebookShareButton>
                    <Tooltip
                      title={<FormattedMessage id="settings.copy_link" />}
                    >
                      <FileCopyOutlinedIcon
                        style={{ cursor: "pointer", marginRight: "4px" }}
                        onClick={() =>
                          handleCopyClick(publicJobUrl, intl, enqueueSnackbar)
                        }
                      />
                    </Tooltip>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {!isApplyModal && <ApplyButton />}

        {/* Metrics  */}

        <Grid item xs={12}>
          <Box style={{ marginTop: "48px", marginBottom: "16px" }}>
            <MetricsGrid
              titleOne="job_offer.company_industry"
              textOne={
                jobOffer?.company_industries?.length > 0
                  ? truncate(
                    jobOffer?.company_industries
                      .map(industry =>
                        intl.formatMessage({
                          id: `company.company_industry.${industry}`,
                        })
                      )
                      .join(", "),
                    12
                  )
                  : ""
              }
              titleTwo={
                jobOffer?.end_date
                  ? "job_offer.duration"
                  : "job_offer.start_date"
              }
              textTwo={formatJobDuration(jobOffer)}
              titleThree="job_offer.job_status"
              textThree={
                jobOffer?.job_statuses?.length > 0 ? (
                  <Tooltip
                    title={jobOffer?.job_statuses
                      .map(status =>
                        intl.formatMessage({
                          id: `candidate.status_map.${status}_hover`,
                        })
                      )
                      .join(", ")}
                    style={{ cursor: "pointer" }}
                    placement="bottom"
                  >
                    <span>
                      {jobOffer?.job_statuses
                        .map(status =>
                          intl.formatMessage({
                            id: `candidate.status_map.${status}`,
                          })
                        )
                        .join(", ")}
                    </span>
                  </Tooltip>
                ) : (
                  <FormattedMessage id="undefined" />
                )
              }
              titleFour="job_offer.monthly_offer"
              textFour={
                jobOffer?.monthly_offer ? (
                  annualyRemuneration(jobOffer?.monthly_offer)
                ) : (
                  <FormattedMessage id="to_discuss" />
                )
              }
            />
          </Box>
        </Grid>

        {/* Description  */}
        <Grid item xs={12}>
          <Box style={{ marginTop: "32px", marginBottom: "16px" }}>
            <Grid container spacing={3}>
              {/* Description */}
              {jobOffer?.description && (
                <TextBlock
                  titleId="job_offer.description"
                  text={jobOffer?.description}
                />
              )}

              {/* Responsibilities */}
              {jobOffer?.responsibilities && (
                <TextBlock
                  titleId="job_offer_creation.responsibilities"
                  text={jobOffer?.responsibilities}
                />
              )}

              {/* Profile */}
              {jobOffer?.profile && (
                <TextBlock
                  titleId="job_offer.profile"
                  text={jobOffer?.profile}
                />
              )}

              {/* Offer */}
              {jobOffer?.offer && (
                <TextBlock titleId="job_offer.offer" text={jobOffer?.offer} />
              )}

              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  rowGap: "16px",
                  columnGap: "16px",
                }}
              >
                {jobOffer?.offer_advantages?.advantages?.map((value, index) => (
                  <Box key={`advantages-${index}`} style={{ display: "flex" }}>
                    <CheckCircleOutlineIcon
                      color="primary"
                      style={{ marginRight: "4px" }}
                    />
                    <FormattedMessage
                      id={`job_offer_edit.advantages.${value}`}
                    />
                  </Box>
                ))}
                {jobOffer?.offer_advantages?.schedules?.map((value, index) => (
                  <Box key={`schedules-${index}`} style={{ display: "flex" }}>
                    <CheckCircleOutlineIcon
                      color="primary"
                      style={{ marginRight: "4px" }}
                    />
                    <FormattedMessage
                      id={`job_offer_edit.schedules.${value}`}
                    />
                  </Box>
                ))}
                {jobOffer?.offer_advantages?.environments?.map(
                  (value, index) => (
                    <Box
                      key={`environments-${index}`}
                      style={{ display: "flex" }}
                    >
                      <CheckCircleOutlineIcon
                        color="primary"
                        style={{ marginRight: "4px" }}
                      />
                      <FormattedMessage
                        id={`job_offer_edit.environment.${value}`}
                      />
                    </Box>
                  )
                )}
                {jobOffer?.offer_advantages?.primes?.map((value, index) => (
                  <Box key={`primes-${index}`} style={{ display: "flex" }}>
                    <CheckCircleOutlineIcon
                      color="primary"
                      style={{ marginRight: "4px" }}
                    />
                    <FormattedMessage id={`job_offer_edit.primes.${value}`} />
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Skills */}
        <Grid item xs={12}>
          <Box style={{ marginTop: "32px", marginBottom: "16px" }}>
            <Grid container spacing={3}>
              {hard_skills?.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    <b>
                      <FormattedMessage id="candidate.profile.skills.hard" />
                    </b>
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      columnGap: "4px",
                      rowGap: "4px",
                      flexFlow: "wrap",
                    }}
                  >
                    {hard_skills.map((value, index) => (
                      <Box key={`hard-${index}`}>
                        <SkillView
                          value={value}
                          category="hard"
                          locale={i18nLocale}
                          level={false}
                        />
                      </Box>
                    ))}
                  </Box>
                </Grid>
              )}

              {soft_skills?.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    <b>
                      <FormattedMessage id="candidate.profile.skills.soft" />
                    </b>
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      columnGap: "4px",
                      rowGap: "4px",
                      flexFlow: "wrap",
                    }}
                  >
                    {soft_skills.map((value, index) => (
                      <Box key={`soft-${index}`}>
                        <SkillView
                          level={false}
                          value={value}
                          category="soft"
                          locale={i18nLocale}
                        />
                      </Box>
                    ))}
                  </Box>
                </Grid>
              )}

              {languages?.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    <b>
                      <FormattedMessage id="candidate.profile.skills.lang" />
                    </b>
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      columnGap: "4px",
                      rowGap: "4px",
                      flexFlow: "wrap",
                    }}
                  >
                    {languages.map((value, index) => (
                      <Box key={`lang-${index}`}>
                        <SkillView
                          value={value}
                          category="lang"
                          locale={i18nLocale}
                        />
                      </Box>
                    ))}
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>

        {!isApplyModal && <ApplyButton />}
      </Grid>
    </Grid>
  );
};

export default JobOfferSummaryDesktop;
