import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { FormattedMessage } from "react-intl";

const LoginOrSignin = ({ onSectionClicked }) => {
  return (
    <Box>
      <Box style={{ marginBottom: "24px" }}>
        <Typography
          variant="body2"
          style={{
            textAlign: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
          }}
        >
          <FormattedMessage id="sub_please_sign" />
        </Typography>
      </Box>

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          rowGap: "8px",
        }}
      >
        <Box>
          <Button
            style={{ minWidth: "250px" }}
            type="submit"
            variant="rounded"
            color="primaryContained"
            onClick={() => onSectionClicked("login")}
          >
            <Typography>
              <FormattedMessage id="signin" />
            </Typography>
          </Button>
        </Box>
        <Box>
          <Button
            style={{ minWidth: "250px" }}
            type="submit"
            variant="rounded"
            color="primaryOutlined"
            onClick={() => onSectionClicked("sign_up")}
          >
            <Typography>
              <FormattedMessage id="not_account" />
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginOrSignin;
