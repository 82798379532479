import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import {
  Typography,
  Button,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import sh from "bundles/common/utils/sh";
import JobOfferList from "bundles/Candidate/components/JobOfferList";
import AuthenticationModal from "bundles/Candidate/components/AuthenticationModal";

const FourBlogJobOffers = () => {
  const theme = useTheme();
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const currentCandidate = useSelector((state) => state.current_candidate);
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [jobOffers, setJobOffers] = useState([]);
  const [singleJob, setSingleJob] = useState(true);
  const [currentTarget, setCurrentTarget] = useState(null);
  const [loginToAddFavorite, setLoginToAddFavorite] = useState(false);
  const [isLoginModalOpened, setIsLoginModalOpened] = useState(false);
  const [loginToAddResume, setLoginToAddResume] = useState(false);

  const handleOfferClicked = (offer, e, addFavorite = false) => {
    e.preventDefault();
    if (currentCandidate) {
      window.location.href = `/${i18nLocale}/candidate/job_offers?jid=${offer.id}`;
    } else {
      setSingleJob(true);
      setCurrentTarget(`/${i18nLocale}/candidate/job_offers?jid=${offer.id}`);
      setLoginToAddFavorite(addFavorite);
      setIsLoginModalOpened(true);
    }
  };

  const handleAllOffersClicked = (e) => {
    e.preventDefault();
    if (currentCandidate) {
      window.location.href = `/${i18nLocale}/candidate/job_offers`;
    } else {
      setSingleJob(false);
      setCurrentTarget(`/${i18nLocale}/candidate/job_offers`);
      setIsLoginModalOpened(true);
    }
  };

  const onAuthenticationModalClosed = () => {
    setIsLoginModalOpened(false);
    setTimeout(() => {
      setLoginToAddResume(false);
      setLoginToAddFavorite(false);
    }, 200);
  };

  const fetchFourJobs = () => {
    sh.get("random_jobs", {
      params: {
        quantity: 4,
        authenticity_token: authenticityToken,
      },
    })
      .then((res) => {
        setJobOffers(res.data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchFourJobs();
  }, []);

  useEffect(() => {
    if (currentCandidate?.id && currentTarget) {
      setCurrentTarget(null);
      let showOnboarding = currentCandidate.new_sign_in_count <= 1;
      let redirectParams = singleJob
        ? `&showOnboarding=${showOnboarding}`
        : `?showOnboarding=${showOnboarding}`;
      window.location.href = `${currentTarget}${redirectParams}`;
    }
  }, [currentCandidate?.id]);

  return (
    <>
      <Box style={{ backgroundColor: "#F9FAFF" }}>
        <Box maxWidth={isDesktop ? "lg" : "90%"} style={{ margin: "auto" }}>
          <Typography
            variant="h2"
            color="secondary"
            style={{
              paddingTop: "36px",
              paddingBottom: "16px",
              fontSize: "36px",
            }}
          >
            <FormattedMessage id="landing_candidate.our_job_offers" />
          </Typography>
          <JobOfferList
            offers={jobOffers}
            handleOfferClick={handleOfferClicked}
            columnPerRow={isDesktop ? 2 : 1}
            redirect={!currentCandidate}
            logoHoverAndRedirect={true}
          />
        </Box>
        <Box style={{ textAlign: "center", marginBottom: "32px" }}>
          <Button
            variant="rounded"
            color="primaryContained"
            style={{ minWidth: "280px" }}
            onClick={handleAllOffersClicked}
          >
            <FormattedMessage id="landing_candidate.less_than_100_jobs" />
          </Button>
        </Box>
      </Box>
      <AuthenticationModal
        isOpen={isLoginModalOpened}
        section="login_or_signin"
        closeModal={onAuthenticationModalClosed}
        singleJob={singleJob}
        addResumeLogin={loginToAddResume}
        addFavoriteLogin={loginToAddFavorite}
      />
    </>
  );
};

export default FourBlogJobOffers;
