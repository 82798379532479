import { fileChecksum } from "./fileChecksum";
import sh from "./sh";

const createPresignedUrl = async (byte_size, checksum, filename) => {
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      file: {
        filename: filename,
        byte_size: byte_size,
        checksum: checksum,
        content_type: "application/pdf",
        metadata: {
          folder: "portfolios",
        },
      },
    }),
  };
  let res = await fetch("/presigned_url", options);
  if (res.status !== 200) return res;
  return await res.json();
};

export const attachPortfolioToCandidate = async ({
  id,
  authenticity_token,
  blob_signed_id,
}) => {
  // 3) confirm&create user with backend
  const params = {
    authenticity_token,
    candidate: {
      id,
      portfolio: blob_signed_id,
    },
  };
  let res = await sh.put("/upload_portfolio", params);
  return res?.data || res;
};

export const uploadPortfolio = async (portfolio, filename = portfolio.name) => {
  // To upload pdf file to S3, we need to do three steps:
  // 1) request a pre-signed PUT request (for S3) from the backend

  const checksum = await fileChecksum(portfolio);
  const presignedFileParams = await createPresignedUrl(
    portfolio.size,
    checksum,
    filename
  );

  // 2) send file to said PUT request (to S3)
  const s3PutOptions = {
    method: "PUT",
    headers: presignedFileParams.direct_upload.headers,
    body: portfolio,
  };
  await fetch(presignedFileParams.direct_upload.url, s3PutOptions);
  return presignedFileParams;
};
