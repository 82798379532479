import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import {
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  Box,
  MenuItem,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import TuneIcon from "@mui/icons-material/Tune";
// import NotificationsIcon from "@mui/icons-material/Notifications";
import ApartmentIcon from "@mui/icons-material/Apartment";
import GroupIcon from "@material-ui/icons/Group";
import Profile from "./Settings/Profile";
import Password from "./Settings/Password";
import Company from "./Settings/Company";
import Team from "./Settings/Team";
import CustomSkills from "./Settings/CustomSkills";
import Notifications from "./Settings/Notifications";

const sections = [
  {
    code: "profile",
    labelId: "settings.profile",
    icon: () => (
      <AccountBoxIcon
        style={{ width: "18px", height: "18px", marginRight: "8px" }}
      />
    ),
    displayOnMobile: true,
  },
  {
    code: "password",
    labelId: "settings.password",
    icon: () => (
      <LockPersonIcon
        style={{ width: "18px", height: "18px", marginRight: "8px" }}
      />
    ),
    displayOnMobile: true,
  },
  {
    code: "company",
    labelId: "settings.company",
    icon: () => (
      <ApartmentIcon
        style={{ width: "18px", height: "18px", marginRight: "8px" }}
      />
    ),
    displayOnMobile: false,
  },
  {
    code: "team",
    labelId: "settings.team",
    icon: () => (
      <GroupIcon
        style={{ width: "18px", height: "18px", marginRight: "8px" }}
      />
    ),
    displayOnMobile: false,
  },
  {
    code: "custom_skills",
    labelId: "settings.custom_skills",
    icon: () => (
      <TuneIcon
        style={{ width: "18px", height: "18px", marginRight: "8px" }}
      />
    ),
    displayOnMobile: false,
  },
  // {
  //   code: "notifications",
  //   labelId: "settings.notifications",
  //   icon: () => (
  //     <NotificationsIcon
  //       style={{ width: "18px", height: "18px", marginRight: "8px" }}
  //     />
  //   ),
  //   displayOnMobile: true,
  // },
];

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Settings = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const query = useQuery();

  const [selectedSection, setSelectedSection] = useState(
    sections.find((s) => s.code == query.get("selectedSection")) || sections[0]
  );

  return (
    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={12} style={{ marginTop: "32px", marginBottom: "8px" }}>
          <Typography variant="h2">
            <FormattedMessage id="settings.title" />
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "32px" }}>
          <Typography variant="body1bold">
            <FormattedMessage id="settings.subtitle" />
          </Typography>
        </Grid>
        <Grid item xs={12} component={Paper} style={{ marginBottom: "32px" }}>
          <Grid container style={{ height: "100%" }}>
            {isDesktop ? (
              <Grid
                item
                md={2}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <Box
                  style={{ height: "100%", flexGrow: 1, padding: "32px 16px" }}
                >
                  {sections.map((section) => {
                    return (
                      <MenuItem
                        key={`section-${section.code}`}
                        style={{
                          fontFamily:
                            section.code == selectedSection.code
                              ? "bold"
                              : "inherit",
                          textDecoration:
                            section.code == selectedSection.code
                              ? "underline"
                              : "none",
                          textUnderlineOffset: "4px",
                          color: theme.palette.secondary.main,
                          marginBottom: "8px",
                        }}
                        onClick={() =>
                          setSelectedSection(
                            sections.find((s) => s.code == section.code)
                          )
                        }
                      >
                        {section.icon()}
                        <Typography variant="body1bold">
                          <FormattedMessage id={section.labelId} />
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Box>
                <Divider orientation="vertical" />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    backgroundColor: "transparent",
                  }}
                >
                  {sections
                    .filter((section) => section.displayOnMobile)
                    .map((section) => {
                      return (
                        <MenuItem
                          key={`section-${section.code}`}
                          style={{
                            width: "100%",
                            justifyContent: "center",
                            borderTopLeftRadius: "8px",
                            borderTopRightRadius: "8px",
                            color:
                              section.code == selectedSection.code
                                ? theme.palette.secondary.light
                                : theme.palette.common.white,
                            backgroundColor:
                              section.code == selectedSection.code
                                ? theme.palette.common.white
                                : theme.palette.secondary.light,
                          }}
                          onClick={() =>
                            setSelectedSection(
                              sections.find((s) => s.code == section.code)
                            )
                          }
                        >
                          {section.icon()}
                        </MenuItem>
                      );
                    })}
                </Box>
                <Divider style={{ borderColor: theme.palette.common.white }} />
              </Grid>
            )}
            <Grid item xs={12} md={10}>
              <Box style={{ padding: isDesktop ? "32px" : "16px" }}>
                {selectedSection.code == "profile" && <Profile />}
                {selectedSection.code == "password" && <Password />}
                {selectedSection.code == "company" && <Company />}
                {selectedSection.code == "team" && <Team />}
                {selectedSection.code == "notifications" && <Notifications />}
                {selectedSection.code == "custom_skills" && <CustomSkills />}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Settings;
