import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Card, Typography, useMediaQuery, Link as MuiLink } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { makeStyles, useTheme } from "@mui/styles";
import { truncate } from "bundles/common/utils/utils";
import HTML from "bundles/common/components/HTML";
import Logo from "icons/logo.svg";
import moment from "moment";

const BlogCard = ({ article, companyId }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const useStyles = makeStyles(() => ({
    card: {
      height: "400px",
      display: "flex",
      cursor: "pointer",
      flexDirection: isDesktop ? "row" : "column",
    },
    logoBox: {
      width: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0",
    },
    logo: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    contentBox: {
      width: isDesktop ? "50%" : "100%",
      padding: isDesktop ? "24px 32px" : "8px 16px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    timeBox: {
      marginBottom: "8px",
    },
    content: {
      display: "-webkit-box",
      "-webkit-line-clamp": 5,
      "-webkit-box-orient": "vertical",
      overflow: "hidden",
      fontSize: "18px !important",
      marginTop: "16px !important",
      color: theme.palette.secondary.main
    },
    readArticleButton: {
      padding: "0px 80px !important",
      margin: "16px 0",
      cursor: "pointer"
    },
  }));
  const classes = useStyles();
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`${article.encoded_title}/${article.id}`);
  };

  return (
    <Card className={classes.card} onClick={onClick}>
      <Box className={classes.logoBox}>
        <img
          className={classes.logo}
          src={article.cover_picture_url || Logo}
          srcSet={`${article.cover_picture_url} 1x, ${article.cover_picture_url} 0.5x`}
          alt={article.title}
        />
      </Box>
      <Box className={classes.contentBox}>
        <Box>
          <Typography variant="subtitle2" className={classes.timeBox}>
            {moment(article.created_at).format("DD/MM/YYYY")}
          </Typography>
          <Typography variant="h2" sx={{ fontSize: "36px", fontWeight: "600", marginTop: "12px" }}>
            {truncate(article.title, 80)}
          </Typography>
          <Typography variant="body1" className={classes.content}>
            <HTML
              html={article.content}
              blacklistedTags={["strong", "p"]}
            />
          </Typography>
        </Box>
        <Box>
          <Box display="flex" style={{ marginBottom: "16px" }}>
            <Typography variant="body2" color="textSecondary">
              <FormattedMessage id="landing_candidate.from" />
            </Typography>
            {companyId && (
              <Typography variant="body2" color="primary" style={{ cursor: "pointer" }}>
                <MuiLink
                  href={`/companies/squarehub/${companyId}`}
                  sx={{
                    marginLeft: 0.6,
                    color: "inherit",
                    textDecoration: "inherit",
                    ":hover": {
                      color: "#035d2a"
                    }
                  }}
                >
                  Squarehub
                </MuiLink>
              </Typography>
            )}
          </Box>
          <Button
            variant="rounded"
            color="primaryOutlined"
            className={classes.readArticleButton}
            onClick={onClick}
          >
            <Typography variant="body2bold">
              <FormattedMessage id="blog.read_article" />
            </Typography>
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default BlogCard;
