import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Button,
  Checkbox,
  Typography,
  ListItemText,
  MenuItem,
  Modal,
  IconButton,
  Divider,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { companyIndustryMap } from "bundles/common/utils/constants";
import { truncate } from "../../../common/utils/utils";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "32px 24px",
  },
  item: {
    cursor: "pointer",
    alignItems: "center",
    padding: "0px 0px 4px 16px",
  },
  checkboxes: {
    padding: "0px",
  },
  filterTitle: {
    color: theme.palette.common.blue,
  },
  modal: {
    overflow: "auto",
    width: "100%",
    height: "100%",
    backgroundColor: `${theme.palette.common.white} !important`,
  },
  modalContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.common.white,
  },
  modalHeader: {
    height: "68px",
    backgroundColor: theme.palette.primary.main,
  },
  modalBox: {},
  category: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 8px",
  },
  badge: {
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: "16px",
    width: "16px",
  },
  menuItem: {
    height: "24px",
    "&.Mui-selected": {
      backgroundColor: "white !important",
    },
  },
}));

const MobileAdvancedFilteringCompany = ({
  selectedIndustries,
  setSelectedIndustries,
  clearAdvancedFilters,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const [currentExpanded, setCurrentExpanded] = useState(null);

  const handleIndustries = (industry) => {
    if (selectedIndustries.includes(industry)) {
      setSelectedIndustries(selectedIndustries.filter((a) => a != industry));
    } else {
      setSelectedIndustries(selectedIndustries.concat(industry));
    }
  };

  const onModalCloses = () => {
    setCurrentExpanded(null);
    setExpanded(false);
  };

  const handleCategoryExpansion = (category) => {
    return currentExpanded == category
      ? setCurrentExpanded(null)
      : setCurrentExpanded(category);
  };

  const totalFilters = selectedIndustries?.length || 0;

  const hasActiveFilters = totalFilters > 0;

  useEffect(() => {
    if (!expanded) setCurrentExpanded(null);
  }, [expanded]);

  return (
    <Box id="sh-advanced-filtering">
      <Box display="flex" justifyContent="center">
        <Button
          variant="textUnderline"
          style={{
            marginTop: "8px",
            marginBottom: hasActiveFilters ? "0px" : "4px",
          }}
          onClick={() => setExpanded(!expanded)}
        >
          <Box>
            <FormattedMessage id="job_search.advanced_search" />
            {"  "}
            {hasActiveFilters && <span>({totalFilters})</span>}
          </Box>
        </Button>
      </Box>

      {hasActiveFilters && (
        <Box textAlign="center">
          <Button
            variant="text"
            style={{
              padding: "0px",
              marginBottom: "4px",
              height: "fit-content",
            }}
            onClick={clearAdvancedFilters}
          >
            <Typography variant="body3">
              <FormattedMessage id="job_search.clear_filters" />
            </Typography>
          </Button>
        </Box>
      )}

      <Modal
        open={expanded}
        onClose={onModalCloses}
        disableAutoFocus={true}
        className={classes.modal}
        hideBackdrop={true}
      >
        <Box className={classes.modalContainer}>
          <Box className={classes.modalHeader}>
            <IconButton
              aria-label="af-go-back"
              onClick={() => setExpanded(false)}
              style={{
                top: "14px",
                left: "16px",
                position: "absolute",
              }}
            >
              <ArrowCircleLeftIcon
                fontSize="medium"
                style={{ color: "white" }}
              ></ArrowCircleLeftIcon>
            </IconButton>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{ height: "100%" }}
            >
              <Typography
                variant="h5"
                style={{ color: theme.palette.common.white }}
              >
                <FormattedMessage id="job_search.advanced_search" />
              </Typography>
            </Box>
          </Box>
          <Box className={classes.modalBox}>
            {/* Sectors */}
            <Box>
              <Box
                className={classes.category}
                onClick={() => handleCategoryExpansion("sectors")}
              >
                <Box>
                  <Box>
                    <Typography
                      variant="body1bold"
                      color="secondary"
                      style={{ display: "flex", alignItems: "baseline" }}
                    >
                      Sector
                      {selectedIndustries?.length > 0 && (
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "8px",
                            textAlign: "center",
                            width: "16px",
                            height: "16px",
                            borderRadius: "50%",
                            color: theme.palette.common.white,
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          <Typography variant="body3">
                            {selectedIndustries?.length}
                          </Typography>
                        </Box>
                      )}
                    </Typography>
                  </Box>

                  {selectedIndustries?.length > 0 && (
                    <Box>
                      <Typography>
                        {truncate(
                          selectedIndustries
                            .map((a) => {
                              return intl.formatMessage({
                                id: `company.company_industry.${a}`,
                              });
                            })
                            .join(", "),
                          40
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box>
                  {currentExpanded == "sectors" ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </Box>
              </Box>
              {currentExpanded == "sectors" && (
                <Box>
                  {companyIndustryMap.map((sector) => {
                    return (
                      <MenuItem
                        onClick={() => handleIndustries(sector)}
                        key={sector}
                        value={sector}
                        className={`${classes.menuItem} ${classes.menuItemOption}`}
                      >
                        <Checkbox
                          checked={selectedIndustries?.includes(sector)}
                        />
                        <ListItemText
                          primary={intl.formatMessage({
                            id: `company.company_industry.${sector}`,
                          })}
                        />
                      </MenuItem>
                    );
                  })}
                </Box>
              )}
              <Divider />
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default MobileAdvancedFilteringCompany;
