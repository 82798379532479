import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import sh from "bundles/common/utils/sh";
import JobOfferIndexDesktop from "./Desktop/JobOfferIndexDesktop";
import JobOfferIndexMobile from "./Mobile/JobOfferIndexMobile";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { incrementJobOfferMetric } from "bundles/common/utils/utils";

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const JobOfferIndex = ({ loading, setLoading }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const currentCandidate = useSelector((state) => state.current_candidate);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const navigate = useNavigate();
  const query = useQuery();

  const [isApplying, setIsApplying] = useState(
    query.get("isApplying") === "true" || false
  );
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobOffers, setJobOffers] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [count, setCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const [queryText, setQueryText] = useState(
    history?.location?.state?.queryText || ""
  );
  const [selectedRegions, setSelectedRegions] = useState(
    history?.location?.state?.selectedRegions || []
  );
  const [onlyFavorites, setOnlyFavorites] = useState(
    searchParams.get("onlyFavorites") === "true" || false
  );
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [selectedJobStatuses, setSelectedJobStatuses] = useState([]);

  useEffect(() => {
    fetchJobOffers({
      page,
      query: queryText,
      filters: createFilters(),
    });
  }, [
    page,
    queryText,
    selectedRegions,
    selectedLanguages,
    selectedIndustries,
    selectedJobStatuses,
    onlyFavorites,
  ]);

  useEffect(() => {
    if (
      !selectedJob &&
      !query.get("jid") &&
      !query.get("selectedJobId")
    ) {
      setSelectedJob(jobOffers.length > 0 ? jobOffers[0] : null);
    }
  }, [jobOffers.length]);

  useEffect(() => {
    if (
      (query.get("jid") || query.get("selectedJobId")) &&
      jobOffers?.length > 0
    ) {
      let queryId = query.get("jid") || query.get("selectedJobId");
      sh.get(`/${i18nLocale}/candidate/job_offers/${queryId}`, {
        authenticity_token: authenticityToken,
      })
        .then((res) => {
          if (res?.data?.data) {
            setSelectedJob(res?.data?.data);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });

  useEffect(() => {
    if (selectedJob?.id) {
      incrementJobOfferMetric(selectedJob, "view_count", authenticityToken);

      let offerIndex = jobOffers.findIndex((o) => o.id == selectedJob?.id);
      if (offerIndex > 0 && offerIndex < jobOffers.length) {
        let updatedOffers = jobOffers;
        updatedOffers[offerIndex] = selectedJob;
        setJobOffers(updatedOffers);
      }

      let search = "";

      if (isApplying) search += "?isApplying=true";
      if (onlyFavorites && isApplying) {
        search += "&onlyFavorites=true";
      } else if (onlyFavorites) {
        search += "?onlyFavorites=true";
      }
      navigate({
        search: search,
      });
    }
  }, [selectedJob]);

  useEffect(() => {
    if (searchParams.get("onlyFavorites") === "true") {
      setOnlyFavorites(true);
    } else {
      setOnlyFavorites(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!onlyFavorites) {
      searchParams.delete("onlyFavorites");
      setSearchParams(searchParams);
    }
  }, [onlyFavorites]);

  const createFilters = () => {
    let filters = {};
    if (selectedLanguages?.length > 0)
      Object.assign(filters, { offer_language: selectedLanguages });
    if (selectedIndustries?.length > 0)
      Object.assign(filters, { company_industries: selectedIndustries });
    if (selectedJobStatuses?.length > 0)
      Object.assign(filters, { job_statuses: selectedJobStatuses });
    if (selectedRegions?.length > 0)
      Object.assign(filters, { regions: selectedRegions });
    if (onlyFavorites) {
      Object.assign(filters, {
        only_favorites: true,
      });
    }
    return filters;
  };

  const fetchJobOffers = ({
    page = 1,
    searchQuery = queryText,
    filters = createFilters(),
    callback,
  } = {}) => {
    setLoading(true);
    sh.get("search/job_offers", {
      params: {
        authenticity_token: authenticityToken,
        query: searchQuery,
        page,
        filters,
      },
    })
      .then((res) => {
        setJobOffers(res.data?.data);
        let hasJobQueryParam = query.get("jid") || query.get("selectedJobId");
        if (
          (res?.data?.pagy?.count || 0) > 0 &&
          !hasJobQueryParam
        ) {
          setSelectedJob(res.data?.data[0]);
        } else {
          setSelectedJob(selectedJob);
        }
        setPageCount(res.data?.pagy?.pages);
        setCount(res?.data?.pagy?.count);
        callback && callback(res.data?.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const closeDialog = () => {
    setSelectedJob(null);
  };

  const clearAdvancedFilters = () => {
    setSelectedLanguages([]);
    setSelectedIndustries([]);
    setSelectedJobStatuses([]);
    setOnlyFavorites(false);
  };

  const handleClicks = (job) => {
    if (selectedJob === job) {
      setIsApplying(true);
    } else {
      setSelectedJob(job);
    }
  };

  return (
    <>
      {isDesktop
        ?
        <JobOfferIndexDesktop
          loading={loading}
          setLoading={setLoading}
          selectedLanguages={selectedLanguages}
          setSelectedLanguages={setSelectedLanguages}
          selectedIndustries={selectedIndustries}
          setSelectedIndustries={setSelectedIndustries}
          selectedJobStatuses={selectedJobStatuses}
          setSelectedJobStatuses={setSelectedJobStatuses}
          selectedRegions={selectedRegions}
          setSelectedRegions={setSelectedRegions}
          clearAdvancedFilters={clearAdvancedFilters}
          queryText={queryText}
          setQueryText={setQueryText}
          onlyFavorites={onlyFavorites}
          setOnlyFavorites={setOnlyFavorites}
          jobOffers={jobOffers}
          selectedJob={selectedJob}
          handleClicks={handleClicks}
          setPage={setPage}
          page={page}
          pageCount={pageCount}
          count={count}
          currentCandidate={currentCandidate}
          isApplying={isApplying}
          setIsApplying={setIsApplying}
          setSelectedJob={setSelectedJob}
        />
        :
        <JobOfferIndexMobile
          loading={loading}
          setLoading={setLoading}
          selectedLanguages={selectedLanguages}
          setSelectedLanguages={setSelectedLanguages}
          selectedIndustries={selectedIndustries}
          setSelectedIndustries={setSelectedIndustries}
          selectedJobStatuses={selectedJobStatuses}
          setSelectedJobStatuses={setSelectedJobStatuses}
          selectedRegions={selectedRegions}
          setSelectedRegions={setSelectedRegions}
          clearAdvancedFilters={clearAdvancedFilters}
          queryText={queryText}
          setQueryText={setQueryText}
          onlyFavorites={onlyFavorites}
          setOnlyFavorites={setOnlyFavorites}
          jobOffers={jobOffers}
          selectedJob={selectedJob}
          handleClicks={handleClicks}
          setPage={setPage}
          page={page}
          pageCount={pageCount}
          count={count}
          currentCandidate={currentCandidate}
          isApplying={isApplying}
          setIsApplying={setIsApplying}
          setSelectedJob={setSelectedJob}
          closeDialog={closeDialog}
        />
      }
    </>
  );
};

export default JobOfferIndex;
