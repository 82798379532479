import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useState } from "react";
import { useSnackbar } from "notistack";
import {
  Grid,
  Typography,
  Box,
  Switch,
  Button,
  useTheme,
  Tooltip,
} from "@mui/material";
import sh from "bundles/common/utils/sh";

const Notifications = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const currentRecruiter = useSelector((state) => state.current_recruiter);

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState(
    currentRecruiter?.notification_preferences
  );

  const handleNotificationChange = (type) => {
    setNotifications({ ...notifications, [type]: !notifications[type] });
  };

  const handleSave = () => {
    setLoading(true);
    sh.put("recruiter", {
      authenticity_token: authenticityToken,
      recruiter: {
        notification_preferences: notifications,
        id: currentRecruiter.id,
      },
    })
      .then(() => {
        enqueueSnackbar(intl.formatMessage(
          { id: "recruiter.updated_profile" },
        ), {
          variant: "success",
        });
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("Oops... Something went wrong", { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{
          marginBottom: "16px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">
          <FormattedMessage id="settings.update_notifications" />
        </Typography>
        <Button
          variant="rounded"
          color="secondaryContained"
          style={{ width: "180px" }}
          onClick={handleSave}
          disabled={loading}
          loading={loading}
        >
          <FormattedMessage id="save" />
        </Button>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "16px" }}>
        <Box>
          <Box style={{ display: "flex" }}>
            <Box
              style={{
                flexGrow: 1,
                borderTop: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderLeft: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderRight: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderTopLeftRadius: "8px",
                padding: "8px 16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body1bold">
                A new candidate matches my needs
              </Typography>
              {!currentRecruiter?.resume_library_access && (
                <Button
                  variant="rounded"
                  color="primaryContained"
                  size="small"
                  href={`/${i18nLocale}/recruiter/pricing`}
                >
                  Get access
                </Button>
              )}
            </Box>
            <Box
              style={{
                borderTop: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderRight: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderTopRightRadius: "8px",
                padding: "8px 16px",
              }}
            >
              {!currentRecruiter?.resume_library_access ? (
                <Tooltip
                  title={<FormattedMessage id="credits_box.no_cv_access" />}
                  style={{ cursor: "not-allowed" }}
                  aria-label="no-access"
                  placement="top"
                >
                  <span>
                    <Switch
                      disabled={true}
                      checked={notifications?.candidate_alert}
                      color="secondary"
                      onChange={() =>
                        handleNotificationChange("candidate_alert")
                      }
                    />
                  </span>
                </Tooltip>
              ) : (
                <Switch
                  checked={notifications?.candidate_alert}
                  color="secondary"
                  onChange={() => handleNotificationChange("candidate_alert")}
                />
              )}
            </Box>
          </Box>
          <Box style={{ display: "flex" }}>
            <Box
              style={{
                flexGrow: 1,
                borderTop: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderLeft: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderRight: `1px solid ${theme.palette.common.sh_lightgrey}`,
                padding: "8px 16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="body1bold">
                A candidate applied to one of my offer
              </Typography>
            </Box>
            <Box
              style={{
                borderTop: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderRight: `1px solid ${theme.palette.common.sh_lightgrey}`,
                padding: "8px 16px",
              }}
            >
              <Switch
                checked={notifications?.new_job_application}
                color="secondary"
                onChange={() => handleNotificationChange("new_job_application")}
              />
            </Box>
          </Box>
          <Box style={{ display: "flex" }}>
            <Box
              style={{
                flexGrow: 1,
                border: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderBottomLeftRadius: "8px",
                padding: "8px 16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="body1bold">
                One of my job offer is about to expire
              </Typography>
            </Box>
            <Box
              style={{
                borderTop: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderRight: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderBottom: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderBottomRightRadius: "8px",
                padding: "8px 16px",
              }}
            >
              <Switch
                checked={notifications?.expired_job_offers}
                color="secondary"
                onChange={() => handleNotificationChange("expired_job_offers")}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Notifications;
