import sh from "bundles/common/utils/sh";

export const REPLACE_CURRENT_RECRUITER = "REPLACE_CURRENT_RECRUITER";

export const replaceCurrentRecruiter = (
  dispatch,
  authenticity_token,
  successCallback=()=>{}
) => {
  sh.get("current_recruiter", {
    authenticity_token,
  })
    .then((res) => {
      dispatch({
        type: REPLACE_CURRENT_RECRUITER,
        payload: res.data,
      });
      successCallback();
    })
    .catch((err) => {
      console.log(err);
    });
};
