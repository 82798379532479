import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Card,
  Typography,
  CardMedia,
  CardContent,
  useMediaQuery,
  Link as MuiLink,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { makeStyles, useTheme } from "@mui/styles";
import moment from "moment";
import { truncate, getFirstPathParam } from "bundles/common/utils/utils";
import HTML from "bundles/common/components/HTML";

const BlogPostCard = ({ post, companyId, landing = false  }) => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [cardIsLarge, setCardIsLarge] = useState(false);

  const useStyles = makeStyles(() => ({
    card: {
      height: isDesktop? "325px": "",
      width: isDesktop ? "275px" : "100%",
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      borderRadius: "10px !important",
      border: "1px solid lightgrey",
      transition:
        "border 0.3s ease, box-shadow 0.3s ease, transform 0.5s ease !important",
      "&:hover": {
        border: `1px solid ${theme.palette.secondary.main}`,
        boxShadow: `0 0 0 2px ${theme.palette.secondary.main}`,
        transform: isDesktop ? "scale(1.06)" : "scale(1.02)"
      },
    },
    title: {
      margin: isDesktop ? "2px 0px 0px 0px !important" : "6px 0px !important",
      height: "2.66668em",
      fontWeight: "500",
      fontSize: isDesktop ? "" : "24px !important",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: cardIsLarge ? "normal" : "normal",
      lineHeight: "normal !important"
    },
    media: {
      height: "120px",
      width: "100%",
      objectFit: "cover",
    },
    content: {
      paddingTop: isDesktop ? "8px !important" : "16px !important",
      paddingBottom: isDesktop ? "" : "12px !important",
    },
  }));
  const classes = useStyles();

  return (
    <MuiLink
      href={`/${i18nLocale}/${landing ? "candidates" : getFirstPathParam()}/posts/${post.encoded_title}/${post.id}`}
      underline="none"
    >
      <Card
        className={classes.card}
        onMouseEnter={() => setCardIsLarge(true)}
        onMouseLeave={() => setCardIsLarge(false)}
      >
        <CardMedia
          component="img"
          className={classes.media}
          src={post.cover_picture_url}
          srcSet={`${post.cover_picture_url} 1x, ${post.small_cover_picture_url} 0.5x`}
          title={post.title}
        />
        <CardContent className={classes.content}>
          <Typography variant="body3" color="textSecondary">
            {moment(post.created_at).format("DD/MM/YYYY")}
            {!isDesktop && (
              <>
                <span> • </span>
                <MuiLink
                  href={`/companies/squarehub/${companyId}`}
                  sx={{
                    color: "primary",
                    textDecoration: "inherit",
                    ":hover": {
                      color: "#035d2a",
                    },
                  }}
                >
                  Squarehub
                </MuiLink>
              </>
            )}
          </Typography>
          <Typography variant="h5" color="secondary" className={classes.title}>
            {truncate(post.title, isDesktop ? 46 : 80)}
          </Typography>
          <Typography
            variant="body2"
            color="secondary"
            style={{ marginTop: "6px", marginBottom: "16px", fontSize: isDesktop ? "" : "12px" }}
          >
            <HTML
              html={truncate(post.content, isDesktop ? 96 : 130)}
              blacklistedTags={["strong", "p"]}
            />
          </Typography>
          {isDesktop && (
            <Box display="flex">
              <Typography variant="body2" color="textSecondary">
                <FormattedMessage id="landing_candidate.from" />
              </Typography>
              <Typography variant="body2" color="primary">
                <MuiLink
                  href={`/companies/squarehub/${companyId}`}
                  sx={{
                    marginLeft: 0.6,
                    color: "inherit",
                    textDecoration: "inherit",
                    ":hover": {
                      color: "#035d2a"
                    }
                  }}
                >
                  Squarehub
                </MuiLink>
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </MuiLink>
  );
};

export default BlogPostCard;
