import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Pagination from "@mui/lab/Pagination";
import {
  Box,
  Container,
  Button,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import BlogPostCard from "bundles/common/components/BlogPostCard";
import FourBlogJobOffers from "bundles/Candidate/components/FourBlogJobOffers";
import BackdropScreen from "bundles/common/components/BackdropScreen";
import ArticleSvg from "articles.svg";

const BlogMobile = ({
  type = "recruiter",
  handleCategoryChange,
  selectedCategory,
  selectedLanguage,
  setSelectedLanguage,
  loading,
  articles,
  setPage,
  pageCount,
  page,
  squarehubId,
}) => {
  const intl = useIntl();
  const useStyles = makeStyles((theme) => ({
    coloredHeader: {
      paddingTop: "24px",
      paddingBottom: "24px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: theme.palette.secondary.main,
    },
    container: { marginTop: "32px" },
    breadcrumbLink: { color: `${theme.palette.common.white} !important` },
    breadcrumbActive: { color: `${theme.palette.text.white} !important` },
    languageFormControl: {
      width: "100%",
      "&:before": { borderColor: theme.palette.secondary.main },
      "&:after": { borderColor: theme.palette.secondary.main },
      "& .MuiInputLabel-root": { color: theme.palette.secondary.main },
      "& .Mui-focused": { color: `${theme.palette.secondary.main} !important` },
    },
    languageSelect: {
      "& .MuiSelect-select": { color: theme.palette.secondary.main },
      "& .MuiSelect-iconOutlined": { color: theme.palette.secondary.main },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.secondary.main} !important`,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: `${theme.palette.secondary.main} !important`,
      },
    },
    mobileTitle: {
      fontSize: "24px !important",
      fontWeight: "600 !important",
      textAlign: "left",
    },
    mobileLinkContainer: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "10px",
      marginTop: "24px",
      marginBottom: "20px",
    },
    mobileLinkButton: {
      backgroundColor: `${theme.palette.secondary.main} !important`,
      color: "white !important",
      padding: "10px",
      textAlign: "center",
      borderRadius: "4px",
    },
  }));

  const classes = useStyles();

  return (
    <Box style={{ backgroundColor: "white" }}>
      <Box style={{margin: "0px 32px", paddingTop: "16px"}}>
        <Typography variant="h1" color="secondary" className={classes.mobileTitle}>
          <FormattedMessage id="menu.blog_posts" />
        </Typography>
        <Box className={classes.mobileLinkContainer}>
          <Button
            className={classes.mobileLinkButton}
            onClick={() => handleCategoryChange("all")}
            style={{ fontWeight: selectedCategory === "all" ? "bold" : "normal" }}
          >
            <FormattedMessage id="blog.categories.all" />
          </Button>
          <Button
            className={classes.mobileLinkButton}
            onClick={() => handleCategoryChange("career_advise")}
            style={{ fontWeight: selectedCategory === "career_advise" ? "bold" : "normal" }}
          >
            <FormattedMessage id="blog.categories.advices" />
          </Button>
          <Button
            className={classes.mobileLinkButton}
            onClick={() => handleCategoryChange("interviews_and_cv")}
            style={{ fontWeight: selectedCategory === "interviews_and_cv" ? "bold" : "normal" }}
          >
            <FormattedMessage id="blog.categories.interviews_and_cv" />
          </Button>
          <Button
            className={classes.mobileLinkButton}
            onClick={() => handleCategoryChange("candidate_actuality")}
            style={{ fontWeight: selectedCategory === "actualities" ? "bold" : "normal" }}
          >
            <FormattedMessage id="blog.categories.actualities" />
          </Button>
        </Box>
        <Box style={{ marginTop: "32px"}}>
          <FormControl variant="outlined" className={classes.languageFormControl}>
            <InputLabel id="posts-select-label-id">
              <FormattedMessage id="languages.languages" />
            </InputLabel>
            <Select
              value={selectedLanguage}
              labelId="posts-select-label-id"
              id="posts-select-label-id"
              className={classes.languageSelect}
              onChange={(e) => setSelectedLanguage(e?.target?.value)}
              input={<OutlinedInput label={intl.formatMessage({ id: "languages.languages" })} />}
            >
              <MenuItem value="all"><FormattedMessage id="languages.all_languages" /></MenuItem>
              <MenuItem value="nl"><FormattedMessage id="languages.dutch" /></MenuItem>
              <MenuItem value="fr"><FormattedMessage id="languages.french" /></MenuItem>
              <MenuItem value="en"><FormattedMessage id="languages.english" /></MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Container maxWidth="lg" className={classes.container}>
        <Box style={{ paddingBottom: "32px" }}>
          <BackdropScreen open={loading} />
          {articles?.length > 0 && !loading && (
            <Box>
              {page === 1 && (
                <>
                  <Typography variant="h2" style={{ fontSize: "24px", fontWeight: "600", margin: "36px 16px" }}>
                    <FormattedMessage id="blog.most_recent" />
                  </Typography>
                </>
              )}
              <Box>
                <Box style={{ display: "grid", gridTemplateColumns: "repeat(1, 1fr)", gap: "20px", margin: "0 16px 20px 16px"}}>
                  {articles.map((post) => (
                    <BlogPostCard post={post} key={`post-${post.id}`} companyId={squarehubId} />
                  ))}
                </Box>
                {pageCount > 1 && (
                  <Pagination size="large" count={pageCount} page={page} onChange={(e, newPage) => setPage(newPage)} style={{ justifyContent: "center", display: "flex" }} />
                )}
              </Box>
            </Box>
          )}
          {articles?.length <= 0 && !loading && (
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "60px" }}>
              <Box style={{ marginBottom: "32px" }}>
                <Typography variant="h6">
                  <FormattedMessage id="blog.no_articles" />
                </Typography>
              </Box>
              <img src={ArticleSvg} style={{ width: "80%" }} />
            </Box>
          )}
        </Box>
      </Container>
      {!loading && type === "candidate" && <FourBlogJobOffers />}
    </Box>
  );
};

export default BlogMobile;
