import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useState } from "react";
import { useSnackbar } from "notistack";
import {
  Grid,
  Typography,
  Box,
  Switch,
  Button,
  useMediaQuery,
} from "@mui/material";
import sh from "bundles/common/utils/sh";
import { useTheme } from "@mui/styles";
import { replaceCurrentCandidate } from "bundles/Candidate/constants/candidateConstants";

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Notifications = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const currentCandidate = useSelector((state) => state.current_candidate);

  const intl = useIntl();
  const theme = useTheme();
  const query = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState(
    currentCandidate?.notification_preferences
  );

  const handleNotificationChange = (type) => {
    setNotifications({ ...notifications, [type]: !notifications[type] });
  };

  const unsubscribeAll = (emailDeliveryId = null) => {
    let newNotifications = {};
    Object.entries(notifications).forEach((notification) => {
      newNotifications[notification[0]] = false;
    });
    setNotifications(newNotifications);
    handleSave(newNotifications);

    if (emailDeliveryId) {
      sh.put(`/email-deliveries/mark-as-unsubscribed/${emailDeliveryId}`, {
        authenticity_token: authenticityToken,
      });
    }
  };

  const handleSave = (newNotifications = notifications) => {
    setLoading(true);

    sh.put("candidate", {
      authenticity_token: authenticityToken,
      candidate: {
        notification_preferences: newNotifications,
        id: currentCandidate.id,
      },
    })
      .then(() => {
        replaceCurrentCandidate(dispatch, authenticityToken, () => {});
        enqueueSnackbar(
          intl.formatMessage({
            id: "candidate.updated_notifications_preferences",
          }),
          {
            variant: "success",
          }
        );
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("Oops... Something went wrong", { variant: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // [LEGACY] This should be removed in August 2023 as the unsubscription system changed
  useEffect(() => {
    if (query.get("unsubscribe") == "true") {
      const hasActiveNotifications = Object.entries(notifications).find(
        (notification) => notification[1] == true
      );

      let emailDeliveryId = query.get("email_delivery_id");
      if (hasActiveNotifications) {
        unsubscribeAll(emailDeliveryId);
      }
      navigate({
        search: "?selectedSection=notifications",
      });
    }
  });

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{
          marginBottom: "16px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {isDesktop && (
          <Typography variant="h4">
            <FormattedMessage id="settings.update_notifications" />
          </Typography>
        )}
        <Button
          variant="rounded"
          color="secondaryContained"
          style={{ width: isDesktop ? "180px" : "100%" }}
          onClick={() => handleSave(notifications)}
          disabled={loading}
          loading={loading}
        >
          <FormattedMessage id="save" />
        </Button>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "16px" }}>
        <Box>
          <Box style={{ display: "flex" }}>
            <Box
              style={{
                flexGrow: 1,
                borderLeft: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderTop: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderBottom: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                padding: "8px 16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body1bold">
                <FormattedMessage id="notification_preferences.regional_job" />
              </Typography>
            </Box>
            <Box
              style={{
                border: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                padding: "8px 16px",
              }}
            >
              <Switch
                checked={notifications?.regional_job}
                color="secondary"
                onChange={() => handleNotificationChange("regional_job")}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "16px" }}>
        <Box>
          <Box style={{ display: "flex" }}>
            <Box
              style={{
                flexGrow: 1,
                borderLeft: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderTop: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderBottom: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                padding: "8px 16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body1bold">
                <FormattedMessage id="notification_preferences.monthly_suggestion" />
              </Typography>
            </Box>
            <Box
              style={{
                border: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                padding: "8px 16px",
              }}
            >
              <Switch
                checked={notifications?.monthly_suggestion}
                color="secondary"
                onChange={() => handleNotificationChange("monthly_suggestion")}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "16px" }}>
        <Box>
          <Box style={{ display: "flex" }}>
            <Box
              style={{
                flexGrow: 1,
                borderLeft: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderTop: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderBottom: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                padding: "8px 16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body1bold">
                <FormattedMessage id="notification_preferences.tips_and_tricks" />
              </Typography>
            </Box>
            <Box
              style={{
                border: `1px solid ${theme.palette.common.sh_lightgrey}`,
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                padding: "8px 16px",
              }}
            >
              <Switch
                checked={notifications?.tips_and_tricks}
                color="secondary"
                onChange={() => handleNotificationChange("tips_and_tricks")}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ marginTop: "32px", display: "flex", justifyContent: "center" }}
      >
        <Button
          variant="rounded"
          size="small"
          color="dangerOutlined"
          onClick={unsubscribeAll}
        >
          <FormattedMessage id="notification_preferences.unsubscribe_all" />
        </Button>
      </Grid>
    </Grid>
  );
};

export default Notifications;
