import React from "react";
import { useTheme, makeStyles } from "@mui/styles";
import { Box, Button, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

const CandidateSelect = ({ triggerNextStep, onChatbotComplete }) => {
  const theme = useTheme();
  const useStyles = makeStyles(() => ({
    customButton: {
      borderRadius: theme.shape.borderRadius,
      padding: "10px 20px",
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      boxShadow: "0px 1px 1px 0px #00000040",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    buttonText: {
      fontSize: "14px",
      fontWeight: "bold",
      lineHeight: "16px",
      textTransform: "none",
    },
  }));

  const classes = useStyles();

  return (
    <Box style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <Button
        className={classes.customButton}
        variant="rounded"
        color="primary"
        size="medium"
        onClick={() => {
          triggerNextStep({ end: true });
          onChatbotComplete(true);
        }}
      >
        <Typography className={classes.buttonText}>
          <FormattedMessage id="candidate_recommendation_bot.your_selection" />
        </Typography>
      </Button>
    </Box>
  );
};

export default CandidateSelect;
