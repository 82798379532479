import React from "react";
import { useIntl } from "react-intl";
import {
  Container,
  Grid,
  Typography,
  Box,
  Modal,
} from "@mui/material";
import PaperBox from "bundles/common/components/PaperBox";
import JobOfferList from "bundles/Candidate/components/JobOfferList";
import JobOfferSummary from "bundles/Candidate/components/JobOfferSummary";
import AdvancedFiltering from "./AdvancedFiltering";
import ApplicationDialog from "../ApplicationDialog";
import NoData from "icons/noDataCandidat.svg";

const JobOfferIndexDesktop = ({
  loading,
  jobOffers,
  fetchJobOffers,
  selectedJob,
  setSelectedJob,
  page,
  setPage,
  pageCount,
  count,
  setSelectedLanguages,
  setSelectedIndustries,
  setSelectedJobStatuses,
  setSelectedRegions,
  clearAdvancedFilters,
  queryText,
  setQueryText,
  onlyFavorites,
  setOnlyFavorites,
  handleClicks,
  currentCandidate,
  isApplying,
  setIsApplying,
}) => {
  const intl = useIntl();
  const JobCount = () => (
    <PaperBox>
      <Typography variant="h6">
        <b>
          {intl.formatMessage(
            { id: "job_search.n_results" },
            { result: count }
          )}{" "}
        </b>
      </Typography>
    </PaperBox>
  );

  return (
    <>
      <Box>
        <Box
          style={{
            background: "linear-gradient(to left, #079344 0%, #004d2c 100%)",
            minHeight: "240px",
            marginTop: "auto",
            marginBottom: "auto",
            display: "flex",
            alignItems: "center",
            paddingTop: "16px",
            paddingBottom: "16px",
          }}
        >
          <AdvancedFiltering
            onlyFavorites={onlyFavorites}
            setSelectedLanguages={setSelectedLanguages}
            setSelectedIndustries={setSelectedIndustries}
            setSelectedJobStatuses={setSelectedJobStatuses}
            setSelectedRegions={setSelectedRegions}
            clearAdvancedFilters={clearAdvancedFilters}
            queryText={queryText}
            setQueryText={setQueryText}
            setOnlyFavorites={setOnlyFavorites}
            fetchRecords={fetchJobOffers}
          />
        </Box>
      </Box>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={4}
          style={{
            marginTop: "16px",
            marginBottom: "16px",
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            style={{ paddingTop: "32px"}}
          >
            <Grid item xs={12}>
              <Box>
                <JobCount />
              </Box>
              <Box
                style={{
                  height: "100%",
                }}
              >
                <JobOfferList
                  offers={jobOffers}
                  activeOffer={selectedJob}
                  handleOfferClick={handleClicks}
                  setPage={setPage}
                  page={page}
                  pageCount={pageCount}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item
            md={6}
            xs={12}
            style={{ overflowY: "auto", overflowX: "hidden" }}
          >
            {selectedJob ? (
              <JobOfferSummary
                jobOffer={selectedJob}
                updateJobOffer={setSelectedJob}
                skills={selectedJob.skills}
                isApplying={isApplying}
                setIsApplying={setIsApplying}
              />
            ) : (
              !loading &&
              count == 0 && (
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={NoData}
                    style={{
                      width: "300px",
                      marginTop: "32px",
                      marginBottom: "32px",
                    }}
                  />
                </Box>
              )
            )}
          </Grid>
        </Grid>
      </Container>

      <Modal
        hideBackdrop={false}
        open={isApplying}
        onClose={() => setIsApplying(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ display: "flex", alignItems: "center" }}
      >
        <ApplicationDialog
          jobOffer={selectedJob}
          setJobOffer={setSelectedJob}
          jobApplication={selectedJob?.job_applications?.find(
            (a) => a.candidate_id == currentCandidate?.id
          )}
          setIsApplying={setIsApplying}
        />
      </Modal>
    </>
  );
};

export default JobOfferIndexDesktop;
