import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography
} from "@mui/material";


const ProjectCard = ({ project }) => {
  const navigate = useNavigate();

  const coverPicture = project.cover_picture_url;
  const smallCoverPicture = project.small_cover_picture_url;
  const title = project.title;
  const location = project.location;
  const endYear = project.end_date ? new Date(project.end_date).getFullYear() : null;
  const encoded_project_title = project.encoded_project_title;

  const getProjectUrl = (project) => {
    const baseUrl = `project/${encoded_project_title}/${project}`;
    return baseUrl;
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      sx={{
        backgroundColor: "#FFFFFF",
        width: "100%",
        height: "100%",
        borderRadius: '8px',
        border: '1px solid #e0e0e0',
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        padding: '16px',
        marginTop: "24px",
        transition: "background-color 0.2s",
        "&:hover": {
          backgroundColor: "#f5f5f5",
          cursor: "pointer",
        },
      }}
      onClick={() => {
        navigate(getProjectUrl(project.id), {
          state: { project: project },
        });
      }}
    >
      <Grid item xs={6}>
        <img
          src={coverPicture}
          alt="Project Cover Picture"
          srcSet={`${coverPicture} 1x, ${smallCoverPicture} 0.5x`}
          style={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={6} sx={{ paddingLeft: "1rem" }}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="body1" fontWeight="600">{title}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{location}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">{endYear}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectCard;
