import React from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Container } from "@mui/material";
import { useTheme } from "@mui/styles";
import translations from "app/libs/i18n/translations.json";
import MainAppBar from "bundles/Layouts/MainAppBar";
import Signup from "bundles/Devise/Signup/components/Signup";
import Crane from "icons/crane.svg";

const SignupApp = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const locale = i18nLocale;
  const messages = translations[locale];
  const theme = useTheme();

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <BrowserRouter>
        <div
          style={{ display: "flex", height: "100%", flexDirection: "column" }}
        >
          <MainAppBar />
          <Container
            className={theme.background}
            style={{
              backgroundImage: `url(${Crane})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "right center",
              height: "calc(100% - 64px)",
            }}
          >
            <Routes>
              <Route
                path={`/${i18nLocale}/candidates/sign_up`}
                element={<Signup />}
              />
              <Route
                path={`/${i18nLocale}`}
                element={<Navigate to={`/${i18nLocale}/candidates/sign_up`} />}
              />
            </Routes>
          </Container>
        </div>
      </BrowserRouter>
    </IntlProvider>
  );
};

export default SignupApp;
