import { Box, Grid, Typography, Container } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import TextBlock from "./TextBlock";

const TermsOfUse = () => {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box my={2}>
            <Typography variant="h1" align="center">
              <b>
                <FormattedMessage id="footer.terms_of_use.terms_of_use" />
              </b>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.terms_of_use.preambule_title"
            textId="footer.terms_of_use.preambule_html"
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.terms_of_use.definition_title"
            textId="footer.terms_of_use.definition_html"
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.terms_of_use.scope_of_application_title"
            textId="footer.terms_of_use.scope_of_application_html"
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.terms_of_use.personal_data_title"
            textId="footer.terms_of_use.personal_data_html"
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.terms_of_use.squarehub_services_title"
            textId="footer.terms_of_use.squarehub_services_html"
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.terms_of_use.squarehub_account_creation_title"
            textId="footer.terms_of_use.squarehub_account_creation_html"
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.terms_of_use.liability_and_exclusion_title"
            textId="footer.terms_of_use.liability_and_exclusion_html"
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.terms_of_use.intellectual_property_title"
            textId="footer.terms_of_use.intellectual_property_html"
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.terms_of_use.cancellation_title"
            textId="footer.terms_of_use.cancellation_html"
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.terms_of_use.hyperlinks_title"
            textId="footer.terms_of_use.hyperlinks_html"
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.terms_of_use.integrity_of_clauses_title"
            textId="footer.terms_of_use.integrity_of_clauses_html"
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.terms_of_use.jurisdiction_and_applicable_law_title"
            textId="footer.terms_of_use.jurisdiction_and_applicable_law_html"
          />
        </Grid>
        <Grid item xs={12}>
          <Box my={4} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default TermsOfUse;
