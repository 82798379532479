import { fileChecksum } from "./fileChecksum";
import sh from "./sh";

const createPresignedUrl = async (file, byte_size, checksum) => {
  let options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      file: {
        filename: file.name,
        byte_size: byte_size,
        checksum: checksum,
        content_type: "image/png",
        metadata: {
          folder: "profile_pictures",
        },
      },
    }),
  };
  let res = await fetch("/presigned_url", options);
  if (res.status !== 200) return res;
  return await res.json();
};

export const uploadPicture = async (
  picture,
  recordId,
  recordType,
  attributeName,
  authenticity_token
) => {
  // To upload pdf file to S3, we need to do three steps:
  // 1) request a pre-signed PUT request (for S3) from the backend

  const checksum = await fileChecksum(picture);
  const presignedFileParams = await createPresignedUrl(
    picture,
    picture.size,
    checksum
  );

  // 2) send file to said PUT request (to S3)
  const s3PutOptions = {
    method: "PUT",
    headers: presignedFileParams.direct_upload.headers,
    body: picture,
  };
  let awsRes = await fetch(presignedFileParams.direct_upload.url, s3PutOptions);
  if (awsRes.status !== 200) return awsRes;

  // 3) confirm&create user with backend
  const params = {
    authenticity_token,
    [recordType]: {
      record_id: recordId,
      attribute_name: attributeName,
      picture: presignedFileParams.blob_signed_id,
    },
  };
  let res = await sh.put(`${recordType}/upload_picture`, params);
  if (res.status !== 200) return res;
  return res.data;
};
