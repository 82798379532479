import React from "react";
import { RemoveScroll } from "react-remove-scroll";
import { useMediaQuery } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import Side from "./SignupModal/Side";
import SignupRecruiter from "./SignupModal/SignupRecruiter";

const contextualSides = {
  cvAccess: {
    title: "recruiter.signup_modal.cv_access.title",
    description: "recruiter.signup_modal.cv_access.description",
    redirectTo: "recruiter/find_candidate",
  },
  businessPurchase: {
    title: "recruiter.signup_modal.credits_purchase.title",
    description: "recruiter.signup_modal.credits_purchase.description",
    redirectTo: "recruiter/order?selectedPrice=Business",
  },
  premiumPurchase: {
    title: "recruiter.signup_modal.credits_purchase.title",
    description: "recruiter.signup_modal.credits_purchase.description",
    redirectTo: "recruiter/order?selectedPrice=Premium",
  },
  companyPage: {
    title: "recruiter.signup_modal.default.title",
    description: "recruiter.signup_modal.default.description",
    redirectTo: "recruiter/settings?selectedSection=company",
  },
  jobOfferCreation: {
    title: "recruiter.signup_modal.default.title",
    description: "recruiter.signup_modal.default.description",
    redirectTo: "recruiter/job_offer_creation",
  },
};

const SignupModal = ({ context, counts }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const useStyles = makeStyles(() => ({
    modalContainer: {
      display: "flex",
      backgroundColor: "white",
      width: isDesktop ? "800px" : "calc(100% - 32px) !important",
      height: isDesktop ? "min(620px, 100%)" : "fit-content",
    },
  }));
  const classes = useStyles();

  return (
    <RemoveScroll className={classes.modalContainer}>
      {isDesktop && (
        <Side
          context={context}
          counts={counts}
          contextualSides={contextualSides}
        />
      )}
      <SignupRecruiter redirectTo={contextualSides[context]?.redirectTo} />
    </RemoveScroll>
  );
};

export default SignupModal;
