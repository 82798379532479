import React from "react";
import { Grid, useMediaQuery } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import Carousel from "react-material-ui-carousel";
import CarouselItem from "bundles/LandingPageRecruiter/components/CarouselItem";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Reviewer1 from "icons/reviewer1.svg";
import Reviewer2 from "icons/reviewer2.svg";
import Reviewer3 from "icons/reviewer3.svg";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  carouselGrid: {
    maxWidth: "600px",
    margin: "auto",
  },
  avatar: {
    width: theme.spacing(35),
    height: theme.spacing(35),
  },
  reverseGrid: {
    flexDirection: "row-reverse",
  },
}));

const LandingReviews = () => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  const reviews = [
    {
      key: 1,
      text: "landing_candidate.reviews.quote1",
      name: "landing_candidate.reviews.name1",
      position: "landing_candidate.reviews.job_title1",
      picture: Reviewer1,
      picturesmall: Reviewer1,
      alt: intl.formatMessage({ id: "landing_candidate.reviews.alt1" }),
    },
    {
      key: 2,
      text: "landing_candidate.reviews.quote2",
      name: "landing_candidate.reviews.name2",
      position: "landing_candidate.reviews.job_title2",
      picture: Reviewer2,
      picturesmall: Reviewer2,
      alt: intl.formatMessage({ id: "landing_candidate.reviews.alt2" }),
    },
    {
      key: 3,
      text: "landing_candidate.reviews.quote3",
      name: "landing_candidate.reviews.name3",
      position: "landing_candidate.reviews.job_title3",
      picture: Reviewer3,
      picturesmall: Reviewer3,
      alt: intl.formatMessage({ id: "landing_candidate.reviews.alt3" }),
    },
  ];

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid item xs={12} className={classes.carouselGrid}>
      <Carousel
        NextIcon={<ArrowForwardIosIcon />}
        PrevIcon={<ArrowBackIosIcon />}
        navButtonsProps={{
          style: {
            backgroundColor: "transparent",
            opacity: isDesktop ? 1 : 0,
            marginRight: isDesktop ? "200px" : "0px",
            marginLeft: isDesktop ? "200px" : "0px",
          },
        }}
        animation={"slide"}
        duration={1}
        autoPlay={false}
      >
        {reviews.map((review) => (
          <CarouselItem carouselItem={review} key={review.key} />
        ))}
      </Carousel>
    </Grid>
  );
};

export default LandingReviews;
