import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import PublicJobOfferSummaryDesktop from "./Desktop/PublicJobOfferSummaryDesktop";
import PublicJobOfferSummaryMobile from "./Mobile/PublicJobOfferSummaryMobile";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { incrementJobOfferMetric } from "bundles/common/utils/utils.js";
import { trackJobOfferApplication } from "bundles/common/utils/marketing";
import sh from "bundles/common/utils/sh";
import { useSnackbar } from "notistack";

const PublicJobOfferSummary = ({ jobOffer, updateJobOffer}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const i18nLocale = useSelector(state => state.i18nLocale);
  const authenticityToken = useSelector(state => state.authenticity_token);
  const currentCandidate = useSelector(state => state.current_candidate);
  const railsEnv = useSelector(state => state.railsEnv);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const [isEasyApplying, setIsEasyApplying] = useState(false);
  const [candidateApplication, setCandidateApplication] = useState(null);
  const [isLoginModalOpened, setIsLoginModalOpened] = useState(false);
  const [isApplicationModalOpened, setIsApplicationModalOpened] = useState(
    false
  );

  useEffect(() => {
    if (jobOffer?.id && jobOffer?.job_applications?.length > 0) {
      let app = jobOffer?.job_applications.find(
        application => application.candidate_id == currentCandidate?.id
      );
      setCandidateApplication(app);
    } else {
      setCandidateApplication(null);
    }
  }, [jobOffer?.id, jobOffer?.job_applications?.length]);

  const handleApply = () => {
    incrementJobOfferMetric(
      jobOffer,
      "intent_apply_count",
      authenticityToken,
      true
    );
    if (jobOffer?.external_application) {
      let url = jobOffer?.external_application_url;
      if (!url.includes("http")) url = `http://${url}`;
      window.open(url);
    } else if (!currentCandidate?.id) {
      setIsLoginModalOpened(true);
    } else {
      if (candidateApplication || !currentCandidate.resume_url) {
        setIsApplicationModalOpened(true);
      } else {
        setIsEasyApplying(true);
        sh.post("/job_application", {
          authenticity_token: authenticityToken,
          job_offer_id: jobOffer.id,
          resume_url: currentCandidate.resume_url,
        })
          .then(res => {
            setTimeout(() => {
              if (railsEnv === "production") trackJobOfferApplication();
              setIsEasyApplying(false);
              updateJobOffer(res.data);
              enqueueSnackbar(
                intl.formatMessage({ id: "applicant.application_submitted" }),
                {
                  variant: "success",
                }
              );
            }, 1000);
          })
          .catch(err => {
            console.error(err);
            setIsEasyApplying(false);
            let message = currentCandidate.whitelisted
              ? "settings.save_failed"
              : "settings.must_be_whitelisted";
            enqueueSnackbar(intl.formatMessage({ id: message }), {
              variant: "error",
            });
          });
      }
    }
  };

  const publicJobUrl = `${window.location.origin}/${i18nLocale}/jobs/${jobOffer?.encoded_job_title}/${params?.job_offer_id}`;


  return (
    <>
      {isDesktop
        ?
        <PublicJobOfferSummaryDesktop
          jobOffer={jobOffer}
          updateJobOffer={updateJobOffer}
          isEasyApplying={isEasyApplying}
          handleApply={handleApply}
          publicJobUrl={publicJobUrl}
          isLoginModalOpened={isLoginModalOpened}
          setIsLoginModalOpened={setIsLoginModalOpened}
          isApplicationModalOpened={isApplicationModalOpened}
          setIsApplicationModalOpened={setIsApplicationModalOpened}
          enqueueSnackbar={enqueueSnackbar}
        />
        :
        <PublicJobOfferSummaryMobile
          jobOffer={jobOffer}
          updateJobOffer={updateJobOffer}
          isEasyApplying={isEasyApplying}
          handleApply={handleApply}
          publicJobUrl={publicJobUrl}
          isLoginModalOpened={isLoginModalOpened}
          isApplicationModalOpened={isApplicationModalOpened}
          setIsApplicationModalOpened={setIsApplicationModalOpened}
          enqueueSnackbar={enqueueSnackbar}
        />
      }
    </>
  );
};

export default PublicJobOfferSummary;
