import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { Typography, useTheme } from "@mui/material";
import Banner from "bundles/common/components/Banner";
import sh from "bundles/common/utils/sh";

const EmailConfirmationBanner = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const currentRecruiter = useSelector((state) => state.current_recruiter);

  const theme = useTheme();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const handleResubmitConfirmation = () => {
    setIsLoading(true);

    sh.post(`${i18nLocale}/recruiters/confirmation`, {
      authenticity_token: authenticityToken,
      recruiter: {
        email: currentRecruiter.email,
      },
    })
      .then(() => {
        enqueueSnackbar(
          intl.formatMessage({ id: "devise.confirmation.confirmation_snack" }),
          {
            variant: "success",
          }
        );
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar({ id: "failed_snack" }, { variant: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Banner
      isLoading={isLoading}
      actionCallback={handleResubmitConfirmation}
      message={
        <Typography
          align="center"
          color={theme.palette.common.white}
          variant="body2bold"
        >
          {intl.formatMessage({
            id: "devise.signup.recruiter_account_confirmation",
          })}
          .
        </Typography>
      }
      actionMessage={
        <Typography
          variant="body2bold"
          style={{
            color: "white",
            textDecoration: "underline",
          }}
        >
          {`${intl.formatMessage({
            id: "resend",
          })}.`}
        </Typography>
      }
    />
  );
};

export default EmailConfirmationBanner;
