import React from "react";
import { Grid } from "@mui/material";
import RecruiterJobOfferSummary from "bundles/Recruiter/components/JobOfferSummary";
import CandidateJobOfferSummary from "bundles/Candidate/components/JobOfferSummary";
import DialogScreen from "bundles/common/components/DialogScreen";
import { useSelector } from "react-redux";

const JobOfferDialog = ({
  company,
  skills,
  jobApplication,
  closeDialog,
  setIsApplying,
  children,
  jobOffer,
  setJobOffer,
}) => {
  const currentRecruiter = useSelector((state) => state.current_recruiter);

  return (
    <DialogScreen open={true} onClose={() => closeDialog()} maxWidth="md">
      <Grid item xs={12}>
        {currentRecruiter ? (
          <RecruiterJobOfferSummary
            jobOffer={jobOffer}
            company={company}
            skills={skills}
            jobApplication={jobApplication}
            setIsApplying={setIsApplying}
          >
            {children}
          </RecruiterJobOfferSummary>
        ) : (
          <CandidateJobOfferSummary
            jobOffer={jobOffer}
            updateJobOffer={setJobOffer}
            company={company}
            skills={skills}
            jobApplication={jobApplication}
            setIsApplying={setIsApplying}
          >
            {children}
          </CandidateJobOfferSummary>
        )}
      </Grid>
    </DialogScreen>
  );
};

export default JobOfferDialog;
