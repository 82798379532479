import React, { useState } from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";

export default function PasswordField({
  name,
  labelId,
  handleInputValue,
  errors,
  color = "primary",
  size = "small",
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      fullWidth
      required
      type={showPassword ? "text" : "password"}
      style={{ marginTop: "8px" }}
      variant="outlined"
      onBlur={handleInputValue}
      onChange={handleInputValue}
      name={name}
      size={size}
      {...(errors[name] && {
        error: errors[name] && errors[name] != "",
        helperText: errors[name] || "",
      })}
      label={<FormattedMessage id={labelId} />}
      color={color}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    ></TextField>
  );
}
