import React from "react";
import { Grid, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { getTranslatedRegionsArray } from "bundles/common/utils/utils.js";
import { useSelector } from "react-redux";

const PreferencesView = (props) => {
  const intl = useIntl();
  const candidate =
    useSelector((state) => state.current_candidate) || props?.candidate || {};

  const regions = candidate?.region
    ? [...new Set(candidate?.desired_locations.concat(candidate?.region))]
    : candidate?.desired_locations;

  return (
    <Grid container spacing={1} align="left">
      {candidate?.desired_job_title && (
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            <FormattedMessage id="candidate.desired_job_title" />
          </Typography>
          <Typography
            variant="body2"
            style={{ fontFamily: "Work Sans", wordBreak: "normal" }}
          >
            <b>
              {candidate?.desired_job_title || (
                <FormattedMessage id="undefined" />
              )}
            </b>
          </Typography>
        </Grid>
      )}
      {regions.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            <FormattedMessage id="candidate.desired_locations" />
          </Typography>
          <Typography
            variant="body2"
            style={{ fontFamily: "Work Sans", wordBreak: "normal" }}
          >
            <b>{getTranslatedRegionsArray(intl, regions)}</b>
          </Typography>
        </Grid>
      )}
      {candidate?.desired_job_type && (
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            <FormattedMessage id="candidate.desired_job_type" />
          </Typography>
          <Typography
            variant="body2"
            style={{ fontFamily: "Work Sans", wordBreak: "normal" }}
          >
            <b>
              {candidate?.desired_job_type || (
                <FormattedMessage id="undefined" />
              )}
            </b>
          </Typography>
        </Grid>
      )}
      {candidate?.desired_sector && (
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            <FormattedMessage id="company.company_industry.company_industry" />
          </Typography>
          <Typography
            variant="body2"
            style={{ fontFamily: "Work Sans", wordBreak: "normal" }}
          >
            <b>
              {candidate?.desired_sector ? (
                <FormattedMessage
                  id={"company.company_industry." + candidate.desired_sector}
                />
              ) : (
                <FormattedMessage id="undefined" />
              )}
            </b>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
export default PreferencesView;
