import React from "react";
import { useSelector } from "react-redux";
import { Grid, Box, Paper, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";

const PaperBox = ({ children }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const isRecruiter = currentRecruiter != null;

  return (
    <Grid item xs={12} md={true}>
      <Grid container>
        <Grid item xs={12}>
          <Grid
            container
            component={Paper}
            justifyContent={isDesktop ? "flex-start" : "center"}
            style={{
              backgroundColor: isRecruiter
                ? theme.palette.secondary.main
                : theme.palette.primary.main,
              color: theme.palette.common.white,
              borderRadius: 10,
            }}
          >
            <Grid item xs={12}>
              <Box px={3} my={1}>
                {children}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaperBox;
