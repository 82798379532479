import React from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
import translations from "app/libs/i18n/translations.json";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import configureStore from '../store/helloWorldStore';
// import HelloWorldContainer from '../containers/HelloWorldContainer';
import LandingPage from "../components/LandingPage";
// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
import MainAppBar from "bundles/Layouts/MainAppBar";
import Footer from "bundles/Layouts/Footer";
import TermsOfUse from "bundles/common/components/TermsOfUse";
import PrivacyPolicy from "bundles/common/components/PrivacyPolicy";
import Cookies from "bundles/common/components/Cookies";
import GeneralSalesConditions from "bundles/common/components/GeneralSalesConditions";
import SiteMap from "bundles/common/components/SiteMap";
import ContactUs from "bundles/common/components/ContactUs";
// import JoinUs from "bundles/common/components/JoinUs";
import Faq from "bundles/common/components/Faq";
import ScrollToTop from "bundles/common/utils/scrollToTop";
import Blog from "bundles/common/components/Blog";
import BlogView from "bundles/common/components/BlogView";
import Unsubscribe from "bundles/common/components/Unsubscribe";

const LandingPageApp = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const locale = i18nLocale;
  const messages = translations[locale];
  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <BrowserRouter forceRefresh>
        <MainAppBar />
        <Box style={{ minHeight: "calc(100vh - 248px)" }}>
          <ScrollToTop />
          <Routes>
            <Route
              path={`/${i18nLocale}/FAQ`}
              element={<Faq type="candidate" />}
            />
            <Route
              path={`/${i18nLocale}/terms-of-use`}
              element={<TermsOfUse />}
            />
            <Route path={`/${i18nLocale}/contact-us`} element={<ContactUs />} />
            {/* <Route path={`/${i18nLocale}/join-us`} element={<JoinUs />} /> */}
            <Route
              path={`/${i18nLocale}/privacy-policy`}
              element={<PrivacyPolicy />}
            />
            <Route
              path={`/${i18nLocale}/cookies`}
              element={<Cookies />}
            />
            <Route
              path={`/${i18nLocale}/general-sales-conditions`}
              element={<GeneralSalesConditions />}
            />
            <Route
              path={`/${i18nLocale}/candidates/posts/:id`}
              element={<BlogView type="candidate" />}
            />
            <Route
              path={`/${i18nLocale}/candidates/posts/:blogTitle/:id`}
              element={<BlogView type="candidate" />}
            />
            <Route
              path={`/${i18nLocale}/candidates/posts`}
              element={<Blog type="candidate" />}
            />
            <Route
              path={`/${i18nLocale}/candidates/unsubscribes`}
              element={<Unsubscribe />}
            />
            <Route path={`/${i18nLocale}/site-map`} element={<SiteMap />} />
            <Route path={`/${i18nLocale}/`} element={<LandingPage />} />
          </Routes>
        </Box>
      </BrowserRouter>
      <Footer />
    </IntlProvider>
  );
};

export default LandingPageApp;
