import React from "react";
import { FormattedMessage } from "react-intl";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

const Side = ({ context, counts, contextualSides }) => {
  const sideContext = contextualSides[context];

  const theme = useTheme();

  return (
    <Box
      style={{
        width: "300px",
        height: "100%",
        background: "radial-gradient(circle, #3C4A74, #2A334E)",
        paddingTop: "48px",
        paddingBottom: "48px",
        paddingLeft: "32px",
        paddingRight: "32px",
        color: "#FFFFFF",
      }}
    >
      {sideContext && (
        <Box>
          <Box style={{ marginBottom: "32px" }}>
            <Typography variant="h3">
              <b>
                <FormattedMessage id={sideContext["title"]} />
              </b>
            </Typography>
          </Box>
          <Box style={{ marginBottom: "24px" }}>
            <Typography variant="body2" style={{ fontWeight: "500" }}>
              <FormattedMessage id={sideContext["description"]} />
            </Typography>
          </Box>
          <Box>
            <Box style={{ marginBottom: "16px" }}>
              <Typography variant="body2bold">Vos avantages ?</Typography>
            </Box>
            <Box>
              <ul style={{ paddingLeft: "unset" }}>
                <Typography variant="subtitle">
                  <li style={{ display: "flex", columnGap: "4px" }}>
                    <Box
                      variant="body2"
                      style={{
                        marginRight: "8px",
                        color: theme.palette.primary.main,
                      }}
                    >
                      ✓
                    </Box>
                    <b
                      style={{
                        fontWeight: 600,
                        fontFamily: "Work Sans Bold",
                      }}
                    >
                      {counts.candidatesCount}
                    </b>
                    <span style={{ whiteSpace: "nowrap" }}>
                      <FormattedMessage
                        id="recruiter.signup_modal.subscribed_candidates"
                        values={{
                          b: (chunks) => <span>{chunks}</span>,
                        }}
                        style={{ color: theme.palette.secondary.main }}
                      />
                    </span>
                  </li>
                </Typography>
                <Typography variant="subtitle">
                  <li style={{ display: "flex", columnGap: "4px" }}>
                    <Box
                      variant="body2"
                      style={{
                        marginRight: "8px",
                        color: theme.palette.primary.main,
                      }}
                    >
                      ✓
                    </Box>
                    <b
                      style={{
                        fontWeight: 600,
                        fontFamily: "Work Sans Bold",
                      }}
                    >
                      {counts.jobApplicationsCount}
                    </b>
                    <span style={{ whiteSpace: "nowrap" }}>
                      <FormattedMessage
                        id="recruiter.signup_modal.job_applications_count"
                        values={{
                          b: (chunks) => <span>{chunks}</span>,
                        }}
                        style={{ color: theme.palette.secondary.main }}
                      />
                    </span>
                  </li>
                </Typography>
                <Typography variant="subtitle">
                  <li style={{ display: "flex", columnGap: "4px" }}>
                    <Box
                      variant="body2"
                      style={{
                        marginRight: "8px",
                        color: theme.palette.primary.main,
                      }}
                    >
                      ✓
                    </Box>
                    <b
                      style={{
                        fontWeight: 600,
                        fontFamily: "Work Sans Bold",
                      }}
                    >
                      {counts.candidatesSinceMonthCount}
                    </b>
                    <span style={{ whiteSpace: "nowrap" }}>
                      <FormattedMessage
                        id="recruiter.signup_modal.candidates_since_month"
                        values={{
                          b: (chunks) => <span>{chunks}</span>,
                        }}
                        style={{ color: theme.palette.secondary.main }}
                      />
                    </span>
                  </li>
                </Typography>
              </ul>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Side;
