import React, { useRef, useImperativeHandle, forwardRef } from "react";

const StripeInput = forwardRef((props, ref) => {
  const { component: Component, ...other } = props;
  const elementRef = useRef();

  useImperativeHandle(ref, () => ({
    focus: () => elementRef.current.focus,
  }));

  return (
    <Component
      onReady={(element) => (elementRef.current = element)}
      {...other}
    />
  );
});

StripeInput.displayName = "StripeInput";

export default StripeInput;
