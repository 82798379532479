import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  Link,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useEffect } from "react";
import sh from "bundles/common/utils/sh";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

const CVBox = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const [candidatesCount, setCandidatesCount] = useState(0);

  const intl = useIntl();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const fetchCandidatesCount = () => {
    sh.get("all_candidates_count", { authenticity_token: authenticityToken })
      .then((res) => {
        setCandidatesCount(res.data.candidates_count);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchCandidatesCount();
  }, []);

  return (
    <Box>
      {isDesktop && (
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" style={{ color: theme.palette.text.blue }}>
            <b>
              <FormattedMessage id="cv_box.title" />
            </b>
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            style={{ height: "48px", borderRadius: "30px", minWidth: "204px" }}
            href={`/${i18nLocale}/recruiter/find_candidate`}
          >
            <FormattedMessage id="cv_box.search_candidates" />
          </Button>
        </Box>
      )}
      <Box>
        <Grid container>
          <Grid
            item
            xs={12}
            component={Paper}
            elevation={0}
            style={{
              padding: "24px",
              marginTop: isDesktop ? "32px" : "0px",
            }}
          >
            {!isDesktop && (
              <Typography
                variant="h3"
                style={{ color: theme.palette.text.blue, marginBottom: "24px" }}
              >
                <b>
                  <FormattedMessage id="cv_box.title" />
                </b>
              </Typography>
            )}
            <Typography variant={isDesktop ? "body1" : "body3"}>
              {intl.formatMessage(
                { id: "cv_box.catch_phrase" },
                {
                  candidatesCount: candidatesCount,
                  a: (str) => (
                    <Link href={`/${i18nLocale}/recruiter/find_candidate`}>
                      <b>{str}</b>
                    </Link>
                  ),
                }
              )}
              {!isDesktop && (
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    height: "48px",
                    borderRadius: "30px",
                    marginTop: "24px",
                    width: "100%",
                  }}
                  href={`/${i18nLocale}/recruiter/find_candidate`}
                >
                  <FormattedMessage id="cv_box.search_candidates" />
                </Button>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CVBox;
