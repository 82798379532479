import React from "react";
import { Provider } from "react-redux";

import appStore from "bundles/common/store/appStore";
import SignupContainer from "bundles/Devise/Signup/container/SignupContainer";
import SquarehubTheme from "bundles/Layouts/SquarehubTheme";
import { SnackbarProvider } from "notistack";
// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
const SignupApp = (props, railsContext) => {
  /* eslint-disable react/display-name */
  return () => (
    <Provider store={appStore(Object.assign(railsContext, props))}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <SquarehubTheme>
          <SignupContainer {...props} />
        </SquarehubTheme>
      </SnackbarProvider>
    </Provider>
  );
};

export default SignupApp;
