import React from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  Modal,
} from "@mui/material";
import { useIntl } from "react-intl";
import PaperBox from "bundles/common/components/PaperBox";
import JobOfferDialog from "bundles/common/components/JobOfferDialog";
import JobOfferList from "bundles/Candidate/components/JobOfferList";
import MobileChurchBar from "bundles/common/components/Mobile/MobileChurchBar";
import ApplicationDialog from "../ApplicationDialog";
import MobileAdvancedFiltering from "./MobileAdvancedFiltering";
import NoData from "icons/noDataCandidat.svg";

const JobOfferIndexMobile = ({
  loading,
  jobOffers,
  fetchJobOffers,
  selectedJob,
  setSelectedJob,
  page,
  setPage,
  pageCount,
  count,
  setSelectedLanguages,
  selectedLanguages,
  setSelectedIndustries,
  selectedIndustries,
  setSelectedJobStatuses,
  selectedJobStatuses,
  setSelectedRegions,
  selectedRegions,
  clearAdvancedFilters,
  queryText,
  setQueryText,
  onlyFavorites,
  setOnlyFavorites,
  handleClicks,
  currentCandidate,
  isApplying,
  setIsApplying,
  closeDialog,
}) => {
  const intl = useIntl();
  const JobCount = () => (
    <PaperBox>
      <Typography variant="h6">
        <b>
          {intl.formatMessage(
            { id: "job_search.n_results" },
            { result: count }
          )}{" "}
        </b>
      </Typography>
    </PaperBox>
  );

  return (
    <>
      <Box>
        <Container maxWidth="lg" style={{ marginTop: "16px" }}>
          <MobileChurchBar
            queryText={queryText}
            setQueryText={setQueryText}
            fetchRecords={fetchJobOffers}
            placeholder="landing_candidate.searched_jobs"
          />
          <MobileAdvancedFiltering
            selectedRegions={selectedRegions}
            selectedLanguages={selectedLanguages}
            selectedIndustries={selectedIndustries}
            selectedJobStatuses={selectedJobStatuses}
            setSelectedRegions={setSelectedRegions}
            setSelectedLanguages={setSelectedLanguages}
            setSelectedIndustries={setSelectedIndustries}
            setSelectedJobStatuses={setSelectedJobStatuses}
            setOnlyFavorites={setOnlyFavorites}
            onlyFavorites={onlyFavorites}
            clearAdvancedFilters={clearAdvancedFilters}
          />
        </Container>
      </Box>

      <Container maxWidth="lg">
        <Grid
          container
          spacing={4}
          style={{
            marginBottom: "16px",
          }}
        >
          <Grid item xs={12} style={{ marginTop: "8px" }}>
            <JobCount />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Grid item xs={12}>
              <Box
                style={{
                  height: "100%",
                }}
              >
                <JobOfferList
                  offers={jobOffers}
                  activeOffer={selectedJob}
                  handleOfferClick={handleClicks}
                  setPage={setPage}
                  page={page}
                  pageCount={pageCount}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid item md={6} xs={12} >
            {selectedJob ? (
              !isApplying && (
                <Grid item xs={12}>
                  <JobOfferDialog
                    skills={selectedJob.skills}
                    closeDialog={closeDialog}
                    setIsApplying={setIsApplying}
                    setJobOffer={setSelectedJob}
                    jobOffer={selectedJob}
                    jobApplication={selectedJob?.job_applications?.find(
                      (a) => a.candidate_id == currentCandidate?.id
                    )}
                  />
                </Grid>
              )
            ) : (
              !loading &&
              count == 0 && (
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={NoData}
                    style={{
                      width: "180px",
                      marginTop: "32px",
                      marginBottom: "32px",
                    }}
                  />
                </Box>
              )
            )}
          </Grid>
        </Grid>
      </Container>

      <Modal
        hideBackdrop={false}
        open={isApplying}
        onClose={() => setIsApplying(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ display: "flex", alignItems: "center" }}
      >
        <ApplicationDialog
          jobOffer={selectedJob}
          setJobOffer={setSelectedJob}
          jobApplication={selectedJob?.job_applications?.find(
            (a) => a.candidate_id == currentCandidate?.id
          )}
          setIsApplying={setIsApplying}
        />
      </Modal>
    </>
  );
};

export default JobOfferIndexMobile;
