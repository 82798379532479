import React, { Fragment, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { useTheme, makeStyles } from "@mui/styles";
import {
  Container,
  Grid,
  Typography,
  Box,
  Paper,
  useMediaQuery,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Link,
} from "@mui/material";
import styled from "styled-components";
import sh from "bundles/common/utils/sh";
import { pricingInformations } from "bundles/common/utils/constants";
import NewContactForm from "bundles/common/components/NewContactForm";
import Jules from "pictures/picture-jules.jpg";
import JulesSmall from "pictures/PictureJulesSmall.jpg";
import Jade from "pictures/picture-jade.jpg";
import JadeSmall from "pictures/PictureJadeSmall.jpg";
import PhoneIcon from "@mui/icons-material/Phone";
import DateRangeIcon from "@mui/icons-material/DateRange";

const Pricing = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [candidatesCount, setCandidatesCount] = useState(0);
  const [monthlyCandidatesCount, setMonthlyCandidatesCount] = useState(0);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  // const currentCompany = currentRecruiter.company;

  // const currentPricing = currentCompany?.available_pricing?.map((price) => {
  //   return {
  //     ...price,
  //     ...pricingInformations.find(
  //       (priceInfo) => priceInfo.nickname == price.nickname
  //     ),
  //   };
  // });

  const useStyles = makeStyles((theme) => ({
    dots: {
      bottom: "32px",
      position: "relative",
      "& li.slick-active button::before": {
        color: theme.palette.secondary.main,
      },
      "& li": {
        "& button::before": {
          fontSize: theme.typography.pxToRem(14),
          color: theme.palette.secondary.lighter,
          opacity: 1,
        },
      },
    },
    contactForm: {
      backgroundImage:
        "radial-gradient(ellipse at 50% 50%, #3F4C71 0.5%, #3F4C71 15%, #2A334E 50%, #2A334E 100%)",
      dropShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      paddingTop: isDesktop ? "64px" : "32px",
      paddingBottom: isDesktop ? "64px" : "32px",
    },
    ellipseStyle: {
      borderRadius: "50%",
      width: "140px",
      height: "140px",
      objectFit: "cover",
      border: "2px solid white",
    },
    cvContainer: {
      width: "100vw",
      minHeight: "70vh",
      backgroundColor: theme.palette.secondary.main,
      paddingTop: "64px",
      paddingBottom: "64px",
      justifyContent: "center",
    },
    card: {
      padding: "5px",
      height: "100%",
      width: "360px",
    },
  }));

  const classes = useStyles();
  // const navigate = useNavigate();

  // const handlePriceClick = (code) => {
  //   navigate({
  //     pathname: `/${i18nLocale}/recruiter/order`,
  //     search: `?selectedPrice=${code}`,
  //   });
  // };

  const fetchCandidatesCount = () => {
    sh.get("all_candidates_count", { authenticity_token: authenticityToken })
      .then((res) => {
        setCandidatesCount(res.data.candidates_count);
        setMonthlyCandidatesCount(res.data.monthly_candidates_count);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchCandidatesCount();
  }, []);

  const settings = {
    arrows: false,
    dots: true,
    className: "slick-list",
    appendDots: (dots) => (
      <>
        <div className={`slick-dots ${classes.dots}`}>
          <ul style={{ padding: 0 }}>{dots}</ul>
        </div>
      </>
    ),
    infinite: false,
    adaptiveHeight: true,
    initialSlide: 1,
  };

  const StyledSlider = styled(Slider)`
    & .slick-list {
      height: inherit;

      & .slick-track {
        display: flex !important;
        height: 100%;

        & .slick-slide {
          height: 580px;
          & div {
            height: 540px;
          }
        }
      }
    }
  `;

  const prices = [
    {
      name: "Basic",
      title: "landing_recruiter.prices.basic2024.title",
      number_annonce: "landing_recruiter.prices.basic2024.number_annonce",
      annonce_price: "landing_recruiter.prices.basic2024.annonce_price",
      bulletpoints: "landing_recruiter.prices.basic2024.bulletpoints",
      annonce: "landing_recruiter.prices.basic2024.annonce",
      nickname: "basic_2024",
    },
    {
      name: "Business",
      title: "landing_recruiter.prices.business2024.title",
      number_annonce: "landing_recruiter.prices.business2024.number_annonce",
      bulletpoints: "landing_recruiter.prices.business2024.bulletpoints",
      annonce: "landing_recruiter.prices.business2024.annonce",
      annonce_price: "landing_recruiter.prices.business2024.annonce_price",
      offerBulletpoints:
        "landing_recruiter.prices.business2024.offer_bulletpoints",
      nickname: "business_2024",
      popular: true,
    },
    {
      name: "Premium",
      title: "landing_recruiter.prices.premium2024.title",
      number_annonce: "landing_recruiter.prices.premium2024.number_annonce",
      bulletpoints: "landing_recruiter.prices.premium2024.bulletpoints",
      annonce: "landing_recruiter.prices.premium2024.annonce",
      // CUSTOM PRICE FOR ADVA CONSULT AND ARBALETT //
      annonce_price: (currentRecruiter.company.id === 798 || currentRecruiter.company.id === 1085) ?
        "landing_recruiter.prices.premium2024.annonce_price_custom" :
        "landing_recruiter.prices.premium2024.annonce_price",
      offerBulletpoints:
        "landing_recruiter.prices.premium2024.offer_bulletpoints",
      nickname: "premium_2024",
    },
  ];

  // const numberPricingPlan = {
  //   basic_october_2022: "basic",
  //   business_october_2022: "business",
  //   premium_october_2022: "premium",
  //   essential_january_2023: "essential",
  //   premium_2023: "premium2023",
  //   february_business_2023: "business2023",
  //   february_premium_2023: "premium2023",
  //   basic_2024: "basic2024",
  //   business_2024: "business2024",
  //   premium_2024: "premium2024",
  // };

  return (
    <>
      <Container maxWidth="lg">
        <Grid style={{ width: "100%" }}>
          <Typography
            align="center"
            variant="h2"
            style={{
              marginTop: "32px",
              paddingBottom: "4px",
              color: theme.palette.secondary.main,
            }}
          >
            <b>
              <FormattedMessage id="order.publish_job_offers" />
            </b>
          </Typography>
        </Grid>
        <Grid
          container
          spacing={isDesktop ? 2 : 4}
          display={isDesktop ? "flex" : "block"}
          style={{
            marginTop: "62px",
            marginBottom: isDesktop ? "64px" : "0px",
            width: "100%",
            marginLeft: "0px",
          }}
        >
          {isDesktop ? (
            prices.map((price, index) => (
              <Grid
                item
                md={4}
                xs={12}
                key={price.title + index}
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  marginTop: index == 1 ? "-48px" : "0px",
                }}
              >
                {price.popular && (
                  <Box position="relative" mt={0}>
                    <Box
                      top="-35px"
                      left="94px"
                      color="white"
                      width="375px"
                      height="48px"
                      bgcolor={theme.palette.primary.main}
                      pt={1}
                      style={{
                        borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px",
                        marginLeft: "0px",
                        marginRight: "0px",
                      }}
                    >
                      <Typography
                        align="center"
                        variant="h6"
                        style={{ marginTop: "4px", fontWeight: "bold" }}
                      >
                        <b>
                          <FormattedMessage id="landing_recruiter.prices.popular" />
                        </b>
                      </Typography>
                    </Box>
                  </Box>
                )}
                <Paper
                  component={Box}
                  p={3}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "left",
                    height: "480px",
                    width: "375px",
                    padding: "0px",
                    marginLeft: index == 0 ? "auto" : "0px",
                    marginRight: index == 0 ? "0px" : "auto",
                    borderTopLeftRadius: price.popular ? "0px" : "4px",
                    borderTopRightRadius: price.popular ? "0px" : "4px",
                  }}
                >
                  <Box py={1}>
                    <Typography
                      variant="h4"
                      align="center"
                      style={{ paddingTop: "24px", fontFamily: "Work Sans Bold" }}
                    >
                      <b>
                        <FormattedMessage id={price.title} />
                      </b>
                    </Typography>
                  </Box>
                  <Box py={1} style={{ display: "flex", justifyContent:"center", alignItems:"center" }}>
                    <Typography variant="h2" align="center" color={theme.palette.primary.main}>
                      <b>
                        <FormattedMessage id={price.annonce_price} />
                      </b>
                    </Typography>
                    <Typography variant="body2" color={theme.palette.primary.main} style={{ marginLeft: "5px", marginTop: "8px" }}>
                      <FormattedMessage  id="landing_recruiter.per_month"/>
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "left",
                      flexDirection: "column",
                      height: "100%",
                      padding: "5px",
                    }}
                  >
                    <Box py={1}>
                      <FormattedMessage
                        id={price.bulletpoints}
                        values={{
                          li: (chunks) => (
                            <li
                              key={chunks}
                              style={{
                                listStyle: "none",
                                display: "flex",
                                marginBottom: "8px",
                                textAlign: "left",
                              }}
                            >
                              <Typography
                                variant="body2"
                                style={{
                                  marginRight: "8px",
                                  marginLeft: "6px",
                                  color: theme.palette.primary.main,
                                }}
                              >
                                ✓
                              </Typography>
                              <Typography
                                variant="body2bold"
                                style={{ textAlign: "left" }}
                              >
                                {chunks}
                              </Typography>
                            </li>
                          ),
                        }}
                      />
                      <Box style={{ marginTop: "32px" }}>
                        {price.offerBulletpoints && (
                          <FormattedMessage
                            id={price.offerBulletpoints}
                            values={{
                              li: (chunks) => (
                                <li
                                  key={chunks}
                                  style={{
                                    listStyle: "none",
                                    display: "flex",
                                    alignItems: "baseline",
                                    marginBottom: "8px",
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    style={{
                                      marginRight: "8px",
                                      marginLeft: "6px",
                                      color: theme.palette.primary.main,
                                    }}
                                  >
                                    ✓
                                  </Typography>
                                  <Typography variant="body2bold">
                                    {chunks}
                                    <b
                                      style={{
                                        marginLeft: "8px",
                                        color: theme.palette.primary.main,
                                      }}
                                    >
                                      <FormattedMessage id="landing_recruiter.prices.offer" />
                                    </b>
                                  </Typography>
                                </li>
                              ),
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            ))
          ) : (
            <StyledSlider {...settings}>
              {prices.map((price, index) => {
                return (
                  <Grid
                    key={`carousel-mobile-${index}`}
                    item
                    xs={12}
                    style={{ height: "inherit" }}
                  >
                    <Paper
                      component={Box}
                      p={3}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "400px",
                        padding: "20px",
                        overflow: "hidden",
                      }}
                    >
                      {price.popular && (
                        <Box position="relative" style={{ height: "5px" }}>
                          <Box
                            position="absolute"
                            top="-40px"
                            color="white"
                            bgcolor={theme.palette.primary.main}
                            pt={1}
                            style={{
                              transform: "rotate(45deg)",
                              top: "-1px",
                              right: "-76px",
                              width: "54%",
                              height: "auto",
                            }}
                          >
                            <Typography
                              align="center"
                              variant="h5"
                              style={{
                                paddingBottom: "1px",
                                borderTopLeftRadius: "4px",
                                borderTopRightRadius: "4px",
                              }}
                            >
                              <b>
                                <FormattedMessage id="landing_recruiter.prices.popular" />
                              </b>
                            </Typography>
                          </Box>
                        </Box>
                      )}
                      <Box py={1} height="auto" component="span">
                        <Typography variant="h2" align="center" style={{ fontWeight: "bolder" }}>
                          <b>
                            <FormattedMessage id={price.title} />
                          </b>
                        </Typography>
                      </Box>
                      <Box py={1} style={{ display: "flex", justifyContent:"center", alignItems:"center" }}>
                        <Typography variant="h2" align="center" color={theme.palette.primary.main}>
                          <b>
                            <FormattedMessage id={price.annonce_price} />
                          </b>
                        </Typography>
                        <Typography variant="body2" color={theme.palette.primary.main} style={{ marginLeft: "5px", marginTop: "5px" }}>
                          <FormattedMessage  id="landing_recruiter.per_month"/>
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexDirection: "column",
                          height: "100%",
                          padding: "16px",
                        }}
                      >
                        <Box py={1}>
                          <FormattedMessage
                            id={price.bulletpoints}
                            values={{
                              li: (chunks) => (
                                <li
                                  key={chunks}
                                  style={{
                                    listStyle: "none",
                                    display: "flex",
                                    alignItems: "baseline",
                                    marginBottom: "4px",
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    style={{
                                      marginRight: "8px",
                                      color: theme.palette.primary.main,
                                    }}
                                  >
                                    ✓
                                  </Typography>
                                  <Typography variant="body2">{chunks}</Typography>
                                </li>
                              ),
                            }}
                          />
                          <Box style={{ marginTop: "35px" }}>
                            {price.offerBulletpoints && (
                              <FormattedMessage
                                id={price.offerBulletpoints}
                                values={{
                                  li: (chunks) => (
                                    <li
                                      key={chunks}
                                      style={{
                                        listStyle: "none",
                                        display: "flex",
                                        alignItems: "baseline",
                                        marginBottom: "4px",
                                      }}
                                    >
                                      <Typography
                                        variant="body2"
                                        style={{
                                          marginRight: "8px",
                                          color: theme.palette.primary.main,
                                        }}
                                      >
                                        ✓
                                      </Typography>
                                      <Typography variant="body2">
                                        {chunks}
                                        <b
                                          style={{
                                            marginLeft: "8px",
                                            color: theme.palette.primary.main,
                                          }}
                                        >
                                          <FormattedMessage id="landing_recruiter.prices.offer" />
                                        </b>
                                      </Typography>
                                    </li>
                                  ),
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                );
              })}
            </StyledSlider>
          )}
          {/* {isDesktop ? (
            currentPricing.map((price, index) => (
              <Fragment key={price.title + index}>
                <Grid item md={12 / currentPricing.length} xs={12}>
                  {price.popular && (
                    <Box position="relative" mt={isDesktop ? 0 : 4}>
                      <Box
                        position="absolute"
                        top="-35px"
                        color={theme.palette.common.white}
                        width="100%"
                        bgcolor={theme.palette.common.blue}
                        pt={1}
                        style={{
                          borderTopLeftRadius: "4px",
                          borderTopRightRadius: "4px",
                        }}
                      >
                        <Typography
                          align="center"
                          variant="h6"
                          style={{ paddingBottom: "1px" }}
                          queryParams={"order"}
                        >
                          <b>
                            <FormattedMessage id="landing_recruiter.prices.popular" />
                          </b>
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Paper
                    component={Box}
                    p={3}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      borderTopLeftRadius: price.popular ? "0px" : "4px",
                      borderTopRightRadius: price.popular ? "0px" : "4px",
                    }}
                  >
                    <Box py={1}>
                      <Typography variant="h5" align="center">
                        <b>
                          <FormattedMessage id={price.title} />
                        </b>
                        <br />
                        <Typography color="green" variant="h3" marginBottom={1}>
                          {price.amount} €
                        </Typography>
                        <FormattedMessage
                          id={`landing_recruiter.prices.${
                            numberPricingPlan[price.nickname.toLowerCase()]
                          }.annonce_subtitle`}
                        />
                      </Typography>
                    </Box>
                    <Box py={1}>
                      <Divider light />
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        flexDirection:
                          currentPricing.length > 1 ? "column" : "row",
                      }}
                    >
                      <Box py={1}>
                        <FormattedMessage
                          id={price.bulletpoints}
                          values={{
                            li: (chunks) => (
                              <li
                                key={chunks}
                                style={{
                                  listStyle: "none",
                                  display: "flex",
                                  alignItems: "baseline",
                                  marginBottom: "4px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  style={{
                                    marginRight: "8px",
                                    color: theme.palette.primary.main,
                                  }}
                                >
                                  ✓
                                </Typography>
                                <Typography>{chunks}</Typography>
                              </li>
                            ),
                          }}
                        />
                      </Box>
                      {price.offerBulletpoints && (
                        <Box py={1}>
                          <FormattedMessage
                            id={price.offerBulletpoints}
                            values={{
                              li: (chunks) => (
                                <li
                                  key={chunks}
                                  style={{
                                    listStyle: "none",
                                    display: "flex",
                                    alignItems: "baseline",
                                    marginBottom: "4px",
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    style={{
                                      marginRight: "8px",
                                      color: theme.palette.primary.main,
                                    }}
                                  >
                                    ✓
                                  </Typography>
                                  <Typography>
                                    {chunks}
                                    <b
                                      style={{
                                        marginLeft: "8px",
                                        color: theme.palette.primary.main,
                                      }}
                                    >
                                      <FormattedMessage id="landing_recruiter.prices.offer" />
                                    </b>
                                  </Typography>
                                </li>
                              ),
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                    <Box
                      style={{
                        flexGrow: 1,
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        style={{ width: "200px", marginTop: "16px" }}
                        onClick={() => handlePriceClick(price.name)}
                        variant="rounded"
                        color={
                          price.name == "business"
                            ? "secondaryContained"
                            : "secondaryOutlined"
                        }
                      >
                        <Typography
                          align="center"
                          variant="h6"
                          style={{
                            paddingBottom: "1px",
                            borderTopLeftRadius: "4px",
                            borderTopRightRadius: "4px",
                          }}
                        >
                          <b>
                            <FormattedMessage id="order.order" />
                          </b>
                        </Typography>
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              </Fragment>
            ))
          ) : (
            <StyledSlider {...settings}>
              {currentPricing.map((price, index) => {
                return (
                  <Grid
                    key={`carousel-mobile-${index}`}
                    item
                    xs={12}
                    style={{ height: "inherit" }}
                  >
                    <Paper
                      component={Box}
                      p={3}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "0px 8px",
                        padding: "16px",
                        overflow: "hidden",
                      }}
                    >
                      {price.popular && (
                        <Box position="relative" style={{ height: "32px" }}>
                          <Box
                            position="absolute"
                            top="-40px"
                            color={theme.palette.common.white}
                            bgcolor={theme.palette.primary.main}
                            pt={1}
                            style={{
                              transform: "rotate(45deg)",
                              top: "-1px",
                              right: "-68px",
                              width: "54%",
                              height: "auto",
                            }}
                          >
                            <Typography
                              align="center"
                              variant="h6"
                              style={{
                                paddingBottom: "1px",
                                borderTopLeftRadius: "4px",
                                borderTopRightRadius: "4px",
                              }}
                            >
                              <b>
                                <FormattedMessage id="landing_recruiter.prices.popular" />
                              </b>
                            </Typography>
                          </Box>
                        </Box>
                      )}
                      <Box py={1} height="auto" component="span">
                        <Typography variant="h4" align="center">
                          <b>
                            {" "}
                            <FormattedMessage id={price.title} />
                          </b>
                        </Typography>
                      </Box>
                      <Typography
                        variant="h3"
                        align="center"
                        marginBottom={1}
                        marginTop={2}
                        color="green"
                      >
                        <FormattedMessage
                          id={`landing_recruiter.prices.${
                            numberPricingPlan[price.nickname.toLowerCase()]
                          }.annonce_price`}
                        />
                      </Typography>
                      <Typography
                        variant="h6"
                        align="center"
                        marginBottom={1}
                        marginTop={2}
                      >
                        <FormattedMessage
                          id={`landing_recruiter.prices.${
                            numberPricingPlan[price.nickname.toLowerCase()]
                          }.annonce_subtitle`}
                        />
                      </Typography>
                      <Divider light />

                      <Box py={1} height="auto" component="span">
                        <FormattedMessage
                          id={price.bulletpoints}
                          values={{
                            li: (chunks) => (
                              <li
                                key={chunks}
                                style={{
                                  listStyle: "none",
                                  display: "flex",
                                  alignItems: "baseline",
                                  marginBottom: "4px",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  style={{
                                    marginRight: "8px",
                                    color: theme.palette.primary.main,
                                  }}
                                >
                                  ✓
                                </Typography>
                                <Typography variant="body2">
                                  {chunks}
                                </Typography>
                              </li>
                            ),
                          }}
                        />
                      </Box>
                      {price.offerBulletpoints && (
                        <Box py={1} height="auto" component="span">
                          <FormattedMessage
                            id={price.offerBulletpoints}
                            values={{
                              li: (chunks) => (
                                <li
                                  key={chunks}
                                  style={{
                                    listStyle: "none",
                                    display: "flex",
                                    alignItems: "baseline",
                                    marginBottom: "4px",
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    style={{
                                      marginRight: "8px",
                                      color: theme.palette.primary.main,
                                    }}
                                  >
                                    ✓
                                  </Typography>
                                  <Typography>
                                    {chunks}
                                    <b
                                      style={{
                                        marginLeft: "8px",
                                        color: theme.palette.primary.main,
                                      }}
                                    >
                                      <FormattedMessage id="landing_recruiter.prices.offer" />
                                    </b>
                                  </Typography>
                                </li>
                              ),
                            }}
                          />
                        </Box>
                      )}
                      <Box
                        style={{
                          flexGrow: 1,
                          display: "flex",
                          alignItems: "end",
                        }}
                      >
                        <Button
                          style={{ width: "100%" }}
                          onClick={() => handlePriceClick(price.name)}
                          variant="rounded"
                          color="secondaryContained"
                        >
                          <Typography
                            align="center"
                            variant="h6"
                            style={{
                              paddingBottom: "1px",
                              borderTopLeftRadius: "4px",
                              borderTopRightRadius: "4px",
                            }}
                          >
                            <b>
                              <FormattedMessage id="order.order" />
                            </b>
                          </Typography>
                        </Button>
                      </Box>
                    </Paper>
                  </Grid>
                );
              })}
            </StyledSlider>
          )} */}
        </Grid>
      </Container>

      {/* {isDesktop ? (
        <Grid container className={classes.cvContainer}>
          <Grid xs={12} item style={{ marginBottom: "32px" }}>
            <Typography
              align="center"
              variant="h2"
              fontWeight="bold"
              style={{ color: theme.palette.common.white }}
            >
              <b>
                <FormattedMessage id="landing_recruiter.title_cv_access"></FormattedMessage>{" "}
              </b>
            </Typography>
          </Grid>
          <Grid
            justifyContent="center"
            flexDirection="column"
            item
            xs={6}
            style={{ background: theme.palette.secondary.main }}
          >
            <Card
              className={classes.card}
              style={{ marginLeft: "auto", marginRight: "72px" }}
            >
              <CardContent>
                <Typography variant="h5" align="center" fontWeight="bold">
                  <b>
                    <FormattedMessage id="landing_recruiter.cv_access.title" />
                  </b>
                </Typography>
                <Typography align="center" marginBottom={1} marginTop={2}>
                  <FormattedMessage id="landing_recruiter.cv_access.available" />
                </Typography>
                <Divider light />
                <Box py={1}>
                  <FormattedMessage
                    id="landing_recruiter.cv_access.bulletpoints"
                    values={{
                      li: (chunks) => (
                        <li
                          key={chunks}
                          style={{
                            listStyle: "none",
                            display: "flex",
                            alignItems: "baseline",
                            marginBottom: "4px",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              marginRight: "8px",
                              color: theme.palette.primary.main,
                            }}
                          >
                            ✓
                          </Typography>
                          <Typography>{chunks}</Typography>
                        </li>
                      ),
                    }}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
            <Box
              style={{
                width: "88%",
                display: "flex",
                flexDirection: "column",
                rowGap: "16px",
              }}
            >
              <Box>
                <Typography variant="h4" color={theme.palette.common.white}>
                  <FormattedMessage id="landing_recruiter.header_cv_access" />
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <Box>
                  <Typography
                    variant="h2"
                    color={theme.palette.common.white}
                    align="center"
                    fontWeight="bold"
                  >
                    <Box>{candidatesCount}</Box>
                  </Typography>
                  <Typography
                    variant="h4"
                    color={theme.palette.common.white}
                    align="justify"
                    fontWeight="bold"
                  >
                    <FormattedMessage
                      id="landing_recruiter.cv_access.catch_phrase_candidat"
                      values={{
                        candidatesCount: candidatesCount,
                      }}
                    />
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h2"
                    color={theme.palette.common.white}
                    fontWeight="bold"
                    align="center"
                  >
                    <Box>{monthlyCandidatesCount}</Box>
                  </Typography>
                  <Typography
                    variant="h4"
                    color={theme.palette.common.white}
                    fontWeight="bold"
                    align="center"
                  >
                    <FormattedMessage id="landing_recruiter.cv_access.catch_phrase_cv" />
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="h5"
                  color={theme.palette.common.white}
                  marginTop="2rem"
                >
                  <FormattedMessage id="landing_recruiter.sub_header_cv_access" />
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h5"
                  color={theme.palette.common.white}
                  marginTop="1rem"
                >
                  <FormattedMessage id="landing_recruiter.sub_header_next_cv_access" />
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container className={classes.cvContainer}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              align="center"
              variant="h3"
              style={{ color: theme.palette.common.white }}
            >
              <b>
                <FormattedMessage id="landing_recruiter.title_cv_access"></FormattedMessage>
              </b>
            </Typography>
          </Grid>
          <Grid flexDirection="column" item xs={12} justifyContent="center">
            <Grid item justifyContent="center" marginLeft={2} marginRight={2}>
              <Typography variant="h5" color={theme.palette.common.white}>
                <b>
                  <FormattedMessage id="landing_recruiter.header_cv_access" />
                </b>
              </Typography>
            </Grid>
            <Grid container style={{ margin: "16px 0px" }}>
              <Grid item xs={6} textAlign="center">
                <Typography variant="h4" color={theme.palette.common.white}>
                  {candidatesCount}
                </Typography>

                <Typography variant="h7" color={theme.palette.common.white}>
                  <FormattedMessage
                    id="landing_recruiter.cv_access.catch_phrase_candidat"
                    values={{
                      candidatesCount: candidatesCount,
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign="center">
                <Typography variant="h4" color={theme.palette.common.white}>
                  {monthlyCandidatesCount}
                </Typography>
                <Typography variant="h7" color={theme.palette.common.white}>
                  <FormattedMessage
                    id="landing_recruiter.cv_access.catch_phrase_cv"
                    values={{
                      cvCount: monthlyCandidatesCount,
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid
              color={theme.palette.common.white}
              style={{ margin: "0px 16px 8px 16px" }}
            >
              <Typography>
                <FormattedMessage id="landing_recruiter.sub_header_cv_access" />
              </Typography>
              <Typography marginTop={1}>
                <FormattedMessage id="landing_recruiter.sub_header_next_cv_access" />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )} */}

      <>
        <Box>
          <Box className={classes.contactForm}>
            <Container maxWidth="lg">
              <Grid container alignItems="center" spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={7}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "40px",
                    paddingBottom: "35px",
                  }}
                >
                  <Box
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h2"
                      style={{
                        color: "white",
                        fontWeight: "600",
                        fontSize: isDesktop ? "42px" : "32px",
                      }}
                    >
                      <FormattedMessage
                        id="landing_recruiter.contact"
                        values={{
                          b: (chunks) => <b>{chunks}</b>,
                        }}
                      />
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      maxWidth: "600px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        color: "white",
                        fontSize: "18px",
                        paddingRight: isDesktop ? "120px" : "0px",
                      }}
                    >
                      <FormattedMessage
                        id="landing_recruiter.contact_sub"
                        values={{
                          b: (chunks) => <b>{chunks}</b>,
                        }}
                      />
                    </Typography>
                  </Box>
                  {isDesktop && (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        columnGap: "50px",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "left",
                        }}
                      >
                        <img
                          src={Jules}
                          srcSet={`${Jules} 1x, ${JulesSmall} 0.5x`}
                          alt="Jules 2"
                          className={classes.ellipseStyle}
                        />
                        <Typography
                          variant="subtitle1"
                          style={{
                            color: "white",
                            paddingTop: "10px",
                          }}
                        >
                          <b>
                            <FormattedMessage id="landing_recruiter.picture_jules" />
                          </b>
                        </Typography>
                        <Box
                          style={{
                            display: "flex",
                            columnGap: "8px",
                            color: "white",
                            paddingTop: "10px",
                          }}
                        >
                          <PhoneIcon />
                          <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="hover"
                            color="white"
                            href="tel:0032493483284"
                          >
                            <Typography>+32 (0) 493 48 32 84</Typography>
                          </Link>
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            columnGap: "8px",
                            color: "white",
                            paddingTop: "10px",
                          }}
                        >
                          <DateRangeIcon />
                          <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="hover"
                            color="white"
                            href="https://calendly.com/demo-squarehub/quick-demo-30-min"
                          >
                            <Typography>
                              <FormattedMessage id="landing_recruiter.contact_form.book_demo" />
                            </Typography>
                          </Link>
                        </Box>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "left",
                        }}
                      >
                        <img
                          src={Jade}
                          srcSet={`${Jade} 1x, ${JadeSmall} 0.5x`}
                          alt="Jade"
                          className={classes.ellipseStyle}
                        />
                        <Typography
                          variant="subtitle1"
                          style={{
                            color: "white",
                            paddingTop: "10px",
                          }}
                        >
                          <b>
                            <FormattedMessage id="landing_recruiter.picture_jade" />
                          </b>
                        </Typography>
                        <Box
                          style={{
                            display: "flex",
                            columnGap: "8px",
                            color: "white",
                            paddingTop: "10px",
                          }}
                        >
                          <PhoneIcon />
                          <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="hover"
                            color="white"
                            href="tel:003223291100"
                          >
                            <Typography>+32 (0) 2 329 11 00</Typography>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Box
                    style={{
                      width: "100%",
                    }}
                  >
                    <NewContactForm />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </>
    </>
  );
};

export default Pricing;
