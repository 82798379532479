import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  Container,
  Grid,
  Typography,
  Box,
  Paper,
  Button,
  CircularProgress,
  useMediaQuery,
  IconButton,
  TextField,
  FormControl,
  RadioGroup,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Link,
} from "@mui/material";
import { useTheme, makeStyles } from "@mui/styles";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import sh from "bundles/common/utils/sh";
import { trackOrder } from "bundles/common/utils/marketing";
import StripeInput from "./StripeInput";
import ThankYou from "icons/thankyou.svg";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    borderRadius: 10,
  },
  promotion: {
    marginTop: "-16px !important",
    marginLeft: "0px",
  },
  promotionCart: {
    marginTop: "-8px !important",
    marginLeft: "0px",
  },
}));

const cardNumberOptions = {
  showIcon: true,
};

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Recruiter = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const navigate = useNavigate();
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const query = useQuery();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { enqueueSnackbar } = useSnackbar();

  const stripe = useStripe();
  const elements = useElements();

  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const currentCompany = currentRecruiter.company;

  const is2Plus1Promotion = Date.now() < Date.parse("01/24/2023");

  const detailedPlan =
    currentCompany?.available_pricing?.find(
      (pricing) => pricing.name == query.get("selectedPrice")
    ) || currentCompany?.available_pricing[0];

  const selectedPlanPrice = detailedPlan?.amount;

  const jobOfferCreditsCount = detailedPlan?.credits?.find(
    (credit) => credit.type == "job_offer_creation"
  )?.quantity;

  const cvAccessCreditsCount = detailedPlan?.credits?.find(
    (credit) => credit.type == "cv_access"
  )?.quantity;

  const initialFormValues = {
    firstName: currentRecruiter?.first_name || "",
    lastName: currentRecruiter?.last_name || "",
    companyName: currentRecruiter?.company?.name || "",
    address: currentRecruiter?.company?.address || "",
    addressNumber: currentRecruiter?.company?.address_number || "",
    zipcode: currentRecruiter?.company?.zipcode || "",
    city: currentRecruiter?.company?.city || "",
    country: currentRecruiter?.company?.country?.toLowerCase() || "",
    vat: currentRecruiter?.company?.vat || "",
    email: currentRecruiter?.email || "",
    paymentMethod: "invoice",
  };

  const [clientSecret, setClientSecret] = useState("");
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [planQuantity, setPlanQuantity] = useState(
    is2Plus1Promotion ? 2 : jobOfferCreditsCount
  );
  const [taxPercentage, setTaxPercentage] = useState(
    values.country == "belgium" ? 0.21 : 0
  );
  const [cardComplete, setCardComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  function handleCardElementOnChange(e) {
    setCardComplete({ ...cardComplete, [e.elementType]: e.complete });
  }

  useEffect(() => {
    if (!isSuccess) {
      createPaymentIntent();
    }
  }, [isSuccess]);

  useEffect(() => {
    setTaxPercentage(values.country == "belgium" ? 0.21 : 0);
  }, [values.country]);

  const totalWithoutVat = () => {
    return (
      detailedPlan.name == "Basic" ||
      detailedPlan.name == "Essential" ||
      detailedPlan.nickname == "premium_2023"
        ? planQuantity * selectedPlanPrice
        : selectedPlanPrice
    ).toFixed(2);
  };

  const totalVat = () => {
    return (totalWithoutVat() * taxPercentage).toFixed(2);
  };

  const totalWithVat = () => {
    return (totalWithoutVat() * (taxPercentage + 1)).toFixed(2);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );
    if ("firstName" in fieldValues) {
      temp.firstName = fieldValues.firstName ? "" : required;
    }
    if ("lastName" in fieldValues) {
      temp.lastName = fieldValues.lastName ? "" : required;
    }
    if ("companyName" in fieldValues) {
      temp.companyName = fieldValues.companyName ? "" : required;
    }
    if ("vat" in fieldValues) {
      temp.vat = fieldValues.vat ? "" : required;
    }

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : required;
    }

    if ("address" in fieldValues) {
      temp.address = fieldValues.address ? "" : required;
    }

    if ("addressNumber" in fieldValues) {
      temp.addressNumber = fieldValues.addressNumber ? "" : required;
    }

    if ("zipcode" in fieldValues) {
      temp.zipcode = fieldValues.zipcode ? "" : required;
    }

    if ("city" in fieldValues) {
      temp.city = fieldValues.city ? "" : required;
    }

    if ("country" in fieldValues) {
      temp.country = fieldValues.country ? "" : required;
    }

    if (
      "paymentMethod" in fieldValues &&
      elements.getElement(CardNumberElement)
    ) {
      if (fieldValues.paymentMethod == "credit_card") {
        temp.ccnumber = cardComplete.cardNumber ? "" : "Cannot be empty";
        temp.cvc = cardComplete.cardCvc ? "" : "Cannot be empty";
        temp.ccexp = cardComplete.cardExpiry ? "" : "Cannot be empty";
      } else {
        temp.ccnumber = "";
        temp.cvc = "";
        temp.ccexp = "";
      }
    }

    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  const updateQuantity = (value) => {
    setPlanQuantity(planQuantity + value);
    createPaymentIntent(planQuantity + value);
  };

  const createPaymentIntent = (quantity = planQuantity) => {
    sh.post("/payment-intent", {
      authenticity_token: authenticityToken,
      items: [{ id: detailedPlan.id, quantity: quantity }],
    }).then((res) => {
      setClientSecret(res.data.clientSecret);
    });
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    let newValues = { ...values, [name]: value };
    setValues(newValues);
    validate({ [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate(values)) {
      if (elements == null) {
        return;
      }

      setLoading(true);

      let formValues = {
        authenticity_token: authenticityToken,
        data: {
          first_name: values.firstName,
          last_name: values.lastName,
          company_name: values.companyName,
          email: values.email,
          address: values.address,
          address_number: values.addressNumber,
          zipcode: values.zipcode,
          city: values.city,
          country: values.country,
          vat: values.vat,
        },
      };

      sh.post("/update_stripe_customer", formValues)
        .then(() => {
          if (values.paymentMethod == "invoice") {
            sh.post("/invoice", {
              authenticity_token: authenticityToken,
              product_id: detailedPlan.id,
              quantity: planQuantity,
            })
              .then(() => {
                setIsSuccess(true);
                trackOrder();
              })
              .catch((e) => {
                console.log(e);
                enqueueSnackbar(
                  intl.formatMessage({
                    id: "flash.unknown",
                  }),
                  { variant: "error" }
                );
              })
              .finally(() => {
                setLoading(false);
              });
          } else if (values.paymentMethod == "credit_card") {
            stripe
              .confirmCardPayment(clientSecret, {
                payment_method: {
                  card: elements.getElement(CardNumberElement),
                },
              })
              .then((res) => {
                if (res?.paymentIntent?.status == "succeeded") {
                  setIsSuccess(true);
                  trackOrder();
                } else {
                  enqueueSnackbar(paymentFailedMessage(res.error.code), {
                    variant: "error",
                  });
                }
                setLoading(false);
              });
          }
        })
        .catch((e) => {
          enqueueSnackbar(paymentFailedMessage(e.response.data.error_code), {
            variant: "error",
          });
          setLoading(false);
        });
    }
  };

  const paymentFailedMessage = (code) => {
    if (code == "card_declined") {
      return "Your card was declined";
    } else if (code == "expired_card") {
      return "Your card has expired";
    } else if (code == "incorrect_cvc") {
      return "Your CVC is incorrect";
    } else if (code == "incorrect_number") {
      return "Your card number is incorrect";
    } else if (code == "tax_id_invalid") {
      return "Your VAT number is invalid";
    } else {
      return "There was an error while processing your payment.";
    }
  };

  return isSuccess ? (
    // THANK YOU
    <Container maxWidth="lg">
      <Grid container spacing={4} align="center" justifyContent="center">
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="h2"
            style={{
              marginTop: "16px",
              paddingBottom: "4px",
              color: theme.palette.secondary.main,
            }}
          >
            <b>
              <FormattedMessage id="order.thank_you_title" />
            </b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="h5">
            <FormattedMessage id="order.thank_you_description" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            component="img"
            id="thank-you"
            alt="Thank-you"
            width="100%"
            height="auto"
            maxWidth={600}
            src={ThankYou}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            fullWidth
            color="secondaryContained"
            variant="rounded"
            style={{ marginBottom: "32px" }}
            onClick={() =>
              navigate({
                pathname: `/${i18nLocale}/recruiter`,
              })
            }
          >
            <FormattedMessage id="homepage" />
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            fullWidth
            color="secondaryContained"
            variant="rounded"
            style={{ marginBottom: "32px" }}
            onClick={() =>
              (window.location.href = `/${i18nLocale}/recruiter/job_offer_creation`)
            }
          >
            <FormattedMessage id="go_job_creation" />
          </Button>
        </Grid>
      </Grid>
    </Container>
  ) : (
    // ORDER FORM
    <Container maxWidth="lg">
      <Grid container component="form" onSubmit={handleSubmit} display="flex">
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="h2"
            style={{
              marginTop: "32px",
              paddingBottom: "32px",
              color: theme.palette.secondary.main,
            }}
          >
            <b>
              <FormattedMessage id="order.order_summary" />
            </b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {/* Products */}
                    <Grid item xs={12}>
                      <Grid
                        container
                        component={Paper}
                        className={classes.paper}
                        align={isDesktop ? "left" : "center"}
                        elevation={0}
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="h4"
                            style={{ fontFamily: "Work Sans" }}
                          >
                            <b>
                              <FormattedMessage id="order.product" />
                            </b>
                          </Typography>
                          <Box mb={2} />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid
                              item
                              xs={12}
                              display="flex"
                              alignItems={
                                detailedPlan.name == "Basic" ||
                                detailedPlan.name == "Essential"
                                  ? "center"
                                  : "start"
                              }
                              flexDirection={
                                detailedPlan.name == "Basic" ||
                                detailedPlan.name == "Essential" ||
                                detailedPlan.nickname == "premium_2023"
                                  ? "row"
                                  : "column"
                              }
                            >
                              <Typography
                                variant="body1"
                                style={{
                                  fontFamily: "Work Sans Bold",
                                  minWidth: "180px",
                                }}
                              >
                                <b>{planQuantity}</b>{" "}
                                <FormattedMessage
                                  id="order.job_offer_credit_description"
                                  values={{ count: planQuantity }}
                                />
                              </Typography>
                              {detailedPlan.name == "Basic" ||
                              detailedPlan.name == "Essential" ||
                              detailedPlan.nickname == "premium_2023" ? (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    style={{ marginLeft: "32px" }}
                                  >
                                    <Box>
                                      <IconButton
                                        size="small"
                                        onClick={() => updateQuantity(-1)}
                                        disabled={planQuantity <= 1}
                                      >
                                        <RemoveCircleOutline />
                                      </IconButton>
                                    </Box>
                                    <Box style={{ minWidth: "24px" }}>
                                      <Typography align="center">
                                        <b>{planQuantity}</b>
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <IconButton
                                        size="small"
                                        onClick={() => updateQuantity(1)}
                                        disabled={planQuantity > 99}
                                      >
                                        <AddCircleOutline />
                                      </IconButton>
                                    </Box>
                                  </Box>
                                ) : (
                                  <Typography
                                    variant="body1"
                                    style={{ fontFamily: "Work Sans Bold" }}
                                  >
                                    <b>{cvAccessCreditsCount} </b>
                                    <FormattedMessage id="order.cv_access_credit_description" />
                                  </Typography>
                                )}
                            </Grid>
                            {is2Plus1Promotion && planQuantity >= 2 && (
                              <Grid item xs={12} className={classes.promotion}>
                                <Typography
                                  variant="body2bold"
                                  style={{
                                    color: theme.palette.primary.main,
                                  }}
                                >
                                  + {Math.floor(planQuantity / 2)}{" "}
                                  <FormattedMessage
                                    id="order.january_promotion"
                                    values={{
                                      count: Math.floor(planQuantity / 2),
                                    }}
                                  />
                                </Typography>
                              </Grid>
                            )}
                            {detailedPlan.name == "Essential" ||
                              (detailedPlan.nickname == "premium_2023" && (
                                <Grid item xs={12}>
                                  <Typography
                                    variant="body1"
                                    style={{ fontFamily: "Work Sans Bold" }}
                                  >
                                    <b>{cvAccessCreditsCount} </b>
                                    <FormattedMessage id="order.cv_access_credit_description" />
                                  </Typography>
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* Billing informations */}
                    <Grid item xs={12}>
                      <Grid
                        container
                        component={Paper}
                        className={classes.paper}
                        align={isDesktop ? "left" : "center"}
                        elevation={0}
                      >
                        <Grid item xs={12}>
                          <Typography
                            variant="h4"
                            style={{ fontFamily: "Work Sans" }}
                          >
                            <b>
                              <FormattedMessage id="order.invoice_information" />
                            </b>
                          </Typography>
                          <Box mb={4} />
                        </Grid>
                        {/* Form */}
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            {/* First data column */}
                            <Grid item xs={12} md={6}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                      <TextField
                                        fullWidth
                                        required
                                        value={values.firstName}
                                        type="text"
                                        name="firstName"
                                        onBlur={handleInputValue}
                                        onChange={handleInputValue}
                                        variant="outlined"
                                        label={
                                          <FormattedMessage id="devise.signup.first_name" />
                                        }
                                        {...(errors["firstName"] && {
                                          error: true,
                                          helperText: errors["firstName"],
                                        })}
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <TextField
                                        fullWidth
                                        required
                                        value={values.lastName}
                                        type="text"
                                        name="lastName"
                                        onBlur={handleInputValue}
                                        onChange={handleInputValue}
                                        variant="outlined"
                                        label={
                                          <FormattedMessage id="devise.signup.last_name" />
                                        }
                                        {...(errors["lastName"] && {
                                          error: true,
                                          helperText: errors["lastName"],
                                        })}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    fullWidth
                                    required
                                    value={values.companyName}
                                    type="text"
                                    name="companyName"
                                    onBlur={handleInputValue}
                                    onChange={handleInputValue}
                                    variant="outlined"
                                    label={
                                      <FormattedMessage id="company.company" />
                                    }
                                    {...(errors["companyName"] && {
                                      error: true,
                                      helperText: errors["companyName"],
                                    })}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    fullWidth
                                    required
                                    value={values.vat}
                                    type="text"
                                    name="vat"
                                    onBlur={handleInputValue}
                                    onChange={handleInputValue}
                                    variant="outlined"
                                    label={
                                      <FormattedMessage id="company.vat_number" />
                                    }
                                    {...(errors["vat"] && {
                                      error: true,
                                      helperText: errors["vat"],
                                    })}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    fullWidth
                                    required
                                    value={values.email}
                                    type="email"
                                    name="email"
                                    onBlur={handleInputValue}
                                    onChange={handleInputValue}
                                    variant="outlined"
                                    label={
                                      <FormattedMessage id="landing_recruiter.contact_form.email_address" />
                                    }
                                    {...(errors["email"] && {
                                      error: true,
                                      helperText: errors["email"],
                                    })}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* Second data column */}
                            <Grid item xs={12} md={6}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={8}>
                                      <TextField
                                        fullWidth
                                        required
                                        value={values.address}
                                        type="text"
                                        name="address"
                                        onBlur={handleInputValue}
                                        onChange={handleInputValue}
                                        variant="outlined"
                                        label={
                                          <FormattedMessage id="company.address" />
                                        }
                                        {...(errors["address"] && {
                                          error: true,
                                          helperText: errors["address"],
                                        })}
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <TextField
                                        fullWidth
                                        required
                                        value={values.addressNumber}
                                        type="text"
                                        name="addressNumber"
                                        onBlur={handleInputValue}
                                        onChange={handleInputValue}
                                        variant="outlined"
                                        label={
                                          <FormattedMessage id="company.address_number" />
                                        }
                                        {...(errors["addressNumber"] && {
                                          error: true,
                                          helperText: errors["addressNumber"],
                                        })}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    fullWidth
                                    required
                                    value={values.zipcode}
                                    type="text"
                                    name="zipcode"
                                    onBlur={handleInputValue}
                                    onChange={handleInputValue}
                                    variant="outlined"
                                    label={
                                      <FormattedMessage id="company.zipcode" />
                                    }
                                    {...(errors["zipcode"] && {
                                      error: true,
                                      helperText: errors["zipcode"],
                                    })}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    fullWidth
                                    required
                                    value={values.city}
                                    type="text"
                                    name="city"
                                    onBlur={handleInputValue}
                                    onChange={handleInputValue}
                                    variant="outlined"
                                    label={
                                      <FormattedMessage id="company.city" />
                                    }
                                    {...(errors["city"] && {
                                      error: true,
                                      helperText: errors["city"],
                                    })}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel id="country-label">
                                      <FormattedMessage id="job_offer.country" />
                                    </InputLabel>
                                    <Select
                                      disabled
                                      name="country"
                                      label={intl.formatMessage({
                                        id: "job_offer.country",
                                      })}
                                      required
                                      value={values.country}
                                      onChange={handleInputValue}
                                    >
                                      <MenuItem value="belgium" key="belgium">
                                        <FormattedMessage id="countries.belgium" />
                                      </MenuItem>
                                      <MenuItem
                                        value="luxembourg"
                                        key="luxembourg"
                                      >
                                        <FormattedMessage id="countries.luxembourg" />
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    marginBottom={3}
                    marginTop={3}
                    container
                    component={Paper}
                    className={classes.paper}
                    align={isDesktop ? "left" : "center"}
                    elevation={0}
                  >
                    <Grid item xs={12} style={{ marginBottom: "8px" }}>
                      <Typography
                        variant="h4"
                        style={{ fontFamily: "Work Sans" }}
                      >
                        <b>
                          <FormattedMessage id="order.payment_method" />
                        </b>
                      </Typography>
                      <Box mb={2} />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl style={{ width: "100%" }}>
                        <RadioGroup
                          name="paymentMethod"
                          value={values["paymentMethod"]}
                          onChange={handleInputValue}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            columnGap: "16px",
                          }}
                        >
                          <FormControlLabel
                            value="invoice"
                            control={<Radio />}
                            label={intl.formatMessage({
                              id: "order.invoice_payment",
                            })}
                          />
                          <FormControlLabel
                            value="credit_card"
                            control={<Radio />}
                            label={intl.formatMessage({
                              id: "order.cc_payment",
                            })}
                          />
                        </RadioGroup>
                        {values["paymentMethod"] == "credit_card" && (
                          <Grid container style={{ marginTop: "8px" }}>
                            <Grid item xs={12} style={{ marginBottom: "16px" }}>
                              <TextField
                                required
                                label={intl.formatMessage({
                                  id: "order.cc_number",
                                })}
                                name="ccnumber"
                                variant="outlined"
                                style={{ width: "100%" }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                  inputComponent: StripeInput,
                                  inputProps: {
                                    onChange: handleCardElementOnChange,
                                    options: cardNumberOptions,
                                    component: CardNumberElement,
                                  },
                                }}
                                {...(errors["ccnumber"] && {
                                  error: true,
                                  helperText: errors["ccnumber"],
                                })}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              style={{ paddingRight: "8px" }}
                            >
                              <TextField
                                label={intl.formatMessage({
                                  id: "order.cc_exp",
                                })}
                                name="ccexp"
                                variant="outlined"
                                required
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                  inputComponent: StripeInput,
                                  inputProps: {
                                    onChange: handleCardElementOnChange,
                                    component: CardExpiryElement,
                                  },
                                }}
                                {...(errors["ccexp"] && {
                                  error: true,
                                  helperText: errors["ccexp"],
                                })}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              style={{ paddingLeft: "8px" }}
                            >
                              <TextField
                                label={intl.formatMessage({
                                  id: "order.cc_cvc",
                                })}
                                name="cvc"
                                variant="outlined"
                                required
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                  inputComponent: StripeInput,
                                  inputProps: {
                                    onChange: handleCardElementOnChange,
                                    component: CardCvcElement,
                                  },
                                }}
                                {...(errors["cvc"] && {
                                  error: true,
                                  helperText: errors["cvc"],
                                })}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "8px" }}>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox required color="primary" />}
                          label={intl.formatMessage(
                            { id: "order.accept_terms" },
                            {
                              a: (str) => (
                                <Link
                                  href="general-sales-conditions"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <b>{str}</b>
                                </Link>
                              ),
                            }
                          )}
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Cart */}
        <Grid
          item
          style={{
            position: isDesktop ? "fixed" : "relative",
            width: isDesktop ? "18%" : "100%",
            right: isDesktop ? "12%" : "unset",
            top: isDesktop ? "180px" : "unset",
            bottom: isDesktop ? "164px" : "unset",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container component={Paper} className={classes.paper}>
                <Grid item xs={12}>
                  <Typography variant="h4" style={{ fontFamily: "Work Sans" }}>
                    <b>
                      <FormattedMessage id="order.cart" />
                    </b>
                  </Typography>
                  <Box mb={2} />
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Typography
                            variant="body1"
                            style={{ fontFamily: "Work Sans Bold" }}
                          >
                            <b>
                              <FormattedMessage id="order.subtotal" />
                            </b>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            align="right"
                            style={{ fontFamily: "Work Sans Bold" }}
                          >
                            <b>{totalWithoutVat()} €</b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {is2Plus1Promotion && planQuantity >= 2 && (
                      <Grid item xs={12} className={classes.promotionCart}>
                        <Typography
                          variant="body2bold"
                          style={{
                            color: theme.palette.primary.main,
                          }}
                        >
                          {Math.floor(planQuantity / 2)}{" "}
                          <FormattedMessage
                            id="order.january_promotion"
                            values={{
                              count: Math.floor(planQuantity / 2),
                            }}
                          />
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <FormattedMessage id="company.vat" /> (
                            {taxPercentage * 100}%)
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography align="right">{totalVat()} €</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Typography
                            variant="body1"
                            style={{ fontFamily: "Work Sans Bold" }}
                          >
                            <b>
                              <FormattedMessage id="order.total" />
                            </b>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            align="right"
                            color="primary"
                            variant="subtitle2"
                            style={{ fontFamily: "Work Sans Bold" }}
                          >
                            <b>{totalWithVat()} €</b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box my={2}>
                <Button
                  style={{
                    marginTop: "16px",
                    margin: "auto",
                  }}
                  variant="rounded"
                  color="primaryContained"
                  type="submit"
                  fullWidth
                >
                  <Box
                    justifyContent="center"
                    style={{ marginTop: loading ? "4px" : "0px" }}
                  >
                    {loading && (
                      <Box component="span">
                        <CircularProgress size={16} style={{ color: "white" }}>
                          <FormattedMessage id="order.order" />
                        </CircularProgress>
                      </Box>
                    )}
                    {!loading && (
                      <Box component="span">
                        <FormattedMessage id="order.order" />
                      </Box>
                    )}
                  </Box>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Recruiter;
