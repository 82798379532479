import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import SpontaneousApplicationDialogDesktop from "./Desktop/SpontaneousApplicationDialogDesktop";
import SpontaneousApplicationDialogMobile from "./Mobile/SpontaneousApplicationDialogMobile";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSnackbar } from "notistack";
import { useTheme } from "@mui/material/styles";
import { uploadResume } from "bundles/common/utils/uploadResume";
import sh from "bundles/common/utils/sh";
import { trackJobOfferApplication } from "bundles/common/utils/marketing";
import { replaceCurrentCandidate } from "bundles/Candidate/constants/candidateConstants";

const SpontaneousApplicationDialog = ({ companyId, closeModal, jobApplication }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const intl = useIntl();
  const currentCandidate = useSelector((state) => state.current_candidate);
  const candidate = useSelector((state) => state.current_candidate) || {};
  const railsEnv = useSelector((state) => state.railsEnv);
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const initialFormValues = {
    cover_letter: jobApplication?.cover_letter || "",
  };

  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [resume, setResume] = useState([]);

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    let newValues = { ...values, [name]: value };
    setValues(newValues);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (resume?.length > 0) {
      let resumeUrl = resume[0];
      if (resumeUrl instanceof File) {
        const presignedFileParams = await uploadResume(resumeUrl);
        resumeUrl = presignedFileParams.blob_signed_id;
      }
      sh.post("/job_application", {
        authenticity_token: authenticityToken,
        cover_letter: values.cover_letter,
        resume_url: resumeUrl,
        company_id: companyId,
      })
        .then(() => {
          replaceCurrentCandidate(dispatch, authenticityToken, () => { });
          if (railsEnv === "production") trackJobOfferApplication();
          closeModal();
          setLoading(false);
          enqueueSnackbar(
            intl.formatMessage({ id: "applicant.application_submitted" }),
            {
              variant: "success",
            }
          );
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
          let message = currentCandidate.whitelisted ? "settings.save_failed" : "settings.must_be_whitelisted";
          enqueueSnackbar(intl.formatMessage({ id: message }), {
            variant: "error",
          });
        });
    } else {
      if (!resume?.length > 0) {
        setErrors({
          ...errors,
          resume: <FormattedMessage id="form_validation.document_required" />,
        });
      }
      setLoading(false);
    }
  };

  const handleNewCVDelete = () => {
    setResume();
  };

  useEffect(() => {
    const files = [];
    if (jobApplication?.resume_url) {
      files.push(jobApplication.resume_url);
    } else if (candidate?.resume_url) {
      files.push(candidate.resume_url);
    }
    setResume(files);
  }, [jobApplication, candidate]);

  return (
    <>
      {isDesktop
        ?
        <SpontaneousApplicationDialogDesktop
          companyId={companyId}
          closeModal={closeModal}
          jobApplication={jobApplication}
          values={values}
          setValues={setValues}
          errors={errors}
          setErrors={setErrors}
          loading={loading}
          handleSubmit={handleSubmit}
          handleInputValue={handleInputValue}
          resume={resume}
          setResume={setResume}
          handleNewCVDelete={handleNewCVDelete}
          candidate={candidate}
        />
        :
        <SpontaneousApplicationDialogMobile
          companyId={companyId}
          closeModal={closeModal}
          jobApplication={jobApplication}
          values={values}
          setValues={setValues}
          errors={errors}
          setErrors={setErrors}
          loading={loading}
          handleSubmit={handleSubmit}
          handleInputValue={handleInputValue}
          resume={resume}
          setResume={setResume}
          handleNewCVDelete={handleNewCVDelete}
          candidate={candidate}
        />
      }
    </>
  );
};

export default SpontaneousApplicationDialog;
