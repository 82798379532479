import { useEffect, useState, useCallback } from "react";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import sh from "bundles/common/utils/sh";
import { useSelector, useDispatch } from "react-redux";
import { replaceCurrentCandidate } from "bundles/Candidate/constants/candidateConstants";

const QuestionSnackbar = ({ setActiveQuestion }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const currentCandidate = useSelector((state) => state.current_candidate);
  const dispatch = useDispatch();

  const snackbarQuestions = [
    {
      attribute: "availability",
      questionLabel: intl.formatMessage(
        { id: "questions.availability_question" },
        { name: currentCandidate?.first_name || " " }
      ),
      questionValues: [
        {
          label: intl.formatMessage({ id: "candidate.looking" }),
          value: "looking",
        },
        {
          label: intl.formatMessage({ id: "candidate.listening" }),
          value: "listening",
        },
        {
          label: intl.formatMessage({ id: "candidate.currently_employed" }),
          value: "currently_employed",
        },
      ],
      percentage: 4,
      type: "boxSelect",
    },
    {
      attribute: "desired_sector",
      questionLabel: intl.formatMessage({ id: "questions.sector_question" }),
      questionValues: [
        {
          label: intl.formatMessage({
            id: "company.company_industry.architecture",
          }),
          value: "architecture",
        },
        {
          label: intl.formatMessage({
            id: "company.company_industry.engineering",
          }),
          value: "engineering",
        },
        {
          label: intl.formatMessage({
            id: "company.company_industry.construction",
          }),
          value: "construction",
        },
        {
          label: intl.formatMessage({
            id: "company.company_industry.administration",
          }),
          value: "administration",
        },
      ],
      percentage: 4,
      type: "boxSelect",
    },
    {
      attribute: "experience_year",
      questionLabel: intl.formatMessage({
        id: "questions.experience_question",
      }),
      inputLabel: intl.formatMessage({ id: "questions.years" }),
      percentage: 2,
      type: "numberInput",
    },
  ];

  const handleSelectValue = useCallback(
    (value, snackbarId, attribute) => {
      setIsLoading(true);
      const data = {
        authenticity_token: authenticityToken,
        id: currentCandidate.id,
        [attribute]: value,
      };

      sh.put("/candidate", data)
        .then((res) => {
          replaceCurrentCandidate(dispatch, authenticityToken, () => {});
          setTimeout(() => {
            showNextQuestion(res.data);
          }, 2000);
        })
        .finally(() => {
          closeSnackbar(snackbarId);
          setIsLoading(false);
          setActiveQuestion(false);
        });
    },
    [authenticityToken, currentCandidate.id, dispatch, closeSnackbar]
  );

  const showNextQuestion = (candidate = currentCandidate) => {
    let question = snackbarQuestions.find((q) => !candidate[q.attribute]);

    if (question) {
      setActiveQuestion(true);
      enqueueSnackbar(question.questionLabel, {
        variant: "candidateQuestion",
        persist: true,
        questionData: question,
        onSelectValue: (value, snackbarId) =>
          handleSelectValue(value, snackbarId, question.attribute),
      });
    } else if (!dataExists()) {
      enqueueSnackbar(intl.formatMessage({ id: "polls.thanks" }), {
        variant: "info",
      });
    }
  };

  useEffect(() => {
    if (!dataExists()) {
      showNextQuestion();
    }
  }, []);

  const dataExists = () => {
    return (
      currentCandidate.desired_sector &&
      currentCandidate.availability &&
      currentCandidate.experience_year
    );
  };

  return null;
};

export default QuestionSnackbar;
