import React from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useTheme } from "@mui/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100% !important",
    marginLeft: "0px !important",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "8px",
  },
  column: {
    display: "flex !important",
    textAlign: "center !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    flexDirection: "column !important",
    padding: "8px 16px !important",
  },
  rightColumnBorder: {
    borderRight: `1px solid ${theme.palette.secondary.main}`,
  },
  rightAndBottomColumnBorder: {
    borderRight: `1px solid ${theme.palette.secondary.main}`,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  bottomColumnBorder: {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
}));

const MetricsGrid = ({
  titleOne,
  textOne,
  titleTwo,
  textTwo,
  titleThree,
  textThree,
  titleFour = null,
  textFour = null,
  number = 4,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container className={classes.container} spacing={2}>
      <Grid
        item
        xs={number == 4 ? 6 : 4}
        md={number == 4 ? 3 : 4}
        className={`${classes.column} ${
          isDesktop || number != 4
            ? classes.rightColumnBorder
            : classes.rightAndBottomColumnBorder
        }`}
      >
        <Typography variant="subtitle2">
          <FormattedMessage id={titleOne} />
        </Typography>
        <Typography variant="body1" style={{ fontFamily: "Work Sans Bold" }}>
          <b>{textOne}</b>
        </Typography>
      </Grid>
      <Grid
        item
        xs={number == 4 ? 6 : 4}
        md={number == 4 ? 3 : 4}
        className={`${classes.column} ${
          isDesktop || number != 4
            ? classes.rightColumnBorder
            : classes.bottomColumnBorder
        }`}
      >
        <Typography variant="subtitle2">
          <FormattedMessage id={titleTwo} />
        </Typography>
        <Typography variant="body1" style={{ fontFamily: "Work Sans Bold" }}>
          <b>{textTwo}</b>
        </Typography>
      </Grid>
      <Grid
        item
        xs={number == 4 ? 6 : 4}
        md={number == 4 ? 3 : 4}
        className={`${classes.column} ${
          number == 4 ? classes.rightColumnBorder : ""
        }`}
      >
        <Typography variant="subtitle2">
          <FormattedMessage id={titleThree} />
        </Typography>
        <Typography variant="body1" style={{ fontFamily: "Work Sans Bold" }}>
          <b>{textThree}</b>
        </Typography>
      </Grid>
      {number == 4 && (
        <Grid item xs={6} md={3} className={`${classes.column}`}>
          <Typography variant="subtitle2">
            <FormattedMessage id={titleFour} />
          </Typography>
          <Typography variant="body1" style={{ fontFamily: "Work Sans Bold" }}>
            <b>{textFour}</b>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default MetricsGrid;
