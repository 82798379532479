import React, { useCallback } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useSnackbar } from "notistack";
import { formatFileSize } from "../utils/utils";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const PictureDropzone = ({
  handleFileInput,
  pictureUrl,
  fileLimit = 1,
  backgroundSize = "contain",
  height = "140px",
  width = "140px",
  maxFileSize = 1000000,
  type,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: `${width} !important`,
      height: `${height} !important`,
      minHeight: `${height} !important`,
      padding:
        backgroundSize == "contain" ? "4px !important" : "1px !important",
      backgroundImage: `url(${pictureUrl})`,
      backgroundSize: backgroundSize,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundClip: "content-box",
      backgroundOrigin: "content-box",
      borderRadius: "4px",
      border: `1px dashed ${theme.palette.text.hint}`,
      "&:hover": {
        border: `1px dashed ${theme.palette.text.placeholder}`,
      },
      "& .MuiDropzonePreviewList-root": {
        justifyContent: "center",
      },
      "& .MuiDropzoneArea-textContainer": {
        display: pictureUrl ? "none" : "flex",
      },
      cursor: "pointer",
    },
  }));
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        fileRejections.forEach((fileRejection) => {
          enqueueSnackbar(
            intl.formatMessage(
              { id: "devise.signup.drop_reject" },
              {
                fileName: fileRejection.file.path,
                sizeLimit: formatFileSize(maxFileSize),
              }
            )
          );
        });
      } else {
        handleFileInput(acceptedFiles[0]);
      }
    },
    [handleFileInput]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: fileLimit > 1,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
      "image/svg+xml": [".svg"]
    },
    maxSize: maxFileSize,
  });

  return (
    <Box {...getRootProps()} className={classes.root}>
      <input {...getInputProps()} />
      {!pictureUrl && (
        <CloudUploadIcon
          className="MuiDropzoneArea-icon"
          style={{ color: type === "candidate" ? theme.palette.primary.main : theme.palette.secondary.main }}
        />
      )}
    </Box>
  );
};

export default PictureDropzone;
