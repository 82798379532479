import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Link,
  Box,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import sh from "bundles/common/utils/sh";
import PasswordField from "bundles/common/components/PasswordField";
import { useSnackbar } from "notistack";
import OauthButton from "bundles/common/components/OauthButton";
import TextDivider from "bundles/common/components/TextDivider";
import { useLocation } from "react-router-dom";
import { replaceCurrentCandidate } from "bundles/Candidate/constants/candidateConstants";
import { replaceCurrentRecruiter } from "bundles/Recruiter/constants/recruiterConstants.js";

const Login = ({ onSectionClicked, onActionFinished, customScope }) => {
  const authenticity_token = useSelector((state) => state.authenticity_token);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useDispatch();

  const initialFormValues = {
    email: "",
    password: "",
    remember_me: false,
  };

  const location = useLocation();
  const oauth_error = new URLSearchParams(location.search).get("oauth_error");

  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [oauthError, setOauthError] = useState(oauth_error);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : required;
    }

    if ("password" in fieldValues) {
      temp.password = fieldValues.password ? "" : required;
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const handleCheckboxValue = (e) => {
    const { name, checked } = e.target;
    setValues({
      ...values,
      [name]: checked,
    });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (validate(values)) {
      sh.post(`/${i18nLocale}/candidates/sign_in`, {
        authenticity_token,
        candidate: {
          email: values.email,
          password: values.password,
          remember_me: values.remember_me,
        },
      })
        .then((res) => {
          setLoading(false);
          // Handling the case when a recruiter logged in from the candidate login page
          if (res.data.company_id) {
            replaceCurrentRecruiter(dispatch, authenticity_token, () => {
              window.location.href = window.location.origin;
            });
          } else {
            replaceCurrentCandidate(
              dispatch,
              authenticity_token,
              onActionFinished
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err.response?.status == 401) {
            enqueueSnackbar(
              intl.formatMessage({
                id: "devise.login.unauthorized",
              }),
              { variant: "error" }
            );
          }
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <Grid container style={{ marginTop: "24px" }}>
      <Grid item xs={12}>
        <Box>
          <Box
            disableTypography
            title={
              <Typography variant="h2" align="center">
                <FormattedMessage
                  id="signin"
                  values={{
                    b: (chunks) => <b>{chunks}</b>,
                  }}
                />
              </Typography>
            }
          ></Box>
          <Box style={{ marginLeft: "8px", marginRight: "8px" }}>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Box style={{ marginBottom: "16px" }}>
                  <OauthButton
                    scope={`${customScope}login`}
                    provider="linkedin"
                  />
                  {oauthError && oauthError == "linkedin_error" && (
                    <Typography style={{ color: theme.palette.error.main }}>
                      <FormattedMessage id={"oauth.errors." + oauthError} />
                    </Typography>
                  )}
                </Box>
                <Box style={{ marginBottom: "16px" }}>
                  <OauthButton
                    scope={`${customScope}login`}
                    provider="facebook"
                  />
                  {oauthError && oauthError == "facebook_error" && (
                    <Typography style={{ color: theme.palette.error.main }}>
                      <FormattedMessage id={"oauth.errors." + oauthError} />
                    </Typography>
                  )}
                </Box>
                <Box style={{ marginBottom: "16px" }}>
                  <OauthButton
                    scope={`${customScope}login`}
                    provider="microsoft"
                  />
                  {oauthError && oauthError == "microsoft_error" && (
                    <Typography style={{ color: theme.palette.error.main }}>
                      <FormattedMessage id={"oauth.errors." + oauthError} />
                    </Typography>
                  )}
                </Box>
                {/* <Box>
                  <OauthButton
                    scope={`${customScope}login`}
                    provider="google"
                  />
                  {oauthError && oauthError == "google_error" && (
                    <Typography style={{ color: theme.palette.error.main }}>
                      <FormattedMessage id={"oauth.errors." + oauthError} />
                    </Typography>
                  )}
                </Box> */}

                <Box style={{ marginTop: "16px", marginBottom: "16px" }}>
                  <TextDivider labelId="oauth.divider" />
                </Box>

                <form onSubmit={handleSubmit}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        size="small"
                        type="email"
                        variant="outlined"
                        onBlur={handleInputValue}
                        onChange={handleInputValue}
                        name="email"
                        {...(errors["email"] && {
                          error: true,
                          helperText: errors["email"],
                        })}
                        label={<FormattedMessage id="devise.signup.email" />}
                      />
                      <PasswordField
                        name="password"
                        labelId="devise.signup.password"
                        handleInputValue={handleInputValue}
                        errors={errors}
                      />
                      <Box style={{ marginBottom: "8px" }}>
                        <Typography variant="body3">
                          <Link
                            onClick={() =>
                              onSectionClicked("forgotten_password")
                            }
                            color="primary"
                            underline="always"
                            style={{ cursor: "pointer" }}
                          >
                            <FormattedMessage id="devise.forgotten_password" />
                          </Link>
                        </Typography>
                      </Box>

                      <FormControlLabel
                        control={
                          <Checkbox
                            value={values.remember_me}
                            onChange={handleCheckboxValue}
                            name="remember_me"
                            color="primary"
                          />
                        }
                        label={
                          <Typography variant="body2">
                            <FormattedMessage id="devise.login.remember_me" />{" "}
                          </Typography>
                        }
                      />

                      <Button
                        fullWidth
                        type="submit"
                        variant="rounded"
                        color="primaryContained"
                        size="medium"
                        disabled={loading}
                      >
                        <Box justifyContent="center">
                          {loading && (
                            <CircularProgress size={16} color="secondary">
                              <FormattedMessage id="signin" />
                            </CircularProgress>
                          )}
                          {!loading && <FormattedMessage id="signin" />}
                        </Box>
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>

              <Grid
                item
                xs={12}
                style={{ marginTop: "16px", textAlign: "center" }}
              >
                <Typography variant="body3" align="center">
                  <FormattedMessage
                    align="center"
                    id="devise.not_yet_subscribed"
                  />{" "}
                  <Link
                    onClick={() => onSectionClicked("sign_up")}
                    color="primary"
                    underline="always"
                    style={{ cursor: "pointer" }}
                  >
                    <FormattedMessage id="signup" />
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography variant="body3" align="center">
                  <FormattedMessage
                    align="center"
                    id="devise.did_not_receive_confirmation"
                  />{" "}
                  <Link
                    onClick={() => onSectionClicked("resend_confirmation")}
                    color="primary"
                    underline="always"
                    style={{ cursor: "pointer" }}
                  >
                    <FormattedMessage id="click_here" />
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
