import React, { useState } from "react";
import {
  Grid,
  Button,
  Box,
  Typography,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  Slider,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import sh from "bundles/common/utils/sh";
import {
  getResponseMessageId,
  companySizeMap,
  companyExternalTools,
} from "bundles/common/utils/constants";

export default function StepThree({ companyData, nextStep }) {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const authenticityToken = useSelector((state) => state.authenticity_token);

  const initialFormValues = {
    team_size: "",
    recruitment_plan: 0,
    use_external_tools: false,
    external_tools: [],
    recruitment_needs: 5,
  };

  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [externalToolsSelectOpen, setExternalToolsSelectOpenLoading] =
    useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );

    if ("recruitment_plan" in fieldValues) {
      temp.recruitment_plan = fieldValues.recruitment_plan ? "" : required;
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputValue = (e) => {
    let name = e.target.name;
    let value;

    switch (name) {
    case "use_external_tools":
      value = !(e.target.value === "true");
      break;
    default:
      value = e.target.value;
      break;
    }

    setExternalToolsSelectOpenLoading(false);

    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const handleSliderValue = (_e, value) => {
    setValues({
      ...values,
      ["recruitment_needs"]: value,
    });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    updateCompany();
  };

  const updateCompany = () => {
    if (validate(values)) {
      sh.put(`${i18nLocale}/company/${companyData.id}`, {
        authenticity_token: authenticityToken,
        company: {
          ...companyData,
          ...values,
        },
      })
        .then(() => {
          nextStep();
        })
        .catch((err) => {
          setLoading(false);
          if (err.response?.data?.message) {
            enqueueSnackbar(
              getResponseMessageId(err?.response?.data?.message),
              { variant: "error" }
            );
          }
          console.error("Error", err);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            style={{ textAlign: "center", marginBottom: "16px" }}
          >
            <b>
              <FormattedMessage id="recruiter.signup_modal.title_step_4" />
            </b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ marginBottom: "8px", marginTop: "4px" }}>
            <Typography variant="body2">
              <FormattedMessage
                id="company.company_size.description"
                values={{
                  name: companyData.name,
                  b: (chunks) => <b>{chunks}</b>,
                }}
              />
            </Typography>
          </Box>
          <Box>
            <FormControl
              fullWidth
              variant="outlined"
              required
              color="secondary"
              size="small"
            >
              <InputLabel>
                <FormattedMessage id="company.company_size.label" />
              </InputLabel>
              <Select
                id="team_size"
                name="team_size"
                required
                value={values?.team_size}
                onChange={handleInputValue}
                label={<FormattedMessage id="company.company_size.label" />}
              >
                {companySizeMap.map((value) => (
                  <MenuItem value={value} key={value}>
                    <FormattedMessage
                      id={"company.company_size.options." + value}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Box style={{ marginBottom: "8px", marginTop: "4px" }}>
              <Typography variant="body2">
                <FormattedMessage
                  id="company.recruitment_plan.description"
                  values={{
                    name: companyData.name,
                    b: (chunks) => <b>{chunks}</b>,
                  }}
                />
              </Typography>
            </Box>
            <Box>
              <TextField
                fullWidth
                required
                type="number"
                color="secondary"
                InputProps={{ inputProps: { min: 0 } }}
                name="recruitment_plan"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                variant="outlined"
                size="small"
                label={<FormattedMessage id="company.recruitment_plan.label" />}
                {...(errors["recruitment_plan"] && {
                  error: true,
                  helperText: errors["recruitment_plan"],
                })}
              ></TextField>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box container spacing={0}>
            <Box style={{ marginBottom: "8px", marginTop: "4px" }}>
              <Typography variant="body2">
                <FormattedMessage id="company.external_tools.description" />
              </Typography>
            </Box>
            <Box>
              <Typography component="div">
                <Grid component="label" container alignItems="center">
                  <Grid item>
                    <b>
                      <FormattedMessage id="company.external_tools.no_label" />
                    </b>
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={values?.use_external_tools}
                      value={values?.use_external_tools}
                      onChange={handleInputValue}
                      name="use_external_tools"
                      size="small"
                      color="secondary"
                    />
                  </Grid>
                  <Grid item>
                    <b>
                      <FormattedMessage id="company.external_tools.yes_label" />
                    </b>
                  </Grid>
                </Grid>
              </Typography>
              {values?.use_external_tools && (
                <FormControl
                  fullWidth
                  variant="outlined"
                  required
                  color="secondary"
                  size="small"
                  style={{ marginTop: "8px" }}
                >
                  <InputLabel>
                    <FormattedMessage id="company.external_tools.tool_label" />
                  </InputLabel>
                  <Select
                    id="external_tools"
                    name="external_tools"
                    multiple
                    required
                    value={values?.external_tools}
                    renderValue={(selected) =>
                      selected
                        .map((tool) =>
                          intl.formatMessage({
                            id: "company.external_tools.tool_options." + tool,
                          })
                        )
                        .join(", ")
                    }
                    onChange={handleInputValue}
                    onOpen={() =>
                      setExternalToolsSelectOpenLoading(
                        !externalToolsSelectOpen
                      )
                    }
                    open={externalToolsSelectOpen}
                    label={
                      <FormattedMessage id="company.external_tools.tool_label" />
                    }
                  >
                    {companyExternalTools.map((value) => (
                      <MenuItem value={value} key={value}>
                        <FormattedMessage
                          id={"company.external_tools.tool_options." + value}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box container spacing={0}>
            <Box item xs={12}>
              <Typography variant="body2">
                <FormattedMessage id="company.recruitment_needs.description" />
              </Typography>
            </Box>
            <Box item xs={12}>
              <Box mt={1}>
                <Slider
                  color="secondary"
                  defaultValue={1}
                  value={values?.recruitment_needs}
                  onChange={handleSliderValue}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="on"
                  step={1}
                  marks
                  min={0}
                  max={10}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginTop: "8px" }}>
        <Grid item xs={12}>
          <Button
            fullWidth
            type="submit"
            variant="rounded"
            color="secondaryContained"
            size="medium"
            disabled={loading}
          >
            <Box justifyContent="center">
              {loading && (
                <CircularProgress size={16} color="secondary">
                  <FormattedMessage id="next" />
                </CircularProgress>
              )}
              {!loading && <FormattedMessage id="devise.signup.build_team" />}
            </Box>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
