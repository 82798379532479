import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import { useSnackbar } from "notistack";
import sh from "bundles/common/utils/sh";

const Poll = ({ poll }) => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const intl = useIntl();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const vote = (answer, snackbarId) => {
    setIsLoading(true);
    sh.post("/poll/vote", {
      authenticity_token: authenticityToken,
      poll_id: poll.id,
      answer: answer,
    })
      .then(() => {
        enqueueSnackbar(intl.formatMessage({ id: "polls.thanks" }), {
          variant: "info",
        });
        closeSnackbar(snackbarId);
      })
      .catch(() => closeSnackbar(snackbarId))
      .finally(() => setIsLoading(false));
  };

  const action = (snackbarId) => (
    <Box>
      <Button
        disabled={isLoading}
        variant="rounded"
        color="primaryContained"
        size="large"
        onClick={() => vote(true, snackbarId)}
      >
        {intl.formatMessage({ id: "polls.yes" })}
      </Button>
      <Button
        disabled={isLoading}
        variant="rounded"
        color="primaryContained"
        size="large"
        onClick={() => vote(false, snackbarId)}
      >
        {intl.formatMessage({ id: "polls.no" })}
      </Button>
    </Box>
  );

  useEffect(() => {
    enqueueSnackbar(intl.formatMessage({ id: poll.question_id }), {
      action,
      persist: true,
      variant: "info",
    });
  }, []);

  return null;
};

export default Poll;
