import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  Box,
  MenuItem,
  Button,
  Menu,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import TuneIcon from "@mui/icons-material/Tune";
import DnsIcon from "@mui/icons-material/Dns";
import EngineeringIcon from "@mui/icons-material/Engineering";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SearchIcon from "@mui/icons-material/Search";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Profile from "./Settings/Profile";
import Password from "./Settings/Password";
import YourSearch from "./Settings/YourSearch";
import Skills from "./Settings/Skills";
import Experience from "./Settings/Experience";
import Training from "./Settings/Training";
import Notifications from "./Settings/Notifications";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import Privacy from "./Settings/Privacy";
import ResumeGenerator from "./Settings/ResumeGenerator";

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Settings = () => {
  const currentCandidate = useSelector((state) => state.current_candidate);
  const i18nLocale = useSelector((state) => state.i18nLocale);

  const query = useQuery();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const sections = [
    {
      code: "profile",
      labelId: "settings.profile",
      icon: () => (
        <AccountBoxIcon
          style={{ width: "18px", height: "18px", marginRight: "8px" }}
        />
      ),
      displayOnMobile: true,
    },
    {
      code: "skills",
      labelId: "settings.skills",
      icon: () => (
        <TuneIcon
          style={{ width: "18px", height: "18px", marginRight: "8px" }}
        />
      ),
      displayOnMobile: true,
    },
    {
      code: "training",
      labelId: "settings.formation",
      icon: () => (
        <DnsIcon
          style={{
            width: "18px",
            height: "18px",
            marginRight: isDesktop ? "8px" : "4px",
          }}
        />
      ),
      displayOnMobile: true,
    },
    {
      code: "experience",
      labelId: "settings.experience",
      icon: () => (
        <EngineeringIcon
          style={{
            width: "18px",
            height: "18px",
            marginRight: isDesktop ? "8px" : "4px",
          }}
        />
      ),
      displayOnMobile: true,
    },
    {
      code: "resume_generator",
      labelId: "settings.generate_your_cv_section",
      icon: () => (
        <CloudDownloadIcon
          style={{ width: "18px", height: "18px", marginRight: "8px" }}
        />
      ),
      displayOnMobile: true,
    },
    {
      code: "your_search",
      labelId: "settings.your_search",
      icon: () => (
        <SearchIcon
          style={{ width: "18px", height: "18px", marginRight: "8px" }}
        />
      ),
      displayOnMobile: true,
    },
    {
      code: "notifications",
      labelId: "settings.notifications",
      icon: () => (
        <NotificationsIcon
          style={{
            width: "18px",
            height: "18px",
            marginRight: isDesktop ? "8px" : "4px",
          }}
        />
      ),
      displayOnMobile: true,
    },
    {
      code: "password",
      labelId: "settings.password",
      icon: () => (
        <LockPersonIcon
          style={{
            width: "18px",
            height: "18px",
            marginRight: isDesktop ? "8px" : "4px",
          }}
        />
      ),
      displayOnMobile: true,
    },
    {
      code: "privacy",
      labelId: "settings.privacy",
      icon: () => (
        <PrivacyTipIcon
          style={{
            width: "18px",
            height: "18px",
            marginRight: isDesktop ? "8px" : "4px",
          }}
        />
      ),
      displayOnMobile: true,
    },
  ];

  const [selectedSection, setSelectedSection] = useState(
    sections.find((s) => s.code == query.get("selectedSection")) || sections[0]
  );
  const [completionCallback, setCompletionCallback] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const mobileMenuOpen = Boolean(anchorEl);
  const handleMobileMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = (section) => {
    setSelectedSection(sections.find((s) => s.code == section.code));
    setAnchorEl(null);
  };

  const onSectionCompletion = (section) => {
    setSelectedSection(sections.find((s) => s.code == section) || sections[0]);
    setCompletionCallback(() => onCompletionCallback);
  };

  const onCompletionCallback = () => {
    setSelectedSection(sections.find((s) => s.code == "resume_generator"));
    setCompletionCallback(null);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (!currentCandidate) {
      navigate({ pathname: `/${i18nLocale}/candidate/job_offers` });
    }
  }, [currentCandidate]);

  return (
    <Container maxWidth="lg">
      <Grid container style={{ marginTop: isDesktop ? "32px" : "8px" }}>
        <Grid item xs={12} style={{ marginBottom: "8px" }}>
          <Typography variant="h2">
            <FormattedMessage id="settings.title" />
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: isDesktop ? "32px" : "8px" }}>
          <Typography variant="body1bold">
            <FormattedMessage id="settings.subtitle" />
          </Typography>
        </Grid>
        {!isDesktop && (
          <Grid
            item
            xs={12}
            style={{ marginBottom: "16px", marginLeft: "-8px" }}
          >
            <Button
              id="basic-button"
              aria-controls={mobileMenuOpen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={mobileMenuOpen ? "true" : undefined}
              onClick={handleMobileMenuClick}
            >
              <Typography variant="body1bold">
                <FormattedMessage id={selectedSection.labelId} />
              </Typography>
              {mobileMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={mobileMenuOpen}
              onClose={() => handleMobileMenuClose(selectedSection)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {sections
                .filter((section) => section.displayOnMobile)
                .map((section) => {
                  return (
                    <MenuItem
                      key={`section-${section.code}`}
                      onClick={() => handleMobileMenuClose(section)}
                    >
                      {section.icon()}
                      <Typography variant="body1bold">
                        <FormattedMessage id={section.labelId} />
                      </Typography>
                    </MenuItem>
                  );
                })}
            </Menu>
          </Grid>
        )}
        <Grid item xs={12} component={Paper} style={{ marginBottom: "32px" }}>
          <Grid container style={{ height: "100%" }}>
            {isDesktop && (
              <Grid
                item
                md={3}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <Box
                  style={{
                    height: "100%",
                    flexGrow: 1,
                    padding: "32px 16px",
                  }}
                >
                  {sections.map((section) => {
                    return (
                      <MenuItem
                        key={`section-${section.code}`}
                        style={{
                          fontFamily:
                            section.code == selectedSection.code
                              ? "bold"
                              : "inherit",
                          textDecoration:
                            section.code == selectedSection.code
                              ? "underline"
                              : "none",
                          textUnderlineOffset: "4px",
                          color:
                            section.code == selectedSection.code
                              ? theme.palette.primary.main
                              : theme.palette.text.primary,
                          marginBottom: "8px",
                        }}
                        onClick={() =>
                          setSelectedSection(
                            sections.find((s) => s.code == section.code)
                          )
                        }
                      >
                        {section.icon()}
                        <Typography variant="body1bold">
                          <FormattedMessage id={section.labelId} />
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Box>
                <Divider orientation="vertical" />
              </Grid>
            )}
            <Grid item xs={12} md={9}>
              <Box
                style={{
                  padding: isDesktop ? "32px" : "16px",
                  height: "100%",
                }}
              >
                {selectedSection.code == "profile" && (
                  <Profile completionCallback={completionCallback} />
                )}
                {selectedSection.code == "skills" && (
                  <Skills completionCallback={completionCallback} />
                )}
                {selectedSection.code == "training" && (
                  <Training completionCallback={completionCallback} />
                )}
                {selectedSection.code == "experience" && (
                  <Experience completionCallback={completionCallback} />
                )}
                {selectedSection.code == "resume_generator" && (
                  <ResumeGenerator onSectionSelected={onSectionCompletion} />
                )}
                {selectedSection.code == "your_search" && <YourSearch />}
                {selectedSection.code == "notifications" && <Notifications />}
                {selectedSection.code == "password" && <Password />}
                {selectedSection.code == "privacy" && <Privacy />}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Settings;
