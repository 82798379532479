import React from "react";
import { Grid, Typography} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    marginLeft: "0px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "8px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px",
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    "&:first-child": {
      borderTop: "none"
    }
  },
  header: {
    flex: 1,
    textAlign: "left",
  },
  text: {
    flex: 1,
    textAlign: "right",
    fontFamily: "Work Sans Bold",
    fontWeight: "bold"
  }
}));

const MetricsGridMobile = ({
  titleOne,
  textOne,
  titleTwo,
  textTwo,
  titleThree,
  textThree,
  titleFour = null,
  textFour = null,
  number = 4,
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.row}>
        <Typography variant="subtitle2" className={classes.header}>
          <FormattedMessage id={titleOne} />
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {textOne}
        </Typography>
      </Grid>

      <Grid item xs={12} className={classes.row}>
        <Typography variant="subtitle2" className={classes.header}>
          <FormattedMessage id={titleTwo} />
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {textTwo}
        </Typography>
      </Grid>

      <Grid item xs={12} className={classes.row}>
        <Typography variant="subtitle2" className={classes.header}>
          <FormattedMessage id={titleThree} />
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {textThree}
        </Typography>
      </Grid>

      {number === 4 && (
        <Grid item xs={12} className={classes.row}>
          <Typography variant="subtitle2" className={classes.header}>
            <FormattedMessage id={titleFour} />
          </Typography>
          <Typography variant="body1" className={classes.text}>
            {textFour}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default MetricsGridMobile;
