import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Box, useMediaQuery, useTheme } from "@mui/material";
import Pagination from "@mui/lab/Pagination";
import NewCandidateCard from "./NewCandidateCard";

const useStyles = makeStyles(() => ({
  scrollArea: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

const CandidateList = ({
  candidates,
  activeCandidate,
  setPage,
  page,
  pageCount,
  CandidateCardProps,
  handleCandidateClick,
}) => {
  const onClickOnCandidate = (candidate) => {
    handleCandidateClick(candidate);
  };

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid
      container
      spacing={2}
      style={{ marginBottom: "16px", marginTop: "16px" }}
      id="candidate-list-container"
    >
      <Grid item xs={12}>
        <Grid
          {...(isDesktop && {
            style: {
              WebkitOverflow: "auto",
              overflow: "auto",
              height: candidates.length <= 6 ? "" : "130vh",
              paddingBottom: "32px",
              overflowX: "hidden",
            },
          })}
          container
          spacing={3}
          alignItems="stretch"
          justifyContent="center"
          className={classes.scrollArea}
        >
          {candidates?.length > 0 &&
            candidates.map((candidate, i) => (
              <Grid
                item
                xs={12}
                key={`job-${i}`}
                id={`job-card-${i}`}
                style={{
                  paddingTop:
                    i == 0
                      ? "0px"
                      : candidate.availability == "available"
                        ? "2px"
                        : "24px",
                  paddingBottom:
                    candidate.availability == "available" ? "24px" : "0px",
                }}
              >
                <NewCandidateCard
                  candidate={candidate}
                  active={
                    activeCandidate && activeCandidate.id === candidate.id
                  }
                  CandidateCardProps={CandidateCardProps}
                  onClickOnCandidate={onClickOnCandidate}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
      {setPage && (
        <Grid item xs={12}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            alignContent="center"
          >
            <Box mt={2}>
              <Pagination
                size="large"
                onChange={(e, page) => setPage(page)}
                count={pageCount}
                page={page}
                variant="outlined"
                color="secondary"
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CandidateList;
