import React, { useEffect, useState } from "react";
import OrderImg from "icons/orderImg.svg";
import {
  Container,
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  Link,
  Button,
  Paper,
  useMediaQuery,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useTheme, makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import sh from "bundles/common/utils/sh";
import CircleIcon from "@mui/icons-material/Circle";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    position: "relative",
    display: "inline-block",
    textDecoration: "underline",
  },
  triangle: {
    content: "\"\"",
    position: "absolute",
    top: "50%",
    left: "-12px",
    transform: "translateY(-50%) rotate(90deg)",
    width: 0,
    height: 0,
    borderLeft: "5px solid transparent",
    borderRight: "5px solid transparent",
    borderBottom: `5px solid ${theme.palette.secondary.main}`,
  },
}));

const OrderHistoryPage = () => {
  const intl = useIntl();
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const authenticity_token = useSelector((state) => state.authenticity_token);
  const theme = useTheme();
  const navigate = useNavigate();

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const classes = useStyles();

  const pricingStructure = {
    cv_access: intl.formatMessage({ id: "landing_recruiter.prices.license" }),
    job_offer_creation: intl.formatMessage({ id: "landing_recruiter.prices.license" }),
    basic_license: 149,
    business_license: 199,
    premium_license: 849,
  };

  const statusColor = {
    pending: theme.palette.text.disabled,
    available: theme.palette.text.green,
    activated: theme.palette.text.green,
    expired: theme.palette.text.secondary,
  };

  const fetchLastOrders = () => {
    setLoading(true);
    sh.get("last_orders", {
      authenticity_token,
    })
      .then((res) => {
        setOrders(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchLastOrders();
  }, []);

  const convertor_date = (order) => {
    let date;
    moment.locale(i18nLocale);
    date = moment(order).format("DD/MM/YYYY");
    moment.locale("en");
    return date;
  };

  function OrderH(props) {
    return (
      <Box
        component="img"
        id="order-history"
        alt="cards"
        width="100%"
        height="auto"
        {...props}
        src={OrderImg}
      />
    );
  }

  const findPrice = (productType) => pricingStructure[productType] || "-";

  return (
    <Container>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} >
          <Box my={4} style={{ display:"flex", justifyContent:"center", alignItems:"center" }}>,
            <Typography align="center" variant="h1" style={{ fontSize: isDesktop ? "48px" : "32px" }}>
              <b>
                <FormattedMessage id="order.order_history" />
              </b>
            </Typography>
          </Box>
          <Box mb={4} style={{ display: "flex", justifyContent: "center" }}>
            <Typography
              align="center"
              variant="body1"
              style={{ width: isDesktop ? "100%" : "280px" }}
            >
              <b>
                <FormattedMessage id="order.order_history_subtext" />
                <Link href="tel:+3223291100" color="secondary" size="small">
                  <FormattedMessage id="order.order_history_phone" />
                </Link>
              </b>
            </Typography>
          </Box>
        </Grid>
        {loading || orders.length > 0 ? (
          isDesktop ? (
            <Grid item xs={12}>
              <Card
                sx={{
                  width: "100%",
                  borderRadius: "30px",
                  marginBottom: "2rem",
                  overflow: "hidden",
                  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.15)",
                }}
              >
                <Box sx={{ backgroundColor: theme.palette.secondary.main, padding: 3, borderTopLeftRadius: "30px", borderTopRightRadius: "30px" }}>
                  <Grid container alignItems="center" justifyContent="space-between" style={{ marginLeft: "30px", marginRight: "50px" }}>
                    <Grid item xs={2} style={{ display: "flex" }}>
                      <Typography variant="h5" style={{ color: "white" }}>
                        <FormattedMessage id="order.product" />
                      </Typography>
                    </Grid>
                    <Grid item xs={3} style={{ display: "flex", justifyContent: "center" }}>
                      <Typography variant="h5" style={{ color: "white", marginRight:"90px" }}>
                        <FormattedMessage id="order.date" />
                      </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
                      <Typography variant="h5" style={{ color: "white", marginLeft:"30px" }}>
                        <FormattedMessage id="order.price" />
                      </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
                      <Typography variant="h5" style={{ color: "white" }}>
                        <FormattedMessage id="order.status" />
                      </Typography>
                    </Grid>
                    <Grid item xs={3} style={{ display: "flex", justifyContent: "center" }}>
                      <Typography variant="h5" style={{ color: "white", marginRight:"40px" }}>
                        <FormattedMessage id="order.action" />
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <CardContent style={{ padding:"unset" }}>
                  <Paper elevation={0} sx={{ width: "100%", overflow: "hidden", backgroundColor: "transparent" }}>
                    {loading && (
                      <Typography align="center">
                        <FormattedMessage id="loading" />
                      </Typography>
                    )}

                    {!loading && orders.length === 0 && (
                      <Typography align="center" variant="h6">
                        <FormattedMessage id="order.no_orders" />
                      </Typography>
                    )}
                    {orders.map((order, index) => (
                      <Grid container key={index}
                        sx={{
                          py: 2,
                          px: 2,
                          borderBottom: 1,
                          borderColor: "divider", "&:last-child": { borderBottom: 0 },
                          height:"85px",
                          backgroundColor: index % 2 === 1 ? "#e0e0e0" : "inherit",
                        }}
                      >
                        <Grid item xs={3} style={{ display:"flex", alignItems:"center" }}>
                          <Typography style={{ fontWeight:"bold" }}><FormattedMessage id={`order.${order.credit_type}`} /></Typography>
                        </Grid>
                        <Grid item xs={3} style={{ display:"flex", alignItems:"center" }}>
                          <Typography>{convertor_date(order.created_at)}</Typography>
                        </Grid>
                        <Grid item xs={2} style={{ display:"flex", alignItems:"center" }}>
                          <Typography>{findPrice(order.credit_type)}</Typography>
                        </Grid>
                        <Grid item xs={2} style={{ display:"flex", alignItems:"center" }}>
                          <CircleIcon sx={{ fontSize: 10, color: statusColor[order.status] }} />
                          <Typography sx={{ color: statusColor[order.status], marginLeft: "3px" }}><FormattedMessage id={`order.credits.${order.status}`} /></Typography>
                        </Grid>
                        <Grid item xs={2} style={{ display:"flex", alignItems:"center", paddingLeft: i18nLocale === "en" ? "10px" : "unset" }}>
                          {order.status !== "activated" && (
                            <Button
                              variant="rounded"
                              color="secondaryOutlined"
                              onClick={() => navigate(`/${i18nLocale}/recruiter/pricing`)}
                            >
                              <FormattedMessage id="order.order_button" />
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </Paper>
                </CardContent>
              </Card>
            </Grid>
          ) : (
            orders.map((order, index) => (
              <Grid item xs={12} key={index}>
                <Card
                  style={{
                    position: "relative",
                    display:"flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    borderRadius:"20px",
                    margin:"5px",
                  }}
                >
                  <CardContent style={{ paddingBottom: 12 }}>
                    <Box
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 1,
                        backgroundColor: statusColor[order.status],
                        padding: "5px 10px",
                        borderTopLeftRadius: "20px",
                        borderBottomRightRadius: "20px"
                      }}
                    >
                      <Typography variant="h4" style={{ color: "white", fontWeight:"bold" }}>
                        <FormattedMessage id={`order.credits.${order.status}`} />
                      </Typography>
                    </Box>
                    <Box style={{ display:"flex", justifyContent:"space-between", alignItems: "inline"}}>
                      <Typography variant="h3" fontWeight="bold" marginTop="15px" >
                        <FormattedMessage id={`order.${order.credit_type}`} />
                      </Typography>
                      <Typography variant="h4" fontWeight="bold" marginTop="12px">
                        <Typography>{findPrice(order.credit_type)}</Typography>
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h4" fontWeight="bold" marginTop="5px">
                        <Typography>{convertor_date(order.created_at)}</Typography>
                      </Typography>
                    </Box>
                    {order.status !== "activated" && (
                      <Box marginTop="3px" >
                        <Button
                          style={{ padding:"unset", height:"20px" }}
                          onClick={() => navigate(`/${i18nLocale}/recruiter/pricing`)}
                        >
                          <Typography variant="body1" className={classes.buttonContainer}>
                            <span className={classes.triangle}></span>
                            <FormattedMessage id="order.order_button" />
                          </Typography>
                        </Button>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))
          )
        ) : (
          <Grid item xs={12}>
            <Typography align="center" variant="h4" gutterBottom>
              <FormattedMessage id="order.order_not_yet" />
            </Typography>
            <Grid container display={"flex"} justifyContent="center">
              <Grid xs={3}>
                <Button
                  fullWidth
                  variant="rounded"
                  color="secondaryContained"
                  style={{
                    marginTop: "16px",
                  }}
                  onClick={() =>
                    navigate({
                      pathname: `/${i18nLocale}/recruiter/pricing`,
                    })
                  }
                >
                  <Typography
                    variant="subtitle2"
                    style={{
                      fontSize: "14px",
                      fontFamily: "Work Sans Bold",
                      fontWeight: "bold",
                      color: theme.palette.text.white,
                    }}
                  >
                    <FormattedMessage id="order.order_button_package" />
                  </Typography>
                </Button>
              </Grid>
            </Grid>

            <Box mt={12} display={"flex"}>
              <OrderH
                justifyContent="center"
                maxWidth={700}
                style={{
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  marginLeft: "25%",
                }}
              />
            </Box>
          </Grid>
        )}
        {loading || orders.length > 0 ? (
          <Grid
            container
            xs={12}
            style={{
              margin: "16px",
              marginTop: "32px",
              marginBottom: "4rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid item>
              <Button
                variant="rounded"
                color="secondaryOutlined"
                style={{ width: isDesktop ? "100%" : "300px", display: "flex", justifyContent: "center", }}
                onClick={() =>
                  navigate({
                    pathname: `/${i18nLocale}/recruiter/pricing`,
                  })
                }
              >
                <Typography fontWeight="bold" >
                  <FormattedMessage id="order.order_button_package" />
                </Typography>
              </Button>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </Container>
  );
};

export default OrderHistoryPage;
