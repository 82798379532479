import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Grid, Dialog, Modal } from "@mui/material";
import JobOfferIndex from "./JobOfferIndex";
import JobApplicationIndex from "./JobApplicationIndex";
import BackdropScreen from "bundles/common/components/BackdropScreen";
import OnboardingModal from "./OnboardingModal";
import AuthenticationModal from "./AuthenticationModal";
import { trackSignup } from "bundles/common/utils/marketing";

const JobOffers = () => {
  const currentCandidate = useSelector((state) => state.current_candidate);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const querySelectedTab = queryParams.get("selectedTab");
  const queryShowOnboarding = queryParams.get("showOnboarding");

  const [showOnboarding, setShowOnboarding] = useState(
    queryShowOnboarding === "true"
  );
  const [isLoginModalOpened, setIsLoginModalOpened] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    querySelectedTab || "find_a_job"
  );
  const [loading, setLoading] = useState(false);
  trackSignup(); // Triggers events tracking

  useEffect(() => {
    if (!currentCandidate)
      window.location.href = `${window.location.origin}/${i18nLocale}`;
  }, []);

  return (
    <>
      <BackdropScreen open={loading} />
      {selectedTab == "find_a_job" ? (
        <JobOfferIndex loading={loading} setLoading={setLoading} />
      ) : (
        selectedTab == "my_jobs" && (
          <Grid container spacing={4}>
            <JobApplicationIndex
              loading={loading}
              setLoading={setLoading}
              setSelectedTab={setSelectedTab}
            />
          </Grid>
        )
      )}
      <Dialog
        open={isLoginModalOpened}
        onClose={() => setIsLoginModalOpened(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AuthenticationModal
          isOpen={isLoginModalOpened}
          closeModal={() => setIsLoginModalOpened(false)}
        />
      </Dialog>
      <Modal
        open={showOnboarding}
        onClose={() => setShowOnboarding(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <OnboardingModal closeModal={() => setShowOnboarding(false)} />
      </Modal>
    </>
  );
};

export default JobOffers;
