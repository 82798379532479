import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/styles";
import {
  Container,
  Grid,
  Typography,
  Box,
  CircularProgress,
  Button,
  Modal,
  useMediaQuery,
} from "@mui/material";
import sh from "bundles/common/utils/sh";
import JobOfferList from "bundles/Candidate/components/JobOfferList";
import { trackSignup } from "bundles/common/utils/marketing";
import ChurchBar from "bundles/common/components/Desktop/ChurchBar";
import MobileChurchBar from "bundles/common/components/Mobile/MobileChurchBar";
import MetricsBar from "./MetricsBar";
import OnboardingModal from "./OnboardingModal";
import Poll from "bundles/common/components/Poll";
import SetPasswordModal from "bundles/common/components/SetPasswordModal";
import QuestionSnackbar from "bundles/common/components/QuestionSnackbar";
import JobOfferRecommendationBot from "bundles/Candidate/components/JobOfferRecommendationBot";

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Candidate = () => {
  const railsEnv = useSelector(state => state.railsEnv);
  if (railsEnv === "production") trackSignup();

  const currentCandidate = useSelector(state => state.current_candidate);
  const authenticityToken = useSelector(state => state.authenticity_token);
  const i18nLocale = useSelector(state => state.i18nLocale);
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const query = useQuery();

  const [activePoll, setActivePoll] = useState(null);
  const [shouldShowPoll, setShouldShowPoll] = useState(false);
  const [jobOffers, setJobOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [queryText, setQueryText] = useState("");
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [showOnboarding, setShowOnboarding] = useState(
    query.get("showOnboarding") === "true" || false
  );
  const [isSetPasswordModalOpened, setIsSetPasswordModalOpened] = useState(
    (currentCandidate?.password_generated &&
      currentCandidate?.signup_origin == "landbot") ||
      false
  );
  const [shouldShowQuestionSnackbar, setShouldShowQuestionSnackbar] = useState(
    false
  );

  const [activeQuestion, setActiveQuestion] = useState(
    sessionStorage.getItem("activeQuestion") == "true" || false
  );

  const handleCloseOnboarding = () => {
    setShowOnboarding(false);
    setShouldShowQuestionSnackbar(!activeQuestion);
  };

  const redirectToJobOfferIndex = () => {
    navigate({
      pathname: `/${i18nLocale}/candidate/job_offers`,
      state: { queryText: queryText, selectedRegions: selectedRegions },
    });
  };

  const handleOfferClick = offer => {
    window.location.href = `/${i18nLocale}/candidate/job_offers?jid=${offer.id}`;
  };

  const fetchLastJobs = () => {
    setLoading(true);
    sh.get("last_n_jobs", {
      params: {
        quantity: 6,
        authenticity_token: authenticityToken,
      },
    })
      .then(res => {
        setJobOffers(res.data?.data);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchActivePoll = () => {
    setLoading(true);
    sh.get("/active_poll", {
      params: {
        authenticity_token: authenticityToken,
      },
    })
      .then(res => {
        setActivePoll(res.data.active_poll);
        setTimeout(() => {
          setShouldShowPoll(!!res.data.active_poll);
          setShouldShowQuestionSnackbar(
            !showOnboarding && !res.data.active_poll && !activeQuestion
          );
        }, 1000);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    sessionStorage.setItem("activeQuestion", activeQuestion);
  }, [activeQuestion]);

  useEffect(() => {
    if (currentCandidate) {
      fetchLastJobs();
      fetchActivePoll();
    } else {
      navigate({ pathname: `/${i18nLocale}/candidate/job_offers` });
    }
  }, []);

  useEffect(() => {
    const handleUnload = () => {
      window.sessionStorage.clear();
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  return (
    <Container maxWidth="lg">
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Box my={4}>
            <Typography
              align="center"
              variant="h2"
              gutterBottom
              style={{
                fontFamily: "Work Sans",
                fontSize: isDesktop ? "28px" : "24px",
                color: theme.palette.text.blue,
              }}
            >
              <b>
                <FormattedMessage
                  id="candidate.welcome"
                  values={{ name: currentCandidate?.first_name }}
                />
              </b>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "16px" }}>
          <MetricsBar />
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            style={{ paddingBottom: "32px" }}
          >
            <Grid
              item
              xs={12}
              style={{ marginTop: "32px", marginBottom: "8px" }}
            >
              {isDesktop ? (
                <Box
                  style={{
                    width: "80%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <ChurchBar
                    queryText={queryText}
                    setQueryText={setQueryText}
                    fetchRecords={redirectToJobOfferIndex}
                    placeholder="landing_candidate.searched_jobs"
                    selectedRegions={selectedRegions}
                    setSelectedRegions={setSelectedRegions}
                  />
                </Box>
              ) : (
                <Box>
                  <MobileChurchBar
                    queryText={queryText}
                    setQueryText={setQueryText}
                    fetchRecords={redirectToJobOfferIndex}
                    placeholder="landing_candidate.searched_jobs"
                  />
                </Box>
              )}
            </Grid>

            <Grid item xs={12}>
              {loading ? (
                <Box align="center">
                  <CircularProgress size={24} color="secondary">
                    <FormattedMessage id="loading" />
                  </CircularProgress>
                </Box>
              ) : (
                <JobOfferList
                  offers={jobOffers}
                  handleOfferClick={handleOfferClick}
                  columnPerRow={isDesktop ? 2 : 1}
                />
              )}
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                variant="rounded"
                color="primaryContained"
                fullWidth
                onClick={() =>
                  navigate({
                    pathname: `/${i18nLocale}/candidate/job_offers`,
                  })
                }
              >
                <FormattedMessage id="see_more" />
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Modal
          open={showOnboarding}
          onClose={handleCloseOnboarding}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <OnboardingModal closeModal={() => setShowOnboarding(false)} />
        </Modal>

        <Modal
          open={isSetPasswordModalOpened}
          onClose={() => {}}
          aria-labelledby="set-password-modal-title"
          aria-describedby="set-password-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <SetPasswordModal
            closeModal={() => setIsSetPasswordModalOpened(false)}
          />
        </Modal>
      </Grid>
      {activePoll && shouldShowPoll && <Poll poll={activePoll} />}
      {shouldShowQuestionSnackbar && (
        <QuestionSnackbar setActiveQuestion={setActiveQuestion} />
      )}
      <JobOfferRecommendationBot />
    </Container>
  );
};

export default Candidate;
