import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import validator from "validator";
import { Grid, Button, useTheme, useMediaQuery } from "@mui/material";
import PasswordField from "bundles/common/components/PasswordField";
import sh from "bundles/common/utils/sh";
import { replaceCurrentCandidate } from "bundles/Candidate/constants/candidateConstants";

const PasswordEdit = ({ userType, onFinished = () => {} }) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const intl = useIntl();
  const dispatch = useDispatch();

  const authenticityToken = useSelector((state) => state.authenticity_token);
  const candidate = useSelector((state) => state.current_candidate) || {};
  const recruiter = useSelector((state) => state.current_recruiter) || {};

  const i18nLocale = useSelector((state) => state.i18nLocale);

  const initialFormValues = {
    currentPassword: "",
    password: "",
  };

  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );
    let not_a_strong_password = (
      <FormattedMessage id="form_validation.not_a_strong_password"></FormattedMessage>
    );

    if (
      "currentPassword" in fieldValues &&
      !candidate?.password_generated &&
      !recruiter?.password_generated
    ) {
      temp.currentPassword = fieldValues.currentPassword ? "" : required;
    }
    if ("password" in fieldValues) {
      temp.password = fieldValues.password ? "" : required;
      if (fieldValues.password) {
        if (!validator.isStrongPassword(fieldValues.password))
          temp.password = not_a_strong_password;
        else {
          temp.password = "";
        }
      }
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate(values)) {
      setLoading(true);
      sh.put(`/${i18nLocale}/${userType}/update_password`, {
        authenticity_token: authenticityToken,
        [userType]: {
          id: candidate?.id || recruiter?.id,
          current_password: values.currentPassword,
          password: values.password,
        },
      })
        .then(() => {
          if (candidate?.id) {
            replaceCurrentCandidate(dispatch, authenticityToken, () => {});
          }
          enqueueSnackbar(
            intl.formatMessage({
              id: "devise.password.update_password_success",
            }),
            { variant: "success" }
          );
          if (onFinished) {
            onFinished();
          }
        })
        .catch((err) => {
          //Devise returns a node "current password" when the current password is invalid
          if (err?.response?.data?.current_password) {
            enqueueSnackbar(
              intl.formatMessage({
                id: "devise.password.invalid_password",
              }),
              { variant: "error" }
            );
          } else {
            //example include: session time-out, clean cookies, ...
            enqueueSnackbar(
              intl.formatMessage({
                id: "flash.unknown",
              }),
              { variant: "error" }
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Grid
      container
      component="form"
      spacing={isDesktop ? 2 : 0}
      onSubmit={handleSubmit}
    >
      {!candidate?.password_generated && !recruiter?.password_generated ? (
        <>
          <Grid item xs={12} md={6}>
            <PasswordField
              name="currentPassword"
              labelId="devise.password.current_password"
              handleInputValue={handleInputValue}
              errors={errors}
              color={candidate ? "primary" : "secondary"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PasswordField
              name="password"
              labelId="devise.password.new_password"
              handleInputValue={handleInputValue}
              errors={errors}
              color={candidate ? "primary" : "secondary"}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <PasswordField
            name="password"
            labelId="devise.password.new_password"
            handleInputValue={handleInputValue}
            errors={errors}
            color={candidate ? "primary" : "secondary"}
          />
        </Grid>
      )}
      <Grid item xs={12} textAlign="center">
        <Button
          variant="rounded"
          color={candidate ? "primaryContained" : "secondaryContained"}
          messageId="save"
          disabled={loading}
          type="submit"
          style={{ width: "200px", marginTop: "16px" }}
        >
          <FormattedMessage id="save" />
        </Button>
      </Grid>
    </Grid>
  );
};

export default PasswordEdit;
