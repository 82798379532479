import React, { useState } from "react";
import {
  Typography, Button, Box, Modal,
  Table, TableHead, TableBody, TableRow, TableCell
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { FormattedMessage, useIntl } from "react-intl";
import Invitation from "./Invitation";
import sh from "bundles/common/utils/sh";
import ConfirmModal from "bundles/common/components/ConfirmModal";
import { replaceCurrentRecruiter } from "bundles/Recruiter/constants/recruiterConstants.js";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  addButton: {
    width: "240px",
  }
}));

const Team = () => {
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const intl = useIntl();

  const teamMembers = (currentRecruiter?.company?.recruiters || []).filter(member => member.id !== currentRecruiter.id);

  const acceptedMembers = teamMembers.filter(member =>
    member.invitation_accepted_at ||
    (!member.invitation_created_at && !member.invitation_sent_at) ||
    member.last_sign_in_at
  );

  const pendingInvitations = teamMembers.filter(member => member.invitation_sent_at && !member.invitation_accepted_at);

  const [isModalOpen, setModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [recruiterToRevoke, setRecruiterToRevoke] = useState(null);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleResend = (invite) => {
    sh.post("/recruiter/resend_invitation", {
      authenticity_token: authenticityToken,
      recruiter: invite
    })
      .then(() => {
        enqueueSnackbar(
          intl.formatMessage({ id: "settings.invitations.invitation_resent" }),
          {
            variant: "success",
          }
        );
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("Oops... Something went wrong", {
          variant: "error",
        });
      });
  };

  const handleRevoke = (invite) => {
    setRecruiterToRevoke(invite);
    setIsConfirmModalOpen(true);
  };

  const handleRevokeConfirmed = (invite) => {
    sh.get(`/${i18nLocale}/recruiters/invitation/remove?id=${invite.id}`)
      .then(() => {
        replaceCurrentRecruiter(dispatch, authenticityToken);
        enqueueSnackbar(
          intl.formatMessage({ id: "settings.invitations.invitation_revoked" }),
          {
            variant: "success",
          }
        );
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("Oops... Something went wrong", {
          variant: "error",
        });
      });
  };

  return (
    <Box>
      <Box className={classes.header}>
        <Typography variant="h4">
          <FormattedMessage id="settings.team" />
        </Typography>
        <Button
          variant="rounded"
          color="secondaryOutlined"
          size="medium"
          onClick={handleOpenModal}
          className={classes.addButton}
        >
          <FormattedMessage id="settings.invitations.add_team_member" />
        </Button>
      </Box>

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="settings.invitations.name" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={currentRecruiter.id}>
            <TableCell>
              {currentRecruiter.first_name} {currentRecruiter.last_name} (<FormattedMessage id="settings.invitations.you" />)
            </TableCell>
          </TableRow>

          {acceptedMembers.map((member) => (
            <TableRow key={member.id}>
              <TableCell>{member.first_name} {member.last_name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>


      {pendingInvitations.length > 0 && (
        <Typography variant="h5" style={{ marginTop: "20px" }}>
          <FormattedMessage id="settings.invitations.pending_invitations" />
        </Typography>
      )}

      <Table className={classes.table}>
        <TableBody>
          {pendingInvitations.map((invite) => (
            <TableRow key={invite.id}>
              <TableCell>{invite.first_name} {invite.last_name}</TableCell>
              <TableCell style={{ textAlign: "right" }}>
                <Button
                  variant="rounded"
                  color="primaryOutlined"
                  size="small"
                  onClick={() => handleResend(invite)}
                  style={{ marginRight: "16px" }}
                >
                  <FormattedMessage id="settings.invitations.resend" />
                </Button>
                <Button
                  variant="rounded"
                  color="dangerOutlined"
                  size="small"
                  onClick={() => handleRevoke(invite)}
                >
                  <FormattedMessage id="settings.invitations.revoke" />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Invitation Modal */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Invitation onClose={handleCloseModal} />
      </Modal>
      {/* Confirm Revoke Modal */}
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        handleConfirm={() => handleRevokeConfirmed(recruiterToRevoke)}
        labelId="settings.invitations.delete_invitation"
      />
    </Box>
  );
};

export default Team;
