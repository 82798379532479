import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import validator from "validator";
import {
  Box,
  Typography,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  IconButton,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import sh from "bundles/common/utils/sh";
import { replaceCurrentRecruiter } from "bundles/Recruiter/constants/recruiterConstants";
import ConfirmModal from "bundles/common/components/ConfirmModal";
import ProjectHeaderFields from "./ProjectHeaderFields";
import ProjectFormFields from "./ProjectFormFields";
import ProjectMediaFields from "./ProjectMediaFields";
import DeleteIcon from '@mui/icons-material/Delete';
import { RemoveScroll } from 'react-remove-scroll';

const steps = [
  <FormattedMessage id="project.title_and_description" />,
  <FormattedMessage id="project.details" />,
  <FormattedMessage id="project.cover_and_gallery" />
];

const EditProjectModal = ({ project, closeModal, isNewProject, setFirstPartAndNew, isNew }) => {
  const isEdit = isNewProject == false;
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  // const currentCompany = currentRecruiter.company;
  const authenticityToken = useSelector((state) => state.authenticity_token);

  const intl = useIntl();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const useStyles = makeStyles(() => ({
    modalContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: "white",
      borderRadius: "8px",
      padding: isDesktop ? "32px 64px 16px 64px" : "32px",
      minWidth: isDesktop ? "1200px" : "unset",
      width: isDesktop ? "1200px" : "calc(100% - 32px) !important",
      height: isDesktop ? "580px" : "fit-content",
    },
    modalBox: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    headerBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "32px",
      gap: "32px"
    },
    inputBox: {
      marginBottom: isDesktop ? "24px" : "16px",
    },
    inputLabel: {
      "&.Mui-focused": {
        color: theme.palette.secondary.main,
      },
    },
  }));
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const initialValues = {
    id: project?.id,
    title: project?.title || "",
    description: project?.description || "",
    location: project?.location || "",
    client_name: project?.client_name || "",
    status: project?.status || "",
    end_date: project?.end_date
  };

  const [values, setValues] = useState(initialValues);

  const handleDelete = (e) => {
    e.preventDefault();
    setIsConfirmModalOpen(true);
  };

  const handleDeleteConfirmed = (e) => {
    e.preventDefault();
    sh.delete(`projects/${project.id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        authenticity_token: authenticityToken,
        project
      },
    })
      .then(() => {
        replaceCurrentRecruiter(dispatch, authenticityToken, () => {
          closeModal();
          enqueueSnackbar(intl.formatMessage({ id: "settings.save_success" }), {
            variant: "success",
          });
        });
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(intl.formatMessage({ id: "settings.save_failed" }), {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <RemoveScroll>
        <Box className={classes.modalContainer}>
          <form className={classes.modalBox}>
            <Box className={classes.headerBox}>
              <Typography variant="h4">
                <FormattedMessage
                  id={isEdit ? "settings.edit_project" : "settings.add_project"}
                />
              </Typography>
              {isEdit && (
                <IconButton
                  aria-label="delete"
                  disabled={loading}
                  onClick={handleDelete}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Stepper activeStep={activeStep} sx={{ width: '80%' }}>
                {steps.map((label) => {
                  const stepProps = {};
                  return (
                    <Step
                      key={label}
                      {...stepProps}
                      sx={{
                        '& .MuiStepLabel-root .Mui-active': {
                          color: 'secondary.main',
                        },
                        '& .MuiStepLabel-root .Mui-completed': {
                          color: 'secondary.main',
                        },
                      }}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>
            <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
              {activeStep === 0 && (
                <ProjectHeaderFields
                  project={project}
                  handleNext={handleNext}
                  classes={classes}
                  currentRecruiter={currentRecruiter}
                  isNew={isNew}
                  setFirstPartAndNew={setFirstPartAndNew}
                />
              )}
              {activeStep === 1 && (
                <ProjectFormFields
                  project={project}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  classes={classes}
                  currentRecruiter={currentRecruiter}
                />
              )}
              {activeStep === 2 && (
                <ProjectMediaFields
                  project={project}
                  handleBack={handleBack}
                  closeModal={closeModal}
                />
              )}
            </Box>
          </form>

          <ConfirmModal
            isOpen={isConfirmModalOpen}
            setIsOpen={setIsConfirmModalOpen}
            handleConfirm={handleDeleteConfirmed}
            labelId="delete_project_confirmation"
          />
        </Box>
      </RemoveScroll>
    </>
  );
};

export default EditProjectModal;
