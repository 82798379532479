import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isToday } from "bundles/common/utils/utils";
import {
  Container,
  Grid,
  Box,
  Typography,
  Link,
  Tooltip,
  Select,
  MenuItem,
  useMediaQuery,
  Badge,
  Paper,
} from "@mui/material";
import Pagination from "@mui/lab/Pagination";
import { useTheme, makeStyles } from "@mui/styles";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import sh from "bundles/common/utils/sh";
import { getFormattedDate } from "bundles/common/utils/utils";
import CandidateDialog from "bundles/common/components/CandidateDialog";
import BackdropScreen from "bundles/common/components/BackdropScreen";
import CardSelect from "bundles/common/components/CardSelect";
import ProfilePicture from "bundles/common/components/ProfilePicture";
import NoData from "icons/noDataRecruiter.svg";
import { getDenialReasons } from "../../common/utils/utils";

const useStyles = makeStyles((theme) => ({
  applicantCard: {
    width: "100%",
    padding: "8px 16px",
    backgroundColor: theme.palette.common.white,
    marginBottom: "16px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
  },
}));

const ApplicantIndex = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const currentRecruiter = useSelector((state) => state.current_recruiter);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const querySelectedTab = queryParams.get("selectedTab");

  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const scopes = [
    { code: "all", label: intl.formatMessage({ id: "application.all" }) },
    {
      code: "pending",
      label: (
        <Box>
          <FormattedMessage id="application.pending" />
          <Badge
            badgeContent={currentRecruiter?.company?.new_applications_count}
            color="primary"
            style={{
              marginLeft:
                currentRecruiter?.company?.new_applications_count > 0
                  ? "16px"
                  : "unset",
            }}
          />
        </Box>
      ),
    },
    {
      code: "open",
      label: intl.formatMessage({ id: "application.open" }),
    },
    {
      code: "closed",
      label: intl.formatMessage({ id: "application.closed" }),
    },
  ];

  const companyJobOffers = [
    {
      id: "all",
      label: intl.formatMessage({ id: "application.all_job_offers" }),
    },
    {
      id: "spontaneous",
      label: intl.formatMessage({ id: "spontaneous_application" }),
    },
  ].concat(
    currentRecruiter.company.job_offers.map((offer) => {
      return { id: offer.id, label: offer.job_title };
    })
  );

  const [loading, setLoading] = useState(false);
  const [applicants, setApplicants] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [selectedApplicant, setSelectedApplicant] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedScope, setSelectedScope] = useState(
    querySelectedTab ? querySelectedTab : "all"
  );
  const [selectedJobOffer, setSelectedJobOffer] = useState("all");

  const fetchApplicants = (
    page = 1,
    status = selectedScope,
    jobOfferId = selectedJobOffer
  ) => {
    setLoading(true);
    sh.get("my_candidates", {
      authenticity_token: authenticityToken,
      params: {
        page,
        status,
        job_offer_id: jobOfferId,
      },
    })
      .then((res) => {
        setApplicants(res.data?.data);
        setPageCount(res.data?.pagy?.pages);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleApplicantClick = (applicant) => {
    setSelectedApplicant(applicant);
    setIsDialogOpen(true);
  };

  const handleScopeChange = (name, value) => {
    let newScope = scopes.find((scope) => scope.code == value)?.code;
    if (newScope) {
      setSelectedScope(newScope);
    }
  };

  const handleJobOfferSelection = (e) => {
    setSelectedJobOffer(e.target.value);
  };

  const handleOfferClicked = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    fetchApplicants(page, selectedScope);
  }, [selectedScope, selectedJobOffer, page]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    setSelectedScope(querySelectedTab || "all");
  }, [querySelectedTab]);

  return (
    <Container>
      <Grid container justifyContent="center" spacing={4}>
        <BackdropScreen open={loading} />
        <Grid item xs={12}>
          <Box my={4}>
            <Typography
              align="center"
              variant="h2"
              style={{ fontFamily: "Work Sans" }}
            >
              <b>
                <FormattedMessage id="candidate_search.my_candidates" />
              </b>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={8} style={{ overflow: "auto" }}>
              <CardSelect
                fieldName="scope"
                selected={selectedScope}
                values={scopes}
                onChange={handleScopeChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: isDesktop ? "0px" : "16px",
              }}
            >
              <Select
                id="job_offer_id"
                name="job_offer_id"
                value={selectedJobOffer}
                onChange={handleJobOfferSelection}
                color="secondary"
                style={{
                  backgroundColor: theme.palette.common.white,
                  width: isDesktop ? "unset" : "100%",
                }}
                MenuProps={{
                  style: { maxHeight: 300, position: "absolute" },
                  disableScrollLock: true,
                }}
              >
                {companyJobOffers.map((jobOffer) => (
                  <MenuItem value={jobOffer.id} key={jobOffer.id}>
                    {jobOffer.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {loading || applicants.length > 0 ? (
            <Box>
              {applicants.map((applicant) => {
                let hasCandidate = applicant?.candidate != null;

                if (hasCandidate) {
                  return (
                    <Grid
                      container
                      className={classes.applicantCard}
                      key={`applicant-${applicant.id}`}
                      component={Paper}
                      elevation={1}
                      onClick={() => handleApplicantClick(applicant)}
                    >
                      <Grid item xs={selectedJobOffer == "all" ? 2 : 3} md={1}>
                        <ProfilePicture
                          picture={applicant?.candidate?.profile_picture_url}
                          rounded
                          size={isDesktop ? 60 : 40}
                          initials={
                            applicant?.candidate?.first_name?.[0] +
                            (applicant?.candidate?.last_name?.[0] || "")
                          }
                          borderRadius="5%"
                          alt={`${applicant?.candidate?.first_name} ${applicant?.candidate?.last_name}`}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={selectedJobOffer == "all" ? 4 : 8}
                        md={selectedJobOffer == "all" ? 2 : 3}
                      >
                        <Typography variant={isDesktop ? "body1bold" : "body3bold"}>
                          {applicant?.candidate?.first_name + " " + applicant?.candidate?.last_name}
                        </Typography>
                      </Grid>
                      {isDesktop && (
                        <>
                          <Grid item md={2}>
                            {applicant?.candidate?.job_title ? (
                              <Typography variant="body1">
                                {applicant?.candidate?.job_title}
                              </Typography>
                            ) : (
                              <Typography
                                variant="body2"
                                style={{ color: theme.palette.common.white }}
                              >
                                <FormattedMessage id="candidate.unspecified_title" />
                              </Typography>
                            )}
                          </Grid>
                          <Grid item md={2}>
                            {applicant?.candidate?.candidate_status ? (
                              <Typography variant="body2">
                                <FormattedMessage
                                  id={`candidate.status_map.${applicant?.candidate?.candidate_status}`}
                                />
                              </Typography>
                            ) : (
                              <Typography
                                variant="body2"
                                style={{ color: theme.palette.common.white }}
                              >
                                <FormattedMessage id="candidate.unspecified_status" />
                              </Typography>
                            )}
                          </Grid>
                        </>
                      )}
                      {selectedJobOffer == "all" && (
                        <Grid xs={5} item md={2}>
                          <Link
                            onClick={handleOfferClicked}
                            href={`/${i18nLocale}/recruiter/job_offers?jid=${applicant?.job_offer_id}`}
                          >
                            <Typography
                              variant={isDesktop ? "body2" : "body3"}
                              style={{ color: theme.palette.primary.main }}
                            >
                              {applicant?.job_offer?.job_title}
                            </Typography>
                          </Link>
                        </Grid>
                      )}
                      {isDesktop && (
                        <Grid item md={selectedJobOffer == "all" ? 2 : 3}>
                          <Typography variant="body2">
                            {isToday(applicant?.created_at) ? (
                              <FormattedMessage id="application.applied_today" />
                            ) : (
                              <>
                                <FormattedMessage id="applicant.applied_on" />{" "}
                                {getFormattedDate(applicant?.created_at, false)}
                              </>
                            )}
                          </Typography>
                        </Grid>
                      )}
                      <Grid item xs={1} md={1}>
                        {applicant?.status == "pending" && (
                          <Typography
                            style={{
                              display: "flex",
                              color: theme.palette.text.warning,
                            }}
                          >
                            <Tooltip
                              title={
                                <FormattedMessage id="application.pending" />
                              }
                              style={{ cursor: "pointer" }}
                              aria-label="add"
                              placement="right"
                            >
                              <PendingActionsIcon />
                            </Tooltip>
                          </Typography>
                        )}
                        {applicant?.status == "open" && (
                          <Typography
                            style={{
                              display: "flex",
                              color: theme.palette.text.green,
                            }}
                          >
                            <Tooltip
                              title={<FormattedMessage id="application.open" />}
                              style={{ cursor: "pointer" }}
                              aria-label="add"
                              placement="right"
                            >
                              <CheckCircleOutlineIcon />
                            </Tooltip>
                          </Typography>
                        )}
                        {applicant?.status == "closed" && (
                          <Typography
                            style={{
                              display: "flex",
                              color: theme.palette.error.main,
                            }}
                          >
                            <Tooltip
                              title={getDenialReasons(
                                applicant.denial_reasons,
                                i18nLocale
                              )}
                              style={{ cursor: "pointer" }}
                              aria-label="add"
                              placement="right"
                            >
                              <HighlightOffIcon />
                            </Tooltip>
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  );
                }
              })}
            </Box>
          ) : (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={NoData}
                style={{
                  width: isDesktop ? "400px" : "180px",
                  marginTop: "32px",
                  marginBottom: "32px",
                }}
              />
              {selectedScope != "all" || selectedJobOffer != "all" ? (
                <Typography variant="h5" style={{ marginBottom: "32px" }}>
                  <FormattedMessage id="no_apps_with_filters" />
                </Typography>
              ) : (
                <Typography variant="h5" style={{ marginBottom: "32px" }}>
                  <FormattedMessage id="no_apps_without_filters" />
                </Typography>
              )}
            </Box>
          )}
        </Grid>
        {applicants.length > 0 && (
          <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              alignContent="center"
            >
              <Box style={{ marginBottom: "32px" }}>
                <Pagination
                  size="large"
                  onChange={(e, page) => setPage(page)}
                  count={pageCount}
                  page={page}
                  variant="outlined"
                  color="secondary"
                />
              </Box>
            </Grid>
          </Grid>
        )}
        {selectedApplicant.candidate && isDialogOpen && (
          <Grid item xs={12}>
            <CandidateDialog
              candidate={selectedApplicant.candidate}
              jobApplication={selectedApplicant}
              setJobApplication={setSelectedApplicant}
              setIndexStatus={setSelectedScope}
              isDialogOpen={isDialogOpen}
              setIsDialogOpen={setIsDialogOpen}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default ApplicantIndex;
