import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Grid,
  Typography,
  Box,
  Button,
  Paper,
  TextField,
  CircularProgress,
} from "@mui/material";
import { useTheme, makeStyles } from "@mui/styles";
import FileDropzone from "bundles/common/components/FileDropzone";
import Interview from "icons/interview.svg";

const SpontaneousApplicationDialogMobile = ({
  companyId,
  closeModal,
  jobApplication,
  handleSubmit,
  handleInputValue,
  handleNewCVDelete,
  setResume,
  setErrors,
  values,
  errors,
  loading,
  candidate,
  resume,
}) => {
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const currentCandidate = useSelector((state) => state.current_candidate);
  const theme = useTheme();

  const useStyles = makeStyles(() => ({
    modalContainer: {
      marginLeft: "16px",
      marginRight: "16px",
      width: "100%",
      height: "calc(100% - 32px)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    modalBox: {
      maxHeight: "100%",
      display: "flex",
    },
    applicationAction: {
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
      padding: "8px",
      maxHeight: "100%",
      overflow: "auto",
    },
  }));
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Box className={classes.modalContainer}>
      <Box className={classes.modalBox}>
        <Grid container component={Paper}>
          <Grid
            item
            md={12}
            sm={7}
            xs={12}
            className={classes.applicationAction}
          >
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography
                align={"center"}
                variant="h2"
                style={{ marginTop: "16px", fontSize: "20px" }}
              >
                <b>
                  <FormattedMessage
                    id={
                      jobApplication != null
                        ? "application.application_finalized"
                        : "application.finalize_your_application"
                    }
                  />
                </b>
              </Typography>
              <Box
                display={"flex"}
                justifyContent="center"
                m={1}
                style={{ marginTop: "32px" }}
              >
                <img
                  src={Interview}
                  alt="Message sent"
                  width="120px"
                  style={{ marginTop: "16px" }}
                />
              </Box>

              <Box
                style={{
                  padding: "0px 16px",
                  width: "100%",
                  marginBottom: "16px",
                }}
              >
                <Typography
                  align="left"
                  mt={2}
                  variant="h3"
                  gutterBottom
                >
                  <b>
                    <FormattedMessage id="application.cover_letter" />
                  </b>
                </Typography>
                <TextField
                  name="cover_letter"
                  required
                  variant="outlined"
                  value={values.cover_letter}
                  placeholder={intl.formatMessage({
                    id: "application.cover_letter",
                  })}
                  onBlur={handleInputValue}
                  onChange={handleInputValue}
                  fullWidth
                  multiline
                  rows={6}
                  {...(errors["cover_letter"] && {
                    error: true,
                    helperText: errors["cover_letter"],
                  })}
                />
              </Box>

              <Box style={{ padding: "0px 16px", width: "100%" }}>
                <Typography
                  align="left"
                  mt={2}
                  variant="h3"
                  gutterBottom
                >
                  <b>
                    <FormattedMessage id="application.resume" />
                  </b>
                </Typography>
                <FileDropzone
                  style={{ maxHeight: "20px" }}
                  handleFileInput={(file) => {
                    setResume(file);
                    setErrors({ ...errors, resume: "" });
                  }}
                  initialFiles={resume}
                  dropzoneTextId="devise.signup.drag_and_drop_zone"
                  deleteJustImportedFile={handleNewCVDelete}
                  fileName={`${candidate.first_name} ${candidate.last_name} - CV.pdf`}
                  type="candidate"
                />
                <Typography
                  align="left"
                  variant="body1"
                  style={{ color: theme.palette.error.main }}
                >
                  {errors?.resume}
                </Typography>
              </Box>

              <Grid
                container
                justifyContent="space-between"
                style={{ marginTop: "12px" }}
              >
                <Grid item xs={12} md={6} textAlign="center">
                  <Button
                    disabled={
                      currentRecruiter != null ||
                      jobApplication != null ||
                      loading ||
                      currentCandidate?.blacklisted_company_ids?.includes(
                        `${companyId}`
                      )
                    }
                    variant="rounded"
                    color="primaryContained"
                    style={{
                      minWidth: "100%",
                      marginTop: "32px",
                    }}
                    type="submit"
                    onClick={handleSubmit}
                  >
                    <Box justifyContent="center">
                      {loading && (
                        <CircularProgress size={16} color="secondary">
                          <FormattedMessage id="apply_to_job" />
                        </CircularProgress>
                      )}
                      {!loading && (
                        <FormattedMessage id="job_search.apply_to_job" />
                      )}
                    </Box>
                  </Button>
                </Grid>
                <Grid item xs={12} md={6} textAlign="center">
                  <Button
                    variant="rounded"
                    color="primaryOutlined"
                    style={{
                      minWidth: "100%",
                      marginTop: "12px",
                    }}
                    onClick={closeModal}
                  >
                    <Typography>
                      <FormattedMessage id="cancel" />
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SpontaneousApplicationDialogMobile;
