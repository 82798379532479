import React, { useState } from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MicrosoftIcon from "microsoft-icon.svg";
import GoogleIcon from "google-icon.svg";
import LinkedinIcon from "linkedin-icon.svg";
import FacebookIcon from "facebook-icon.svg";
import { FormattedMessage } from "react-intl";
import sh from "bundles/common/utils/sh";

const providerIcons = {
  google: GoogleIcon,
  microsoft: MicrosoftIcon,
  linkedin: LinkedinIcon,
  facebook: FacebookIcon,
};

const OauthButton = ({ provider, scope, redirectTo = null }) => {
  const isSignup = scope.includes("signup");
  const labelId = isSignup ? `${provider}_signup` : `${provider}_login`;

  const [loading, setLoading] = useState(false);

  const useStyles = makeStyles(() => ({
    button: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      columnGap: "8px",
    },
  }));
  const classes = useStyles();

  const handleClick = () => {
    setLoading(true);
    try {
      let path = `auth/${provider}?scope=${scope}`;
      if (redirectTo) path += `&redirect_to=${redirectTo}`;

      sh.get(path, {}).then((res) => {
        if (res.data?.redirect_uri) {
          console.log(res.data?.redirect_uri);
          window.location.replace(res.data.redirect_uri);
        } else {
          setLoading(false);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Button
      type="button"
      className={classes.button}
      variant="rounded"
      color="secondaryOutlined"
      size="medium"
      startIcon={
        <img
          src={providerIcons[provider]}
          style={{ height: "22px", width: "22px" }}
        />
      }
      disabled={loading}
      onClick={handleClick}
    >
      <FormattedMessage id={labelId} />
    </Button>
  );
};

export default OauthButton;
