import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  Autocomplete,
  TextField,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { makeStyles, useTheme } from "@mui/styles";
import translations from "app/libs/i18n/translations.json";
import SelectRegion from "bundles/common/components/SelectRegion";

const FilteringModal = ({
  sections,
  selectedSection,
  selectedFilters,
  setSelectedFilters,
  onlyFavorites,
  setOnlyFavorites,
  closeModal,
}) => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const theme = useTheme();
  const intl = useIntl();
  const useStyles = makeStyles(() => ({
    modalContainer: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white",
      width: "600px",
      maxHeight: "90%",
      overflow: "auto",
    },
    sectionBox: {
      display: "flex",
      columnGap: "8px",
    },
    filtersCount: {
      backgroundColor: theme.palette.secondary.main,
      height: "24px",
      width: "24px",
      borderRadius: "50%",
      color: theme.palette.text.white,
      display: "flex",
      justifyContent: "center",
    },
    filterButtonBox: {
      display: "flex",
      columnGap: "8px",
    },
    filterButton: {
      padding: "4px 8px",
      border: `2px solid ${theme.palette.common.sh_grey}`,
      borderRadius: "30px",
    },
    filterButtonSelected: {
      border: `2px solid ${theme.palette.secondary.main}`,
    },
    footer: {
      padding: "8px 16px",
      display: "flex",
      justifyContent: "space-between",
    },
  }));

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  const classes = useStyles();

  const [expanded, setExpanded] = useState(selectedSection);

  const handleExpansion = (code) => {
    if (expanded == code) {
      setExpanded(null);
    } else {
      setExpanded(code);
    }
  };

  const handleSelection = (filterSectionCode, filter) => {
    let newSelectedFilters = selectedFilters[filterSectionCode];
    if (newSelectedFilters.includes(filter)) {
      newSelectedFilters = newSelectedFilters.filter((elem) => elem != filter);
    } else {
      newSelectedFilters.push(filter);
    }

    let newSelectedSections = {};
    sections.forEach((section) => {
      if (section.code == filterSectionCode) {
        newSelectedSections[filterSectionCode] = newSelectedFilters;
      } else {
        newSelectedSections[section.code] = selectedFilters[section.code];
      }
    });

    setSelectedFilters(newSelectedSections);
  };

  const isFilterSelected = (filterSectionCode, filter) => {
    return selectedFilters[filterSectionCode].includes(filter);
  };

  const handleSelect = (filterSectionCode, newValue) => {
    let newSelectedFilters = selectedFilters[filterSectionCode];
    newSelectedFilters = newValue.map((v) => v.code || v);

    let newSelectedSections = {};
    sections.forEach((section) => {
      if (section.code == filterSectionCode) {
        newSelectedSections[filterSectionCode] = newSelectedFilters;
      } else {
        newSelectedSections[section.code] = selectedFilters[section.code];
      }
    });

    setSelectedFilters(newSelectedSections);
  };

  const handleRegions = (values) => {
    let newRegionFilters = selectedFilters["region"];
    newRegionFilters = values;

    let newSelectedSections = {};
    sections.forEach((section) => {
      if (section.code == "region") {
        newSelectedSections["region"] = newRegionFilters;
      } else {
        newSelectedSections[section.code] = selectedFilters[section.code];
      }
    });

    setSelectedFilters(newSelectedSections);
  };

  return (
    <Box className={classes.modalContainer}>
      {sections.map((section) => (
        <Accordion
          expanded={expanded === section.code}
          onChange={() => handleExpansion(section.code)}
          key={`modal-section-${section.code}`}
        >
          <AccordionSummary>
            <Box className={classes.sectionBox}>
              <Box>
                <Typography>
                  <FormattedMessage id={section.label} />
                </Typography>
              </Box>
              {selectedFilters[section.code]?.length > 0 && (
                <Box className={classes.filtersCount}>
                  {selectedFilters[section.code]?.length}
                </Box>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails
            style={{
              padding: section.display == "select_region" ? "4px" : "16px",
            }}
          >
            {section.display == "box" && (
              <Box className={classes.filterButtonBox}>
                {section.values.map((value) => (
                  <Button
                    key={`filter-modal-button-${value.code}`}
                    className={`${classes.filterButton} ${
                      isFilterSelected(section.code, value.code)
                        ? classes.filterButtonSelected
                        : ""
                    }`}
                    onClick={() => handleSelection(section.code, value.code)}
                  >
                    <FormattedMessage id={value.label} />
                  </Button>
                ))}
              </Box>
            )}
            {section.display == "skill_select" && (
              <Box>
                <Autocomplete
                  multiple
                  disableCloseOnSelect={true}
                  options={section.values}
                  getOptionLabel={(option) =>
                    option.label ||
                    translations[i18nLocale][
                      `skills_list.${section.code.split("_")[0]}.${option}`
                    ]
                  }
                  value={selectedFilters[section.code]}
                  name="name"
                  onChange={(e, newValue) =>
                    handleSelect(section.code, newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={intl.formatMessage({ id: "autocomplete" })}
                      variant="outlined"
                      color="secondary"
                      name="name"
                    />
                  )}
                />
              </Box>
            )}
            {section.display == "select_region" && (
              <SelectRegion
                required
                multiple
                labelId="landing_recruiter.searched_location"
                value={selectedFilters[section.code]}
                handleInputValue={(values) => handleRegions(values)}
                displayEmpty={true}
                list={true}
                renderValue={(selected) => {
                  return selected.length > 0 ? (
                    selected
                      ?.map((value) =>
                        intl.formatMessage({ id: `regions.${value}` })
                      )
                      ?.join(", ")
                  ) : (
                    <Typography style={{ color: "#C7C7CD" }}>
                      <FormattedMessage
                        id={"landing_recruiter.searched_location"}
                      />
                    </Typography>
                  );
                }}
              />
            )}
          </AccordionDetails>
        </Accordion>
      ))}
      <Box className={classes.footer}>
        <Box>
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                checked={onlyFavorites}
                onClick={() => setOnlyFavorites(!onlyFavorites)}
              />
            }
            label={
              <Typography variant="body1bold" className={classes.filterTitle}>
                <b>
                  <FormattedMessage id="candidate_search.saved" />
                </b>
              </Typography>
            }
          />
        </Box>
        <Box>
          <Button
            variant="rounded"
            color="secondaryContained"
            onClick={closeModal}
          >
            <FormattedMessage id="show_results" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FilteringModal;
