import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  useMediaQuery,
  TextField,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  Grid,
  InputLabel,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { useSnackbar } from "notistack";
import sh from "bundles/common/utils/sh";
import { replaceCurrentRecruiter } from "bundles/Recruiter/constants/recruiterConstants";
import ConfirmModal from "bundles/common/components/ConfirmModal";

const EditCustomSkillsModal = ({ skill, closeModal }) => {
  const isEdit = skill != null;
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const intl = useIntl();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const useStyles = makeStyles(() => ({
    modalContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: "white",
      borderRadius: "8px",
      padding: isDesktop ? "32px 64px" : "32px",
      minWidth: isDesktop ? "600px" : "unset",
      width: isDesktop ? "unset" : "calc(100% - 32px) !important",
      height: isDesktop ? "unset" : "fit-content",
    },
    headerBox: {
      textAlign: "center",
      marginBottom: "32px",
    },
    inputBox: {
      marginBottom: isDesktop ? "24px" : "16px",
    },
  }));
  const classes = useStyles();

  const initialValues = {
    id: skill?.id,
    name: skill?.name || "",
    category: skill?.category || "hard",
  };

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );

    if ("name" in fieldValues) {
      temp.name = fieldValues.name ? "" : required;
    }

    if ("category" in fieldValues) {
      temp.category = fieldValues.category ? "" : required;
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    let newValues = { ...values, [name]: value };
    setValues(newValues);
    validate({ [name]: value });
  };

  const handleSuccessfulSave = () => {
    replaceCurrentRecruiter(dispatch, authenticityToken, () => {
      closeModal();
      enqueueSnackbar(intl.formatMessage({ id: "settings.save_success" }), {
        variant: "success",
      });
    });
  };

  const handleFailedSave = (err) => {
    console.error(err);
    enqueueSnackbar(intl.formatMessage({ id: "settings.save_failed" }), {
      variant: "error",
    });
  };

  const handleFailedDelete = (err) => {
    console.error(err);
    enqueueSnackbar(intl.formatMessage({ id: "settings.delete_failed" }), {
      variant: "error",
    });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    if (validate(values)) {
      let skill = {
        recruiter_id: currentRecruiter.id,
        id: values.id,
        name: values.name,
        category: values.category,
      };

      if (values.id) {
        sh.put(`skill/${skill.id}`, {
          authenticity_token: authenticityToken,
          skill,
        })
          .then(handleSuccessfulSave)
          .catch(handleFailedSave)
          .finally(() => {
            setLoading(false);
          });
      } else {
        sh.post("skill", {
          authenticity_token: authenticityToken,
          skill,
        })
          .then(handleSuccessfulSave)
          .catch(handleFailedSave)
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setLoading(false);
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setIsConfirmModalOpen(true);
  };

  const handleDeleteConfirmed = (e) => {
    e.preventDefault();

    sh.delete(`skill/${skill.id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        authenticity_token: authenticityToken,
        skill: skill,
      },
    })
      .then(handleSuccessfulSave)
      .catch(handleFailedDelete)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box className={classes.modalContainer}>
      <form>
        <Box className={classes.headerBox}>
          <Typography variant="h4">
            <FormattedMessage
              id={
                isEdit
                  ? "settings.custom_skills_edit"
                  : "settings.custom_skills_add"
              }
            />
          </Typography>
        </Box>
        <Box className={classes.inputBox}>
          <TextField
            size="small"
            fullWidth
            required
            InputLabelProps={{ required: true }}
            type="text"
            value={values.name}
            name="name"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            variant="outlined"
            label={<FormattedMessage id="settings.custom_skills_name" />}
            {...(errors["name"] && {
              error: true,
              helperText: errors["name"],
            })}
          />
        </Box>
        <Box className={classes.inputBox}>
          <FormControl fullWidth variant="outlined" size="small" required>
            <InputLabel id="category-select-label">
              <FormattedMessage id="settings.custom_skills_category" />
            </InputLabel>
            <Select
              value={values.category || "hard"}
              disabled={true}
              onChange={handleInputValue}
              onBlur={handleInputValue}
              {...(errors["category"] && {
                error: true,
                helperText: errors["category"],
              })}
              labelId="test-select-label"
              label={<FormattedMessage id="settings.custom_skills_category" />}
              name="category"
            >
              <MenuItem key={1} value="hard">
                <FormattedMessage id="candidate.profile.skills.hard" />
              </MenuItem>
              <MenuItem key={2} value="soft">
                <FormattedMessage id="candidate.profile.skills.soft" />
              </MenuItem>
              <MenuItem key={3} value="lang">
                <FormattedMessage id="candidate.profile.skills.lang" />
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Grid
          container
          spacing={isDesktop ? 4 : 2}
          className={classes.inputBox}
        >
          <Grid item xs={12} md={6}>
            <Button
              type="submit"
              fullWidth
              variant="rounded"
              color="primaryContained"
              disabled={loading}
              loading={loading}
              onClick={handleSubmit}
            >
              <FormattedMessage id="save" />
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            {isEdit ? (
              <Button
                fullWidth
                variant="rounded"
                color="dangerOutlined"
                disabled={loading}
                loading={loading}
                onClick={handleDelete}
              >
                <FormattedMessage id="delete" />
              </Button>
            ) : (
              <Button
                fullWidth
                variant="rounded"
                color="secondaryOutlined"
                onClick={closeModal}
              >
                <FormattedMessage id="cancel" />
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        handleConfirm={handleDeleteConfirmed}
        labelId="settings.delete_skill_confirmation"
      />
    </Box>
  );
};

export default EditCustomSkillsModal;
