import React, { useState, useCallback, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, TextField, Typography } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import _debounce from "lodash/debounce";
import { useSelector } from "react-redux";

const SmartChurchBar = ({
  queryText,
  setQueryText,
  fetchRecords = () => {},
  selectedFilters,
  setSelectedFilters,
  sections,
  onlyFavorites,
  setOnlyFavorites,
  placeholder,
}) => {
  const candidate = useSelector((state) => state.current_candidate);
  const isCandidate = candidate?.id != null;
  const intl = useIntl();
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      minHeight: "60px",
      backgroundColor: theme.palette.common.white,
      boxShadow: "0px 1px 1px 0px #00000040",
      alignItems: "center",
      borderRadius: "30px",
    },
    queryInput: { height: "100%", width: "100%" },
    inputFilterBox: {
      border: `2px solid ${
        isCandidate ? theme.palette.primary.main : theme.palette.secondary.main
      }`,
      borderRadius: "16px",
      backgroundColor: isCandidate
        ? theme.palette.primary.main
        : theme.palette.secondary.main,
      color: theme.palette.common.white,
      height: "24px",
      width: "max-content",
      padding: "4px 8px",
      display: "flex",
      alignItems: "center",
      columnGap: "4px",
    },
    removeFilterButton: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },
  }));
  const classes = useStyles();

  const [unbouncedQueryText, setUnbouncedQueryText] = useState(queryText);

  const handleQueryChange = (e) => {
    setUnbouncedQueryText(e.target.value);
    debouncedQueryText(e.target.value);
  };

  const debouncedQueryText = useCallback(_debounce(setQueryText, 500), []);

  const [filterBoxes, setFilterBoxes] = useState([]);

  const removeFilter = (filterSectionCode, filter) => {
    if (filterSectionCode === "onlyFavorites") {
      setOnlyFavorites(false);
    } else {
      let newSelectedFilters = selectedFilters[filterSectionCode].filter(
        (elem) => elem !== filter
      );
      setSelectedFilters({
        ...selectedFilters,
        [filterSectionCode]: newSelectedFilters,
      });
    }
  };

  const handleBackspace = () => {
    if (filterBoxes.length > 0) {
      let lastFilter = filterBoxes[filterBoxes.length - 1];
      removeFilter(lastFilter.section, lastFilter.filter);
    }
  };

  const focusTextfield = (e) => {
    if (e.target.id == "input-box-id") {
      const textfield = document.getElementById("input-textfield-id");
      textfield.focus();
    }
  };

  useEffect(() => {
    let newFilterBoxes = [];
    Object.entries(selectedFilters).forEach(([sectionCode, filters]) => {
      const section = sections.find(s => s.code === sectionCode);
      if (section) {
        filters.forEach((filter) => {
          const foundFilter = section.values.find(f => f.code === filter);
          if (foundFilter) {
            newFilterBoxes.push({
              section: sectionCode,
              filter,
              labelId: foundFilter.label,
            });
          }
        });
      }
    });

    if (onlyFavorites) {
      newFilterBoxes.push({
        section: "onlyFavorites",
        filter: true,
        labelId: "candidate_search.saved",
      });
    }

    setFilterBoxes(newFilterBoxes);
  }, [selectedFilters, onlyFavorites, sections]);

  let filterBoxWidth =
    document.getElementById("filters-box-id")?.getBoundingClientRect()?.width ||
    0;
  let textfieldWidth = 840 - filterBoxWidth < 300 ? 840 : 840 - filterBoxWidth;

  return (
    <Box display="flex" className={classes.container}>
      <Box
        id="input-box-id"
        style={{
          padding: "16px",
          width: "99%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          columnGap: "8px",
          rowGap: "16px",
          flexWrap: "wrap",
        }}
        onClick={focusTextfield}
      >
        <Box
          id="filters-box-id"
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "8px",
            rowGap: "16px",
            flexWrap: "wrap",
          }}
        >
          {filterBoxes.length > 0 ? (
            filterBoxes.map(({ section, filter, labelId }) => (
              <Box
                key={`input-filter-box-${filter}`}
                className={classes.inputFilterBox}
              >
                <Typography variant="body3">
                  <FormattedMessage id={labelId} />
                </Typography>
                <Box
                  className={classes.removeFilterButton}
                  onClick={() => removeFilter(section, filter)}
                >
                  <CloseIcon style={{ width: "16px", height: "16px" }} />
                </Box>
              </Box>
            ))
          ) : (
            <SearchOutlined />
          )}
        </Box>
        <Box
          style={{
            minWidth: `${textfieldWidth}px`,
          }}
        >
          <TextField
            style={{
              height: "100%",
              width: "100%",
            }}
            id="input-textfield-id"
            label=""
            value={unbouncedQueryText}
            placeholder={intl.formatMessage({ id: placeholder })}
            onChange={handleQueryChange}
            InputProps={{
              disableUnderline: true,
            }}
            inputProps={{
              className: classes.queryInput,
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                fetchRecords();
              }
              if (event.key === "Backspace" && (!queryText || queryText === "")) {
                handleBackspace();
              }
            }}
            variant="standard"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SmartChurchBar;
