import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";
import {
  Box,
  Card,
  Container,
  Link,
  Typography,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import HTML from "bundles/common/components/HTML";
import Logo from "icons/logo.svg";
import moment from "moment";
import NotFound from "icons/notFound.svg";
import { handleLinkedinShare, getFirstPathParam } from "bundles/common/utils/utils";
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";
import FacebookIcon from "facebook-blue-background.svg";
import LinkedinIcon from "linkedin-blue-background.svg";
import MailIcon from "mail-blue-background.svg";
import WhatsappIcon from "whatsapp-blue-background.svg";

const BlogViewMobile = ({
  type = "candidate",
  article,
  squarehubId,
  publicArticleUrl,
  notFound,
}) => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const theme = useTheme();
  const useStyles = makeStyles(() => ({
    card: {
      padding: "8px 16px",
      marginTop: "32px",
      marginBottom: "32px",
      boxShadow: "none !important",
      borderRadius: "16px !important"
    },
    logo: { width: "60px", objectFit: "contain" },
    coverImage: {
      width: "100%",
      height: "142px",
      objectFit: "cover",
      borderRadius: "8px",
      marginTop: "32px",
      marginBottom: "8px",
    },
    logoAndSocialMedia: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "36px",
    },
    socialMediaBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "8px",
      margin: "24px 0"
    },
    blogContent: {
      color: theme.palette.secondary.main,
      "& h2": {
        fontSize: "24px",
        color: theme.palette.secondary.main,
      },
    },
    notFoundBox: {
      display: "flex",
      alignItems: "center",
      padding: "32px",
      width: "100px"
    },
  }));
  const classes = useStyles();

  return (
    <>
      <Helmet>
        {article?.id && (
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "NewsArticle",
              headline: article.title,
              image: [article.logo_url || `${window.location.origin}${Logo}`],
              datePublished: article.created_at,
              author: (typeof article?.authors == "string"
                ? JSON.parse(article?.authors)
                : article.authors
              ).map((author) => {
                return {
                  "@type": author.type,
                  name: author.name,
                  url: author.url,
                };
              }),
            })}
          </script>
        )}
      </Helmet>
      <Container maxWidth="md">
        <Link href={`/${i18nLocale}/${getFirstPathParam()}/posts`} style={{ textDecoration: "none", display: "block", marginTop: "20px" }}>
          <Typography
            color="secondary"
            style={{
              textAlign: "left",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            ← <FormattedMessage id="blog.tips_and_actualities" />
          </Typography>
        </Link>
        <Card className={classes.card}>
          {article?.id && (
            <>
              <Typography
                variant="h1"
                color="secondary"
                style={{
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "bold",
                  marginTop: "16px"
                }}
              >
                {article.title}
              </Typography>
              <img
                src={article.cover_picture_url || NotFound}
                alt={article.title}
                className={classes.coverImage}
              />
              <Box style={{ textAlign: "left", display: "flex", alignItems: "center" }}>
                <Typography variant="body2" color="primary" style={{ cursor: "pointer", marginRight: "16px" }}>
                  <Link
                    href={`/companies/squarehub/${squarehubId}`}
                    sx={{
                      color: "inherit",
                      textDecoration: "inherit",
                      ":hover": {
                        color: "#035d2a"
                      }
                    }}
                  >
                    Squarehub
                  </Link>
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  <FormattedMessage id="blog.updated_at" /> {moment(article.updated_at).format("DD.MM.YY")}
                </Typography>
              </Box>
              <Box className={classes.blogContent}>
                <HTML html={article.content} h2={true} />
              </Box>
              <Box className={classes.socialMediaBox}>
                <EmailShareButton
                  subject={article.title}
                  body={"Check out this article on Squarehub:"}
                  url={publicArticleUrl}
                >
                  <img src={MailIcon} alt="Mail Icon" />
                </EmailShareButton>
                <FacebookShareButton url={publicArticleUrl}>
                  <img src={FacebookIcon} alt="Facebook Icon" />
                </FacebookShareButton>
                <Box onClick={() => handleLinkedinShare(publicArticleUrl)} style={{ cursor: "pointer" }}>
                  <img src={LinkedinIcon} alt="Linkedin Icon" />
                </Box>
                <WhatsappShareButton url={publicArticleUrl}>
                  <img src={WhatsappIcon} alt="Whatsapp Icon" />
                </WhatsappShareButton>
              </Box>
            </>
          )}
          {notFound && (
            <Box className={classes.notFoundBox}>
              <img src={NotFound} alt="Not Found" />
            </Box>
          )}
        </Card>
      </Container>
    </>
  );
};

export default BlogViewMobile;
