import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "transparent !important",
    color: "white",
  },
}));

const BackdropScreen = ({ open }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Backdrop open={open} className={classes.backdrop}>
      <CircularProgress style={{ color: theme.palette.common.sh_grey }} />
    </Backdrop>
  );
};

export default BackdropScreen;
