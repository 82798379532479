import React from "react";
import { FormattedMessage } from "react-intl";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import PasswordEdit from "bundles/common/components/PasswordEdit";

const Password = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Grid container>
      {isDesktop && (
        <Grid item xs={12} style={{ marginBottom: "16px" }}>
          <Typography variant="h4">
            <FormattedMessage id="settings.change_password" />
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <PasswordEdit userType="candidate" />
      </Grid>
    </Grid>
  );
};

export default Password;
