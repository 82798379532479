import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles, useTheme, withStyles } from "@mui/styles";
import {
  Toolbar,
  AppBar,
  Drawer,
  List,
  ListItem,
  Button,
  IconButton,
  Box,
  Hidden,
  Divider,
  Collapse,
  Badge,
  useMediaQuery,
  Link as MuiLink,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DateOutlined from "icons/DateOutlined.svg";
import LogInFigma from "icons/LogInFigma.svg";
import PersonOutlinedFigma from "icons/Vector.svg";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import DividerFigma from "icons/Divider.svg";
import SettingsIcon from "@mui/icons-material/Settings";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
// import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  usePopupState,
  bindHover,
  bindMenu,
} from "material-ui-popup-state/hooks";
import HoverMenu from "material-ui-popup-state/HoverMenu";
import Logo from "icons/logo.svg";
import LogoWhite from "icons/logoWhite.svg";
import SelectLanguage from "./SelectLanguage";
import { FormattedMessage } from "react-intl";
import sh from "bundles/common/utils/sh";
import ProfilePicture from "../common/components/ProfilePicture";
import { ExpandMore } from "@mui/icons-material";
import {
  desktopAuthenticatedSubmenus,
  mobileAuthenticatedSubmenus,
  mobileUnauthMenus,
} from "bundles/common/utils/recruiters/navbar";
import { debounce } from "lodash";

export default function MainAppBarRecruiter({ children }) {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const authenticity_token = useSelector((state) => state.authenticity_token);
  const theme = useTheme();
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 0,
    },
    logo: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: theme.palette.text.blue,
      textTransform: "none",
    },
    drawer: {
      width: "240px !important",
      borderTopRightRadius: "30px",
      borderBottomRightRadius: "30px",
    },
    drawerHeader: {
      height: "100px",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    rightHeader: {
      marginRight: "16px",
    },
    headerTitle: {
      fontWeight: 600,
    },
    drawerItem: {
      justifyContent: "left",
      columnGap: "4px",
    },
    divider: {
      margin: "8px 16px !important",
      fontWeight: "bolder !important",
    },
    candidateButton: {
      fontWeight: "bolder",
      marginLeft: "16px !important",
    },
  }));
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [submenus, setSubmenus] = useState(
    matches ? desktopAuthenticatedSubmenus : mobileAuthenticatedSubmenus
  );
  const [submenusUpdateCounter, setSubmenusUpdateCounter] = useState(0);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsDrawerOpen(open);
  };

  const handleSubmenuClicked = (submenu) => {
    let tempSubmenus = submenus;
    let tempSubmenu = tempSubmenus.find((s) => s.code == submenu.code);
    tempSubmenus.forEach((s) => {
      if (s.code != submenu.code) s.isOpen = false;
    });
    tempSubmenu.isOpen = !tempSubmenu.isOpen;
    setSubmenus(tempSubmenus);
    setSubmenusUpdateCounter(submenusUpdateCounter + 1);
  };

  const handleRedirectClick = (link, popupState = null) => {
    if (link.logout) return logout();

    if (matches && popupState) {
      popupState.close();
    } else {
      setIsDrawerOpen(false);
    }

    let redirectUrl = `/${i18nLocale}/${link.target}`;
    if (link.search) redirectUrl += link.search;
    window.location.href = redirectUrl;
  };

  const LogoIcon = (props) => {
    return (
      <MuiLink
        href={currentRecruiter ? `/${i18nLocale}/recruiter` : `/${i18nLocale}/`}
      >
        <Box
          component="img"
          id="logo-squarehub"
          alt="Squarehub"
          width="100%"
          height="auto"
          maxWidth={200}
          {...props}
          src={props.white ? LogoWhite : Logo}
        />
      </MuiLink>
    );
  };

  const logout = (callback) => {
    sh.delete(`/${i18nLocale}/recruiters/sign_out`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        authenticity_token,
      },
    })
      .then(() => {
        if (callback && typeof callback == "function") callback();
        if (window) window.location.replace("/");
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {});
  };

  const CandidatesMenu = ({ classes }) => {
    const popupState = usePopupState({
      variant: "popover",
      popupId: "candidatesMenu",
    });

    const trigger = useScrollTrigger();

    useEffect(() => {
      if (popupState?.isOpen && trigger) {
        popupState.close();
      }
    }, [trigger, popupState?.isOpen]);

    const handleHoverDebounced = debounce(() => {
      popupState.close();
    }, 100);

    return (
      <Box style={{ marginRight: "16px" }}>
        <Button
          variant="text"
          {...bindHover({
            ...popupState,
            onMouseEnter: () => handleHoverDebounced(),
          })}
        >
          <Badge
            badgeContent={currentRecruiter?.favorite_candidate_ids?.length || 0}
            color="secondary"
            style={{ marginRight: "8px" }}
          >
            <Typography variant="body2">
              <FormattedMessage id="job_offer.cv_theque" />
            </Typography>
          </Badge>
          <ExpandMore />
        </Button>
        <HoverMenu {...bindMenu(popupState)} PaperProps={{ onMouseLeave: popupState.close }}>
          <MenuItem
            className={classes.userMenuItem}
            onClick={() =>
              handleRedirectClick(
                { target: "recruiter/find_candidate" },
                popupState
              )
            }
          >
            <SearchOutlinedIcon style={{ marginRight: "8px" }} />
            <Typography variant="body2bold">
              <FormattedMessage id="nav.recruiter.find_candidate" />
            </Typography>
          </MenuItem>
          <MenuItem
            className={classes.userMenuItem}
            onClick={() =>
              handleRedirectClick(
                {
                  target: "recruiter/find_candidate",
                  search: "?onlyFavorites=true",
                },
                popupState
              )
            }
          >
            <Badge
              badgeContent={
                currentRecruiter?.favorite_candidate_ids?.length || 0
              }
              color="secondary"
              style={{ marginRight: "8px" }}
            >
              <FavoriteBorderIcon />
            </Badge>
            <Typography variant="body2bold">
              <FormattedMessage id="nav.recruiter.favorite_candidates" />
            </Typography>
          </MenuItem>
        </HoverMenu>
      </Box>
    );
  };

  const CustomCandidatesMenu = withStyles(() => ({
    iconButton: {
      "&:hover": {
        backgroundColor: "unset",
        opacity: 0.84,
      },
    },
    userMenuItem: {
      width: "240px !important",
      "&:hover": {
        backgroundColor: `${theme.palette.text.blue} !important`,
        color: `${theme.palette.common.white} !important`,
      },
    },
    divider: {
      margin: "8px 16px !important",
    },
  }))(CandidatesMenu);

  const UserMenu = ({ classes }) => {
    const popupState = usePopupState({
      variant: "popover",
      popupId: "demoMenu",
    });

    return (
      <Box>
        <IconButton
          disableRipple={true}
          className={classes.iconButton}
          {...bindHover(popupState)}
        >
          <ProfilePicture
            rounded
            picture={currentRecruiter?.company?.logo_url}
            initials={
              currentRecruiter?.first_name[0] + currentRecruiter?.last_name[0]
            }
          />{" "}
          <ExpandMore />
        </IconButton>
        <HoverMenu {...bindMenu(popupState)} PaperProps={{ onMouseLeave: popupState.close }}>
          <MenuItem
            className={classes.userMenuItem}
            onClick={() => handleRedirectClick({ target: "" }, popupState)}
          >
            <HomeOutlinedIcon style={{ marginRight: "8px" }} />
            <Typography variant="body2bold">
              <FormattedMessage id="menu.home" />
            </Typography>
          </MenuItem>
          <MenuItem
            className={classes.userMenuItem}
            onClick={() =>
              handleRedirectClick({ target: "recruiter/settings" }, popupState)
            }
          >
            <SettingsIcon style={{ marginRight: "8px" }} />
            <Typography variant="body2bold">
              <FormattedMessage id="menu.settings" />
            </Typography>
          </MenuItem>
          <Divider className={classes.divider} />
          {desktopAuthenticatedSubmenus.map((submenu) => {
            return (
              <Box key={`key-${submenu.titleId}`}>
                <Typography variant="body2bold" style={{ marginLeft: "16px" }}>
                  <FormattedMessage id={submenu.titleId} />
                </Typography>
                <Box style={{ marginTop: "8px" }}>
                  {submenu.links.map((link) => {
                    return (
                      <MenuItem
                        key={link.titleId}
                        className={classes.userMenuItem}
                        onClick={() => {
                          handleRedirectClick(link, popupState);
                        }}
                      >
                        {link.icon && link.icon()}
                        <Typography
                          variant="body2"
                          style={{ marginLeft: "8px" }}
                        >
                          <FormattedMessage id={link.titleId} />
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Box>
                <Divider className={classes.divider} />
              </Box>
            );
          })}
          <Box style={{ marginTop: "16px", marginBottom: "16px" }}>
            <SelectLanguage initLocale={i18nLocale} inline={true} />
          </Box>
          <Divider className={classes.divider} />
          {currentRecruiter && (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={logout}
                className={classes.userMenuItem}
                style={{
                  textAlign: "center",
                }}
              >
                <FormattedMessage id="logout" />
              </Button>
            </Box>
          )}
        </HoverMenu>
      </Box>
    );
  };

  const CustomUserMenu = withStyles(() => ({
    iconButton: {
      "&:hover": {
        backgroundColor: "unset",
        opacity: 0.84,
      },
    },
    userMenuItem: {
      width: "240px !important",
      "&:hover": {
        backgroundColor: `${theme.palette.text.blue} !important`,
        color: `${theme.palette.common.white} !important`,
      },
    },
    divider: {
      margin: "8px 16px !important",
    },
  }))(UserMenu);

  const RecruiterAvatar = () => {
    return <CustomUserMenu />;
  };

  useEffect(() => {
    if (!isDrawerOpen && !matches) {
      let menus = mobileAuthenticatedSubmenus.map((menu) => {
        let copy = menu;
        copy.isOpen = false;
        return copy;
      });
      setSubmenus(menus);
    }
  }, [isDrawerOpen]);

  const countPendingApplications =
    currentRecruiter?.company?.new_applications_count;

  return (
    <div className={classes.root} id="client-sh-header">
      <AppBar
        position="static"
        elevation={0}
        style={{
          height: matches ? "inherit" : "68px",
          justifyContent: "center",
          backgroundColor: theme.palette.common.white,
        }}
      >
        <Toolbar style={{ paddingRight: matches ? "16px" : "4px" }}>
          {matches && (
            <Box flexGrow={1}>
              <LogoIcon />
            </Box>
          )}
          <Hidden mdDown>
            {currentRecruiter ? (
              <>
                <Button
                  variant="text"
                  className={classes.menuButton}
                  href={`/${i18nLocale}/recruiter/job_offers`}
                >
                  <FormattedMessage id="job_offer.my_job_offers" />
                </Button>
                <Button
                  variant="text"
                  className={classes.menuButton}
                  href={`/${i18nLocale}/recruiter/my_candidates`}
                >
                  <Badge
                    badgeContent={countPendingApplications}
                    color="primary"
                    style={{ marginRight: "8px" }}
                  >
                    <Typography variant="body2">
                      <FormattedMessage id="job_search.my_jobs" />
                    </Typography>
                  </Badge>
                </Button>
                <CustomCandidatesMenu />
                <Button
                  size="small"
                  variant="rounded"
                  color="secondaryOutlined"
                  onClick={() =>
                    handleRedirectClick({
                      target: "recruiter/job_offer_creation",
                    })
                  }
                >
                  <FormattedMessage id="job_offer.post_new_job" />
                </Button>
                <RecruiterAvatar />
              </>
            ) : (
              <>
                <Button
                  className={classes.menuButton}
                  variant="text"
                  href={`/${i18nLocale}/recruiters#pricing`}
                >
                  <FormattedMessage id="menu.pricing" />
                </Button>
                <Button
                  className={classes.menuButton}
                  variant="text"
                  href={`/${i18nLocale}/recruiters/posts`}
                >
                  <FormattedMessage id="blog.tips_and_actualities" />
                </Button>
                <Button
                  variant="text"
                  href={`/${i18nLocale}`}
                  className={classes.menuButton}
                >
                  <FormattedMessage id="candidate.candidate_space" />
                </Button>
                <Button
                  className={classes.candidateButton}
                  variant="rounded"
                  size="small"
                  color="secondaryOutlined"
                  style={{ minWidth: "150px" }}
                  href={`/${i18nLocale}/recruiters/sign_up`}
                >
                  <FormattedMessage id="signup" />
                </Button>
                <Button
                  className={classes.candidateButton}
                  variant="rounded"
                  size="small"
                  color="secondaryContained"
                  style={{ marginRight: "16px", minWidth: "150px" }}
                  href={`/${i18nLocale}/recruiters/sign_in`}
                >
                  <FormattedMessage id="signin" />
                </Button>
                <SelectLanguage initLocale={i18nLocale} />
              </>
            )}
            <Box mx={1} />
          </Hidden>
          <Hidden lgUp>
            <IconButton
              edge="end"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          {!matches && (
            <Box
              flexGrow={1}
              style={{ marginLeft: "-48px", textAlign: "center" }}
            >
              <LogoIcon />
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <nav aria-label="mobile navbar">
        <Hidden lgUp implementation="css">
          <Drawer
            anchor="left"
            open={isDrawerOpen}
            onClose={toggleDrawer(false)}
            classes={{ paper: classes.drawer }}
          >
            <Box className={classes.drawerHeader}>
              <IconButton onClick={toggleDrawer(false)}>
                <ArrowBackIosIcon
                  style={{ color: "white", marginLeft: "8px" }}
                />
              </IconButton>
              <Box className={classes.rightHeader}>
                {currentRecruiter ? (
                  <Box display="flex" alignItems="center" columnGap="8px">
                    <Box
                      display="flex"
                      flexDirection="column"
                      textAlign="right"
                    >
                      <Typography variant="h7" className={classes.headerTitle}>
                        {currentRecruiter?.first_name}{" "}
                        {currentRecruiter?.last_name}
                      </Typography>
                      <Typography variant="body3">
                        {currentRecruiter?.company?.name}
                      </Typography>
                    </Box>
                    <img
                      src={currentRecruiter?.company?.logo_url}
                      style={{ width: "50px", borderRadius: "4px" }}
                    />
                  </Box>
                ) : (
                  <Box textAlign="right">
                    <LogoIcon white={true} style={{ width: "140px" }} />
                    <Typography
                      variant="body3bold"
                      style={{ textTransform: "uppercase" }}
                    >
                      <FormattedMessage id="recruiter.recruiter_space" />
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <List>
              {currentRecruiter ? (
                <>
                  <ListItem
                    button
                    className={classes.drawerItem}
                    onClick={() => handleRedirectClick({ target: "" })}
                  >
                    <HomeOutlinedIcon />
                    <FormattedMessage id="menu.home" />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.drawerItem}
                    onClick={() =>
                      handleRedirectClick({ target: "recruiter/settings" })
                    }
                  >
                    <SettingsOutlinedIcon />
                    <FormattedMessage id="menu.settings" />
                  </ListItem>
                  <Divider className={classes.divider} />
                  {submenus.map((submenu) => {
                    return (
                      <Box key={`key-${submenu.titleId}-${Math.random()}`}>
                        <ListItem
                          button
                          className={classes.drawerItem}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          onClick={() => handleSubmenuClicked(submenu)}
                        >
                          <Typography variant="body2bold">
                            <FormattedMessage id={submenu.titleId} />
                          </Typography>
                          {submenu.isOpen ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </ListItem>
                        <Collapse
                          in={submenu.isOpen}
                          timeout="auto"
                          unmountOnExit
                        >
                          {submenu.links.map((link) => {
                            return (
                              <ListItem
                                key={link.titleId}
                                className={`${classes.drawerItem} ${classes.drawerSubItem}`}
                                onClick={() => handleRedirectClick(link)}
                              >
                                {link.icon && link.icon()}
                                <Typography
                                  variant="body2"
                                  style={{ marginLeft: "8px" }}
                                >
                                  <FormattedMessage id={link.titleId} />
                                </Typography>
                              </ListItem>
                            );
                          })}
                        </Collapse>
                      </Box>
                    );
                  })}
                </>
              ) : (
                <>
                  <ListItem
                    button
                    className={classes.drawerItem}
                    onClick={() => {
                      if (window)
                        window.location.href = `/${i18nLocale}/recruiter/sign_in`;
                    }}
                  >
                    <img src={LogInFigma} alt="LogInFigma" />
                    <Typography variant="body1bold" marginLeft="2.5px">
                      <FormattedMessage id="signin" />
                    </Typography>
                  </ListItem>
                  <ListItem
                    button
                    className={classes.drawerItem}
                    onClick={() => {
                      if (window)
                        window.location.href = `/${i18nLocale}/recruiters/sign_up`;
                    }}
                  >
                    <img src={PersonOutlinedFigma} alt="Person Icon" width="15" height="16"/>
                    <Typography variant="body1bold" marginLeft="5px">
                      <FormattedMessage id="signup" />
                    </Typography>
                  </ListItem>
                  <img src={DividerFigma} alt="Divider" style={{ margin: "14px", width:"210px" }} />
                  <ListItem
                    onClick={() => {
                      window.location.href = "https://calendly.com/demo-squarehub/quick-demo-30-min";
                    }}
                  >
                    <img src={DateOutlined} alt="DateIcon" style={{ width: "15", height: "16", marginLeft: "3px" }}/>
                    <Typography variant="body2" style={{ marginLeft: "12px" }}>
                      <FormattedMessage id="landing_recruiter.contact_form.book_demo" />
                    </Typography>
                  </ListItem>
                  {mobileUnauthMenus.map((link) => {
                    return (
                      <ListItem
                        key={link.titleId}
                        className={`${classes.drawerItem} ${classes.drawerSubItem}`}
                        onClick={() => handleRedirectClick(link)}
                      >
                        {link.icon && link.icon()}
                        <Typography
                          variant="body2"
                          style={{ marginLeft: "8px" }}
                        >
                          <FormattedMessage id={link.titleId} />
                        </Typography>
                      </ListItem>
                    );
                  })}
                </>
              )}
              <img src={DividerFigma} alt="Divider" style={{ margin: "14px", marginBottom: "5px", width:"210px" }}/>
              <ListItem
                className={classes.drawerItem}
              >
                <SelectLanguage initLocale={i18nLocale} inline={true} />
              </ListItem>
              <img src={DividerFigma} alt="Divider" style={{ margin: "14px", width:"210px" }}/>
              {currentRecruiter && (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={logout}
                    className={classes.drawerItem}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <FormattedMessage id="logout" />
                  </Button>
                </Box>
              )}
            </List>
          </Drawer>
        </Hidden>
      </nav>
      {children}
    </div>
  );
}
