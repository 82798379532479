import React from "react";
import { Grid, Typography, Box, Paper, Link, Modal } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SkillView from "bundles/Recruiter/components/SkillView";
import MetricsGrid from "bundles/common/components/Desktop/MetricsGridDesktop";
import ProfilePicture from "bundles/common/components/ProfilePicture";
import ApplicationDialog from "bundles/Candidate/components/ApplicationDialog";
import ShLoadingButton from "bundles/common/components/ShLoadingButton";
import AuthenticationModal from "bundles/Candidate/components/AuthenticationModal";
import HTML from "bundles/common/components/HTML";
import {
  truncate,
  annualyRemuneration,
  handleLinkedinShare,
  handleCopyClick,
} from "bundles/common/utils/utils.js";
import {
  formatJobOfferLocation,
  formatJobDuration,
} from "bundles/common/utils/utils";
import Tooltip from "@material-ui/core/Tooltip";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { FacebookShareButton } from "react-share";

const useStyles = makeStyles(theme => ({
  green_paper: {
    padding: theme.spacing(2),
    zIndex: 0,
    margin: "auto",
    backgroundColor: theme.palette.text.light_green,
    borderRadius: "10px 10px 0px 0px",
  },
  paper: {
    padding: theme.spacing(4),
    margin: "auto",
    borderRadius: "0px 0px 10px 10px",
  },
}));

const TextBlock = ({ titleId, text }) => {
  return (
    <Grid item xs={12}>
      <Typography variant="h2" gutterBottom>
        <b>
          <FormattedMessage id={titleId} />
        </b>
      </Typography>
      <HTML html={text} />
    </Grid>
  );
};

const PublicJobOfferSummaryMobile = ({
  jobOffer,
  updateJobOffer,
  handleApply,
  isEasyApplying,
  candidateApplication,
  isLoginModalOpened,
  publicJobUrl,
  isApplicationModalOpened,
  setIsApplicationModalOpened,
  enqueueSnackbar,
}) => {
  const i18nLocale = useSelector(state => state.i18nLocale);
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          component={Paper}
          elevation={0}
          className={classes.paper}
        >
          <Grid container justifyContent={"center"}>
            <Grid item xs={12}>
              <Grid container style={{ alignItems: "center" }}>
                <Grid item xs={12} md={3}>
                  <ProfilePicture
                    picture={jobOffer?.company?.logo_url}
                    picturesmall={jobOffer?.company?.small_logo_url}
                    size={120}
                    initials={jobOffer?.company?.name?.[0]}
                    alt={`Logo ${jobOffer?.company?.name}`}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h1"
                    style={{
                      fontFamily: "Work Sans Bold",
                      color: theme.palette.text.blue,
                      marginTop: "20px",
                    }}
                  >
                    <b>{jobOffer?.job_title}</b>
                  </Typography>
                  <Typography variant="body1bold">
                    <Link
                      href={`/${i18nLocale}/companies/${jobOffer?.company?.encoded_name}/${jobOffer?.company?.id}`}
                    >
                      <b>{truncate(jobOffer?.company?.name, 30)}</b>
                    </Link>
                  </Typography>
                  {jobOffer?.locations?.map(location => {
                    return (
                      <Box key={Object.values(location).join("-")}>
                        <Typography
                          variant="body2"
                          style={{
                            color: theme.palette.text.blue,
                            textTransform: "capitalize",
                          }}
                        >
                          <b>{formatJobOfferLocation(location)}</b>
                        </Typography>
                      </Box>
                    );
                  })}
                  <Box
                    style={{ display: "flex", width: "100%", marginTop: "6px" }}
                  >
                    <Box
                      onClick={() => handleLinkedinShare(publicJobUrl)}
                      style={{ cursor: "pointer" }}
                    >
                      <LinkedInIcon
                        style={{ fill: "#0077B5", marginRight: "4px" }}
                        size={32}
                        round={true}
                      />
                    </Box>
                    <FacebookShareButton url={publicJobUrl}>
                      <FacebookIcon
                        style={{ fill: "#1877F2", marginRight: "4px" }}
                        size={32}
                        round={true}
                      />
                    </FacebookShareButton>
                    <Tooltip
                      title={<FormattedMessage id="settings.copy_link" />}
                    >
                      <FileCopyOutlinedIcon
                        style={{ cursor: "pointer", marginRight: "4px" }}
                        onClick={() =>
                          handleCopyClick(publicJobUrl, intl, enqueueSnackbar)
                        }
                      />
                    </Tooltip>
                  </Box>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Grid container spacing={2} justifyContent={"center"}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginTop: "16px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <ShLoadingButton
                        size="large"
                        width="100%"
                        color="primaryContained"
                        variant="rounded"
                        onClick={handleApply}
                        loading={isEasyApplying}
                        pulsating={!isEasyApplying}
                        showFinishedState={true}
                        isSuccessful={candidateApplication?.id}
                        defaultLabelId="job_search.apply_to_job"
                        loadingLabelId="job_search.applying"
                        successLabelId="job_search.applied_successfully"
                        failLabelId="job_search.applied_failed"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {/* -> Uncomment this when we want to show the timing again
                        <Typography align="right" variant="body2">
                          {getDurationMessage(jobOffer)}
                        </Typography>
                      */}
                      <Typography
                        align="center"
                        variant="subtitle2"
                        style={{
                          fontWeight: "bold",
                          fontFamily: "Work Sans Bold",
                          color: theme.palette.primary.main,
                        }}
                      >
                        <FormattedMessage
                          id="job_offer.apply_job_candidate"
                          values={{
                            candidate: jobOffer.job_applications_count || 0,
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box my={6}>
                <MetricsGrid
                  titleOne="job_offer.company_industry"
                  textOne={
                    jobOffer?.company_industries.length > 0
                      ? truncate(
                        jobOffer.company_industries
                          .map(industry =>
                            intl.formatMessage({
                              id: `company.company_industry.${industry}`,
                            })
                          )
                          .join(", "),
                        12
                      )
                      : ""
                  }
                  titleTwo="job_offer.start_date"
                  textTwo={formatJobDuration(jobOffer)}
                  titleThree="job_offer.job_status"
                  textThree={
                    jobOffer?.job_statuses?.length > 0 ? (
                      truncate(
                        jobOffer?.job_statuses
                          .map(status =>
                            intl.formatMessage({
                              id: `candidate.status_map.${status}`,
                            })
                          )
                          .join(", "),
                        12
                      )
                    ) : (
                      <FormattedMessage id="undefined" />
                    )
                  }
                  titleFour="job_offer.monthly_offer"
                  textFour={
                    jobOffer?.monthly_offer ? (
                      annualyRemuneration(jobOffer?.monthly_offer)
                    ) : (
                      <FormattedMessage id="to_discuss" />
                    )
                  }
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={3}>
                {/* Description */}
                {jobOffer?.description && (
                  <TextBlock
                    titleId="job_offer.description"
                    text={jobOffer.description}
                  />
                )}

                {/* Responsibilities */}
                {jobOffer?.responsibilities && (
                  <TextBlock
                    titleId="job_offer_creation.responsibilities"
                    text={jobOffer.responsibilities}
                  />
                )}
                {/* Profile */}
                {jobOffer?.profile && (
                  <TextBlock
                    titleId="job_offer.profile"
                    text={jobOffer.profile}
                  />
                )}
                {/* Offer */}
                {jobOffer?.offer && (
                  <TextBlock titleId="job_offer.offer" text={jobOffer.offer} />
                )}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                flexWrap: "wrap",
                rowGap: "16px",
                columnGap: "16px",
                marginTop: "16px",
                marginBottom: "32px",
              }}
            >
              {jobOffer?.offer_advantages?.advantages?.map((value, index) => (
                <Box key={`advantages-${index}`} style={{ display: "flex" }}>
                  <CheckCircleOutlineIcon
                    color="primary"
                    style={{ marginRight: "4px" }}
                  />
                  <FormattedMessage id={`job_offer_edit.advantages.${value}`} />
                </Box>
              ))}
              {jobOffer?.offer_advantages?.schedules?.map((value, index) => (
                <Box key={`schedules-${index}`} style={{ display: "flex" }}>
                  <CheckCircleOutlineIcon
                    color="primary"
                    style={{ marginRight: "4px" }}
                  />
                  <FormattedMessage id={`job_offer_edit.schedules.${value}`} />
                </Box>
              ))}
              {jobOffer?.offer_advantages?.environments?.map((value, index) => (
                <Box key={`environments-${index}`} style={{ display: "flex" }}>
                  <CheckCircleOutlineIcon
                    color="primary"
                    style={{ marginRight: "4px" }}
                  />
                  <FormattedMessage
                    id={`job_offer_edit.environment.${value}`}
                  />
                </Box>
              ))}
              {jobOffer?.offer_advantages?.primes?.map((value, index) => (
                <Box key={`primes-${index}`} style={{ display: "flex" }}>
                  <CheckCircleOutlineIcon
                    color="primary"
                    style={{ marginRight: "4px" }}
                  />
                  <FormattedMessage id={`job_offer_edit.primes.${value}`} />
                </Box>
              ))}
            </Grid>

            {/* Skills */}
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {jobOffer.hard_skills?.length > 0 && (
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      <b>
                        <FormattedMessage id="candidate.profile.skills.hard" />
                      </b>
                    </Typography>
                    <Box
                      style={{
                        display: "flex",
                        columnGap: "8px",
                        rowGap: "4px",
                        flexWrap: "wrap",
                      }}
                    >
                      {jobOffer.hard_skills.map((value, index) => (
                        <Box key={`hard-skill-${index}`}>
                          <SkillView
                            level={false}
                            value={value}
                            category="hard"
                            locale={i18nLocale}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )}

                {jobOffer.soft_skills?.length > 0 && (
                  <Grid item xs={12}>
                    <Typography variant="h2" style={{ marginBottom: "12px" }}>
                      <b>
                        <FormattedMessage id="candidate.profile.skills.soft" />
                      </b>
                    </Typography>
                    <Box
                      style={{
                        display: "flex",
                        columnGap: "8px",
                        rowGap: "4px",
                        flexWrap: "wrap",
                      }}
                    >
                      {jobOffer.soft_skills.map((value, index) => (
                        <Box key={`soft-skill-${index}`}>
                          <SkillView
                            level={false}
                            value={value}
                            category="soft"
                            locale={i18nLocale}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )}

                {jobOffer.language_skills?.length > 0 && (
                  <Grid item xs={12}>
                    <Typography variant="h2" style={{ marginBottom: "12px" }}>
                      <b>
                        <FormattedMessage id="candidate.profile.skills.lang" />
                      </b>
                    </Typography>
                    <Box
                      style={{
                        display: "flex",
                        columnGap: "8px",
                        rowGap: "4px",
                        flexWrap: "wrap",
                      }}
                    >
                      {jobOffer.language_skills.map((value, index) => (
                        <Box key={`lang-skill-${index}`}>
                          <SkillView
                            level={false}
                            value={value}
                            category="lang"
                            locale={i18nLocale}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <ShLoadingButton
                    size="large"
                    width="100%"
                    color="primaryContained"
                    variant="rounded"
                    onClick={handleApply}
                    loading={isEasyApplying}
                    pulsating={!isEasyApplying}
                    showFinishedState={true}
                    isSuccessful={candidateApplication?.id}
                    defaultLabelId="job_search.apply_to_job"
                    loadingLabelId="job_search.applying"
                    successLabelId="job_search.applied_successfully"
                    failLabelId="job_search.applied_failed"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        hideBackdrop={false}
        open={isApplicationModalOpened}
        onClose={() => setIsApplicationModalOpened(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ display: "flex", alignItems: "center" }}
      >
        <ApplicationDialog
          jobOffer={jobOffer}
          setJobOffer={updateJobOffer}
          jobApplication={candidateApplication}
          setIsApplying={setIsApplicationModalOpened}
        />
      </Modal>
      <AuthenticationModal
        isOpen={isLoginModalOpened}
        section="login_or_signin"
        closeModal={() => location.reload()}
        singleJob={true}
        customScope={`application_${jobOffer.id}_S`}
      />
    </>
  );
};

export default PublicJobOfferSummaryMobile;
