import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import Autocomplete from "react-google-autocomplete";
import { useLocation } from "react-router-dom";
import {
  Button,
  Grid,
  Typography,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Dialog,
  Modal,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  companyIndustryMap,
  countryMap,
  companySizeMap,
  pictureRatios,
} from "bundles/common/utils/constants";
import RichTextEditor from "bundles/common/components/RichTextEditor";
import PictureDropzone from "bundles/common/components/PictureDropzone";
import { uploadPicture } from "bundles/common/utils/uploadProfilePicture";
import ImageCropper from "bundles/common/components/ImageCropper";
import sh from "bundles/common/utils/sh";
import { useSnackbar } from "notistack";
import Bugsnag from "@bugsnag/js";
import EditProjectModal from "./EditProjectModal";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { sortDateRange, normalizeRegion } from "bundles/common/utils/utils";
import ProjectSettingsView from "./ProjectSettingsView";
import { replaceCurrentRecruiter } from "bundles/Recruiter/constants/recruiterConstants";

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Company = () => {
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const currentCompany = currentRecruiter.company;
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const dispatch = useDispatch();

  const query = useQuery();
  const theme = useTheme();
  const intl = useIntl();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { enqueueSnackbar } = useSnackbar();

  const [isCropModalOpened, setIsCropModalOpened] = useState(false);
  const [logo, setLogo] = useState(currentCompany.logo_url);

  //This extra is needed for the cropper
  const [logoUrl, setLogoUrl] = useState(currentCompany.logo_url);
  const [coverPicture, setCoverPicture] = useState(
    currentCompany.cover_picture_url
  );
  //This extra is needed for the cropper
  const [coverPictureUrl, setCoverPictureUrl] = useState(
    currentCompany.cover_picture_url
  );
  const [cropable, setCropable] = useState("cover_picture");
  const [isCropped, setIsCropped] = useState(false);

  const [generalInfoErrors, setGeneralInfoErrors] = useState({});
  const [detailedInfoErrors, setDetailedInfoErrors] = useState({});

  const [isEditModalOpen, setIsEditModalOpen] = useState(query.get("newProject") === "true" || false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isNew, setIsNew] = useState(null);
  const [firstPartAndNew, setFirstPartAndNew] = useState(false);

  const [showProjects, setShowProjects] = useState(true);
  const [showDrafts, setShowDrafts] = useState(false);
  const [showAllProjects, setShowAllProjects] = useState(false);

  const initialGeneralInfoValues = {
    logo: currentCompany.logo_url,
    cover_picture: currentCompany.cover_picture_url,
    name: currentCompany.name,
    location:
      currentCompany.city ||
      (currentCompany.region
        ? intl.formatMessage({ id: `regions.${currentCompany.region}` })
        : null) ||
      (currentCompany.country
        ? intl.formatMessage({ id: `countries.${currentCompany.country}` })
        : null),
    company_industry: currentCompany.company_industry,
    team_size: currentCompany.team_size,
    creation_year: currentCompany.creation_year,
    average_age: currentCompany.average_age,
  };

  const [generalInfoValues, setGeneralInfoValues] = useState(
    initialGeneralInfoValues
  );

  const initialDetailedInfoValues = {
    about: currentCompany.about,
    references: currentCompany.references,
    awards: currentCompany.awards,
  };

  const [detailedInfoValues, setDetailedInfoValues] = useState(
    initialDetailedInfoValues
  );

  const sortedProjects = sortDateRange(currentCompany?.projects);
  const draftProjectsCount = sortedProjects.filter(
    (project) => project.is_draft
  ).length;

  const handleManualCrop = () => {
    setCropable("cover_picture");
    setIsCropModalOpened(true);
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    let newValues = { ...generalInfoValues, [name]: value };

    setGeneralInfoValues(newValues);
  };

  const onPlacesSelect = (place) => {
    if (place.address_components) {
      let compPostalCode = "";
      let compLocality = "";
      let compAdministrativeAreaLevel2 = "";
      let compAdministrativeAreaLevel1 = "";
      let countryShortName = "";
      let regionValue = "";

      place.address_components.forEach((c) => {
        if (c.types.includes("postal_code")) {
          compPostalCode = c.long_name;
        }
        if (c.types.includes("locality")) {
          compLocality = c.long_name;
        }
        if (c.types.includes("administrative_area_level_2")) {
          compAdministrativeAreaLevel2 = c.long_name
            ?.toLowerCase()
            ?.replace(/ /g, "_");
        }
        if (c.types.includes("administrative_area_level_1")) {
          compAdministrativeAreaLevel1 = c.long_name
            ?.toLowerCase()
            ?.replace(/ /g, "_");
        }
        if (c.types.includes("country")) {
          countryShortName = c.short_name.toLowerCase();
        }
      });

      if (countryShortName === "fr") {
        regionValue = compAdministrativeAreaLevel1 || compAdministrativeAreaLevel2;
        regionValue = normalizeRegion(regionValue);
      } else if (countryShortName === "be") {
        if (compAdministrativeAreaLevel2 === "luxembourg") {
          regionValue = "be_luxembourg";
        } else {
          regionValue = compAdministrativeAreaLevel2 || compAdministrativeAreaLevel1;
        }
      } else {
        regionValue = compAdministrativeAreaLevel2 || compAdministrativeAreaLevel1;
      }

      let newValues = {
        ...generalInfoValues,
        location: place.formatted_address,
        postal_code: compPostalCode,
        city: compLocality,
        region: regionValue,
        country: countryShortName,
      };

      setGeneralInfoValues(newValues);
    }
  };

  const handleTextArea = (name) => (value) => {
    detailedInfoValues[name] = value;
    setDetailedInfoValues(detailedInfoValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sh.put(`${i18nLocale}/company/${currentCompany.id}`, {
      authenticity_token: authenticityToken,
      company: { ...generalInfoValues, ...detailedInfoValues },
    })
      .then(() => {
        enqueueSnackbar(intl.formatMessage({ id: "settings.save_success" }), {
          variant: "success",
        });
      })
      .catch((err) => {
        Bugsnag.notify(err);
        enqueueSnackbar(intl.formatMessage({ id: "settings.save_failed" }), {
          variant: "error",
        });
      });
  };

  const projectValues = {
    title: "",
    description: "",
    location: "",
    client_name: "",
    status: "planned",
    company_id: currentCompany.id,
  };

  const handleNewProjectClicked = (e) => {
    sh.post("projects", {
      authenticity_token: authenticityToken,
      project_data: projectValues,
    }).then((res) => {
      const createdProject = res.data;
      setSelectedProject(createdProject);
      replaceCurrentRecruiter(dispatch, authenticityToken);
      setIsEditModalOpen(true);
      setIsNew(true);
      setFirstPartAndNew(true);
    });
    e.preventDefault();
  };

  const deleteProject = () => {
    sh.delete(`projects/${selectedProject.id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        authenticity_token: authenticityToken,
        selectedProject,
      },
    }).then(() => {
      replaceCurrentRecruiter(dispatch, authenticityToken);
    });
  };

  const handleProjectClicked = (project) => {
    setSelectedProject(project);
    setIsEditModalOpen(true);
    setIsNew(false);
  };

  const displayProjects = () => {
    setShowProjects(true);
    setShowDrafts(false);
  };

  const displayDrafts = () => {
    setShowProjects(false);
    setShowDrafts(true);
  };

  const projectsToShow = showProjects
    ? sortedProjects.filter((project) => !project.is_draft)
    : sortedProjects.filter((project) => project.is_draft);

  useEffect(() => {
    let project = sortedProjects.find((p) => p.id == selectedProject?.id);
    if (project) {
      setSelectedProject(project);
    }
  }, [sortedProjects.find((p) => p.id == selectedProject?.id)]);

  useEffect(() => {
    if (draftProjectsCount === 0) {
      setShowProjects(true);
    }
  }, [draftProjectsCount]);

  useEffect(() => {
    if (logo && typeof logo != "string") {
      uploadPicture(
        logo,
        currentCompany.id,
        "company",
        "logo",
        authenticityToken
      )
        .then((res) => {
          let newValues = { ...generalInfoValues, logo: res.company.logo_url };

          setGeneralInfoValues(newValues);
          setLogoUrl(res.company.logo_url);

          if (isCropped) {
            setIsCropped(false);
            setIsCropModalOpened(false);
            enqueueSnackbar(
              intl.formatMessage({ id: "settings.save_success" }),
              {
                variant: "success",
              }
            );
          } else {
            setCropable("logo");
            setIsCropModalOpened(true);
          }
        })
        .catch((err) => {
          Bugsnag.notify(err);
          enqueueSnackbar(intl.formatMessage({ id: "settings.save_failed" }), {
            variant: "error",
          });
        });
    }
  }, [logo]);

  useEffect(() => {
    if (coverPicture && typeof coverPicture != "string") {
      uploadPicture(
        coverPicture,
        currentCompany.id,
        "company",
        "cover_picture",
        authenticityToken
      )
        .then((res) => {
          let newValues = {
            ...generalInfoValues,
            cover_picture: res.company.cover_picture_url,
          };

          setGeneralInfoValues(newValues);
          setCoverPictureUrl(res.company.cover_picture_url);

          if (isCropped) {
            setIsCropped(false);
            setIsCropModalOpened(false);
            enqueueSnackbar(
              intl.formatMessage({ id: "settings.save_success" }),
              {
                variant: "success",
              }
            );
          } else {
            setCropable("cover_picture");
            setIsCropModalOpened(true);
          }
        })
        .catch(() => {
          enqueueSnackbar(intl.formatMessage({ id: "settings.save_failed" }), {
            variant: "error",
          });
        });
    }
  }, [coverPicture]);

  return (
    <>
      <Grid container component="form" onSubmit={handleSubmit}>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">
            <FormattedMessage id="settings.company_page" />
          </Typography>
          <Button
            variant="rounded"
            color="secondaryOutlined"
            href={`/${i18nLocale}/recruiter/company`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: "180px" }}
          >
            <FormattedMessage id="settings.show" />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                columnGap: "32px",
                marginBottom: "16px",
                flexDirection: isDesktop ? "row" : "column",
              }}
            >
              <Box>
                <Typography variant="h5" style={{ marginBottom: "16px" }}>
                  <FormattedMessage id="settings.logo" />
                </Typography>
                <PictureDropzone
                  handleFileInput={(file) => {
                    setLogo(file);
                    setGeneralInfoErrors({ ...generalInfoErrors, logo: "" });
                  }}
                  filesLimit={1}
                  maxFileSize={204800}
                  pictureUrl={generalInfoValues.logo}
                  width="200px"
                  height="200px"
                  type="recruiter"
                />
                <Typography variant="body3">
                  <FormattedMessage id="settings.recommended_size" /> 250x250
                </Typography>
              </Box>
              <Box style={{ flexGrow: 1 }}>
                <Typography variant="h5" style={{ marginBottom: "16px" }}>
                  <FormattedMessage id="settings.cover_picture" />
                </Typography>
                <PictureDropzone
                  handleFileInput={(file) => {
                    setCoverPicture(file);
                    setGeneralInfoErrors({
                      ...generalInfoErrors,
                      cover_picture: "",
                    });
                  }}
                  acceptedFiles={["image/png", "image/jpeg", "image/jpg"]}
                  filesLimit={1}
                  maxFileSize={2048000}
                  backgroundSize="cover"
                  pictureUrl={generalInfoValues.cover_picture}
                  width="100%"
                  height="200px"
                  type="recruiter"
                />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography variant="body3" textAlign="right">
                      <FormattedMessage id="settings.recommended_size" />{" "}
                      1000x350
                    </Typography>
                  </Box>
                  <Box>
                    <Button
                      variant="textUnderline"
                      onClick={handleManualCrop}
                      size="xsmall"
                      style={{ padding: "0" }}
                    >
                      <Typography variant="body3" textAlign="right">
                        <FormattedMessage id="image_cropper.title" />
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" style={{ marginBottom: "16px" }}>
                <FormattedMessage id="settings.general_info" />
              </Typography>
              <Box
                style={{
                  height: "200px",
                  display: "flex",
                  columnGap: "16px",
                }}
              >
                <Box style={{ flex: "1 1 0px" }}>
                  <TextField
                    fullWidth
                    value={generalInfoValues.name}
                    type="text"
                    name="name"
                    onBlur={handleInputValue}
                    onChange={handleInputValue}
                    variant="outlined"
                    label={<FormattedMessage id="settings.company_name" />}
                    style={{ marginBottom: "16px" }}
                    {...(generalInfoErrors["name"] && {
                      error: true,
                      helperText: generalInfoErrors["name"],
                    })}
                  />
                  <FormControl
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: "16px" }}
                  >
                    <InputLabel id="field-label">
                      <FormattedMessage id="company.company_industry.company_industry" />
                    </InputLabel>
                    <Select
                      name="company_industry"
                      value={generalInfoValues.company_industry}
                      onChange={handleInputValue}
                      label={
                        <FormattedMessage id="company.company_industry.company_industry" />
                      }
                    >
                      {companyIndustryMap.map((value) => {
                        return (
                          <MenuItem value={value} key={value}>
                            <FormattedMessage
                              id={"company.company_industry." + value}
                            />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    color="secondary"
                    name="location"
                    variant="outlined"
                    placeholder={intl.formatMessage({
                      id: "devise.signup.search_city_or_zipcode",
                    })}
                    InputProps={{
                      inputComponent: ({ ...props }) => (
                        <Autocomplete
                          /* eslint-disable no-undef */
                          apiKey={process.env.GOOGLE_AUTOCOMPLETE_API_KEY}
                          multiple
                          language="en"
                          {...props}
                          onPlaceSelected={onPlacesSelect}
                          options={{
                            types: ["(regions)"],
                            componentRestrictions: {
                              country: ["be", "lu", "fr"],
                            },
                          }}
                          defaultValue={generalInfoValues.location}
                        />
                      ),
                    }}
                    {...(generalInfoErrors["locations"] && {
                      error: true,
                      helperText: generalInfoErrors["locations"],
                    })}
                  />
                </Box>
                <Box style={{ flex: "1 1 0px" }}>
                  <TextField
                    fullWidth
                    value={generalInfoValues.creation_year}
                    type="number"
                    name="creation_year"
                    onBlur={handleInputValue}
                    onChange={handleInputValue}
                    variant="outlined"
                    label={<FormattedMessage id="settings.creation_year" />}
                    style={{ marginBottom: "16px" }}
                    InputProps={{
                      inputProps: {
                        max: new Date().getFullYear(),
                        min: 1800,
                      },
                    }}
                    {...(generalInfoErrors["creation_year"] && {
                      error: true,
                      helperText: generalInfoErrors["creation_year"],
                    })}
                  />
                  <FormControl
                    fullWidth
                    variant="outlined"
                    style={{ marginBottom: "16px" }}
                  >
                    <InputLabel id="field-label">
                      <FormattedMessage id="settings.team_size" />
                    </InputLabel>
                    <Select
                      name="team_size"
                      value={generalInfoValues.team_size}
                      onChange={handleInputValue}
                      label={<FormattedMessage id="settings.team_size" />}
                    >
                      {companySizeMap.map((value) => (
                        <MenuItem value={value} key={value}>
                          <FormattedMessage
                            id={"company.company_size.options." + value}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    value={generalInfoValues.average_age}
                    type="number"
                    name="average_age"
                    onBlur={handleInputValue}
                    onChange={handleInputValue}
                    variant="outlined"
                    label={<FormattedMessage id="settings.average_age" />}
                    style={{ marginBottom: "16px" }}
                    InputProps={{
                      inputProps: {
                        max: 99,
                        min: 18,
                      },
                    }}
                    {...(generalInfoErrors["average_age"] && {
                      error: true,
                      helperText: generalInfoErrors["average_age"],
                    })}
                  />
                </Box>
              </Box>
              <Box textAlign="center" style={{ marginTop: "32px" }}>
                <Button
                  variant="rounded"
                  color="secondaryContained"
                  type="submit"
                  style={{ width: "180px" }}
                >
                  <FormattedMessage id="save" />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider style={{ marginTop: "32px", marginBottom: "32px" }} />
        </Grid>

        <Grid item xs={12}>
          <Box style={{ marginBottom: "32px" }}>
            <Typography variant="h5" style={{ marginBottom: "16px" }}>
              <FormattedMessage id="company.about" />
            </Typography>
            <RichTextEditor
              height="120px"
              onChange={handleTextArea("about")}
              defaultValue={detailedInfoValues.about}
              {...(detailedInfoErrors["about"] && {
                error: true,
                helperText: detailedInfoErrors["about"],
              })}
            />
          </Box>
          <Box style={{ marginBottom: "32px" }}>
            <Typography variant="h5" style={{ marginBottom: "16px" }}>
              <FormattedMessage id="company.references" />
            </Typography>
            <RichTextEditor
              height="120px"
              onChange={handleTextArea("references")}
              defaultValue={detailedInfoValues.references}
              {...(detailedInfoErrors["references"] && {
                error: true,
                helperText: detailedInfoErrors["references"],
              })}
            />
          </Box>
          <Box style={{ marginBottom: "32px" }}>
            <Typography variant="h5" style={{ marginBottom: "16px" }}>
              <FormattedMessage id="company.awards" />
            </Typography>
            <RichTextEditor
              height="120px"
              onChange={handleTextArea("awards")}
              defaultValue={detailedInfoValues.awards}
              {...(detailedInfoErrors["awards"] && {
                error: true,
                helperText: detailedInfoErrors["awards"],
              })}
            />
          </Box>
          <Box textAlign="center">
            <Button
              variant="rounded"
              color="secondaryContained"
              type="submit"
              style={{ width: "180px" }}
            >
              <FormattedMessage id="save" />
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Divider style={{ marginTop: "32px", marginBottom: "32px" }} />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "16px",
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    textDecoration:
                      showProjects && draftProjectsCount > 0
                        ? "underline"
                        : "none",
                    cursor: draftProjectsCount > 0 ? "pointer" : "",
                    marginRight: "32px",
                  }}
                  onClick={displayProjects}
                >
                  <FormattedMessage id="company.projects" />
                </Typography>
                {draftProjectsCount > 0 && (
                  <Typography
                    variant="h6"
                    style={{
                      textDecoration: showDrafts ? "underline" : "none",
                      cursor: "pointer",
                      color: "gray",
                    }}
                    onClick={displayDrafts}
                  >
                    <FormattedMessage
                      id="project.drafts"
                      values={{ count: draftProjectsCount }}
                    />
                  </Typography>
                )}
              </Box>
              {projectsToShow.length > 0 ? (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      {projectsToShow
                        .slice(0, showAllProjects ? projectsToShow.length : 3)
                        .map((project, i) => (
                          <Box key={`project-${i}`}>
                            <Grid item xs={12}>
                              <Box>
                                <ProjectSettingsView
                                  project={project}
                                  editable={true}
                                  onEdit={handleProjectClicked}
                                />
                                {(showAllProjects || i !== 2) &&
                                  i !== projectsToShow.length - 1 && (
                                  <Divider
                                    style={{
                                      marginBottom: "16px",
                                      marginTop: "16px",
                                    }}
                                  />
                                )}
                              </Box>
                            </Grid>
                          </Box>
                        ))}
                      {projectsToShow.length > 3 && (
                        <Box>
                          <Button
                            variant="outlined"
                            color="secondary"
                            style={{ marginTop: "16px" }}
                            onClick={() => setShowAllProjects(!showAllProjects)}
                          >
                            <FormattedMessage
                              id={showAllProjects ? "see_less" : "see_more"}
                            />
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Box sx={{ color: "grey", textAlign: "center" }}>
                    <FormattedMessage id="project.no_project_added" />
                  </Box>
                </Grid>
              )}
              <Box textAlign="center" style={{ marginTop: "32px" }}>
                <Button
                  variant="rounded"
                  color="secondaryContained"
                  style={{ marginTop: "8px" }}
                  onClick={handleNewProjectClicked}
                >
                  <ControlPointIcon style={{ marginRight: "8px" }} />
                  <FormattedMessage id="settings.add_project" />
                </Button>
              </Box>
            </Grid>
            <Modal
              open={isEditModalOpen}
              onClose={() => {
                setIsEditModalOpen(false);
                if (firstPartAndNew) {
                  deleteProject();
                  setFirstPartAndNew(false);
                }
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
                overflow: "hidden !important",
              }}
            >
              <EditProjectModal
                closeModal={() => setIsEditModalOpen(false)}
                project={selectedProject}
                isNewProject={isNew}
                isNew={isNew}
                setFirstPartAndNew={setFirstPartAndNew}
              />
            </Modal>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={isCropModalOpened}
        onClose={() => setIsCropModalOpened(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth="xl"
        maxWidth
      >
        <ImageCropper
          ratio={
            cropable == "logo" ? pictureRatios.logo : pictureRatios.coverPicture
          }
          src={cropable == "logo" ? logoUrl : coverPictureUrl}
          filename={
            cropable == "logo"
              ? logoUrl.split("filename=")[1] || `logo_${currentCompany.name}`
              : coverPictureUrl.split("filename=")[1] ||
                `cover_${currentCompany.name}`
          }
          closeModal={() => setIsCropModalOpened(false)}
          handleNewImg={(img, callback) => {
            setIsCropped(true);
            if (cropable == "logo") {
              setLogo(img[0]);
              setGeneralInfoErrors({
                ...generalInfoErrors,
                logo: "",
              });
            } else {
              setCoverPicture(img[0]);
              setGeneralInfoErrors({
                ...generalInfoErrors,
                cover_picture: "",
              });
            }
            if (callback) {
              setTimeout(() => {
                callback();
              }, 1500);
            }
          }}
        />
      </Dialog>
    </>
  );
};

export default Company;
