import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Link,
  List,
  ListItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import sh from "bundles/common/utils/sh";

const ResumeGenerator = ({ onSectionSelected }) => {
  const currentCandidate = useSelector(state => state.current_candidate);
  const authenticityToken = useSelector(state => state.authenticity_token);

  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const useStyles = makeStyles(() => ({
    shareModal: {
      "& .MuiDialog-container": {
        "& .MuiDialog-paper": {
          padding: isDesktop ? "48px" : "32px",
          borderRadius: "30px",
          width: "min(100%, 480px)",
        },
      },
    },
  }));
  const classes = useStyles();

  const [remainingSteps, setRemainingSteps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resumeUrl, setResumeUrl] = useState(null);
  const [shareModalOpen, setShareModalOpen] = useState(false);

  useEffect(() => {
    setRemainingSteps(steps.filter(step => !step.completed));
  }, [currentCandidate?.id]);

  const steps = [
    {
      number: 1,
      code: "profile_completion",
      label: "settings.cv_complete_profile_title",
      conditionLabel: "settings.cv_complete_profile_condition",
      subLabel: "settings.cv_complete_cta",
      completedSubLabel: "settings.cv_completed_cta",
      target: "profile",
      completed:
        currentCandidate?.first_name &&
        currentCandidate?.last_name &&
        currentCandidate?.email &&
        currentCandidate?.presentation,
    },
    {
      number: 2,
      code: "skills_completion",
      label: "settings.cv_complete_skills_title",
      conditionLabel: "settings.cv_complete_skills_condition",
      subLabel: "settings.cv_add_cta",
      completedSubLabel: "settings.cv_completed_cta",
      target: "skills",
      completed:
        currentCandidate?.hard_skills?.length > 0 &&
        currentCandidate?.soft_skills?.length > 0 &&
        currentCandidate?.language_skills?.length > 0,
    },
    {
      number: 3,
      code: "trainings_completion",
      label: "settings.cv_complete_trainings_title",
      conditionLabel: "settings.cv_complete_trainings_condition",
      subLabel: "settings.cv_add_cta",
      completedSubLabel: "settings.cv_completed_cta",
      target: "training",
      completed: currentCandidate?.trainings?.length > 0,
    },
    {
      number: 4,
      code: "experiences_completion",
      label: "settings.cv_complete_experiences_title",
      conditionLabel: "settings.cv_complete_experiences_condition",
      subLabel: "settings.cv_add_cta",
      completedSubLabel: "settings.cv_completed_cta",
      target: "experience",
      completed: currentCandidate?.experiences?.length > 0,
    },
  ];

  const handleCompleteClick = target => {
    onSectionSelected(target);
  };

  const copyResumeUrl = () => {
    navigator.clipboard.writeText(resumeUrl);
    enqueueSnackbar(intl.formatMessage({ id: "settings.copy_in_clipboard" }), {
      variant: "success",
    });
  };

  const downloadResume = url => {
    enqueueSnackbar(intl.formatMessage({ id: "settings.cv_being_generated" }), {
      variant: "success",
    });

    const link = document.createElement("a");
    link.href = url;
    link.download = `${currentCandidate.first_name}.pdf`;

    const clickEvent = new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    });

    link.dispatchEvent(clickEvent);
  };

  const generateResume = share => {
    if (share) {
      setShareModalOpen(true);
      if (resumeUrl) return;
    }

    setLoading(true);
    sh.get("/generate_resume", {
      authenticity_token: authenticityToken,
      candidate: currentCandidate,
    })
      .then(res => {
        if (res?.data?.resume_url) {
          setResumeUrl(res.data.resume_url);
          if (!share) {
            downloadResume(res.data.resume_url);
          }
        } else {
          enqueueSnackbar("Oops… an error occured.", {
            variant: "error",
          });
        }
      })
      .catch(() => {
        enqueueSnackbar("Oops… an error occured.", {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Box>
        <Typography variant="h4">
          <FormattedMessage id="settings.generate_your_cv" />
        </Typography>

        <Box style={{ marginTop: "24px" }}>
          <Typography variant="body1bold">
            {remainingSteps.length > 0 ? (
              <FormattedMessage
                id="settings.generate_cv_emphasis"
                values={{ remaining: remainingSteps.length }}
              />
            ) : (
              <FormattedMessage id="settings.generate_cv_completed" />
            )}
          </Typography>
        </Box>
        <List>
          {steps.map(step => {
            return (
              <Box style={{ marginBottom: "16px" }} key={step.code}>
                <ListItem style={{ paddingBottom: "0px" }}>
                  <Typography
                    variant="body2"
                    style={{
                      display: "flex",
                      width: "inherit",
                      alignItems: "center",
                    }}
                  >
                    <Box style={{ marginRight: "8px", paddingTop: "4px" }}>
                      {step.completed ? (
                        <CheckCircleIcon fontSize="small" color="primary" />
                      ) : (
                        <WarningIcon fontSize="small" color="warning" />
                      )}
                    </Box>

                    <Box style={{ display: "flex", columnGap: "8px" }}>
                      <Typography variant="body2bold">
                        <FormattedMessage id={step.label} />
                      </Typography>
                      {!step.completed && (
                        <Typography variant="body2">
                          <FormattedMessage id={step.conditionLabel} />
                        </Typography>
                      )}
                    </Box>
                  </Typography>
                </ListItem>
                <Link
                  onClick={() => handleCompleteClick(step.target)}
                  underline="hover"
                  color="neutral"
                  style={{ cursor: "pointer" }}
                >
                  <Typography variant="body2" style={{ marginLeft: "44px" }}>
                    <FormattedMessage
                      id={
                        step.completed ? step.completedSubLabel : step.subLabel
                      }
                    />
                  </Typography>
                </Link>
              </Box>
            );
          })}
        </List>
        <Box
          style={{
            display: "flex",
            flexDirection: isDesktop ? "row" : "column",
            justifyContent: "center",
            marginTop: "24px",
            columnGap: "16px",
            rowGap: "16px",
          }}
        >
          <Button
            variant="rounded"
            color="primaryContained"
            style={{ width: isDesktop ? "200px" : "100%" }}
            loading={loading}
            disabled={remainingSteps?.length > 0 || loading}
            onClick={() => generateResume(false)}
          >
            <FormattedMessage id="settings.generate_cta" />
          </Button>
          <Button
            variant="rounded"
            color="primaryOutlined"
            style={{ width: isDesktop ? "200px" : "100%" }}
            loading={loading}
            disabled={remainingSteps?.length > 0 || loading}
            onClick={() => generateResume(true)}
          >
            <FormattedMessage id="settings.share_resume_cta" />
          </Button>
        </Box>
      </Box>
      <Dialog
        open={shareModalOpen}
        onClose={() => setShareModalOpen(false)}
        aria-labelledby="share-resume-modal-title"
        aria-describedby="share-resume-modal-description"
        className={classes.shareModal}
      >
        <Box>
          <Box style={{ marginBottom: "16px" }}>
            <Typography variant="h2">
              <b>
                <FormattedMessage id="settings.share_resume_title" />
              </b>
            </Typography>
          </Box>

          <Box style={{ marginBottom: "12px" }}>
            <List>
              <ListItem style={{ padding: "0px" }}>
                <CheckCircleIcon
                  fontSize="small"
                  color="primary"
                  style={{ marginRight: "4px" }}
                />
                <Typography variant="body2">
                  <FormattedMessage id="settings.share_resume_email" />
                </Typography>
              </ListItem>
              <ListItem style={{ padding: "0px" }}>
                <CheckCircleIcon
                  fontSize="small"
                  color="primary"
                  style={{ marginRight: "4px" }}
                />
                <Typography variant="body2">
                  <FormattedMessage id="settings.share_resume_socials" />
                </Typography>
              </ListItem>
              <ListItem style={{ padding: "0px" }}>
                <CheckCircleIcon
                  fontSize="small"
                  color="primary"
                  style={{ marginRight: "4px" }}
                />
                <Typography variant="body2">
                  <FormattedMessage id="settings.share_resume_website" />
                </Typography>
              </ListItem>
            </List>
          </Box>

          <Box style={{ marginBottom: "24px" }}>
            <Box style={{ marginBottom: "4px" }}>
              <Typography variant="body3">
                <FormattedMessage id="settings.copy_url" />
              </Typography>
            </Box>
            {loading ? (
              <Box style={{ textAlign: "center" }}>
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <TextField fullWidth value={resumeUrl} onClick={copyResumeUrl} />
            )}
          </Box>

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              rowGap: "4px",
            }}
          >
            <Button
              fullWidth
              variant="rounded"
              color="primaryContained"
              onClick={copyResumeUrl}
              disabled={loading}
            >
              <FormattedMessage id="settings.copy_link" />
            </Button>
            <Button
              variant="textUnderline"
              style={{ width: "50%" }}
              disabled={loading}
              href={resumeUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="settings.open_link" />
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ResumeGenerator;
