import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Button,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import JobOfferCard from "../JobOfferCard";

const jobOffersSorting = (a, b) => {
  // Filter online first
  if (
    a.offer_status == "online" &&
    (b.offer_status == "draft" || b.offer_status == "closed")
  ) {
    return -1;
  } else if (a.offer_status == "online" && b.offer_status == "online") {
    //Then filter the closted to expire
    const diff = new Date(a.end_of_mandate) - new Date(b.end_of_mandate);

    // Then filter by pending candidates
    if (diff == 0) {
      return (
        a.job_applications.filter((app) => app.status == "pending").length -
        b.job_applications.filter((app) => app.status == "pending").length
      );
    } else {
      return diff;
    }
  } else {
    return 1;
  }
};

const useStyles = makeStyles(() => ({
  indexContainer: {
    width: "100% !important",
    marginLeft: "0",
  },
}));

const JobOffersSummary = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const currentCompany = currentRecruiter.company;
  const jobOffers = (currentCompany?.job_offers || []).sort((a, b) =>
    jobOffersSorting(a, b)
  );
  const displayedOffers = jobOffers.slice(0, 3);

  const getApplicationsCount = (offer, applicationStatus = null) => {
    if (!applicationStatus) return offer.job_applications.length;

    return offer.job_applications.filter(
      (application) => application.status == applicationStatus
    ).length;
  };
  const theme = useTheme();
  const classes = useStyles();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          flexDirection: isDesktop ? "row" : "column",
          justifyContent: "space-between",
          alignItems: isDesktop ? "center" : "start",
        }}
      >
        <Typography variant="h3" style={{ color: theme.palette.text.blue }}>
          <b>
            <FormattedMessage id="job_offer_summary.title" /> (
            {jobOffers.length || 0})
          </b>
        </Typography>
        {isDesktop && (
          <Button
            variant="contained"
            color="secondary"
            style={{
              height: "48px",
              borderRadius: "30px",
              minWidth: "204px",
              marginTop: "0px",
              marginBottom: "0px",
            }}
            href={`/${i18nLocale}/recruiter/job_offer_creation`}
          >
            <FormattedMessage id="landing_recruiter.new_offer" />
          </Button>
        )}
      </Box>
      {displayedOffers.length > 0 && (
        <Box style={{ marginTop: "16px" }}>
          <Grid container className={classes.indexContainer}>
            {displayedOffers.some(
              (offer) => offer.offer_status == "online"
            ) && (
              <Grid
                item
                xs={12}
                style={{ paddingLeft: "24px", marginBottom: "8px" }}
              >
                <Grid container>
                  <Grid item xs={6} md={3}>
                    {isDesktop && (
                      <FormattedMessage id="job_offer_summary.header_offers" />
                    )}
                  </Grid>
                  {isDesktop && (
                    <>
                      <Grid
                        item
                        xs={2}
                        style={{ textAlign: "center", paddingRight: "16px" }}
                      >
                        <FormattedMessage
                          id="job_offer_summary.count_candidates"
                          values={{ view: 2 }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        style={{ textAlign: "center", paddingRight: "32px" }}
                      >
                        <FormattedMessage
                          id="job_offer_summary.header_candidates"
                          values={{ candidates: 2 }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        style={{ textAlign: "center", paddingRight: "32px" }}
                      >
                        <FormattedMessage id="job_offer_summary.header_closed" />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={6} md={3}></Grid>
                </Grid>
              </Grid>
            )}
            {displayedOffers.map((offer) => (
              <Box key={`${offer.id}--summary`} style={{ width: "100%" }}>
                <JobOfferCard offer={offer} />
              </Box>
            ))}
            {jobOffers.length > displayedOffers.length && (
              <Grid
                item
                xs={12}
                style={{ textAlign: "center", marginTop: "8px" }}
              >
                <Button href={`/${i18nLocale}/recruiter/job_offers`}>
                  <FormattedMessage id="job_offer_summary.see_more" />
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
      {!isDesktop && (
        <Button
          variant="contained"
          color="secondary"
          style={{
            height: "48px",
            borderRadius: "30px",
            marginTop: "24px",
            marginLeft: "24px",
            marginBottom: "8px",
            width: "calc(100% - 48px)",
            alignSelf: "center",
          }}
          href={`/${i18nLocale}/recruiter/job_offer_creation`}
        >
          <FormattedMessage id="landing_recruiter.new_offer" />
        </Button>
      )}
    </Box>
  );
};

export default JobOffersSummary;
