import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import PhoneInput from "react-phone-input-2";
import { useSnackbar } from "notistack";
import { Grid, TextField, Typography, Button, useTheme } from "@mui/material";
import { countryMap } from "bundles/common/utils/constants";
import { replaceCurrentRecruiter } from "bundles/Recruiter/constants/recruiterConstants.js";
import sh from "bundles/common/utils/sh";

const Profile = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const intl = useIntl();
  const defaultPhoneValue = "+32 490 12 34 56";
  const initialFormValues = {
    first_name: currentRecruiter.first_name || "",
    last_name: currentRecruiter.last_name || "",
    phone: currentRecruiter.phone || "",
    email: currentRecruiter.email || "",
    job_title: currentRecruiter.job_title || "",
  };

  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );

    if ("first_name" in fieldValues) {
      temp.first_name = fieldValues.first_name ? "" : required;
    }

    if ("last_name" in fieldValues) {
      temp.last_name = fieldValues.last_name ? "" : required;
    }

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : required;
    }

    if ("phone" in fieldValues) {
      temp.phone =
        fieldValues.phone && fieldValues.phone != defaultPhoneValue
          ? ""
          : required;
    }

    if ("job_title" in fieldValues) {
      temp.job_title = fieldValues.job_title ? "" : required;
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handlePhoneInputClick = () => {
    if (values.phone === defaultPhoneValue)
      setValues({ ...values, ["phone"]: "" });
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (validate(values)) {
      sh.put("recruiter", {
        authenticity_token: authenticityToken,
        recruiter: { ...values, id: currentRecruiter.id },
      })
        .then(() => {
          replaceCurrentRecruiter(dispatch, authenticityToken, () => {});
          enqueueSnackbar(
            intl.formatMessage({ id: "recruiter.updated_profile" }),
            {
              variant: "success",
            }
          );
        })
        .catch((err) => {
          console.error(err);
          enqueueSnackbar("Oops... Something went wrong", { variant: "error" });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ marginBottom: "16px" }}>
          <Typography variant="h4">
            <FormattedMessage id="settings.general_info" />
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            label={<FormattedMessage id="devise.signup.first_name" />}
            name="first_name"
            value={values.first_name}
            variant="outlined"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            {...(errors["first_name"] && {
              error: true,
              helperText: errors["first_name"],
            })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            value={values.last_name}
            type="text"
            name="last_name"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            variant="outlined"
            label={<FormattedMessage id="devise.signup.last_name" />}
            {...(errors["last_name"] && {
              error: true,
              helperText: errors["last_name"],
            })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            value={values.email}
            type="text"
            name="email"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            variant="outlined"
            label={<FormattedMessage id="devise.signup.email" />}
            {...(errors["email"] && {
              error: true,
              helperText: errors["email"],
            })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PhoneInput
            country={"be"}
            onlyCountries={countryMap.values}
            inputStyle={{
              height: "56px",
              minWidth: "100%",
              color:
                values.phone == defaultPhoneValue
                  ? theme.palette.text.secondary
                  : theme.palette.text.primary,
            }}
            value={values.phone}
            color="primary"
            placeholder="+32 490 12 34 56"
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: false,
              value: values.phone,
              onBlur: handleInputValue,
              onChange: handleInputValue,
              onClick: handlePhoneInputClick,
            }}
            {...(errors["phone"] && {
              error: true,
              helperText: errors["phone"],
            })}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            required
            value={values.job_title}
            type="text"
            name="job_title"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            variant="outlined"
            label={<FormattedMessage id="recruiter.job_title" />}
            {...(errors["job_title"] && {
              error: true,
              helperText: errors["job_title"],
            })}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            disabled={loading}
            type="submit"
            variant="rounded"
            color="secondaryContained"
            style={{ width: "200px", marginTop: "16px" }}
          >
            <FormattedMessage id="save" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Profile;
