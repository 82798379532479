import React from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Button,
  Grid,
  Paper,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

const CreditsBox = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();
  const currentRecruiter = useSelector((state) => state.current_recruiter);

  const resumeLibraryCredit = currentRecruiter.resume_library_access;
  const jobOfferCredits = currentRecruiter.job_offer_credits || [];

  const groupedJobOfferCredits = jobOfferCredits.reduce(function (rv, x) {
    (rv[x["expire_at"]] = rv[x["expire_at"]] || []).push(x);
    return rv;
  }, {});

  const groupedJobOfferKeys = Object.keys(groupedJobOfferCredits);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const getCreditExpirationInDays = (credit) => {
    return Math.round((new Date(credit.expire_at) - Date.now()) / 86400000);
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        component={Paper}
        elevation={0}
        style={{
          padding: isDesktop ? "32px" : "24px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h3"
          style={{ color: theme.palette.text.blue, marginBottom: "32px" }}
        >
          <b>
            <FormattedMessage id="credits_box.title" />
          </b>
        </Typography>
        <Typography variant="h5" style={{ marginBottom: "4px" }}>
          <b>
            <FormattedMessage id="credits_box.job_offer_credits" /> (
            {jobOfferCredits.length})
          </b>
        </Typography>
        {groupedJobOfferKeys.length > 0 ? (
          groupedJobOfferKeys.map((key) => {
            return (
              <Grid
                container
                spacing={1}
                key={`${key}--groupedoffers`}
                style={{ alignItems: "baseline" }}
              >
                <Grid item xs={1}>
                  <Typography variant="body2">
                    <b>{groupedJobOfferCredits[key].length}</b>{" "}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body3" style={{ marginLeft: "4px" }}>
                    <FormattedMessage id="credits_box.remaining_credits" />
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body3"
                    style={{ color: theme.palette.primary.main }}
                  >
                    {getCreditExpirationInDays(groupedJobOfferCredits[key][0]) <
                    11 ? (
                        <b>
                          <FormattedMessage id="credits_box.expire_in" />{" "}
                          <Box component="span" style={{ marginRight: "4px" }}>
                            {getCreditExpirationInDays(
                              groupedJobOfferCredits[key][0]
                            )}
                          </Box>
                          {getCreditExpirationInDays(
                            groupedJobOfferCredits[key][0]
                          ) > 1
                            ? intl.formatMessage({ id: "credits_box.days" })
                            : intl.formatMessage({ id: "credits_box.day" })}
                        </b>
                      ) : (
                        <span>
                          <FormattedMessage id="credits_box.expire_in" />{" "}
                          <Box component="span" style={{ marginRight: "4px" }}>
                            {getCreditExpirationInDays(
                              groupedJobOfferCredits[key][0]
                            )}
                          </Box>
                          {getCreditExpirationInDays(
                            groupedJobOfferCredits[key][0]
                          ) > 1
                            ? intl.formatMessage({ id: "credits_box.days" })
                            : intl.formatMessage({ id: "credits_box.day" })}
                        </span>
                      )}
                  </Typography>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Typography variant="body3">
            <FormattedMessage id="credits_box.no_job_offer_credits" />
          </Typography>
        )}
        <Typography
          variant="h5"
          style={{ marginTop: "16px", marginBottom: "4px" }}
        >
          <b>
            <FormattedMessage id="credits_box.cv_access" />
          </b>
        </Typography>
        {resumeLibraryCredit ? (
          <Typography
            variant="body3"
            style={{ color: theme.palette.primary.main }}
          >
            <b>
              <FormattedMessage id="credits_box.access_expire_in" />
              <Box
                component="span"
                style={{ marginRight: "4px", marginLeft: "4px" }}
              >
                {getCreditExpirationInDays(resumeLibraryCredit)}
              </Box>
              {getCreditExpirationInDays(resumeLibraryCredit) > 1
                ? intl.formatMessage({ id: "credits_box.days" })
                : intl.formatMessage({ id: "credits_box.day" })}
            </b>
          </Typography>
        ) : (
          <Typography variant="body3">
            <FormattedMessage id="credits_box.no_cv_access" />
          </Typography>
        )}
        <Button
          variant="contained"
          color="secondary"
          style={{
            height: "48px",
            borderRadius: "30px",
            marginTop: "32px",
            marginLeft: "auto",
            marginRight: "auto",
            width: isDesktop ? "80%" : "100%",
          }}
          href={`/${i18nLocale}/recruiter/pricing`}
        >
          <FormattedMessage id="credits_box.add_credits" />
        </Button>
      </Grid>
    </Grid>
  );
};

export default CreditsBox;
