import React from "react";
import { useTheme } from "@mui/styles";
import { Box, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Banner = ({
  message,
  actionMessage,
  actionCallback,
  isLoading,
  message2,
  actionMessage2,
  closable = false,
  onClose = () => {},
}) => {
  const theme = useTheme();

  return (
    <Box
      style={{
        background: theme.palette.common.blue,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        columnGap: "4px",
        flexWrap: "wrap",
        padding: "8px",
      }}
    >
      <Box>{message}</Box>
      {actionMessage && (
        <Box>
          <Button
            variant="textUnderline"
            onClick={actionCallback}
            disabled={isLoading}
            size="small"
            style={{ padding: "0" }}
          >
            {actionMessage}
          </Button>
        </Box>
      )}
      {message2 && <Box>{message2}</Box>}
      {actionMessage2 && (
        <Box>
          <Button
            variant="textUnderline"
            onClick={actionCallback}
            disabled={isLoading}
            size="small"
            style={{ padding: "0" }}
          >
            {actionMessage2}
          </Button>
        </Box>
      )}
      {closable && (
        <IconButton
          size="small"
          aria-label="close"
          onClick={onClose}
          style={{
            marginLeft: "auto",
            color: theme.palette.common.white,
          }}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
      )}
    </Box>
  );
};

export default Banner;
