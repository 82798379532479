import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import LoginOrSignin from "./Authentication/LoginOrSignin";
import Login from "./Authentication/Login";
import SignUp from "./Authentication/SignUp";
import ForgottenPassword from "./Authentication/ForgottenPassword";
import ResendConfirmation from "./Authentication/ResendConfirmation";

const Authentication = ({
  onActionFinished,
  customScope = "",
  section = "login",
  singleJob = true,
  addResumeLogin = false,
  addFavoriteLogin = false,
  isSpontaneous = false
}) => {
  const [currentSection, setCurrentSection] = useState(section);
  let login_or_signin_title;
  if (addResumeLogin) {
    login_or_signin_title = "login_or_signin_add_resume";
  } else if (addFavoriteLogin) {
    login_or_signin_title = "login_or_signin_add_favorite";
  } else if (isSpontaneous) {
    login_or_signin_title = "login_or_signin_spontaneous_application";
  } else if (singleJob) {
    login_or_signin_title = "login_or_signin";
  } else {
    login_or_signin_title = "login_or_signin_multi_jobs";
  }

  const titles = {
    login: "connection_login",
    sign_up: "please_sign_up",
    resend_confirmation: "please_confirm_email",
    forgotten_password: "please_new_password",
    login_or_signin: login_or_signin_title,
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box>
      <Typography
        variant="h1"
        style={{
          marginBottom: "16px",
          textAlign: "center",
          fontSize: isDesktop ? "32px" : "18px",
        }}
      >
        <b>
          <FormattedMessage id={titles[currentSection]} />
        </b>
      </Typography>
      {currentSection == "login_or_signin" && (
        <LoginOrSignin onSectionClicked={setCurrentSection} />
      )}
      {currentSection == "login" && (
        <Login
          onSectionClicked={setCurrentSection}
          onActionFinished={onActionFinished}
          customScope={customScope}
        />
      )}
      {currentSection == "sign_up" && (
        <SignUp
          onSectionClicked={setCurrentSection}
          onActionFinished={onActionFinished}
          customScope={customScope}
        />
      )}
      {currentSection == "resend_confirmation" && (
        <ResendConfirmation
          onSectionClicked={setCurrentSection}
          onActionFinished={onActionFinished}
        />
      )}
      {currentSection == "forgotten_password" && (
        <ForgottenPassword onSectionClicked={setCurrentSection} />
      )}
    </Box>
  );
};

export default Authentication;
