import React, { useState, useEffect, useCallback }  from "react";
import JobOfferSummaryDesktop from "./Desktop/JobOfferSummaryDesktop";
import JobOfferSummaryMobile from "./Mobile/JobOfferSummaryMobile";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  incrementJobOfferMetric,
} from "bundles/common/utils/utils";
import { trackJobOfferApplication } from "bundles/common/utils/marketing";
import sh from "bundles/common/utils/sh";

const JobOfferSummary = ({
  jobOffer,
  updateJobOffer,
  skills,
  isApplying,
  setIsApplying,
  isApplyModal,
  company = jobOffer?.company,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const currentCandidate = useSelector((state) => state.current_candidate);
  const railsEnv = useSelector((state) => state.railsEnv);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isEasyApplying, setIsEasyApplying] = useState(false);
  const [candidateApplication, setCandidateApplication] = useState(null);


  useEffect(() => {
    if (jobOffer?.id && jobOffer?.job_applications?.length > 0 && !isApplying) {
      let app = jobOffer?.job_applications.find(
        (application) => application.candidate_id == currentCandidate?.id
      );
      setCandidateApplication(app);
    } else {
      setCandidateApplication(null);
    }
  }, [jobOffer?.id, jobOffer?.job_applications?.length, isApplying]);

  const hard_skills = skills?.filter((skill) => skill.category == "hard");
  const soft_skills = skills?.filter((skill) => skill.category == "soft");
  const languages = skills?.filter((skill) => skill.category == "lang");

  const handleApply = () => {
    incrementJobOfferMetric(
      jobOffer,
      "intent_apply_count",
      authenticityToken,
      true
    );
    if (jobOffer?.external_application) {
      let url = jobOffer?.external_application_url;
      if (!url.includes("http")) url = `http://${url}`;
      window.open(url);
    } else {
      if (candidateApplication || !currentCandidate.resume_url) {
        setIsApplying(true);
      } else {
        setIsEasyApplying(true);
        sh.post("/job_application", {
          authenticity_token: authenticityToken,
          job_offer_id: jobOffer.id,
          resume_url: currentCandidate.resume_url,
        })
          .then((res) => {
            setTimeout(() => {
              if (railsEnv === "production") trackJobOfferApplication();
              setIsEasyApplying(false);
              updateJobOffer(res.data);
              enqueueSnackbar(
                intl.formatMessage({ id: "applicant.application_submitted" }),
                {
                  variant: "success",
                }
              );
            }, 1000);
          })
          .catch((err) => {
            console.error(err);
            setIsEasyApplying(false);
            let message = currentCandidate.whitelisted ? "settings.save_failed" : "settings.must_be_whitelisted";
            enqueueSnackbar(
              intl.formatMessage({ id: message }),
              {
                variant: "error",
              }
            );
          });
      }
    }
  };

  const updatecurrentCandidate = useCallback(
    (candidate) => {
      dispatch({ type: "UPDATE_CURRENT_CANDIDATE", payload: candidate });
    },
    [dispatch]
  );

  const [currentFavorites, setCurrentFavorites] = useState(
    currentCandidate?.favorite_offers || []
  );

  const isFavorite = currentFavorites.includes(`${jobOffer?.id}`);

  const handleFavorite = (e) => {
    e.stopPropagation();
    e.preventDefault();

    let currentFavorites;
    if (isFavorite) {
      currentFavorites = currentCandidate?.favorite_offers.filter(
        (favorite) => favorite != `${jobOffer?.id}`
      );
    } else {
      currentFavorites = currentCandidate?.favorite_offers.concat([
        jobOffer?.id,
      ]);
    }

    sh.put("update_favorite_offers", {
      authenticity_token: authenticityToken,
      favorite_offers: currentFavorites,
    }).then((res) => {
      if (res.data) {
        setCurrentFavorites(res.data.favorite_offers);
        updatecurrentCandidate(res.data);
      }
    });
  };

  const publicJobUrl = `${window.location.origin}/${i18nLocale}/jobs/${jobOffer?.encoded_job_title}/${jobOffer?.id}`;

  useEffect(() => {
    setCurrentFavorites(currentCandidate?.favorite_offers);
  }, [currentCandidate?.favorite_offers]);

  return (
    <>
      {isDesktop
        ?
        <JobOfferSummaryDesktop
          jobOffer={jobOffer}
          isApplyModal={isApplyModal}
          company={company}
          handleApply={handleApply}
          handleFavorite={handleFavorite}
          isFavorite={isFavorite}
          publicJobUrl={publicJobUrl}
          hard_skills={hard_skills}
          soft_skills={soft_skills}
          isEasyApplying={isEasyApplying}
          languages={languages}
          candidateApplication={candidateApplication}
          enqueueSnackbar={enqueueSnackbar}
        />
        :
        <JobOfferSummaryMobile
          jobOffer={jobOffer}
          isApplyModal={isApplyModal}
          company={company}
          handleApply={handleApply}
          handleFavorite={handleFavorite}
          isFavorite={isFavorite}
          publicJobUrl={publicJobUrl}
          hard_skills={hard_skills}
          soft_skills={soft_skills}
          isEasyApplying={isEasyApplying}
          languages={languages}
          candidateApplication={candidateApplication}
          enqueueSnackbar={enqueueSnackbar}
        />
      }
    </>
  );
};

export default JobOfferSummary;
