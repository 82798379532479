import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Link,
  Box,
  CircularProgress,
  Divider,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import sh from "bundles/common/utils/sh";
import { useSnackbar } from "notistack";

const ForgottenPassword = ({ onSectionClicked }) => {
  const authenticity_token = useSelector((state) => state.authenticity_token);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const initialFormValues = {
    email: "",
  };

  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : required;
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (validate(values)) {
      sh.post(`/${i18nLocale}/candidates/password`, {
        authenticity_token,
        candidate: {
          email: values.email,
        },
      })
        .then(() => {
          setIsSuccess(true);
        })
        .catch(() => {
          enqueueSnackbar(
            intl.formatMessage({
              id: "flash.unknown",
            }),
            { variant: "error" }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{ marginBottom: "32px" }}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Box>
            <Box
              disableTypography
              title={
                <Typography variant="h4" align="center">
                  <FormattedMessage
                    id="devise.forgotten_password"
                    values={{
                      b: (chunks) => <b>{chunks}</b>,
                    }}
                  />
                </Typography>
              }
            ></Box>
            <Box>
              <Grid container spacing={2} justifyContent="center">
                {!isSuccess ? (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">
                        <FormattedMessage id="devise.password.reset_password_instructions" />
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "16px" }}>
                      <TextField
                        fullWidth
                        required
                        type="email"
                        margin="normal"
                        variant="outlined"
                        onBlur={handleInputValue}
                        onChange={handleInputValue}
                        name="email"
                        {...(errors["email"] && {
                          error: true,
                          helperText: errors["email"],
                        })}
                        label={<FormattedMessage id="devise.signup.email" />}
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} style={{ marginBottom: "16px" }}>
                    <Typography variant="body1">
                      <FormattedMessage id="devise.password.reset_password_success" />
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    color="primaryContained"
                    type="submit"
                    variant="rounded"
                    disabled={loading || isSuccess}
                  >
                    <Box justifyContent="center">
                      {loading && (
                        <CircularProgress size={16} color="secondary">
                          <FormattedMessage id="send" />
                        </CircularProgress>
                      )}
                      {!loading && <FormattedMessage id="send" />}
                    </Box>
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Box>
                    <Typography variant="body2" align="center">
                      <Link
                        onClick={() => onSectionClicked("login")}
                        color="primary"
                        underline="always"
                        style={{ cursor: "pointer" }}
                      >
                        <FormattedMessage id="devise.password.back_to_login" />
                      </Link>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
};

export default ForgottenPassword;
