import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import ChatBot from "react-simple-chatbot";
import { useIntl } from "react-intl";
import { Container } from "@mui/material";
import RegionSelect from "./BotRecommendation/RegionSelect";
import CandidateSelect from "./BotRecommendation/CandidateSelect";
import Sasha from "pictures/sasha_new.jpg";
import defaultAvatar from "icons/avatar.svg";

const CandidateRecommendationBot = () => {
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [isChatbotComplete, setIsChatbotComplete] = useState(false);
  const i18nLocale = useSelector((state) => state.i18nLocale);

  useEffect(() => {
    if (isChatbotComplete) {
      window.location.href = getCandidateUrl();
    }
  }, [isChatbotComplete]);

  function getCandidateUrl() {
    let baseUrl = `${window.location.origin}/${i18nLocale}/recruiter/find_candidate`;

    const queryParams = new URLSearchParams();
    if (selectedSector) {
      queryParams.append("selectedSector", selectedSector);
    }
    if (selectedRegion.length > 0) {
      queryParams.append("selectedRegion", selectedRegion.join(","));
    }
    if (selectedExperience) {
      queryParams.append("selectedExperience", selectedExperience);
    }

    return `${baseUrl}?${queryParams.toString()}`;
  }

  const candidateUrl = getCandidateUrl();
  const intl = useIntl();
  const currentRecruiter = useSelector(state => state.current_recruiter);

  const WelcomeMessage = () => {
    const id = "candidate_recommendation_bot.step_one.welcome";
    const value = { recruiter: currentRecruiter?.first_name };
    return intl.formatMessage({ id: id }, value);
  };

  const onSectorSelect = (sector) => {
    setSelectedSector(sector);
  };

  const onRegionSelect = (region) => {
    setSelectedRegion(region);
  };

  const onExperienceSelect = (experience) => {
    setSelectedExperience(experience);
  };

  const handleChatbotCompletion = () => {
    setIsChatbotComplete(true);
  };

  const chatbot_theme = {
    background: "#f5f8fb",
    botDelay: 20000,
    userDelay: 0,
    fontFamily: "Work Sans Bold",
    headerBgColor: "#2A334E",
    headerFontColor: "#fff",
    headerFontSize: "15px",
    botBubbleColor: "#E4E6EB",
    botFontColor: "black",
    userBubbleColor: "#2A334E",
    userFontColor: "#fff",
    boxShadow: "0 2px 5px #2A334E",
  };

  const chatBotSteps = [
    {
      id: "0",
      hideInput: true,
      message: WelcomeMessage(),
      trigger: "1",
    },
    {
      id: "1",
      hideInput: true,
      options: [
        {
          value: "architecture",
          label: intl.formatMessage({ id: "company.company_industry.architecture" }),
          trigger: () => {
            onSectorSelect("architecture");
            return "2";
          },
        },
        {
          value: "engineering",
          label: intl.formatMessage({ id: "company.company_industry.engineering" }),
          trigger: () => {
            onSectorSelect("engineering");
            return "2";
          },
        },
        {
          value: "construction",
          label: intl.formatMessage({ id: "company.company_industry.construction" }),
          trigger: () => {
            onSectorSelect("construction");
            return "2";
          },
        },
      ],
    },
    {
      id: "2",
      hideInput: true,
      message: `${intl.formatMessage({
        id: "candidate_recommendation_bot.step_one.second_message",
        values: { recruiter: currentRecruiter?.first_name },
      })}`,
      trigger: "3",
    },
    {
      id: "3",
      hideInput: true,
      component: (
        <RegionSelect
          onRegionSelect={onRegionSelect}
        />
      ),
      waitAction: true,
      replace: false,
      trigger: "4",
    },
    {
      id: "4",
      hideInput: true,
      message: `${intl.formatMessage({
        id: "candidate_recommendation_bot.step_three.experience_length",
      })}`,
      trigger: "5",
    },
    {
      id: "5",
      hideInput: true,
      options: [
        {
          value: "1",
          label: intl.formatMessage({ id: "candidate_recommendation_bot.step_three.low_experience" }),
          trigger: () => {
            onExperienceSelect("0, 1, 2");
            return "6";
          },
        },
        {
          value: "5",
          label: intl.formatMessage({ id: "candidate_recommendation_bot.step_three.low_medium_experience" }),
          trigger: () => {
            onExperienceSelect("3,4,5");
            return "6";
          },
        },
        {
          value: "7",
          label: intl.formatMessage({ id: "candidate_recommendation_bot.step_three.medium_experience" }),
          trigger: () => {
            onExperienceSelect("6,7,8,9,10");
            return "6";
          },
        },
        {
          value: "10",
          label: intl.formatMessage({ id: "candidate_recommendation_bot.step_three.high_experience" }),
          trigger: () => {
            onExperienceSelect("10+");
            return "6";
          },
        },
      ],
    },
    {
      id: "6",
      hideInput: true,
      message: `${intl.formatMessage({
        id: "candidate_recommendation_bot.step_one.good_news",
        values: { recruiter: currentRecruiter?.first_name },
      })}`,
      trigger: "7",
    },
    {
      id: "7",
      hideInput: true,
      component: (
        <CandidateSelect
          onChatbotComplete={handleChatbotCompletion}
        />
      ),
      end: true,
    },
  ];

  return (
    <Container>
      <ThemeProvider theme={chatbot_theme}>
        <ChatBot
          hideInput={true}
          steps={chatBotSteps}
          floating={true}
          opened={true}
          headerTitle={intl.formatMessage({
            id: "candidate_recommendation_bot.header_title",
          })}
          botAvatar={Sasha}
          userAvatar={currentRecruiter ? currentRecruiter.company.small_logo_url : defaultAvatar}
        />
      </ThemeProvider>
    </Container>
  );
};

export default CandidateRecommendationBot;
