import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Grid, Button, CircularProgress, TextField, useTheme, useMediaQuery } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import validator from "validator";
import sh from "bundles/common/utils/sh";
import { useSelector } from "react-redux";

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const AcceptInvitation = () => {
  const invitee = useSelector((state) => state.invitee);
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const query = useQuery();
  const intl = useIntl();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { enqueueSnackbar } = useSnackbar();

  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "80vh",
    },
    mainBox: {
      height: "auto",
      backgroundColor: "white",
      display: "flex",
      borderRadius: "8px",
      margin: isDesktop ? "" : "60px"
    },
    sideBox: {
      width: isDesktop ? "50%" : "100%",
      overflow: "scroll",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.dark,
      borderTopLeftRadius: "8px",
      borderTopRightRadius: isDesktop ? "" : "8px",
      borderBottomLeftRadius: isDesktop ? "8px" : "",
      padding: isDesktop ? "2rem" : "1rem",
    },
    formBox: {
      width: isDesktop ? "50%" : "100%",
      padding: isDesktop ? "" : "1rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "auto"
    },
    loginCard: {
      width: "440px"
    }
  }));
  const classes = useStyles();

  const initialFormValues = {
    password: "",
    password_confirmation: "",
  };

  const invitationToken = query.get("invitation_token");

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = <FormattedMessage id="settings.invitations.required" />;
    let notAStrongPassword = <FormattedMessage id="settings.invitations.strong_password" />;

    if ("password" in fieldValues) {
      temp.password = fieldValues.password ? "" : required;
      if (
        fieldValues.password &&
        !validator.isStrongPassword(fieldValues.password)
      ) {
        temp.password = notAStrongPassword;
      }
    }

    if ("password_confirmation" in fieldValues) {
      temp.password_confirmation = fieldValues.password_confirmation ? "" : required;
      if (fieldValues.password && fieldValues.password_confirmation != fieldValues.password) {
        temp.password_confirmation = <FormattedMessage id="settings.invitations.no_match" />;
      }
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (validate(values)) {
      sh.put(`/${i18nLocale}/recruiters/invitation`, {
        authenticity_token: authenticityToken,
        password: values.password,
        password_confirmation: values.password_confirmation,
        invitation_token: invitationToken
      })
        .then(() => {
          enqueueSnackbar(
            intl.formatMessage({ id: "settings.invitations.success_signin" }),
            {
              variant: "success",
            }
          );
          setTimeout(() => {
            setLoading(false);
            window.location.pathname = "/fr/recruiter";
          }, 400);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <Box className={classes.container}>
      <Grid container direction={isDesktop ? "row" : "column"} className={classes.mainBox}>
        <Box className={classes.sideBox} style={{ textAlign: !isDesktop ? "center" : "initial" }}>
          <h1 style={{ marginBottom: "8px" }}>
            <FormattedMessage id="settings.invitations.welcome" values={{ first_name: invitee.first_name }} />
          </h1>
          <h3 style={{ marginBottom: "32px" }}>
            <FormattedMessage id="settings.invitations.choose_password" />
          </h3>
        </Box>
        <Box className={classes.formBox}>
          <form onSubmit={handleSubmit} style={{ width: isDesktop ? "min(50%, 600px)" : "min(80%, 600px)" }}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  type="password"
                  variant="outlined"
                  onBlur={handleInputValue}
                  onChange={handleInputValue}
                  name="password"
                  label={<FormattedMessage id="settings.invitations.password_label" />}
                  {...(errors["password"] && {
                    error: true,
                    helperText: errors["password"],
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  size="small"
                  type="password"
                  variant="outlined"
                  onBlur={handleInputValue}
                  onChange={handleInputValue}
                  name="password_confirmation"
                  label={<FormattedMessage id="settings.invitations.password_confirmation_label" />}
                  {...(errors["password_confirmation"] && {
                    error: true,
                    helperText: errors["password_confirmation"],
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  type="submit"
                  variant="rounded"
                  color="secondaryContained"
                  size="medium"
                  disabled={loading}
                >
                  <Box justifyContent="center">
                    {loading && (
                      <CircularProgress size={16} color="secondary">
                        <FormattedMessage id="settings.invitations.accepting" />
                      </CircularProgress>
                    )}
                    {!loading && <FormattedMessage id="settings.invitations.set_password" />}
                  </Box>
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
    </Box>
  );
};

export default AcceptInvitation;
