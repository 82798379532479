import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import ChatBot from "react-simple-chatbot";
import { useIntl } from "react-intl";
import { Container } from "@mui/material";
import RegionSelect from "./BotRecommendation/RegionSelect";
import JobOfferSelect from "./BotRecommendation/JobOfferSelect";
import Sasha from "pictures/sasha_new.jpg";
import defaultAvatar from "icons/avatar.svg";

const JobOfferRecommendationBot = () => {
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [isChatbotComplete, setIsChatbotComplete] = useState(false);
  const i18nLocale = useSelector(state => state.i18nLocale);

  useEffect(() => {
    if (isChatbotComplete) {
      window.location.href = getJobOfferUrl();
    }
  }, [isChatbotComplete]);

  function getJobOfferUrl() {
    let baseUrl = `${window.location.origin}/${i18nLocale}/candidate/job_offers`;

    const queryParams = new URLSearchParams();
    if (selectedSector) {
      queryParams.append("selectedSector", selectedSector);
    }
    if (selectedRegion.length > 0) {
      queryParams.append("selectedRegion", selectedRegion.join(","));
    }

    return `${baseUrl}?${queryParams.toString()}`;
  }

  const jobOfferUrl = getJobOfferUrl();
  const intl = useIntl();
  const currentCandidate = useSelector(state => state.current_candidate);

  const WelcomeMessage = () => {
    const id = "job_offer_recommendation_bot.step_one.welcome";
    const value = { candidate: currentCandidate?.first_name };
    return intl.formatMessage({ id: id }, value);
  };

  const onSectorSelect = sector => {
    setSelectedSector(sector);
  };

  const onRegionSelect = (region) => {
    setSelectedRegion(region);
  };

  const handleChatbotCompletion = () => {
    setIsChatbotComplete(true);
  };

  const themes = {
    background: "#f5f8fb",
    botDelay: 20000,
    userDelay: 0,
    fontFamily: "Work Sans Bold",
    headerBgColor: "#079344",
    headerFontColor: "#fff",
    headerFontSize: "15px",
    botBubbleColor: "#E4E6EB",
    botFontColor: "black",
    userBubbleColor: "#079344",
    userFontColor: "#fff",
    boxShadow: "0 2px 5px #2A334E",
  };

  const steps = [
    {
      id: "0",
      hideInput: true,
      message: WelcomeMessage(),
      trigger: "1",
    },
    {
      id: "1",
      hideInput: true,
      options: [
        {
          value: "architecture",
          label: intl.formatMessage({ id: "company.company_industry.architecture" }),
          trigger: () => {
            onSectorSelect("architecture");
            return "2";
          },
        },
        {
          value: "engineering",
          label: intl.formatMessage({ id: "company.company_industry.engineering" }),
          trigger: () => {
            onSectorSelect("engineering");
            return "2";
          },
        },
        {
          value: "construction",
          label: intl.formatMessage({ id: "company.company_industry.construction" }),
          trigger: () => {
            onSectorSelect("construction");
            return "2";
          },
        },
      ],
    },
    {
      id: "2",
      hideInput: true,
      message: `${intl.formatMessage({
        id: "candidate_recommendation_bot.step_one.second_message",
        values: { recruiter: currentCandidate?.first_name },
      })}`,
      trigger: "3",
    },
    {
      id: "3",
      hideInput: true,
      component: (
        <RegionSelect
          onRegionSelect={onRegionSelect}
        />
      ),
      waitAction: true,
      replace: false,
      trigger: "4",
    },
    {
      id: "4",
      hideInput: true,
      message: `${intl.formatMessage({
        id: "job_offer_recommendation_bot.step_one.good_news",
        values: { candidate: currentCandidate?.first_name },
      })}`,
      trigger: "5",
    },
    {
      id: "5",
      hideInput: true,
      component: (
        <JobOfferSelect
          onChatbotComplete={handleChatbotCompletion}
        />
      ),
      end: true,
    },
  ];

  return (
    <Container>
      <ThemeProvider theme={themes}>
        <ChatBot
          hideInput={true}
          steps={steps}
          floating={true}
          opened={true}
          headerTitle={intl.formatMessage({
            id: "candidate_recommendation_bot.header_title",
          })}
          botAvatar={Sasha}
          userAvatar={currentCandidate ? currentCandidate.small_profile_picture_url : defaultAvatar}
        />
      </ThemeProvider>
    </Container>
  );
};
export default JobOfferRecommendationBot;
