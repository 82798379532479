import React, { Fragment, useState } from "react";
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Button,
  Link,
  Divider,
  Box,
  CircularProgress,
} from "@mui/material";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import sh from "bundles/common/utils/sh";
import { useSnackbar } from "notistack";
import validator from "validator";
import { useTheme } from "@mui/styles";
import PasswordField from "bundles/common/components/PasswordField";

const ChangePassword = ({ userType }) => {
  const authenticity_token = useSelector((state) => state.authenticity_token);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const initialFormValues = {
    password: "",
    confirm_password: "",
  };

  const reset_password_token = new URLSearchParams(
    window?.location?.search
  )?.get("reset_password_token");

  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );
    let not_a_strong_password = (
      <FormattedMessage id="form_validation.not_a_strong_password"></FormattedMessage>
    );
    let password_does_not_match = (
      <FormattedMessage id="form_validation.password_does_not_match"></FormattedMessage>
    );

    if ("password" in fieldValues) {
      temp.password = fieldValues.password ? "" : required;
      if (fieldValues.password) {
        if (!validator.isStrongPassword(fieldValues.password))
          temp.password = not_a_strong_password;
        else {
          temp.password = "";
          // Recalculates password match in case confirm_password was already provided
          if (values.confirm_password) {
            temp.confirm_password = validator.equals(
              fieldValues.password,
              values.confirm_password
            )
              ? ""
              : password_does_not_match;
          }
        }
      }
    }

    if ("confirm_password" in fieldValues) {
      temp.confirm_password = fieldValues.confirm_password ? "" : required;
      if (fieldValues.confirm_password) {
        if (!validator.equals(values.password, fieldValues.confirm_password))
          temp.confirm_password = password_does_not_match;
        else temp.confirm_password = "";
      }
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (validate(values)) {
      sh.put(`${i18nLocale}/${userType}/password`, {
        authenticity_token,
        [userType.slice(0, -1)]: {
          password: values.password,
          password_confirmation: values.confirm_password,
          reset_password_token,
        },
      })
        .then(() => {
          setIsSuccess(true);
        })
        .catch(() => {
          enqueueSnackbar(
            intl.formatMessage({
              id: "flash.unknown",
            }),
            { variant: "error" }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Box my={4} />
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              disableTypography
              title={
                <Typography variant="h4" align="center">
                  <FormattedMessage
                    id="devise.password.reset_my_password"
                    values={{
                      b: (chunks) => <b>{chunks}</b>,
                    }}
                  />
                </Typography>
              }
            ></CardHeader>
            <CardContent>
              <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Typography align="center">
                    <b>
                      <FormattedMessage id="devise.password.choose_password"></FormattedMessage>
                    </b>
                  </Typography>
                </Grid>
                {!isSuccess ? (
                  <Fragment>
                    <Grid item xs={12}>
                      <PasswordField
                        name="password"
                        labelId="devise.signup.password"
                        handleInputValue={handleInputValue}
                        errors={errors}
                      />
                      <PasswordField
                        name="confirm_password"
                        labelId="devise.signup.confirm_password"
                        handleInputValue={handleInputValue}
                        errors={errors}
                      />
                    </Grid>
                  </Fragment>
                ) : (
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <FormattedMessage id="devise.password.update_password_success" />
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <CardActions>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    color="primary"
                    {...(userType == "recruiters" &&
                      !loading &&
                      !isSuccess && {
                      style: {
                        backgroundColor: theme.palette.text.blue,
                        color: theme.palette.text.white,
                      },
                    })}
                    type="submit"
                    variant="contained"
                    disabled={loading || isSuccess}
                  >
                    <Box justifyContent="center">
                      {loading && (
                        <CircularProgress size={16} color="secondary">
                          <FormattedMessage id="send" />
                        </CircularProgress>
                      )}
                      {!loading && <FormattedMessage id="send" />}
                    </Box>
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Box mb={2}>
                    <Typography variant="body2" align="center">
                      <Link
                        href={`/${i18nLocale}/${userType}/sign_in`}
                        color="primary"
                        underline="always"
                      >
                        <FormattedMessage
                          id={
                            isSuccess
                              ? "devise.password.go_to_homepage"
                              : "devise.password.back_to_login"
                          }
                        />
                      </Link>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </form>
      </Grid>
    </Grid>
  );
};

export default ChangePassword;
