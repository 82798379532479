import React from "react";
import { Divider, Typography, Box } from "@mui/material";
import { FormattedMessage } from "react-intl";

const TextDivider = ({ labelId }) => {
  return (
    <Box display="flex" alignItems="center">
      <Divider style={{ flexGrow: 2 }} />
      <Box style={{ marginLeft: "8px", marginRight: "8px" }}>
        <Typography>
          <FormattedMessage id={labelId} />
        </Typography>
      </Box>
      <Divider style={{ flexGrow: 2 }} />
    </Box>
  );
};

export default TextDivider;
