import { Box, Grid, Typography, Container } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import TextBlock from "./TextBlock";

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box my={2}>
            <Typography variant="h1" align="center">
              <b>
                <FormattedMessage id="footer.privacy_policy.privacy_policy" />
              </b>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.privacy_policy.preambule_title"
            textId="footer.privacy_policy.preambule_html"
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.privacy_policy.data_controller_title"
            textId="footer.privacy_policy.data_controller_html"
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.privacy_policy.consent_title"
            textId="footer.privacy_policy.consent_html"
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.privacy_policy.data_title"
            textId="footer.privacy_policy.data_html"
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.privacy_policy.purpose_and_legal_basis_title"
            textId="footer.privacy_policy.purpose_and_legal_basis_html"
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.privacy_policy.technical_measures_title"
            textId="footer.privacy_policy.technical_measures_html"
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.privacy_policy.data_access_title"
            textId="footer.privacy_policy.data_access_html"
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.privacy_policy.personal_data_protection_title"
            textId="footer.privacy_policy.personal_data_protection_html"
          />
        </Grid>
        <Grid item xs={12}>
          <TextBlock
            titleId="footer.privacy_policy.contact_us_title"
            textId="footer.privacy_policy.contact_us_html"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" style={{ whiteSpace: "pre-wrap" }}>
            <FormattedMessage id="footer.privacy_policy.last_update" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box my={4} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PrivacyPolicy;
