import React, { useState, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { getOfferStatus, truncate } from "bundles/common/utils/utils";
import ConfirmModal from "bundles/common/components/ConfirmModal";
import { formatJobOfferLocations } from "bundles/common/utils/utils";
import sh from "bundles/common/utils/sh";

const JobOfferCard = (props) => {
  const offer = props.offer;
  const key = props.key;
  const selected = props.selected || false;
  const isIndex = props.handleJobClicked != null;
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const theme = useTheme();
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const useStyles = makeStyles({
    countItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    jobCard: {
      minHeight: "82px",
      padding: isDesktop ? "0px 24px !important" : "16px",
      marginBottom: "16px !important",
      display: "flex",

      backgroundColor: selected ? "#F4F4F4" : theme.palette.common.white,

      "&:hover": {
        backgroundColor: selected ? "#F4F4F4" : "#FCFCFC",
        cursor: "pointer",
      },
    },
    statusBox: {
      display: "flex",
      alignItems: "center",
      marginTop: "4px",
    },
    statusBoxActive: {
      color: theme.palette.primary.main,
    },
    statusBoxBold: {
      fontWeight: "700",
    },
    statusIcon: {
      height: "16px",
      width: "16px",
      marginRight: "4px",
    },
  });
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const handleJobClicked = (offer) => {
    if (props.handleJobClicked) return props.handleJobClicked(offer);

    let jobUrl = `/${i18nLocale}/recruiter/job_offers?selectedTab=${offer.offer_status}&jid=${offer.id}`;
    navigate(jobUrl);
  };

  const handleApplicationsCountClicked = (e) => {
    e.stopPropagation();
    navigate(`/${i18nLocale}/recruiter/my_candidates`);
  };

  const handleDuplicateClicked = (e) => {
    e.stopPropagation();
    sh.get(`recruiter/duplicate_job_offers/${offer.id}`, {
      authenticity_token: authenticityToken,
    })
      .then((res) => {
        window.location.href = `/${i18nLocale}/recruiter/job_offer_creation?jobOffer=${res.data.data.id}`;
      })
      .catch(() => {
        enqueueSnackbar(
          intl.formatMessage({
            id: "flash.unknown",
          }),
          { variant: "error" }
        );
      });
  };

  const handlePublishClicked = (e) => {
    setIsConfirmModalOpen(true);
  };

  const handlePublish = (e) => {
    e.stopPropagation();
    sh.put(`job_offer/${offer.id}`, {
      authenticity_token: authenticityToken,
      job_offer: offer,
      skills: offer.skills,
      with_publish: true,
    })
      .then((res) => {
        handleSuccessfulSave(res, false);
      })
      .catch((err) => {
        console.log(err);
        handleFailedSave(err);
      });
  };

  const handleSuccessfulSave = (res, created) => {
    let status = "";
    if (res.data.result.publish_error) {
      const messageId = created
        ? "flash.success.job_offer_creation_without_publish"
        : "flash.success.job_offer_edition_without_publish";

      enqueueSnackbar(
        intl.formatMessage({
          id: messageId,
        }),
        { variant: "warning" }
      );

      status = "draft";
    } else {
      const messageId = created
        ? "flash.success.job_offer_creation_with_publish"
        : "flash.success.job_offer_edition_with_publish";

      enqueueSnackbar(
        intl.formatMessage({
          id: messageId,
        }),
        { variant: "success" }
      );
      updateCurrentRecruiter(res.data.recruiter);
      status = "online";
    }
    navigate({
      pathname: `/${i18nLocale}/recruiter/job_offers`,
      search: `?selectedTab=${status}`,
    });
  };

  const handleFailedSave = (err) => {
    enqueueSnackbar(
      intl.formatMessage({
        id: "flash.unknown",
      }),
      { variant: "error" }
    );
  };

  const updateCurrentRecruiter = useCallback(
    (recruiter) => {
      dispatch({ type: "UPDATE_CURRENT_RECRUITER", payload: recruiter });
    },
    [dispatch]
  );

  const getCustomOfferStatus = (offer) => {
    let message = getOfferStatus(offer);
    switch (offer.offer_status) {
    case "online":
      let expirationDiffInDays =
          (new Date(offer.end_of_mandate) - Date.now()) / 86400000;
      if (expirationDiffInDays > 5) {
        return (
          <Box className={`${classes.statusBox} ${classes.statusBoxActive}`}>
            <FiberManualRecordIcon className={classes.statusIcon} />
            <FormattedMessage id={message.id} />
          </Box>
        );
      } else {
        return (
          <Box
            className={`${classes.statusBox} ${classes.statusBoxActive} ${classes.statusBoxBold}`}
          >
            <FiberManualRecordIcon className={classes.statusIcon} />
            <FormattedMessage id={message.id} values={message.values} />
          </Box>
        );
      }
    default:
      return (
        <Box className={classes.statusBox}>
          <FiberManualRecordIcon className={classes.statusIcon} />
          <FormattedMessage id={message.id} />
        </Box>
      );
    }
  };

  const getApplicationsCount = (offer, applicationStatus = null) => {
    if (!applicationStatus) return offer.job_applications.length;

    return offer.job_applications.filter(
      (application) => application.status == applicationStatus
    ).length;
  };

  return (
    <>
      <Grid
        key={key}
        item
        xs={12}
        component={Paper}
        elevation={1}
        onClick={() => handleJobClicked(offer)}
        className={classes.jobCard}
      >
        <Grid container style={{ justifyContent: "space-between" }}>
          <Grid
            item
            xs={6}
            md={isIndex ? 4 : 3}
            style={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant={isDesktop ? "h6" : "h7"}
                style={{ color: theme.palette.text.blue }}
              >
                {truncate(offer.job_title, isDesktop ? 50 : 30)}
              </Typography>
              <Typography
                variant="body3"
                style={{ textTransform: "capitalize" }}
              >
                {formatJobOfferLocations(offer?.locations || []).join(", ")}
              </Typography>
              <Typography variant="body3">
                {getCustomOfferStatus(offer)}
              </Typography>
            </Box>
          </Grid>

          {isDesktop && offer.offer_status == "online" && (
            <>
              <Grid item xs={2} className={classes.countItem}>
                <Box style={{ textAlign: isIndex ? "center" : "unset" }}>
                  <Typography
                    variant="h3"
                    style={{ color: theme.palette.text.placeholder }}
                  >
                    {offer.view_count}
                  </Typography>
                  {isIndex && (
                    <Typography
                      variant="body3"
                      style={{ color: theme.palette.text.placeholder }}
                    >
                      <FormattedMessage
                        id="job_offer_summary.count_candidates"
                        values={{ view: offer.view_count }}
                      />
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={2} className={classes.countItem}>
                <Box style={{ textAlign: isIndex ? "center" : "unset" }}>
                  <Typography
                    variant="h3"
                    style={{ color: theme.palette.text.placeholder }}
                  >
                    {getApplicationsCount(offer)}
                  </Typography>
                  {isIndex && (
                    <Typography
                      variant="body3"
                      style={{ color: theme.palette.text.placeholder }}
                    >
                      <FormattedMessage
                        id="job_offer_summary.header_candidates"
                        values={{
                          candidates: offer.job_applications_count || 0,
                        }}
                      />
                    </Typography>
                  )}
                </Box>
              </Grid>
              {!isIndex && (
                <Grid item xs={2} className={classes.countItem}>
                  <Box>
                    <Typography
                      variant="h3"
                      style={{ color: theme.palette.text.placeholder }}
                    >
                      {getApplicationsCount(offer, "closed")}
                    </Typography>
                  </Box>
                </Grid>
              )}
            </>
          )}
          {offer.offer_status == "online" ? (
            <Grid
              item
              xs={6}
              md={isIndex ? 4 : 3}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              {isDesktop &&
                (getApplicationsCount(offer, "pending") > 0 ? (
                  <Button
                    variant="textUnderline"
                    href={`/${i18nLocale}/recruiter/my_candidates?selectedTab=pending`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Typography variant="body2">
                      {getApplicationsCount(offer, "pending")}{" "}
                      <FormattedMessage id="job_offer_summary.new_application" />
                    </Typography>
                  </Button>
                ) : (
                  <Typography variant="body2">
                    <FormattedMessage id="job_offer_summary.no_new_candidate" />
                  </Typography>
                ))}

              {!isDesktop && (
                <Box
                  style={{
                    alignItems: "center",
                    justifyContent: "end",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    variant="textUnderline"
                    href={`/${i18nLocale}/recruiter/my_candidates`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Typography variant="body3">
                      {getApplicationsCount(offer)}{" "}
                      <FormattedMessage
                        id="job_offer_summary.header_candidates"
                        values={{ candidates: 2 }}
                      />
                    </Typography>
                  </Button>
                  {getApplicationsCount(offer) > 0 && (
                    <Typography variant="body3">
                      {getApplicationsCount(offer)}{" "}
                      <FormattedMessage id="job_offer_summary.new_application" />
                    </Typography>
                  )}
                </Box>
              )}
            </Grid>
          ) : (
            <Grid
              item
              xs={6}
              align="right"
              style={{
                justifyContent: "end",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Grid container>
                {offer.offer_status == "draft" && (
                  <>
                    <Grid item xs={12} md={6}>
                      <Button
                        style={{ width: "120px" }}
                        size="small"
                        variant="rounded"
                        color="secondaryOutlined"
                        href={`/${i18nLocale}/recruiter/job_offer_creation?jobOffer=${offer.id}`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <FormattedMessage id="job_offer.edit" />
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        size="small"
                        variant="rounded"
                        color="primaryContained"
                        style={{
                          marginTop: isDesktop ? "0px" : "8px",
                          marginLeft: isDesktop ? "8px" : "0px",
                          width: "120px",
                        }}
                        onClick={handlePublishClicked}
                      >
                        <FormattedMessage id="job_offer.publish" />
                      </Button>
                    </Grid>
                  </>
                )}
                {offer.offer_status == "closed" && (
                  <>
                    <Grid item xs={12} md={6}>
                      <Button
                        size="small"
                        variant="textUnderline"
                        onClick={handleApplicationsCountClicked}
                      >
                        {getApplicationsCount(offer)}{" "}
                        <FormattedMessage id="job_offer_summary.header_candidates" values={{candidates: getApplicationsCount(offer)}} />
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        size="small"
                        variant="rounded"
                        color="secondaryOutlined"
                        onClick={handleDuplicateClicked}
                        style={{
                          marginTop: isDesktop ? "0px" : "8px",
                          marginLeft: isDesktop ? "8px" : "0px",
                          width: "120px",
                        }}
                      >
                        <FormattedMessage id="job_offer.repost" />
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        handleConfirm={handlePublish}
        labelId="publish_offer_confirmation"
      />
    </>
  );
};

export default JobOfferCard;
