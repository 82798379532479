import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import Autocomplete from "react-google-autocomplete";
import validator from "validator";
import { getHtmlText, formatStringToRange } from "bundles/common/utils/utils";
import RichTextEditor from "bundles/common/components/RichTextEditor";
import CardSelect from "bundles/common/components/CardSelect";
import ChipSelection from "bundles/common/components/ChipSelection";
import JobOfferSummary from "bundles/Recruiter/components/JobOfferSummary";
import { jobOfferAdvantages } from "bundles/common/utils/constants";
import InfoIcon from "@mui/icons-material/Info";
import { useTheme, makeStyles } from "@mui/styles";
import { Slider } from "@mui/material";
import { FormGroup, Checkbox } from "@mui/material";
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  Box,
  TextField,
  Button,
  Typography,
  useMediaQuery,
  Paper,
  FormControlLabel,
  Switch,
  Tooltip,
  Stack,
  Alert,
} from "@mui/material";
import {
  languages,
  localLanguages,
  companyIndustryMap,
  offerStatuses,
} from "bundles/common/utils/constants";
import SkillSelect from "../../common/components/SkillSelect";
import { useEffect } from "react";
import { formatJobOfferLocation } from "../../common/utils/utils";
import { normalizeRegion } from "bundles/common/utils/utils";

export default function JobOfferEdit({
  setJobOffer,
  jobOffer,
  setSkills,
  skills,
  saveJobOffer,
  setActiveBlocker
}) {
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  const useStyles = makeStyles((theme) => {
    return {
      stepper: {
        width: isDesktop ? "80%" : "100%",
        marginLeft: "auto",
        marginRight: "auto",
        "& .MuiStepLabel-iconContainer": {
          padding: "0px",
        },
        "& .MuiStepIcon-root": {
          height: "40px",
          width: "40px",
          color: `${theme.palette.secondary.main} !important`,
        },
        "& .MuiStepIcon-active": {
          height: "40px",
          width: "40px",
          color: `${theme.palette.secondary.main} !important`,
        },
        "& .MuiStepIcon-completed": {
          height: "40px",
          width: "40px",
          color: `${theme.palette.secondary.main} !important`,
        },
        "& .Mui-disabled .MuiStepIcon-root": {
          height: "40px",
          width: "40px",
          color: `${theme.palette.text.disabled} !important`,
        },
      },
      stepContainer: {
        marginTop: "64px !important",
        marginLeft: isDesktop ? "24px !important" : "32px !important",
        paddingLeft: isDesktop ? "48px !important" : "16px !important",
        paddingRight: isDesktop ? "48px !important" : "16px !important",
        paddingBottom: "32px !important",
        width: isDesktop ? "unset" : "calc(100% - 32px) !important",
      },
      stepBox: {
        flex: "0 0 auto",
        width: "100%",
        marginTop: "8px",
      },
      title: {
        marginBottom: "24px !important",
      },
      textfield: {
        minWidth: isDesktop ? "280px !important" : "100% !important",
      },
      formItem: {
        marginBottom: "16px !important",
        overflowX: "auto",
      },
      chipSelection: {
        overflowX: "hidden !important",
        marginBottom: "0px !important",
      },
      textareaItem: {
        marginBottom: "32px !important",
        alignItems: "left !important",
      },
      footerButtons: {
        width: isDesktop ? "200px !important" : "100% !important",
        marginLeft: isDesktop ? "24px !important" : "0px !important",
        marginBottom: "16px !important",
      },
    };
  });
  const classes = useStyles();
  const intl = useIntl();

  const initialValidity = () => {
    if (jobOffer?.days_validity) {
      return jobOffer?.days_validity;
    } else {
      return 90;
    }
  };

  const [value, setValue] = useState(() => {
    let annualyJobOffer = jobOffer?.monthly_offer;
    if (annualyJobOffer == null || annualyJobOffer == []) {
      return [0, 0];
    } else if (annualyJobOffer) {
      return formatStringToRange(annualyJobOffer)
        .split("-")
        .map((value) => {
          return parseInt(value);
        });
    } else {
      return [25, 35];
    }
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [advantages, setAdvantages] = useState(
    jobOffer?.offer_advantages?.advantages || []
  );
  const [environments, setEnvironments] = useState(
    jobOffer?.offer_advantages?.environments || []
  );
  const [schedules, setSchedules] = useState(
    jobOffer?.offer_advantages?.schedules || []
  );
  const [primes, setPrimes] = useState(
    jobOffer?.offer_advantages?.primes || []
  );

  const handleCheck = (val) => {
    if (advantages.includes(val)) {
      setAdvantages(advantages.filter((a) => a != val));
    } else {
      setAdvantages(advantages.concat(val));
    }
  };

  const handleCheckEnvironment = (val) => {
    if (environments.includes(val)) {
      setEnvironments(environments.filter((a) => a != val));
    } else {
      setEnvironments(environments.concat(val));
    }
  };

  const handleCheckSchedules = (val) => {
    if (schedules.includes(val)) {
      setSchedules(schedules.filter((a) => a != val));
    } else {
      setSchedules(schedules.concat(val));
    }
  };

  const handleCheckPrimes = (val) => {
    if (primes.includes(val)) {
      setPrimes(primes.filter((a) => a != val));
    } else {
      setPrimes(primes.concat(val));
    }
  };

  const initialFormValues = {
    id: jobOffer?.id || "",
    offer_language:
      jobOffer?.offer_language || [
        localLanguages.find(
          (local) => local.value == currentRecruiter.preferred_locale
        )?.longValue,
      ] ||
      [],
    company_industries:
      jobOffer?.company_industries || [
        currentRecruiter.company.company_industry,
      ] ||
      [],
    job_title: jobOffer?.job_title || "",
    job_statuses: jobOffer?.job_statuses || [],
    locations:
      jobOffer && jobOffer.locations && jobOffer.locations.length > 0
        ? jobOffer?.locations?.map((location) => {
          return {
            zipcode: location?.zipcode || "",
            city: location?.city || "",
            region: location?.region || "",
            district: location?.district || "",
            country: location?.country || "",
          };
        })
        : [],
    monthly_offer: jobOffer?.monthly_offer || value,
    start_date: jobOffer?.start_date
      ? new Date(jobOffer.start_date)?.toISOString().slice(0, 10)
      : "",
    end_date: jobOffer?.end_date
      ? new Date(jobOffer.end_date)?.toISOString().slice(0, 10)
      : "",
    end_of_mandate: jobOffer?.end_of_mandate
      ? new Date(jobOffer.end_of_mandate)?.toISOString().slice(0, 10)
      : "",
    published_at: jobOffer?.published_at || "",
    offer_status: jobOffer?.offer_status || "",
    offer_advantages: jobOffer?.offer_advantages || [],
    external_application: jobOffer?.external_application || false,
    external_application_url: jobOffer?.external_application_url || "",
    days_validity: initialValidity(),
  };

  const stepValues = {
    0: {
      validationfields: [
        "job_title",
        "offer_language",
        "company_industry",
        "job_statuses",
        "start_date",
        "end_date",
        "locations",
      ],
    },
    1: {
      validationfields: ["description", "responsibilities", "profile", "offer"],
    },
    2: { validationfields: [] },
    3: { validationfields: [] },
  };

  const steps = [
    { index: 0, key: 1, finale: false },
    { index: 1, key: 2, finale: false },
    { index: 2, key: 3, finale: true },
    { index: 3, key: 4, finale: true },
  ];

  const [textArea, setTextArea] = useState({
    description: jobOffer?.description,
    responsibilities: jobOffer?.responsibilities,
    profile: jobOffer?.profile,
    offer: jobOffer?.offer,
  });

  const [currentStep, setCurrentStep] = useState(0);
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});

  const [hardSkills, setHardSkills] = useState([]);
  const [softSkills, setSoftSkills] = useState([]);
  const [languageSkills, setLanguageSkills] = useState([]);
  const [isAbroad, setIsAbroad] = useState(jobOffer?.abroad || false);

  useEffect(() => {
    setHardSkills(skills?.filter((skill) => skill.category == "hard"));
    setSoftSkills(skills?.filter((skill) => skill.category == "soft"));
    setLanguageSkills(skills?.filter((skill) => skill.category == "lang"));
  }, [skills.length]);

  const toggleExternalApplication = () => {
    setValues({
      ...values,
      ["external_application"]: !values["external_application"],
    });
  };

  const handleValidityValue = (event, value) => {
    setValues({
      ...values,
      ["days_validity"]: value,
    });
  };

  const validate = (fieldValues = {}) => {
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );
    let end_date_must_be_higher = (
      <FormattedMessage id="form_validation.end_date_must_be_higher"></FormattedMessage>
    );
    let date_must_be_valid = (
      <FormattedMessage id="form_validation.date_must_be_valid"></FormattedMessage>
    );

    if (
      "offer_language" in fieldValues &&
      stepValues[currentStep].validationfields.includes("offer_language")
    ) {
      temp.offer_language = fieldValues.offer_language ? "" : required;
    }
    if (
      "company_industries" in fieldValues &&
      stepValues[currentStep].validationfields.includes("company_industries")
    ) {
      temp.company_industries = fieldValues.company_industries ? "" : required;
    }
    if (
      "job_title" in fieldValues &&
      stepValues[currentStep].validationfields.includes("job_title")
    ) {
      temp.job_title = fieldValues.job_title ? "" : required;
    }
    if (stepValues[currentStep].validationfields.includes("description")) {
      temp.description =
        textArea.description && getHtmlText(textArea.description)
          ? ""
          : required;
    } else {
      temp.description = "";
    }
    if (
      "job_statuses" in fieldValues &&
      stepValues[currentStep].validationfields.includes("job_statuses")
    ) {
      temp.job_statuses = fieldValues.job_statuses.length > 0 ? "" : required;
    }

    if (
      "start_date" in fieldValues &&
      stepValues[currentStep].validationfields.includes("start_date")
    ) {
      if (
        fieldValues.start_date &&
        validator.isAfter(fieldValues.start_date, "01/01/2122")
      ) {
        temp.start_date = date_must_be_valid;
      } else if (fieldValues.start_date) {
        temp.start_date = "";
      }
    }

    if ("external_application" in fieldValues) {
      if (
        !!values.external_application &&
        values.external_application_url == ""
      ) {
        temp.external_application_url = required;
      } else {
        temp.external_application_url = "";
      }
    }

    if (
      "end_date" in fieldValues &&
      stepValues[currentStep].validationfields.includes("end_date") &&
      !values.job_statuses?.some(
        (status) => status === "permanent" || status === "freelance" || status === "interim"
      )
    ) {
      if (values.start_date) {
        if (
          fieldValues.end_date &&
          !validator.isAfter(fieldValues.end_date, values.start_date)
        ) {
          temp.end_date = end_date_must_be_higher;
        } else if (
          fieldValues.end_date &&
          validator.isAfter(fieldValues.end_date, "01/01/2122")
        ) {
          temp.end_date = date_must_be_valid;
        } else if (fieldValues.end_date) {
          temp.end_date = "";
        }
      }
      if (values.end_date && !values.start_date) {
        temp.start_date = required;
      }
    }
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSkillChange = (category, value) => {
    let formattedValue = value?.map((elem) => {
      return {
        name: elem.id,
        category: category,
        company_id: elem.company_id,
      };
    });

    if (category === "hard") {
      setHardSkills(formattedValue);
    } else if (category === "soft") {
      setSoftSkills(formattedValue);
    } else if (category === "lang") {
      setLanguageSkills(formattedValue);
    }
  };

  const handleCardSelected = (name, value, multiple) => {
    if (multiple) {
      if (values[name].includes(value)) {
        setValues({
          ...values,
          [name]: values[name].filter((v) => v != value),
        });
      } else {
        setValues({ ...values, [name]: values[name].concat([value]) });
      }
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  useEffect(() => {
    if (values.job_statuses.includes("permanent") || values.job_statuses.includes("freelance") || values.job_statuses.includes("interim")) {
      setValues({ ...values, end_date: "" });
    }
  }, [values.job_statuses]);

  const onPlacesSelect = (place) => {
    if (place.address_components) {
      let compPostalCode = "";
      let compLocality = "";
      let compAdministrativeAreaLevel2 = "";
      let compAdministrativeAreaLevel1 = "";
      let countryShortName = "";
      let regionValue = "";

      place.address_components.forEach((c) => {
        if (c.types.includes("postal_code")) {
          compPostalCode = c.long_name;
        }
        if (c.types.includes("locality")) {
          compLocality = c.long_name;
        }
        if (c.types.includes("administrative_area_level_2")) {
          compAdministrativeAreaLevel2 = c.long_name
            ?.toLowerCase()
            ?.replace(/ /g, "_");
        }
        if (c.types.includes("administrative_area_level_1")) {
          compAdministrativeAreaLevel1 = c.long_name
            ?.toLowerCase()
            ?.replace(/ /g, "_");
        }
        if (c.types.includes("country")) {
          countryShortName = c.short_name.toLowerCase();
        }
      });

      if (countryShortName === "fr") {
        regionValue = compAdministrativeAreaLevel1 || compAdministrativeAreaLevel2;
        regionValue = normalizeRegion(regionValue);
      } else if (countryShortName === "be") {
        if (compAdministrativeAreaLevel2 === "luxembourg") {
          regionValue = "be_luxembourg";
        } else {
          regionValue = compAdministrativeAreaLevel2 || compAdministrativeAreaLevel1;
        }
      } else if (countryShortName === "lu") {
        regionValue = compAdministrativeAreaLevel1;
      } else {
        regionValue = compAdministrativeAreaLevel2 || compAdministrativeAreaLevel1;
      }

      let newLocation = {
        zipcode: compPostalCode,
        city: compLocality,
        region: regionValue?.replace(/-/g, "_"),
        district: compAdministrativeAreaLevel1?.replace(/-/g, "_"),
        country: countryShortName?.replace(/-/g, "_"),
      };

      let newValues = {
        ...values,
        locations: values.locations
          .map((location) => {
            return {
              zipcode: location.zipcode,
              city: location.city,
              region: location.region?.replace(/-/g, "_"),
              district: location.district?.replace(/-/g, "_"),
              country: location.country?.replace(/-/g, "_"),
            };
          })
          .concat([newLocation]),
      };

      setValues(newValues);
      validate({
        locations: newValues.locations,
      });
    }
  };

  const handleDeleteLocation = (deletedLocation) => {
    let newValues = {
      ...values,
      locations: values.locations.filter((location) => {
        return (
          Object.values(location).sort().join("") !=
          Object.values(deletedLocation.value).sort().join("")
        );
      }),
    };
    setValues(newValues);
  };

  const handleDeleteSkill = (deletedSkill, category) => {
    let skills;
    if (category === "hard") {
      skills = hardSkills.filter((value) => value.name != deletedSkill.value);
      setHardSkills(skills);
    } else if (category === "soft") {
      skills = softSkills.filter((value) => value.name != deletedSkill.value);
      setSoftSkills(skills);
    } else if (category === "lang") {
      skills = languageSkills.filter(
        (value) => value.name != deletedSkill.value
      );
      setLanguageSkills(skills);
    }
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleTextArea = (name) => (value) => {
    textArea[name] = value;
    setTextArea(textArea);
    validate();
  };

  const handleNextClicked = () => {
    const newValues = {
      ...values,
      ...textArea,
      offer_advantages: {
        advantages: advantages,
        environments: environments,
        schedules: schedules,
        primes: primes,
      },
      monthly_offer: value.join("-"),
      end_of_mandate: jobOffer.end_of_mandate,
      abroad: isAbroad,
    };
    if (validate(newValues)) {
      const hard_skills = hardSkills.filter((skill) => !!skill.name);
      const soft_skills = softSkills.filter((skill) => !!skill.name);
      const languages_skills = languageSkills.filter((skill) => !!skill.name);
      setJobOffer(newValues);
      setSkills(hard_skills.concat(soft_skills, languages_skills));
      setCurrentStep(currentStep + 1);
      window.scrollTo(0, 0);
    }
  };

  function StepOne() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.formItem} align="left">
          <Typography variant="h4">
            <b>
              <FormattedMessage id="job_offer_creation.basic_informations" />
            </b>
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.formItem} align="left">
          <TextField
            color="secondary"
            className={classes.textfield}
            required
            label={<FormattedMessage id="job_offer.job_title" />}
            name="job_title"
            value={values.job_title}
            variant="outlined"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            {...(errors["job_title"] && {
              error: true,
              helperText: errors["job_title"],
            })}
            inputProps={{ maxLength: 60 }}
          />
          <Box width={isDesktop ? "30%" : "100%"}>
            <Typography align="right">
              {values.job_title.length}/60{" "}
              {intl.formatMessage({ id: "characters" })}
            </Typography>
          </Box>
        </Grid>
        <Grid item
          xs={12}
          className={classes.formItem}
          align="left"
          style={{
            display: "flex",
            alignContent: "center",
            flexDirection: isDesktop ? "row" : "column",
            columnGap: isDesktop ? "20px" : "0px",
            rowGap: isDesktop ? "0px" : "8px",
          }}
        >
          <Typography variant="h5" style={{ paddingTop: "7px" }}>
            <FormattedMessage id="job_offer_creation.location_label" />
          </Typography>
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                checked={isAbroad}
                onChange={(e) => setIsAbroad(e.target.checked)}
              />
            }
            label={
              <Typography variant="h5">
                <FormattedMessage id="job_offer_creation.abroad_label" />
              </Typography>
            }
            style={{ paddingBottom: "5px" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          className={`${classes.formItem} ${classes.chipSelection}`}
          align="left"
          style={{
            display: "flex",
            flexDirection: isDesktop ? "row" : "column",
            columnGap: isDesktop ? "16px" : "0px",
            rowGap: isDesktop ? "0px" : "8px",
          }}
        >
          <TextField
            color="secondary"
            className={classes.textfield}
            name="locations"
            variant="outlined"
            disabled={values.locations?.length >= 3 || isAbroad}
            placeholder={intl.formatMessage({
              id: "devise.signup.search_city_or_zipcode",
            })}
            InputProps={{
              inputComponent: ({ ...props }) => (
                <Autocomplete
                  /* eslint-disable no-undef */
                  apiKey={process.env.GOOGLE_AUTOCOMPLETE_API_KEY}
                  multiple
                  language="en"
                  {...props}
                  onPlaceSelected={onPlacesSelect}
                  options={{
                    types: ["(regions)"],
                    componentRestrictions: {
                      country: ["be", "lu", "fr"],
                    },
                  }}
                />
              ),
            }}
            {...(errors["locations"] && {
              error: true,
              helperText: errors["locations"],
            })}
          />
          <ChipSelection
            style={{ marginTop: isDesktop ? "0px" : "8px" }}
            values={values.locations?.map((location) => {
              return {
                value: location,
                label: formatJobOfferLocation(location),
              };
            })}
            backgroundColor={theme.palette.secondary.main}
            color={theme.palette.common.white}
            maxSelection={3}
            onDelete={handleDeleteLocation}
          />
        </Grid>
        <Grid item xs={12} className={classes.formItem} align="left">
          <Typography variant="h5">
            <FormattedMessage id="job_offer_creation.language_label" />
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.formItem} align="left">
          <CardSelect
            multiple={false}
            fieldName="offer_language"
            selected={values.offer_language}
            values={languages.map((language) => {
              return {
                code: language,
                label: intl.formatMessage({ id: `languages.${language}` }),
              };
            })}
            onChange={handleCardSelected}
          />
          {errors["offer_language"] && (
            <p
              className="MuiFormHelperText-root MuiFormHelperText-sizeSmall Mui-error"
              style={{ color: "#f44336" }}
            >
              {errors["offer_language"]}
            </p>
          )}
        </Grid>
        <Grid item xs={12} className={classes.formItem} align="left">
          <Typography variant="h5">
            <FormattedMessage id="job_offer_creation.industry_label" />
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.formItem} align="left">
          <CardSelect
            multiple={true}
            fieldName="company_industries"
            selected={values.company_industries}
            values={companyIndustryMap.map((industry) => {
              return {
                code: industry,
                label: intl.formatMessage({
                  id: `company.company_industry.${industry}`,
                }),
              };
            })}
            onChange={handleCardSelected}
          />
          {errors["company_industries"] && (
            <p
              className="MuiFormHelperText-root MuiFormHelperText-sizeSmall Mui-error"
              style={{ color: "#f44336" }}
            >
              {errors["company_industries"]}
            </p>
          )}
        </Grid>
        <Grid item xs={12} className={classes.formItem} align="left">
          <Typography variant="h5">
            <FormattedMessage id="job_offer_creation.status_label" />
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.formItem} align="left">
          <CardSelect
            multiple={true}
            fieldName="job_statuses"
            selected={values.job_statuses}
            values={offerStatuses.map((status) => {
              if (status.tooltip) {
                return {
                  code: status.code,
                  label: (
                    <Tooltip
                      title={intl.formatMessage({
                        id: `candidate.status_map.${status.code}_hover`,
                      })}
                      style={{ cursor: "pointer" }}
                      placement="bottom"
                    >
                      <Box>
                        <span>
                          {intl.formatMessage({
                            id: `candidate.status_map.${status.code}`,
                          })}
                        </span>
                        <span>
                          <InfoIcon style={{ fontSize: "small" }} />
                        </span>
                      </Box>
                    </Tooltip>
                  ),
                };
              } else {
                return {
                  code: status.code,
                  label: intl.formatMessage({
                    id: `candidate.status_map.${status.code}`,
                  }),
                };
              }
            })}
            onChange={handleCardSelected}
          />
          {errors["job_statuses"] && (
            <p
              className="MuiFormHelperText-root MuiFormHelperText-sizeSmall Mui-error"
              style={{ color: "#f44336" }}
            >
              {errors["job_statuses"]}
            </p>
          )}
        </Grid>
        <Grid item xs={12} className={classes.formItem} align="left">
          <Typography variant="h5">
            {values.job_statuses?.some(
              (status) => status === "permanent" || status === "freelance" || status === "interim"
            ) ? (
                <FormattedMessage id="job_offer_creation.date_label_start" />
              ) : (
                <FormattedMessage id="job_offer_creation.date_label" />
              )}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.formItem} align="left">
          <TextField
            color="secondary"
            className={classes.textfield}
            name="start_date"
            required
            value={values.start_date}
            type="date"
            variant="outlined"
            label={<FormattedMessage id="job_offer.start_date" />}
            onBlur={handleInputValue}
            onChange={handleInputValue}
            InputLabelProps={{
              shrink: true,
            }}
            {...(errors["start_date"] && {
              error: true,
              helperText: errors["start_date"],
            })}
          />
          {!values.job_statuses?.some(
            (status) => status === "permanent" || status === "freelance" || status === "interim"
          ) && (
            <TextField
              className={classes.textfield}
              name="end_date"
              value={values.end_date}
              type="date"
              variant="outlined"
              label={<FormattedMessage id="job_offer.end_date" />}
              onBlur={handleInputValue}
              onChange={handleInputValue}
              InputLabelProps={{
                shrink: true,
              }}
              style={{
                marginLeft: isDesktop ? "16px" : "0px",
                marginTop: isDesktop ? "0px" : "16px",
              }}
              {...(errors["end_date"] && {
                error: true,
                helperText: errors["end_date"],
              })}
            />
          )}
        </Grid>
        <Grid item xs={12} className={classes.formItem} align="left">
          <Typography variant="h5">
            <FormattedMessage id="job_offer_creation.remuneration_label" />
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.formItem} align="left">
          <Box
            marginTop={"0.8rem"}
            width={isDesktop ? "500px" : "80%"}
            marginLeft={"0.5rem"}
          >
            <Slider
              color="secondary"
              min={0}
              max={200}
              getAriaLabel={() => "monthly remuneration"}
              value={value}
              name="monthly_offer"
              onChange={handleChange}
            />
            <Typography
              align="center"
              variant={"h5"}
              color={"secondary"}
              fontWeight={"600"}
            >
              {value[0]}K - {value[1]}K €
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} className={classes.formItem} align="left">
          <Typography variant="h5">
            <FormattedMessage id="job_offer_creation.application_label" />
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.formItem} align="left">
          <Box style={{ display: "flex", alignItems: "center" }}>
            <FormControlLabel
              control={
                <Switch
                  checked={!values.external_application}
                  color="secondary"
                  onChange={toggleExternalApplication}
                />
              }
              label={<FormattedMessage id="job_offer.external_application" />}
            />
            <Tooltip
              title={<FormattedMessage id="job_offer.squarehub_apply" />}
              style={{ cursor: "pointer" }}
              aria-label="add"
              placement="right"
            >
              <InfoIcon />
            </Tooltip>
          </Box>
          {values?.external_application && (
            <>
              <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert severity="error">
                  <FormattedMessage id="job_offer_creation.external_application_warning" />
                </Alert>
              </Stack>
              <TextField
                fullWidth
                label={
                  <FormattedMessage id="job_offer.external_application_url" />
                }
                style={{ marginTop: "8px" }}
                name="external_application_url"
                value={values.external_application_url}
                variant="outlined"
                color="secondary"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                {...(errors["external_application_url"] && {
                  error: true,
                  helperText: errors["external_application_url"],
                })}
              />
            </>
          )}
        </Grid>

        {!jobOffer?.id && (
          <>
            <Grid item xs={12} className={classes.formItem} align="left">
              <Typography variant="h5">
                <FormattedMessage id="job_offer_creation.validity_label" />
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.formItem} align="left">
              <Box
                marginTop={"0.5rem"}
                marginLeft={"1rem"}
                width={isDesktop ? "500px" : "80%"}
              >
                <Slider
                  min={1}
                  max={90}
                  valueLabelDisplay="auto"
                  defaultValue={90}
                  getAriaLabel={() => ""}
                  align="left"
                  color="secondary"
                  disableSwap
                  className={classes.textfield}
                  value={values.days_validity}
                  onChange={handleValidityValue}
                  type="number"
                />
                <Typography align="center" fontWeight="bold">
                  {values.days_validity}{" "}
                  <FormattedMessage id="job_offer_creation.validity_unit" />
                </Typography>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    );
  }

  function StepTwo() {
    return (
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          component={Paper}
          elevation={0}
          style={{
            marginTop: "8px",
            paddingLeft: "16px",
          }}
        >
          <Grid container style={{ paddingBottom: "0px" }}>
            <Grid item xs={12} className={classes.title} align="left">
              <Typography variant="h4">
                <b>
                  <FormattedMessage id="job_offer_creation.description" />
                </b>
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.formItem}>
              <Typography
                align={isDesktop ? "left" : "center"}
                variant="h5"
                style={{ fontFamily: "Work Sans" }}
              >
                <b>
                  <FormattedMessage
                    id="job_offer_creation.summary_label"
                    values={{
                      b: (chunks) => <b>{chunks}</b>,
                    }}
                  />
                </b>
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.formItem}>
              <Typography
                align={isDesktop ? "left" : "center"}
                variant="body1"
                style={{ fontFamily: "Work Sans" }}
              >
                <FormattedMessage
                  id="job_offer_creation.summary_sublabel"
                  values={{
                    b: (chunks) => <b>{chunks}</b>,
                  }}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.textareaItem}>
              <RichTextEditor
                onChange={handleTextArea("description")}
                defaultValue={jobOffer.description || textArea.description}
                {...(errors["description"] && {
                  error: true,
                  helperText: errors["description"],
                })}
              />
            </Grid>
            <Grid item xs={12} className={classes.formItem}>
              <Typography
                align={isDesktop ? "left" : "center"}
                variant="h5"
                style={{ fontFamily: "Work Sans" }}
              >
                <b>
                  <FormattedMessage
                    align="center"
                    id="job_offer_creation.responsibilities_label"
                  />
                </b>
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.formItem}>
              <Typography
                align={isDesktop ? "left" : "center"}
                variant="body1"
                style={{ fontFamily: "Work Sans" }}
              >
                <FormattedMessage
                  id="job_offer_creation.responsibilities_sublabel"
                  values={{
                    b: (chunks) => <b>{chunks}</b>,
                  }}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.textareaItem}>
              <RichTextEditor
                onChange={handleTextArea("responsibilities")}
                defaultValue={
                  jobOffer.responsibilities || textArea.responsibilities
                }
                {...(errors["responsibilities"] && {
                  error: true,
                  helperText: errors["responsibilities"],
                })}
              />
            </Grid>

            <Grid item xs={12} className={classes.formItem}>
              <Typography
                align={isDesktop ? "left" : "center"}
                variant="h5"
                style={{ fontFamily: "Work Sans" }}
              >
                <b>
                  <FormattedMessage
                    align="center"
                    id="job_offer_creation.profile_label"
                  />
                </b>
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.formItem}>
              <Typography
                align={isDesktop ? "left" : "center"}
                variant="body1"
                style={{ fontFamily: "Work Sans" }}
              >
                <FormattedMessage
                  id="job_offer_creation.profile_sublabel"
                  values={{
                    b: (chunks) => <b>{chunks}</b>,
                  }}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.textareaItem}>
              <RichTextEditor
                onChange={handleTextArea("profile")}
                defaultValue={jobOffer.profile || textArea.profile}
                {...(errors["profile"] && {
                  error: true,
                  helperText: errors["profile"],
                })}
              />
            </Grid>

            <Grid item xs={12} className={classes.formItem}>
              <Typography
                align={isDesktop ? "left" : "center"}
                variant="h5"
                style={{ fontFamily: "Work Sans" }}
              >
                <b>
                  <FormattedMessage
                    align="center"
                    id="job_offer_creation.offer_label"
                  />
                </b>
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.formItem}>
              <Typography
                align={isDesktop ? "left" : "center"}
                variant="body1"
                style={{ fontFamily: "Work Sans" }}
              >
                <FormattedMessage
                  id="job_offer_creation.offer_sublabel"
                  values={{
                    b: (chunks) => <b>{chunks}</b>,
                  }}
                />
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <FormGroup>
                    <Typography
                      variant="h6"
                      style={{ marginBottom: "4px", marginTop: "16px" }}
                    >
                      <FormattedMessage id="job_offer_edit.advantages_title" />
                    </Typography>

                    {jobOfferAdvantages.advantages.map((advantage) => {
                      return (
                        <FormControlLabel
                          key={`advantage-${advantage}`}
                          control={
                            <Checkbox
                              checked={advantages.includes(advantage)}
                            />
                          }
                          label={intl.formatMessage({
                            id: `job_offer_edit.advantages.${advantage}`,
                          })}
                          onChange={() => {
                            handleCheck(advantage);
                          }}
                        />
                      );
                    })}
                  </FormGroup>

                  <FormGroup>
                    <Typography
                      variant="h6"
                      style={{ marginBottom: "4px", marginTop: "16px" }}
                    >
                      <FormattedMessage id="job_offer_edit.environment_title" />
                    </Typography>
                    {jobOfferAdvantages.environment.map((environment) => {
                      return (
                        <FormControlLabel
                          key={`environment-${environment}`}
                          control={
                            <Checkbox
                              checked={environments.includes(environment)}
                            />
                          }
                          label={intl.formatMessage({
                            id: `job_offer_edit.environment.${environment}`,
                          })}
                          onChange={() => {
                            handleCheckEnvironment(environment);
                          }}
                        />
                      );
                    })}
                  </FormGroup>

                  <FormGroup>
                    <Typography
                      variant="h6"
                      style={{ marginBottom: "4px", marginTop: "16px" }}
                    >
                      <FormattedMessage id="job_offer_edit.schedules_title" />
                    </Typography>
                    {jobOfferAdvantages.schedules.map((schedule) => {
                      return (
                        <FormControlLabel
                          key={`environment-${schedule}`}
                          control={
                            <Checkbox checked={schedules.includes(schedule)} />
                          }
                          label={intl.formatMessage({
                            id: `job_offer_edit.schedules.${schedule}`,
                          })}
                          onChange={() => {
                            handleCheckSchedules(schedule);
                          }}
                        />
                      );
                    })}
                  </FormGroup>

                  <FormGroup>
                    <Typography
                      variant="h6"
                      style={{ marginBottom: "4px", marginTop: "16px" }}
                    >
                      <FormattedMessage id="job_offer_edit.prime_title" />
                    </Typography>

                    {jobOfferAdvantages.primes.map((prime) => {
                      return (
                        <FormControlLabel
                          key={`primes-${prime}`}
                          control={
                            <Checkbox checked={primes.includes(prime)} />
                          }
                          label={intl.formatMessage({
                            id: `job_offer_edit.primes.${prime}`,
                          })}
                          onChange={() => {
                            handleCheckPrimes(prime);
                          }}
                        />
                      );
                    })}
                  </FormGroup>
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.textareaItem}>
              <RichTextEditor
                onChange={handleTextArea("offer")}
                defaultValue={jobOffer.offer || textArea.offer}
                {...(errors["offer"] && {
                  error: true,
                  helperText: errors["offer"],
                })}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function StepThree() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.formItem} align="left">
          <Typography variant="h4">
            <b>
              <FormattedMessage id="job_offer_creation.required_skills" />
            </b>
          </Typography>
        </Grid>

        {/* Hard Skills */}
        <Grid item xs={12} className={classes.formItem}>
          <Typography
            align={isDesktop ? "left" : "center"}
            variant="h5"
            style={{ fontFamily: "Work Sans" }}
          >
            <b>
              <FormattedMessage id="candidate.profile.skills.hard" />
            </b>
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.formItem}>
          <Typography
            align={isDesktop ? "left" : "center"}
            variant="body1"
            style={{ fontFamily: "Work Sans" }}
          >
            <FormattedMessage id="job_offer_creation.hard_skills_subtitle" />
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.formItem}
          style={{
            display: "flex",
            columnGap: "48px",
            flexDirection: isDesktop ? "row" : "column",
          }}
        >
          <SkillSelect
            selectedValues={hardSkills.map((s) => s.name)}
            category="hard"
            label={intl.formatMessage({
              id: "candidate.profile.skills.hard",
            })}
            disabled={hardSkills.length >= 10}
            onChange={handleSkillChange}
            allowAddingCustomSkills={true}
          />
          <ChipSelection
            style={{ marginTop: isDesktop ? "0px" : "8px" }}
            size="large"
            values={hardSkills?.map((s) => {
              return {
                value: s.name,
                label: s.company_id
                  ? s.name
                  : intl.formatMessage({
                    id: `skills_list.hard.${s.name}`,
                  }),
              };
            })}
            backgroundColor={theme.palette.secondary.main}
            color={theme.palette.common.white}
            maxSelection={10}
            category="hard"
            onDelete={(skill) => handleDeleteSkill(skill, "hard")}
          />
        </Grid>

        {/* Soft Skills */}
        <Grid item xs={12} className={classes.formItem}>
          <Typography
            align={isDesktop ? "left" : "center"}
            variant="h5"
            style={{ fontFamily: "Work Sans" }}
          >
            <b>
              <FormattedMessage id="candidate.profile.skills.soft" />
            </b>
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.formItem}>
          <Typography
            align={isDesktop ? "left" : "center"}
            variant="body1"
            style={{ fontFamily: "Work Sans" }}
          >
            <FormattedMessage id="job_offer_creation.soft_skills_subtitle" />
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.formItem}
          style={{
            display: "flex",
            columnGap: "48px",
            flexDirection: isDesktop ? "row" : "column",
          }}
        >
          <SkillSelect
            selectedValues={softSkills.map((s) => s.name)}
            category="soft"
            label={intl.formatMessage({
              id: "candidate.profile.skills.soft",
            })}
            disabled={softSkills.length >= 10}
            onChange={handleSkillChange}
          />
          <ChipSelection
            style={{ marginTop: isDesktop ? "0px" : "8px" }}
            size="large"
            values={softSkills?.map((s) => {
              return {
                value: s.name,
                label: s.company_id
                  ? s.name
                  : intl.formatMessage({
                    id: `skills_list.soft.${s.name}`,
                  }),
              };
            })}
            backgroundColor={theme.palette.primary.main}
            color={theme.palette.common.white}
            maxSelection={10}
            category="soft"
            onDelete={(skill) => handleDeleteSkill(skill, "soft")}
          />
        </Grid>

        {/* Lang Skills */}
        <Grid item xs={12} className={classes.formItem}>
          <Typography
            align={isDesktop ? "left" : "center"}
            variant="h5"
            style={{ fontFamily: "Work Sans" }}
          >
            <b>
              <FormattedMessage id="candidate.profile.skills.lang" />
            </b>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.formItem}
          style={{
            display: "flex",
            columnGap: "48px",
            flexDirection: isDesktop ? "row" : "column",
          }}
        >
          <SkillSelect
            selectedValues={languageSkills.map((s) => s.name)}
            category="lang"
            label={intl.formatMessage({
              id: "candidate.profile.skills.lang",
            })}
            disabled={languageSkills.length >= 3}
            onChange={handleSkillChange}
          />
          <ChipSelection
            style={{ marginTop: isDesktop ? "0px" : "8px" }}
            size="large"
            values={languageSkills?.map((s) => {
              return {
                value: s.name,
                label: s.company_id
                  ? s.name
                  : intl.formatMessage({
                    id: `skills_list.lang.${s.name}`,
                  }),
              };
            })}
            backgroundColor={theme.palette.text.hint}
            color={theme.palette.common.white}
            maxSelection={3}
            category="lang"
            onDelete={(skill) => handleDeleteSkill(skill, "lang")}
          />
        </Grid>
      </Grid>
    );
  }

  function StepFour() {
    return (
      <Grid
        container
        spacing={2}
        style={{ width: "100% !important", marginLeft: "-8px" }}
      >
        <JobOfferSummary
          jobOffer={jobOffer}
          skills={skills || jobOffer.skills}
          isEdit={true}
          saveJobOffer={saveJobOffer}
          showNavigationMessage={setActiveBlocker}
        />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Stepper
            activeStep={currentStep}
            className={classes.stepper}
            color="secondary"
          >
            {steps.map((step) => {
              return (
                <Step key={step.key}>
                  <StepLabel />
                </Step>
              );
            })}
          </Stepper>
        </Grid>
        <Grid
          item
          xs={12}
          component={currentStep < 3 ? Paper : Box}
          className={classes.stepContainer}
        >
          {currentStep === 0 && (
            <Box className={classes.stepBox}>{StepOne()}</Box>
          )}
          {currentStep === 1 && (
            <Box className={classes.stepBox}>{StepTwo()}</Box>
          )}
          {currentStep === 2 && (
            <Box className={classes.stepBox}>{StepThree()}</Box>
          )}
          {currentStep === 3 && (
            <Box className={classes.stepBox}>{StepFour()}</Box>
          )}
        </Grid>
        {currentStep < 3 && (
          <>
            <Grid
              item
              xs={12}
              md={6}
              align={isDesktop ? "left" : "center"}
              style={{ paddingLeft: isDesktop ? "0px" : "32px" }}
            >
              {currentStep == 0 && (
                <Button
                  variant="rounded"
                  color="secondaryOutlined"
                  className={classes.footerButtons}
                  onClick={() => navigate(-1)}
                >
                  <FormattedMessage id="cancel" />
                </Button>
              )}
              {currentStep > 0 && (
                <Button
                  variant="rounded"
                  color="secondaryOutlined"
                  className={classes.footerButtons}
                  onClick={() => setCurrentStep(currentStep - 1)}
                >
                  <FormattedMessage id="back" />
                </Button>
              )}
            </Grid>
            <Grid item xs={12} md={6} align={isDesktop ? "right" : "center"}>
              <Button
                variant="rounded"
                color="secondaryContained"
                className={classes.footerButtons}
                onClick={handleNextClicked}
              >
                <FormattedMessage id="next" />
              </Button>
            </Grid>
          </>
        )}
        {currentStep == 3 && (
          <Grid item xs={12} align="center" style={{ marginBottom: "32px" }}>
            <Button
              style={{ width: isDesktop ? "280px" : "100%" }}
              variant="rounded"
              color="secondaryOutlined"
              onClick={() => setCurrentStep(currentStep - 1)}
            >
              <FormattedMessage id="back" />
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
