import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Typography, Box, useMediaQuery } from "@mui/material";
import { useTheme, makeStyles } from "@mui/styles";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { truncate } from "bundles/common/utils/utils";

const CompanyCard = ({ company, onClick }) => {
  const intl = useIntl();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const useStyles = makeStyles(() => ({
    card: {
      borderRadius: "16px",
      height: "100%",
      cursor: "pointer",
      border: "1px solid lightgrey",
      transition: "box-shadow 0.3s ease",
      "&:hover": {
        boxShadow: `0 0 0 2px ${theme.palette.secondary.main}`,
        border: `1px solid ${theme.palette.secondary.main}`,
      },
      overflow: "hidden",
    },
    header: {
      display: "flex",
      alignItems: "center",
      backgroundImage: isDesktop ? `url(${company.cover_picture_url})` : `url(${company.small_cover_picture_url})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      padding: "16px",
      height: "115px",
    },
    footer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      backgroundColor: "#FFF",
      padding: "16px",
      height: "210px",
    },
  }));
  const classes = useStyles();
  const isLandingPageRecruiter =
    window.location.pathname.includes("recruiters");
  const isLandingPageCandidate =
    document.getElementById("landingPageCandidate") !== null;

  const companyUrl = isLandingPageRecruiter
    ? `companies/${company.encoded_name}/${company.id}?redirectTo=recruiters`
    : isLandingPageCandidate
      ? `companies/${company.encoded_name}/${company.id}?redirectTo=candidates`
      : `companies/${company.encoded_name}/${company.id}?redirectTo=companiesIndex`;

  return (
    <Box className={classes.card} onClick={onClick}>
      <Box className={classes.header}>
        <img
          src={company.logo_url}
          srcSet={`${company.logo_url} 1x, ${company.small_logo_url} 0.5x`}
          alt={`Logo ${company?.name}`}
          loading="lazy"
          style={{ width: "80px", height: "80px", objectFit: "contain" }}
        />
      </Box>
      <Box className={classes.footer}>
        <Box>
          <Box style={{ marginBottom: "4px" }}>
            <Typography
              variant="h5"
              style={{
                color: theme.palette.secondary.main,
                textTransform: "uppercase",
              }}
            >
              <span style={{ fontWeight: "600" }}>
                {truncate(company.name, 20)}
              </span>
            </Typography>
          </Box>
          <Box style={{ marginBottom: "12px" }}>
            {(company.online_job_offers_count || 0) > 0 && (
              <Typography
                variant="body2"
                style={{ color: theme.palette.primary.main }}
              >
                <b>
                  {intl.formatMessage(
                    { id: "landing_candidate.jobs_count" },
                    { jobCount: company.online_job_offers_count }
                  )}
                </b>
              </Typography>
            )}
          </Box>
          {company.city && (
            <Box
              style={{
                display: "flex",
                color: "#000",
                marginBottom: "6px",
              }}
            >
              {company.city && (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "4px",
                  }}
                >
                  <LocationOnIcon
                    style={{
                      fontSize: "medium",
                      marginRight: "3px",
                      color: theme.palette.secondary.main,
                    }}
                  />
                  <Typography variant="body3bold" color="secondary">
                    {company.city}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          {company.company_industry && (
            <Box
              style={{
                display: "flex",
                color: "#000",
                marginBottom: "6px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "4px",
                }}
              >
                <BusinessCenterIcon
                  style={{
                    fontSize: "medium",
                    marginRight: "3px",
                    color: theme.palette.secondary.main,
                  }}
                />
                <Typography variant="body3bold" color="secondary">
                  <FormattedMessage
                    id={`company.company_industry.${company.company_industry}`}
                  />
                </Typography>
              </Box>
            </Box>
          )}
          {company.team_size && (
            <Box
              style={{ display: "flex", color: "#000", marginBottom: "8px" }}
            >
              {company.team_size && (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "4px",
                  }}
                >
                  <SupervisorAccountIcon
                    style={{
                      fontSize: "medium",
                      marginRight: "3px",
                      color: theme.palette.secondary.main,
                    }}
                  />
                  <Typography variant="body3bold" color="secondary">
                    <FormattedMessage
                      id={`company.company_size.options.${company.team_size}`}
                    />{" "}
                    {intl
                      .formatMessage({ id: "company.employee_count" })
                      ?.toLowerCase()}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box style={{ position: "relative", marginTop: "48px" }}>
          <Box style={{ position: "absolute", bottom: "0" }}>
            <Button
              href={companyUrl}
              variant={"textUnderlineOnHover"}
              onClick={
                isLandingPageRecruiter || isLandingPageCandidate
                  ? undefined
                  : onClick
              }
            >
              <FormattedMessage id="company_card.see_company" />
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyCard;
