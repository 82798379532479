import React, { useState } from "react";
import { Box, Button, Autocomplete, TextField } from "@mui/material";
import { useIntl, FormattedMessage } from "react-intl";
import { be_regions, lu_regions, fr_regions } from "bundles/common/utils/constants";

const RegionSelect = ({ onRegionSelect, triggerNextStep }) => {
  const [selectedRegions, setSelectedRegions] = useState([]);
  const intl = useIntl();

  const handleRegionChange = (event, value) => {
    setSelectedRegions(value);
    onRegionSelect(value.map(item => item.code));
  };

  const filteredBeRegions = be_regions.filter(region => region !== "be_luxembourg");

  const regionsOptions = [...new Set(filteredBeRegions.concat(lu_regions, fr_regions))].map((region) => ({
    code: region,
    label: intl.formatMessage({ id: `regions.${region}` }),
  }));

  return (
    <Box style={{ width: "100%" }}>
      <Autocomplete
        multiple={true}
        id="region-autocomplete"
        options={regionsOptions}
        value={selectedRegions}
        onChange={handleRegionChange}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        renderInput={(params) => (
          <TextField
            {...params}
            label={intl.formatMessage({ id: "candidate_recommendation_bot.placeholer" })}
            placeholder="Select"
            variant="outlined"
          />
        )}
      />
      <Box display="flex" justifyContent="center" mt={1}>
        <Button
          variant="rounded"
          color="primary"
          size="small"
          onClick={() => {
            triggerNextStep({ trigger: "4" });
          }}
          disabled={selectedRegions == 0 ? true : false}
          sx={{ textTransform: "none" }}
        >
          <Box style={{ display: "flex", alignItems: "start" }}>
            <FormattedMessage id="candidate_recommendation_bot.step_one.continue" />
          </Box>
        </Button>
      </Box>
    </Box>
  );
};

export default RegionSelect;
