import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import Autocomplete from "react-google-autocomplete";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import validator from "validator";
import {
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import sh from "bundles/common/utils/sh";
import { replaceCurrentCandidate } from "bundles/Candidate/constants/candidateConstants";
import ConfirmModal from "bundles/common/components/ConfirmModal";

const EditTrainingModal = ({ training, closeModal, completionCallback }) => {
  const isEdit = training != null;
  const currentCandidate = useSelector((state) => state.current_candidate);
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const i18nLocale = useSelector((state) => state.i18nLocale);

  const intl = useIntl();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const useStyles = makeStyles(() => ({
    modalContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: "white",
      borderRadius: "8px",
      padding: isDesktop ? "32px 64px" : "32px",
      minWidth: isDesktop ? "600px" : "unset",
      width: isDesktop ? "unset" : "calc(100% - 32px) !important",
      height: isDesktop ? "unset" : "fit-content",
    },
    modalBox: {},
    headerBox: {
      textAlign: "center",
      marginBottom: "32px",
    },
    inputBox: {
      marginBottom: isDesktop ? "24px" : "16px",
    },
  }));
  const classes = useStyles();

  const initialValues = {
    id: training?.id,
    institution: training?.institution || "",
    diploma: training?.diploma || "",
    location: training?.location || "",
    honors: training?.honors || "",
    start_date: training?.start_date,
    end_date: training?.end_date,
  };
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );
    let end_date_must_be_higher = (
      <FormattedMessage id="form_validation.end_date_must_be_higher"></FormattedMessage>
    );

    if ("institution" in fieldValues) {
      temp.institution = fieldValues.institution ? "" : required;
    }

    if ("diploma" in fieldValues) {
      temp.diploma = fieldValues.diploma ? "" : required;
    }

    if ("location" in fieldValues) {
      temp.location = fieldValues.location ? "" : required;
    }

    if ("start_date" in fieldValues) {
      temp.start_date = fieldValues.start_date ? "" : required;
    }

    if ("end_date" in fieldValues) {
      if (values.start_date) {
        if (
          values.end_date &&
          !validator.isAfter(fieldValues.end_date, values.start_date)
        ) {
          temp.end_date = end_date_must_be_higher;
        } else {
          temp.end_date = "";
        }
      }
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const onPlacesSelect = (place) => {
    let newValues = {
      ...values,
      institution: place.name,
      location: place.address_components.find((c) =>
        c.types.includes("administrative_area_level_1")
      )?.short_name,
    };
    setValues(newValues);
    validate({ insitution: place.name });
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    let newValues = {
      ...values,
      [name]: value,
      institution: institutionValue(),
    };
    setValues(newValues);
    validate({ [name]: value, institution: institutionValue() });
  };

  const institutionValue = () => {
    let currentValue = document.getElementById(
      "institution_autocomplete"
    ).value;
    if (
      !values.institution ||
      values.institution == "" ||
      values.institution != currentValue
    ) {
      return currentValue;
    } else {
      return values.institution;
    }
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (validate(values)) {
      let training = {
        candidate_id: currentCandidate.id,
        id: values.id,
        institution: institutionValue(),
        diploma: values.diploma,
        location: values.location,
        honors: values.honors,
        start_date: values.start_date,
        end_date: values.end_date,
      };

      if (values.id) {
        sh.put(`training/${training.id}`, {
          authenticity_token: authenticityToken,
          training,
        })
          .then(() => {
            replaceCurrentCandidate(dispatch, authenticityToken, () => {
              closeModal();
              enqueueSnackbar(
                intl.formatMessage({ id: "settings.save_success" }),
                {
                  variant: "success",
                }
              );
            });
          })
          .catch((err) => {
            console.error(err);
            enqueueSnackbar(
              intl.formatMessage({ id: "settings.save_failed" }),
              { variant: "error" }
            );
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        sh.post("training", {
          authenticity_token: authenticityToken,
          training,
        })
          .then(() => {
            replaceCurrentCandidate(dispatch, authenticityToken, () => {
              closeModal();
              enqueueSnackbar(
                intl.formatMessage({ id: "settings.save_success" }),
                {
                  variant: "success",
                }
              );
              if (completionCallback) completionCallback();
            });
          })
          .catch((err) => {
            console.error(err);
            enqueueSnackbar(
              intl.formatMessage({ id: "settings.save_failed" }),
              { variant: "error" }
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setLoading(false);
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setIsConfirmModalOpen(true);
  };

  const handleDeleteConfirmed = (e) => {
    e.preventDefault();
    sh.delete(`training/${training.id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        authenticity_token: authenticityToken,
        training: training,
      },
    })
      .then(() => {
        replaceCurrentCandidate(dispatch, authenticityToken, () => {
          closeModal();
          enqueueSnackbar(intl.formatMessage({ id: "settings.save_success" }), {
            variant: "success",
          });
        });
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(intl.formatMessage({ id: "settings.save_failed" }), {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box className={classes.modalContainer}>
      <form className={classes.modalBox}>
        <Box className={classes.headerBox}>
          <Typography variant="h4">
            <FormattedMessage
              id={isEdit ? "settings.edit_tr" : "settings.add_tr"}
            />
          </Typography>
        </Box>
        <Box className={classes.inputBox}>
          <TextField
            size="small"
            fullWidth
            color="primary"
            name="institution"
            variant="outlined"
            placeholder={intl.formatMessage({
              id: "training.institution",
            })}
            id="institution_autocomplete"
            InputProps={{
              inputComponent: ({ ...props }) => (
                <Autocomplete
                  /* eslint-disable no-undef */
                  apiKey={process.env.GOOGLE_AUTOCOMPLETE_API_KEY}
                  language={i18nLocale}
                  {...props}
                  onPlaceSelected={onPlacesSelect}
                  options={{
                    types: ["establishment"],
                    fields: ["address_components", "name"],
                  }}
                  defaultValue={values.institution}
                />
              ),
            }}
            {...(errors["institution"] && {
              error: true,
              helperText: errors["institution"],
            })}
          />
        </Box>
        <Box className={classes.inputBox}>
          <TextField
            size="small"
            fullWidth
            required
            InputLabelProps={{ required: false }}
            value={values.diploma}
            type="text"
            name="diploma"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            variant="outlined"
            label={<FormattedMessage id="training.diploma" />}
            {...(errors["diploma"] && {
              error: true,
              helperText: errors["diploma"],
            })}
          />
        </Box>
        <Box className={classes.inputBox}>
          <TextField
            size="small"
            fullWidth
            required
            InputLabelProps={{ required: false }}
            value={values.location}
            type="text"
            name="location"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            variant="outlined"
            label={<FormattedMessage id="training.location" />}
            {...(errors["location"] && {
              error: true,
              helperText: errors["location"],
            })}
          />
        </Box>
        <Box className={classes.inputBox}>
          <TextField
            size="small"
            fullWidth
            value={values.honors}
            type="text"
            name="honors"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            variant="outlined"
            label={<FormattedMessage id="training.honors" />}
            {...(errors["honors"] && {
              error: true,
              helperText: errors["honors"],
            })}
          />
        </Box>
        <Box
          className={classes.inputBox}
          style={{ display: "flex", columnGap: "8px" }}
        >
          <TextField
            size="small"
            name="start_date"
            value={values.start_date}
            type="date"
            variant="outlined"
            label={<FormattedMessage id="training.start_date" />}
            fullWidth
            onBlur={handleInputValue}
            onChange={handleInputValue}
            InputLabelProps={{
              shrink: true,
            }}
            {...(errors["start_date"] && {
              error: true,
              helperText: errors["start_date"],
            })}
          />
          <TextField
            size="small"
            name="end_date"
            value={values.end_date}
            type="date"
            variant="outlined"
            label={<FormattedMessage id="training.end_date" />}
            fullWidth
            onBlur={handleInputValue}
            onChange={handleInputValue}
            InputLabelProps={{
              shrink: true,
            }}
            {...(errors["end_date"] && {
              error: true,
              helperText: errors["end_date"],
            })}
          />
        </Box>
        <Grid
          container
          spacing={isDesktop ? 4 : 2}
          className={classes.inputBox}
        >
          <Grid item xs={12} md={6}>
            <Button
              type="submit"
              fullWidth
              variant="rounded"
              color="primaryContained"
              disabled={loading}
              loading={loading}
              onClick={handleSubmit}
            >
              <FormattedMessage id="save" />
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            {isEdit ? (
              <Button
                fullWidth
                variant="rounded"
                color="dangerOutlined"
                disabled={loading}
                loading={loading}
                onClick={handleDelete}
              >
                <FormattedMessage id="delete" />
              </Button>
            ) : (
              <Button
                fullWidth
                variant="rounded"
                color="secondaryOutlined"
                onClick={closeModal}
              >
                <FormattedMessage id="cancel" />
              </Button>
            )}
          </Grid>
        </Grid>
      </form>

      <ConfirmModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        handleConfirm={handleDeleteConfirmed}
        labelId="delete_training_confirmation"
      />
    </Box>
  );
};

export default EditTrainingModal;
