import axios from "axios";

const sh = axios.create({
  baseURL: "/",
  timeout: 10000,
  withCredentials: true,
  xsrfCookieName: "CSRF-TOKEN",
  xsrfHeaderName: "X-CSRF-TOKEN",
  headers: {
    Accept: "application/json",
  },
});

const cancellationTokens = {};

sh.interceptors.request.use(
  (config) => {
    const { url } = config;

    // If a previous request for this endpoint is still active, cancel it
    if (cancellationTokens[url]) {
      cancellationTokens[url]("Request canceled due to new request");
    }

    // Create a new cancel token for this endpoint
    config.cancelToken = new axios.CancelToken(function executor(c) {
      cancellationTokens[url] = c;
    });

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default sh;
