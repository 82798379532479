import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import {
  Grid,
  Typography,
  FormControl,
  Checkbox,
  Autocomplete,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ShLoadingButton from "bundles/common/components/ShLoadingButton";
import { replaceCurrentCandidate } from "bundles/Candidate/constants/candidateConstants";
import sh from "bundles/common/utils/sh";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Privacy = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const currentCandidate = useSelector((state) => state.current_candidate);
  const authenticityToken = useSelector((state) => state.authenticity_token);

  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [blacklistedCompanies, setBlacklistedCompanies] = useState(
    currentCandidate?.blacklisted_companies || []
  );

  const save = () => {
    setIsLoading(true);
    sh.put("candidate", {
      authenticity_token: authenticityToken,
      id: currentCandidate.id,
      candidate: {
        blacklisted_company_ids: blacklistedCompanies.map((c) => c.id),
      },
    })
      .then(() => {
        replaceCurrentCandidate(dispatch, authenticityToken, () => {});
        enqueueSnackbar(intl.formatMessage({ id: "settings.save_success" }), {
          variant: "success",
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    sh.get(`${i18nLocale}/company`, {}).then((res) => {
      setCompanies(res?.data);
    });
  }, []);

  return (
    <Grid container spacing={2} justifyContent="space-between">
      {isDesktop && (
        <Grid
          item
          xs={12}
          md={9}
          style={{
            marginBottom: "8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">
            <FormattedMessage id="settings.privacy" />
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="h5">
          <FormattedMessage id="settings.company_hidden" />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <FormControl sx={{ width: "100%" }}>
          <Autocomplete
            multiple
            options={companies}
            value={blacklistedCompanies}
            onChange={(event, newValue) => {
              setBlacklistedCompanies(newValue);
            }}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => {
              return option.id == value.id;
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            style={{ width: "100%" }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label={intl.formatMessage({
                    id: "settings.blacklisted_companies",
                  })}
                  placeholder={intl.formatMessage({
                    id: "settings.search_for_companies",
                  })}
                />
              );
            }}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <ShLoadingButton
          size="large"
          width={isDesktop ? "240px" : "100%"}
          color="primaryContained"
          variant="rounded"
          onClick={save}
          loading={isLoading}
          pulsating={false}
          showFinishedState={false}
          defaultLabelId="save"
          loadingLabelId="saving"
        />
      </Grid>
    </Grid>
  );
};

export default Privacy;
