import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  TextField,
  Button,
  Link,
  Divider,
  Box,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import sh from "bundles/common/utils/sh";
import TabSwitch from "bundles/common/components/TabSwitch";
import PasswordField from "bundles/common/components/PasswordField";
import { useSnackbar } from "notistack";

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Login = () => {
  const authenticity_token = useSelector((state) => state.authenticity_token);
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const query = useQuery();

  const initialFormValues = {
    email: "",
    password: "",
    remember_me: false,
  };

  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : required;
    }

    if ("password" in fieldValues) {
      temp.password = fieldValues.password ? "" : required;
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const handleCheckboxValue = (e) => {
    const { name, checked } = e.target;
    setValues({
      ...values,
      [name]: checked,
    });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (validate(values)) {
      sh.post(`/${i18nLocale}/recruiters/sign_in`, {
        authenticity_token,
        recruiter: {
          email: values.email,
          password: values.password,
          remember_me: values.remember_me,
        },
      })
        .then((res) => {
          const sign_in_language = res.data.preferred_locale
            ? res.data.preferred_locale
            : i18nLocale;
          setLoading(false);
          redirectAfterLogin(sign_in_language);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response?.status == 401) {
            enqueueSnackbar(
              intl.formatMessage({
                id: "devise.login.unauthorized",
              }),
              { variant: "error" }
            );
          }
        });
    } else {
      setLoading(false);
    }
  };

  const redirectAfterLogin = (sign_in_language) => {
    let redirectTo = query.get("redirectTo");
    window.location.href = redirectTo
      ? `${window.location.origin}/${i18nLocale}${redirectTo}`
      : `/${sign_in_language}/recruiter`;
  };

  useEffect(() => {
    if (currentRecruiter?.id) {
      redirectAfterLogin();
    }
  }, [currentRecruiter]);

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Box my={4} />
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              disableTypography
              title={
                <Typography variant="h1" align="center" style={{ fontSize: "30px" }}>
                  <FormattedMessage
                    id="signin"
                    values={{
                      b: (chunks) => <b>{chunks}</b>,
                    }}
                  />
                </Typography>
              }
            ></CardHeader>
            <CardContent>
              <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                  <Divider />
                </Grid>
                <Grid item>
                  <TabSwitch
                    textLeft={<FormattedMessage id="candidate.candidate" />}
                    leftValue="candidate"
                    textRight={<FormattedMessage id="recruiter.recruiter" />}
                    rightValue="recruiter"
                    value="recruiter"
                    onClick={() => {
                      if (window)
                        window.location.href = `/${i18nLocale}/candidates/sign_in`;
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    type="email"
                    margin="normal"
                    variant="outlined"
                    onBlur={handleInputValue}
                    onChange={handleInputValue}
                    name="email"
                    size="small"
                    color="secondary"
                    {...(errors["email"] && {
                      error: true,
                      helperText: errors["email"],
                    })}
                    label={<FormattedMessage id="devise.signup.email" />}
                  />
                  <PasswordField
                    name="password"
                    labelId="devise.signup.password"
                    handleInputValue={handleInputValue}
                    color="secondary"
                    errors={errors}
                  />
                  <Box mb={2}>
                    <Typography variant="body2">
                      <Link
                        href={`/${i18nLocale}/recruiters/password/new`}
                        color="secondary"
                        underline="always"
                      >
                        <FormattedMessage id="devise.forgotten_password" />
                      </Link>
                    </Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={values.remember_me}
                        onChange={handleCheckboxValue}
                        name="remember_me"
                        color="secondary"
                      />
                    }
                    label={<FormattedMessage id="devise.login.remember_me" />}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions style={{ marginLeft: "8px", marginRight: "8px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="rounded"
                    color="secondaryContained"
                    size="large"
                    disabled={loading}
                  >
                    <Box justifyContent="center">
                      {loading && (
                        <CircularProgress size={16} color="secondary">
                          <FormattedMessage id="signin" />
                        </CircularProgress>
                      )}
                      {!loading && <FormattedMessage id="signin" />}
                    </Box>
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Box mb={2}>
                    <Typography variant="body2" align="center">
                      <FormattedMessage
                        align="center"
                        id="devise.not_yet_subscribed"
                      />{" "}
                      <Link
                        href={`/${i18nLocale}/recruiters/sign_up`}
                        color="secondary"
                        underline="always"
                      >
                        <FormattedMessage id="signup" />
                      </Link>
                    </Typography>
                    <Typography variant="body2" align="center">
                      <FormattedMessage
                        align="center"
                        id="devise.did_not_receive_confirmation"
                      />{" "}
                      <Link
                        href={`/${i18nLocale}/recruiters/confirmation/new`}
                        color="secondary"
                        underline="always"
                      >
                        <FormattedMessage id="click_here" />
                      </Link>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </form>
      </Grid>
    </Grid>
  );
};

export default Login;
