import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import sh from "bundles/common/utils/sh";
import PublicJobOfferSummary from "bundles/common/components/PublicJobOfferSummary";
import NotFound from "icons/notFound.svg";
import { FormattedMessage } from "react-intl";
import { useTheme } from "@mui/styles";

const PublicJobOffer = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const i18nLocale = useSelector((state) => state.i18nLocale);

  const params = useParams();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [jobOffer, setJobOffer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const fetchJobOffer = () => {
    setLoading(true);
    sh.get(`public_job_offers/${params.job_offer_id}`, {
      authenticity_token: authenticityToken,
    })
      .then((res) => {
        setJobOffer(res.data?.data);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setNotFound(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchJobOffer();
  }, []);

  return (
    <Container maxWidth="lg">
      <Grid container style={{ marginTop: "32px", marginBottom: "32px" }}>
        <Grid item xs={12}>
          {jobOffer && (
            <PublicJobOfferSummary
              jobOffer={jobOffer}
              updateJobOffer={setJobOffer}
            />
          )}
          {notFound && (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                padding: "32px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginBottom: "64px",
                }}
              >
                <Box style={{ marginBottom: "16px", textAlign: "center" }}>
                  <Typography variant="body1bold">
                    <FormattedMessage id="public_job_page.not_found" />
                  </Typography>
                </Box>
                <Button
                  color="primaryContained"
                  variant="rounded"
                  href={`${window.location.origin}/${i18nLocale}/candidates/sign_up?redirectTo=%2Fcandidate%2Fjob_offers`}
                >
                  <FormattedMessage id="public_job_page.not_found_cta" />
                </Button>
              </Box>
              <img
                src={NotFound}
                style={{ width: isDesktop ? "600px" : "80%" }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default PublicJobOffer;
