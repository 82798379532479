import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import {
  TextField,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import sh from "bundles/common/utils/sh";
import { replaceCurrentRecruiter } from "bundles/Recruiter/constants/recruiterConstants";
import RichTextEditor from "bundles/common/components/RichTextEditor";

const ProjectHeaderFields = ({ project, classes, handleNext, currentRecruiter, isNew, setFirstPartAndNew }) => {
  const theme = useTheme();
  const intl = useIntl();
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const initialValues = {
    id: project?.id,
    title: project?.title || "",
    description: project?.description || "",
  };

  const [values, setValues] = useState(initialValues);
  const [descriptionValue, setDescriptionValue] = useState(initialValues.description);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    let newValues = { ...values, [name]: value };
    setValues(newValues);
    validate({ [name]: value });
  };

  const handleTextArea = (name) => (value) => {
    setDescriptionValue(value);
    validate({ [name]: value });
  };

  const handleSaveFormFields = () => {
    setLoading(true);
    let project_data = {
      id: project.id,
      company_id: currentRecruiter.company.id,
      title: values.title,
      description: descriptionValue,
    };

    sh.put(`projects/${project_data.id}`, {
      authenticity_token: authenticityToken,
      project_data,
    })
      .then(() => {
        replaceCurrentRecruiter(dispatch, authenticityToken, () => { });
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar(
          intl.formatMessage({ id: "settings.save_failed" }),
          { variant: "error" }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );

    if ("title" in fieldValues) {
      temp.title = fieldValues.title ? "" : required;
    }

    if ("description" in fieldValues) {
      temp.description = fieldValues.description ? "" : required;
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleNextAndSubmit = () => {
    const isValid = validate({ ...values, ...{ description: descriptionValue } });
    if (isValid) {
      handleNext();
      setFirstPartAndNew(false);
      handleSaveFormFields();
    }
  };

  useEffect(() => {
    if (isNew) {
      setFirstPartAndNew(true);
    }
  }, [isNew]);

  return (
    <Box style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%"}}>
      <Box>
        <Box className={classes.inputBox} style={{ width: "50%", margin: "24px auto" }}>
          <TextField
            size="small"
            fullWidth
            required
            InputLabelProps={{ required: false }}
            value={values.title}
            type="text"
            name="title"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            color="secondary"
            variant="outlined"
            label={<FormattedMessage id="project.title" />}
            {...(errors["title"] && {
              error: true,
              helperText: errors["title"],
            })}
          />
        </Box>
        <Box
          className={classes.inputBox}
        >
          <Typography variant="body1" style={{ marginBottom: "8px" }}>
            <FormattedMessage id="project.description" />
          </Typography>
          <RichTextEditor
            height="160px"
            onChange={handleTextArea("description")}
            defaultValue={descriptionValue}
            {...(errors["description"] && {
              error: true,
              helperText: errors["description"],
            })}
          />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="rounded"
          color="secondaryContained"
          onClick={handleNextAndSubmit}
        >
          <FormattedMessage id="next" />
        </Button>
      </Box>
    </Box>
  );
};

export default ProjectHeaderFields;
