import React, { useEffect, useState, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import StickyBox from "react-sticky-box";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  useMediaQuery,
} from "@mui/material";
import Pagination from "@mui/lab/Pagination";
import { useTheme, makeStyles } from "@mui/styles";
import sh from "bundles/common/utils/sh";
import MetricsBar from "./MetricsBar";
import { StyledTabs, StyledTab } from "bundles/common/components/StyledTabs";
import JobOfferCard from "./JobOfferCard";
import JobOfferDialog from "bundles/common/components/JobOfferDialog";
import JobOfferSummary from "./JobOfferSummary";
import BackdropScreen from "bundles/common/components/BackdropScreen";
import wipImg from "pictures/wip.png";

const JobOfferIndex = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const useStyles = makeStyles(() => ({
    tabItems: {
      backgroundColor: `${theme.palette.secondary.main} !important`,
      color: `${theme.palette.common.white} !important`,

      "&.Mui-selected": {
        color: `${theme.palette.common.white} !important`,
      },
    },
  }));
  const classes = useStyles();

  const authenticityToken = useSelector((state) => state.authenticity_token);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const querySelectedTab = queryParams.get("selectedTab") || "online";
  const queryjid = queryParams.get("jid");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [jobOffers, setJobOffers] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [selectedTab, setSelectedTab] = useState(querySelectedTab);
  const [selectedJob, setSelectedJob] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [redirectToEdit] = useState(false);
  const [onlineJobCount, setOnlineJobCount] = useState(0);
  const [draftJobCount, setDraftJobCount] = useState(0);
  const [closedJobCount, setClosedJobCount] = useState(0);

  useEffect(() => {
    if (querySelectedTab) setSelectedTab(querySelectedTab);
  }, [querySelectedTab]);

  useEffect(() => {
    if (selectedTab) fetchJobOffers(selectedTab, page);
  }, [selectedTab, page, isDesktop]);

  const closeDialog = () => {
    setSelectedJob(null);
  };

  const fetchJobOffers = (offer_status = "", page = 1) => {
    setLoading(true);
    sh.get("job_offer", {
      authenticity_token: authenticityToken,
      params: {
        offer_status: offer_status,
        page: page,
      },
    })
      .then((res) => {
        setJobOffers(res.data?.data?.job_offers);
        setOnlineJobCount(res.data?.data?.online_job_count);
        setDraftJobCount(res.data?.data?.draft_job_count);
        setClosedJobCount(res.data?.data?.closed_job_count);
        setPageCount(res.data?.pagy?.pages);
        let selected =
          res.data?.data?.job_offers.find((offer) => offer.id == queryjid) ||
          res.data?.data?.job_offers[0];
        if (isDesktop && selected) {
          setSelectedJob(selected);
          setSelectedTab(selected?.offer_status);
          setIsDialogOpen(true);
          queryParams.delete("jid");
          navigate({
            search: `?${queryParams.toString()}`,
          });
        } else if (!selected && res.data?.data?.job_offers?.length == 0) {
          setSelectedJob(null);
          setIsDialogOpen(false);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleJobClick = (jobOffer) => {
    setSelectedJob(jobOffer);
    setIsDialogOpen(true);
  };

  const handleNoOfferCTA = () => {
    if (selectedTab == "draft") {
      window.location.href = `/${i18nLocale}/recruiter/job_offer_creation`;
    } else if (selectedTab == "online") {
      draftJobCount > 0
        ? setSelectedTab("draft")
        : (window.location.href = `/${i18nLocale}/recruiter/job_offer_creation`);
    } else {
      let destinationTab =
        onlineJobCount > draftJobCount && onlineJobCount != 0
          ? "online"
          : "draft";
      setSelectedTab(destinationTab);
    }
  };

  const handlePublish = () => {
    sh.put(`job_offer/${selectedJob.id}`, {
      authenticity_token: authenticityToken,
      job_offer: selectedJob,
      skills: selectedJob.skills,
      with_publish: true,
    }).then((res) => {
      if (res.data.result.publish_error) {
        const messageId = "flash.success.job_offer_edition_without_publish";
        showSnackbar(
          intl.formatMessage({
            id: messageId,
          }),
          "warning",
          15000
        );
      } else {
        const messageId = "flash.success.job_offer_edition_with_publish";
        showSnackbar(
          intl.formatMessage({
            id: messageId,
          }),
          "success",
          2000
        );
        updateCurrentRecruiter(res.data.recruiter);
        navigate({ search: "?selectedTab=online" });
      }
    });
  };

  const showSnackbar = (message, variant, autoHideDuration) => {
    enqueueSnackbar(message, {
      variant: variant,
      autoHideDuration: autoHideDuration,
    });
  };

  const updateCurrentRecruiter = useCallback(
    (recruiter) => {
      dispatch({ type: "UPDATE_CURRENT_RECRUITER", payload: recruiter });
    },
    [dispatch]
  );

  if (redirectToEdit) {
    return;
    // return (
    //   <Navigate
    //     to={{
    //       pathname: `/${i18nLocale}/recruiter/job_offer_creation`,
    //       state: { jobOffer: selectedJob },
    //     }}
    //   />
    // );
  }

  return (
    <Container maxWidth="lg">
      <Grid
        container
        spacing={4}
        style={{ marginTop: "16px", marginBottom: "48px" }}
      >
        <BackdropScreen open={loading} />
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: isDesktop ? "row" : "column",
          }}
        >
          <Typography
            align="center"
            variant="h2"
            style={{ fontFamily: "Work Sans" }}
          >
            <b>
              <FormattedMessage id="job_offer.my_job_offers" />
            </b>
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            style={{
              height: "48px",
              borderRadius: "30px",
              minWidth: isDesktop ? "200px" : "100%",
              marginTop: isDesktop ? "unset" : "32px",
            }}
            href={`/${i18nLocale}/recruiter/job_offer_creation`}
          >
            <FormattedMessage id="landing_recruiter.new_offer" />
          </Button>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "16px" }}>
          <MetricsBar />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledTabs
                value={selectedTab}
                style={{
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                }}
                onChange={(e, v) => {
                  setPage(1);
                  navigate({ search: `?selectedTab=${v}` });
                }}
              >
                <StyledTab
                  value="draft"
                  className={classes.tabItems}
                  label={
                    <Box>
                      <FormattedMessage id="job_offer.draft" /> ({draftJobCount}
                      )
                    </Box>
                  }
                />
                <StyledTab
                  value="online"
                  className={classes.tabItems}
                  label={
                    <Box>
                      <FormattedMessage id="job_offer.online" /> (
                      {onlineJobCount})
                    </Box>
                  }
                />

                <StyledTab
                  value="closed"
                  className={classes.tabItems}
                  label={
                    <Box>
                      <FormattedMessage id="job_offer.closed" /> (
                      {closedJobCount})
                    </Box>
                  }
                />
              </StyledTabs>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ paddingLeft: "16px", paddingRight: "16px" }}
            >
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
                style={{ width: "100% !important", marginLeft: "0" }}
              >
                {jobOffers?.length > 0 &&
                  jobOffers.map((offer, i) => {
                    return (
                      <JobOfferCard
                        offer={offer}
                        key={`${i}--summary`}
                        handleJobClicked={handleJobClick}
                        selected={offer.id == selectedJob?.id}
                      />
                    );
                  })}
              </Grid>
            </Grid>
            {pageCount > 1 && (
              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  alignContent="center"
                >
                  <Box mt={2}>
                    <Pagination
                      size="large"
                      onChange={(e, page) => setPage(page)}
                      count={pageCount}
                      page={page}
                      variant="outlined"
                      color="secondary"
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {selectedJob && (
          <Grid
            md={6}
            item
            {...(isDesktop && {
              style: { overflow: "auto" },
            })}
          >
            {selectedJob &&
              (isDesktop ? (
                <StickyBox>
                  <JobOfferSummary
                    jobOffer={selectedJob}
                    skills={selectedJob.skills}
                    saveJobOffer={handlePublish}
                  />
                </StickyBox>
              ) : (
                <Grid item xs={12}>
                  <JobOfferDialog
                    skills={selectedJob.skills}
                    closeDialog={closeDialog}
                    setIsApplying={() => {}}
                    jobOffer={selectedJob}
                  ></JobOfferDialog>
                </Grid>
              ))}
          </Grid>
        )}
        {!loading && jobOffers.length == 0 && (
          <Grid item xs={12}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography style={{ marginBottom: "32px", textAlign: "center" }}>
                <FormattedMessage id={`recruiter.no_${selectedTab}_offers`} />
              </Typography>
              <img
                src={wipImg}
                style={{ maxWidth: isDesktop ? "unset" : "280px" }}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleNoOfferCTA}
                style={{
                  height: "48px",
                  borderRadius: "30px",
                  marginTop: "32px",
                }}
              >
                <FormattedMessage
                  id={`recruiter.no_${selectedTab}_offers_cta`}
                />
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default JobOfferIndex;
