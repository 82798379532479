import React, { useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import WorkIcon from "icons/work.svg";
import EnvelopeIcon from "icons/envelope.svg";
import EnvelopeOpenIcon from "icons/envelope-open.svg";
import PeopleIcon from "icons/people.svg";
import { FormattedMessage } from "react-intl";
import { useEffect } from "react";

const MetricsBar = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const useStyles = makeStyles({
    container: {
      height: "100%",
    },
    item: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: isDesktop ? "16px" : "8px",
      paddingBottom: isDesktop ? "16px" : "8px",

      "&:hover": {
        backgroundColor: "#FCFCFC",
        cursor: "pointer",
      },
    },
    box: {
      flexGrow: 1,
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minHeight: isDesktop ? "unset" : "96px",
    },
    divider: {
      height: isDesktop ? "96%" : "calc(100% + 8px)",
      marginTop: isDesktop ? "auto" : "-8px",
      marginBottom: isDesktop ? "auto" : "-8px",
    },
    dividerHorizontal: {
      width: "100%",
    },
    img: {
      flexGrow: 1,
      marginTop: isDesktop ? "12px" : "4px",
      width: isDesktop ? "44px" : "24px",
    },
  });
  const classes = useStyles();
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const currentRecruiter = useSelector((state) => state.current_recruiter);

  const [currentCompany, setCurrentCompany] = useState(
    currentRecruiter?.company
  );

  useEffect(() => {
    setCurrentCompany(currentRecruiter?.company);
  }, [currentRecruiter?.id]);

  const handleCardClicked = (cardValue) => {
    switch (cardValue) {
    case "online_jobs":
      window.location.href = `/${i18nLocale}/recruiter/job_offers`;
      break;
    case "pending_applications":
      window.location.href = `/${i18nLocale}/recruiter/my_candidates?selectedTab=pending`;
      break;
    case "open_applications":
      window.location.href = `/${i18nLocale}/recruiter/my_candidates?selectedTab=open`;
      break;
    case "all_applications":
      window.location.href = `/${i18nLocale}/recruiter/my_candidates`;
      break;
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} component={Paper} elevation={1}>
        <Grid container className={classes.container}>
          <Grid
            item
            xs={6}
            md={3}
            className={classes.item}
            onClick={() => handleCardClicked("online_jobs")}
          >
            {isDesktop ? (
              <>
                <Box className={classes.box}>
                  <Typography variant="h2" color="secondary">
                    <b>{currentCompany?.online_job_offers_count}</b>
                  </Typography>
                  <Typography variant="h5">
                    <b>
                      <FormattedMessage id="metrics_bar.online_jobs" />
                    </b>
                  </Typography>
                  <img src={WorkIcon} className={classes.img} />
                </Box>
                <Divider orientation="vertical" className={classes.divider} />
              </>
            ) : (
              <>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                  }}
                >
                  <Box style={{ height: "100%", display: "flex" }}>
                    <Box className={classes.box}>
                      <Typography variant="h2" color="secondary">
                        <b>{currentCompany?.online_job_offers_count}</b>
                      </Typography>
                      <Typography variant={isDesktop ? "h5" : "h8"}>
                        <b>
                          <FormattedMessage id="metrics_bar.online_jobs" />
                        </b>
                      </Typography>
                      <img src={WorkIcon} className={classes.img} />
                    </Box>
                    <Divider
                      orientation="vertical"
                      className={classes.divider}
                    />
                  </Box>
                  <Divider
                    orientation="horizontal"
                    className={classes.dividerHorizontal}
                  />
                </Box>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={6}
            md={3}
            className={classes.item}
            style={{ flexDirection: isDesktop ? "row" : "column" }}
            onClick={() => handleCardClicked("pending_applications")}
          >
            <Box className={classes.box}>
              <Typography variant="h2" color="secondary">
                <b>{currentCompany?.new_applications_count}</b>
              </Typography>
              <Typography variant={isDesktop ? "h5" : "h8"}>
                <b>
                  <FormattedMessage id="metrics_bar.pending_applications" />
                </b>
              </Typography>
              <img src={EnvelopeIcon} className={classes.img} />
            </Box>
            {isDesktop ? (
              <Divider orientation="vertical" className={classes.divider} />
            ) : (
              <Divider
                orientation="horizontal"
                className={classes.dividerHorizontal}
              />
            )}
          </Grid>
          <Grid
            item
            xs={6}
            md={3}
            className={classes.item}
            onClick={() => handleCardClicked("open_applications")}
          >
            <Box className={classes.box}>
              <Typography variant="h2" color="secondary">
                <b>{currentCompany?.open_applications_count}</b>
              </Typography>
              <Typography variant={isDesktop ? "h5" : "h8"}>
                <b>
                  <FormattedMessage id="metrics_bar.open_applications" />
                </b>
              </Typography>
              <img src={PeopleIcon} className={classes.img} />
            </Box>
            <Divider
              orientation="vertical"
              className={classes.divider}
              style={
                isDesktop
                  ? {}
                  : {
                    height: "calc(100% + 24px)",
                    marginTop: "-16px",
                    marginBottom: "-16px",
                  }
              }
            />
          </Grid>
          <Grid
            item
            xs={6}
            md={3}
            className={classes.item}
            onClick={() => handleCardClicked("all_applications")}
          >
            <Box className={classes.box}>
              <Typography variant="h2" color="secondary">
                <b>{currentCompany?.applications_count}</b>
              </Typography>
              <Typography variant={isDesktop ? "h5" : "h8"}>
                <b>
                  <FormattedMessage id="metrics_bar.all_applications" />
                </b>
              </Typography>
              <img src={EnvelopeOpenIcon} className={classes.img} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MetricsBar;
