import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import {
  Grid,
  Typography,
  Button,
  Box,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { replaceCurrentCandidate } from "bundles/Candidate/constants/candidateConstants";
import { useTheme } from "@mui/styles";
import sh from "bundles/common/utils/sh";
import ChipSelection from "bundles/common/components/ChipSelection";
import SkillSelect from "bundles/common/components/SkillSelect";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const Skills = ({ completionCallback }) => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const currentCandidate = useSelector((state) => state.current_candidate);
  const dispatch = useDispatch();
  const theme = useTheme();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [hardSkills, setHardSkills] = useState(
    currentCandidate.hard_skills || []
  );
  const [softSkills, setSoftSkills] = useState(
    currentCandidate.soft_skills || []
  );
  const [languageSkills, setLanguageSkills] = useState(
    currentCandidate.language_skills || []
  );

  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [displayedSkillSection, setDisplayedSkillSection] = useState(null);

  const handleSectionClicked = (section) => {
    if (displayedSkillSection == section) {
      setDisplayedSkillSection(null);
    } else {
      setDisplayedSkillSection(section);
    }
  };

  const handleSkillChange = (category, value, values) => {
    let formattedValue = value?.map((elem) => {
      return { name: elem.id, category: category };
    });

    if (category === "hard") {
      setHardSkills(formattedValue);
    } else if (category === "soft") {
      setSoftSkills(formattedValue);
    } else if (category === "lang") {
      setLanguageSkills(formattedValue);
    }

    let newValues = { ...values, [category]: formattedValue };
    setValues(newValues);
  };

  const handleDeleteSkill = (deletedSkill, category) => {
    let skills;
    if (category === "hard") {
      skills = hardSkills.filter((value) => value.name != deletedSkill.value);
      setHardSkills(skills);
    } else if (category === "soft") {
      skills = softSkills.filter((value) => value.name != deletedSkill.value);
      setSoftSkills(skills);
    } else if (category === "lang") {
      skills = languageSkills.filter(
        (value) => value.name != deletedSkill.value
      );
      setLanguageSkills(skills);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const hard_skills_temp = hardSkills.filter((skill) => !!skill.name);
    const soft_skills_temp = softSkills.filter((skill) => !!skill.name);
    const languages_temp = languageSkills.filter((skill) => !!skill.name);

    sh.post(`/update_skills/${currentCandidate.id}`, {
      authenticity_token: authenticityToken,
      skills: [...hard_skills_temp, ...soft_skills_temp, ...languages_temp],
    })
      .then(() => {
        sh.put("candidate", {
          authenticity_token: authenticityToken,
          candidate: { ...values, id: currentCandidate.id },
        })
          .then((res) => {
            replaceCurrentCandidate(dispatch, authenticityToken, () => {});
            enqueueSnackbar(
              intl.formatMessage(
                { id: "candidate.updated_profile" },
                { percentage: res?.data.profile_completion }
              ),
              {
                variant: "success",
              }
            );
            if (completionCallback) completionCallback();
          })
          .catch((err) => {
            console.error(err);
            enqueueSnackbar("Oops... Something went wrong", {
              variant: "error",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setHardSkills(hard_skills_temp);
        setSoftSkills(soft_skills_temp);
        setLanguageSkills(languages_temp);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Hard Skills */}
      <Grid container>
        <Grid
          item
          xs={12}
          md={9}
          style={{
            marginBottom: "8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">
            <FormattedMessage id="settings.skills" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            onClick={() => handleSectionClicked("hard")}
            style={{ cursor: "pointer" }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <Typography
                align={isDesktop ? "left" : "center"}
                style={{ marginTop: "16px" }}
                variant="h5"
              >
                <FormattedMessage id="candidate.profile.skills.hard" />
              </Typography>

              {displayedSkillSection == "hard" ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </Box>
            <Typography
              align={isDesktop ? "left" : "center"}
              variant="body1"
              style={{
                fontFamily: "Work Sans",
                marginTop: "8px",
                marginBottom: "8px",
              }}
            >
              <FormattedMessage id="candidate.profile.skills.add_up_to_hard" />
            </Typography>
          </Box>
          {displayedSkillSection == "hard" && (
            <Box
              style={{
                display: "flex",
                columnGap: "48px",
                flexDirection: isDesktop ? "row" : "column",
              }}
            >
              <SkillSelect
                selectedValues={hardSkills.map((s) => s.name)}
                category="hard"
                label={intl.formatMessage({
                  id: "candidate.profile.skills.hard",
                })}
                disabled={hardSkills.length >= 10}
                onChange={handleSkillChange}
              />
              <ChipSelection
                style={{ marginTop: isDesktop ? "0px" : "8px" }}
                size="large"
                values={hardSkills?.map((s) => {
                  return {
                    value: s.name,
                    label: s.company_id
                      ? s.name
                      : intl.formatMessage({
                          id: `skills_list.hard.${s.name}`,
                        }),
                  };
                })}
                backgroundColor={theme.palette.primary.main}
                color={theme.palette.common.white}
                maxSelection={10}
                category="hard"
                onDelete={(skill) => handleDeleteSkill(skill, "hard")}
              />
            </Box>
          )}
        </Grid>

        <Grid item xs={12} style={{ marginTop: "32px" }}>
          <Divider />
        </Grid>

        {/* Soft Skills */}
        <Grid item xs={12}>
          <Box
            onClick={() => handleSectionClicked("soft")}
            style={{ cursor: "pointer" }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <Typography
                align={isDesktop ? "left" : "center"}
                style={{ marginTop: "16px" }}
                variant="h5"
              >
                <FormattedMessage id="candidate.profile.skills.soft" />
              </Typography>

              {displayedSkillSection == "soft" ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </Box>
            <Typography
              align={isDesktop ? "left" : "center"}
              variant="body1"
              style={{
                fontFamily: "Work Sans",
                marginTop: "8px",
                marginBottom: "8px",
              }}
            >
              <FormattedMessage id="candidate.profile.skills.add_up_to_soft" />
            </Typography>
          </Box>
          {displayedSkillSection == "soft" && (
            <Box
              style={{
                display: "flex",
                columnGap: "48px",
                flexDirection: isDesktop ? "row" : "column",
              }}
            >
              <SkillSelect
                selectedValues={softSkills.map((s) => s.name)}
                category="soft"
                label={intl.formatMessage({
                  id: "candidate.profile.skills.soft",
                })}
                disabled={softSkills.length >= 10}
                onChange={handleSkillChange}
              />
              <ChipSelection
                style={{ marginTop: isDesktop ? "0px" : "8px" }}
                size="large"
                values={softSkills?.map((s) => {
                  return {
                    value: s.name,
                    label: s.company_id
                      ? s.name
                      : intl.formatMessage({
                          id: `skills_list.soft.${s.name}`,
                        }),
                  };
                })}
                backgroundColor={theme.palette.primary.main}
                color={theme.palette.common.white}
                maxSelection={10}
                category="soft"
                onDelete={(skill) => handleDeleteSkill(skill, "soft")}
              />
            </Box>
          )}
        </Grid>

        <Grid item xs={12} style={{ marginTop: "32px" }}>
          <Divider />
        </Grid>

        {/* Language Skills */}
        <Grid item xs={12}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              cursor: "pointer",
            }}
            onClick={() => handleSectionClicked("lang")}
          >
            <Typography
              align={isDesktop ? "left" : "center"}
              style={{ marginTop: "16px", marginBottom: "8px" }}
              variant="h5"
            >
              <FormattedMessage id="candidate.profile.skills.lang" />
            </Typography>
            {displayedSkillSection == "lang" ? (
              <ExpandLessIcon />
            ) : (
              <ExpandMoreIcon />
            )}
          </Box>
          {displayedSkillSection == "lang" && (
            <Box
              style={{
                display: "flex",
                columnGap: "48px",
                flexDirection: isDesktop ? "row" : "column",
              }}
            >
              <SkillSelect
                selectedValues={languageSkills.map((s) => s.name)}
                category="lang"
                label={intl.formatMessage({
                  id: "candidate.profile.skills.lang",
                })}
                onChange={handleSkillChange}
              />
              <ChipSelection
                style={{ marginTop: isDesktop ? "0px" : "8px" }}
                size="large"
                values={languageSkills?.map((s) => {
                  return {
                    value: s.name,
                    label: s.company_id
                      ? s.name
                      : intl.formatMessage({
                          id: `skills_list.lang.${s.name}`,
                        }),
                  };
                })}
                backgroundColor={theme.palette.primary.main}
                color={theme.palette.common.white}
                category="lang"
                onDelete={(skill) => handleDeleteSkill(skill, "lang")}
              />
            </Box>
          )}
          {/* Save button */}
          <Grid item xs={12} textAlign="center">
            <Button
              disabled={loading}
              type="submit"
              variant="rounded"
              color="primaryContained"
              style={{
                width: isDesktop ? "144px" : "100%",
                marginTop: "32px",
                marginBottom: "8px",
              }}
            >
              <FormattedMessage id="save" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default Skills;
