import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  Grid,
  Typography,
  Button,
  Box,
  IconButton,
  Modal,
} from "@mui/material";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@mui/styles";
import { grey } from "@mui/material/colors";
import EditIcon from "@mui/icons-material/Edit";
import SkillIcon from "nextIcon.svg";
import EditCustomSkillsModal from "./EditCustomSkillsModal";

const useStyles = makeStyles((theme) => ({
  skill: {
    color: "white",
    padding: theme.spacing(1),
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  hr: {
    color: theme.palette.common.sh_grey,
    opacity: 0.5,
  },
}));

const CustomSkills = () => {
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const theme = useTheme();
  const categoryColor = (cat) => {
    switch (cat) {
      case "hard":
        return theme.palette.common.blue;
      case "soft":
        return theme.palette.primary.main;
      case "lang":
        return grey[500];
      default:
        return theme.palette.primary.main;
    }
  };
  const classes = useStyles();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState(null);

  const handleNewSkillClicked = (e) => {
    e.preventDefault();
    setSelectedSkill(null);
    setIsEditModalOpen(true);
  };

  const handleSkillEditClicked = (skill) => {
    setSelectedSkill(skill);
    setIsEditModalOpen(true);
  };

  return (
    <Grid container>
      {currentRecruiter.company.skills.length > 0 ? (
        <>
          <Grid
            item
            xs={12}
            style={{
              marginBottom: "32px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h4">
              <FormattedMessage id="settings.custom_skills_header" />
            </Typography>
            <Button
              variant="rounded"
              color="secondaryOutlined"
              size="medium"
              onClick={handleNewSkillClicked}
              style={{ width: "240px" }}
            >
              <FormattedMessage id="settings.custom_skills_add" />
            </Button>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={8}
              style={{
                marginBottom: "24px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">
                <FormattedMessage id="settings.custom_skills_header2" />
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                marginBottom: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">
                <FormattedMessage id="settings.custom_skills_header3" />
              </Typography>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "8px" }}>
            {currentRecruiter.company.skills.map((skill) => (
              <Box key={`${skill.id}--skill`}>
                <Grid
                  container
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={8}>
                    <Typography
                      variant="body2bold"
                      style={{ marginRight: "8px", width: "50%" }}
                    >
                      {skill.name}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={classes.skill}
                    style={{
                      backgroundColor: categoryColor(skill.category),
                    }}
                  >
                    <Typography
                      variant="body2bold"
                      style={{ textAlign: "center" }}
                    >
                      <FormattedMessage
                        id={`candidate.profile.skills.${skill.category}`}
                      />
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingLeft: "20px",
                    }}
                  >
                    <IconButton>
                      <EditIcon onClick={() => handleSkillEditClicked(skill)} />
                    </IconButton>
                  </Grid>
                </Grid>
                <hr className={classes.hr} />
              </Box>
            ))}
          </Grid>
        </>
      ) : (
        <>
          <Grid
            item
            xs={12}
            style={{
              marginBottom: "32px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h4">
              <FormattedMessage id="settings.custom_skills_header" />
            </Typography>
            <Button
              variant="rounded"
              color="secondaryOutlined"
              size="medium"
              onClick={handleNewSkillClicked}
              style={{ width: "240px" }}
            >
              <FormattedMessage id="settings.custom_skills_add" />
            </Button>
          </Grid>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Grid
              item
              style={{ width: "50%" }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="body2bold"
                  style={{
                    marginTop: "10px",
                    marginBottom: "16px",
                  }}
                >
                  <FormattedMessage id="settings.custom_skills_empty" />
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <img
                  style={{ width: "250px", marginTop: "40px" }}
                  src={SkillIcon}
                  alt="Skill Icon"
                />
              </Box>
            </Grid>
          </Grid>
        </>
      )}
      <Modal
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <EditCustomSkillsModal
          closeModal={() => setIsEditModalOpen(false)}
          skill={selectedSkill}
        />
      </Modal>
    </Grid>
  );
};

export default CustomSkills;
