import React, { useState } from "react";
import {
  Grid,
  Button,
  Box,
  Link,
  Typography,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import sh from "bundles/common/utils/sh";
import { useSnackbar } from "notistack";
import {
  companyIndustryMap,
  getResponseMessageId,
} from "bundles/common/utils/constants";

// Event snippet for Inscription Client SH conversion page In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button.
function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof url != "undefined") {
      window.location = url;
    }
  };
  gtag("event", "conversion", {
    send_to: "AW-10868281309/HoiQCI_m_asDEN2fs74o",
    event_callback: callback,
  });
  return false;
}

export default function StepTwo({
  recruiterData,
  setRecruiterData,
  setCompanyData,
  nextStep,
}) {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const railsEnv = useSelector((state) => state.railsEnv);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const initialFormValues = {
    name: "",
    job_title: "",
  };

  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );

    if ("name" in fieldValues) {
      temp.name = fieldValues.name ? "" : required;
    }

    if ("job_title" in fieldValues) {
      temp.job_title = fieldValues.job_title ? "" : required;
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (validate(values)) {
      setRecruiterData({ ...recruiterData, job_title: values["job_title"] });
      setCompanyData({
        name: values["name"],
        company_industry: values["company_industry"],
      });
      createCompany();
    } else {
      setLoading(false);
    }
  };

  const createCompany = () => {
    if (validate(values)) {
      sh.post(`${i18nLocale}/recruiters`, {
        recruiter: { ...recruiterData, job_title: values["job_title"] },
        company: {
          name: values["name"],
          company_industry: values["company_industry"],
        },
      })
        .then((res) => {
          if (typeof fbq !== "undefined")
            fbq("trackCustom", "Inscription Recruteur SH");
          if (window && typeof window.lintrk !== "undefined")
            window.lintrk("track", { conversion_id: 8587650 });
          if (railsEnv === "production" && typeof gtag !== "undefined")
            gtag_report_conversion();

          setCompanyData({ name: values["name"], id: res?.data?.company_id });
          nextStep();
        })
        .catch((err) => {
          setLoading(false);
          if (err.response?.data?.message) {
            enqueueSnackbar(
              getResponseMessageId(err?.response?.data?.message),
              { variant: "error" }
            );
          }
          console.error("Error", err);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            style={{ textAlign: "center", marginBottom: "32px" }}
          >
            <b>
              <FormattedMessage
                id="recruiter.signup_modal.title_step_3"
                values={{ firstname: recruiterData.first_name }}
              />
            </b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            name="name"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            variant="outlined"
            label={<FormattedMessage id="company.company" />}
            color="secondary"
            {...(errors["name"] && {
              error: true,
              helperText: errors["name"],
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            type="text"
            variant="outlined"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            name="job_title"
            {...(errors["job_title"] && {
              error: true,
              helperText: errors["job_title"],
            })}
            label={<FormattedMessage id="recruiter.job_title" />}
            color="secondary"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined" color="secondary" required>
            <InputLabel id="field-label">
              <FormattedMessage id="company.company_industry.company_industry" />
            </InputLabel>
            <Select
              id="type"
              label={`${intl.formatMessage({
                id: "company.company_industry.company_industry",
              })} *`}
              name="company_industry"
              required
              value={values.company_industry}
              onChange={handleInputValue}
            >
              {companyIndustryMap.map((value) => (
                <MenuItem value={value} key={value}>
                  <FormattedMessage id={"company.company_industry." + value} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: "8px" }}>
        <Grid item xs={12}>
          <Button
            fullWidth
            type="submit"
            variant="rounded"
            color="secondaryContained"
            size="medium"
            disabled={loading}
          >
            <Box justifyContent="center">
              {loading && (
                <CircularProgress size={16} color="secondary">
                  <FormattedMessage id="next" />
                </CircularProgress>
              )}
              {!loading && <FormattedMessage id="devise.signup.build_team" />}
            </Box>
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Box mb={2}>
            <Typography variant="body2" align="center">
              <FormattedMessage align="center" id="devise.already_subscribed" />{" "}
              <Link
                href={`/${i18nLocale}/recruiters/sign_in`}
                color="secondary"
                underline="always"
              >
                <FormattedMessage id="signin" />
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}
