import React from "react";
import {
  Container,
  Grid,
  Box,
  Modal,
  Typography,
  Button,
} from "@mui/material";
import Pagination from "@mui/lab/Pagination";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import JobApplicationCard from "bundles/common/components/JobApplicationCard";
import ApplicationDialog from "../ApplicationDialog";
import SpontaneousApplicationDialog from "../SpontaneousApplicationDialog";
import CardSelect from "bundles/common/components/CardSelect";
import NoData from "icons/noDataCandidat.svg";

const JobApplicationIndexDesktop = ({
  jobApplications,
  page,
  setPage,
  pageCount,
  selectedApplication,
  isDialogOpen,
  setIsDialogOpen,
  selectedStatus,
  scopes,
  handleScopeChange,
  handleApplicationClick,
}) => {
  const i18nLocale = useSelector((state) => state.i18nLocale);

  return (
    <Container
      maxWidth="lg"
      style={{ paddingLeft: "0px", paddingRight: "0px" }}
    >
      <Grid
        container
        justifyContent="center"
        style={{
          marginLeft: "16px",
          marginTop: "64px",
          paddingLeft: "32px",
          paddingRight: "32px",
        }}
      >
        <Grid item xs={12} marginTop={2} marginBottom={3}>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={8} style={{ overflow: "auto" }}>
              <CardSelect
                fieldName="scope"
                selected={selectedStatus}
                values={scopes}
                onChange={handleScopeChange}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                {jobApplications?.length > 0 ? (
                  jobApplications?.map((key, i) => (
                    <JobApplicationCard
                      jobApplication={key}
                      key={`application-${i}`}
                      onClick={() => handleApplicationClick(key)}
                    />
                  ))
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={NoData}
                      style={{
                        width: "300px",
                        marginTop: "32px",
                        marginBottom: "32px",
                      }}
                    />
                    {selectedStatus != "all" ? (
                      <Typography variant="h5" style={{ marginBottom: "32px" }}>
                        <FormattedMessage id="no_apps_with_filters" />
                      </Typography>
                    ) : (
                      <Grid item xs={12}>
                        <Typography
                          variant="h5"
                          style={{ marginBottom: "32px" }}
                        >
                          <FormattedMessage id="no_apps_without_filters" />
                        </Typography>
                        <Button
                          size={"large"}
                          variant="rounded"
                          color="primaryContained"
                          onClick={() => {
                            window.location.href = `/${i18nLocale}/candidate/job_offers`;
                          }}
                        >
                          <Typography>
                            <FormattedMessage id="job_offer_summary.see_more"></FormattedMessage>
                          </Typography>
                        </Button>
                      </Grid>
                    )}
                  </Box>
                )}
              </Grid>
            </Grid>
            {jobApplications.length > 0 && (
              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  alignContent="center"
                >
                  <Box mt={2} style={{ marginBottom: "32px" }}>
                    <Pagination
                      size="large"
                      onChange={(e, page) => setPage(page)}
                      count={pageCount}
                      page={page}
                      variant="outlined"
                      color="primary"
                    />
                  </Box>
                </Grid>
              </Grid>
            )}

            {selectedApplication && isDialogOpen && (
              <Modal
                hideBackdrop={false}
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ display: "flex", alignItems: "center" }}
              >
                {selectedApplication?.job_offer ? (
                  <ApplicationDialog
                    jobOffer={selectedApplication?.job_offer}
                    setJobOffer={() => {}}
                    jobApplication={selectedApplication}
                    setIsApplying={setIsDialogOpen}
                  />
                ) : (
                  <SpontaneousApplicationDialog
                    companyId={selectedApplication.company}
                    closeModal={setIsDialogOpen}
                    jobApplication={jobApplications}
                  />
                )}
              </Modal>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default JobApplicationIndexDesktop;
