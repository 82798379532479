import React from "react";
import { Provider } from "react-redux";

import appStore from "bundles/common/store/appStore";
import LandingPageRecruiterContainer from "bundles/LandingPageRecruiter/container/LandingPageRecruiterContainer";
import SquarehubTheme from "bundles/Layouts/SquarehubTheme";
import { SnackbarProvider } from "notistack";

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
function LandingPageRecruiterApp(props, railsContext) {
  /* eslint-disable react/display-name */
  return () => (
    <Provider store={appStore(Object.assign(railsContext, props))}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <SquarehubTheme>
          <LandingPageRecruiterContainer {...props} />
        </SquarehubTheme>
      </SnackbarProvider>
    </Provider>
  );
}

export default LandingPageRecruiterApp;
