import React, { useState } from "react";
import {
  Grid,
  Button,
  Box,
  Link,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import PhoneInput from "react-phone-input-2";
import validator from "validator";
import { useSelector } from "react-redux";
import PasswordField from "bundles/common/components/PasswordField";

export default function StepOne({ setRecruiterData, nextStep }) {
  const i18nLocale = useSelector((state) => state.i18nLocale);

  const initialFormValues = {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
  };

  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );
    let not_an_email = (
      <FormattedMessage id="form_validation.not_an_email"></FormattedMessage>
    );
    let not_a_strong_password = (
      <FormattedMessage id="form_validation.not_a_strong_password"></FormattedMessage>
    );
    let invalid_number = (
      <FormattedMessage id="form_validation.invalid_number"></FormattedMessage>
    );

    if ("first_name" in fieldValues) {
      temp.first_name = fieldValues.first_name ? "" : required;
    }

    if ("last_name" in fieldValues) {
      temp.last_name = fieldValues.last_name ? "" : required;
    }

    if ("phone" in fieldValues) {
      if (fieldValues.phone && fieldValues.phone.length > 24) {
        temp.phone = invalid_number;
      } else {
        temp.phone = "";
      }
    }

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : required;
      if (fieldValues.email && !validator.isEmail(fieldValues.email))
        temp.email = not_an_email;
    }

    if ("password" in fieldValues) {
      temp.password = fieldValues.password ? "" : required;
      if (
        fieldValues.password &&
        !validator.isStrongPassword(fieldValues.password)
      )
        temp.password = not_a_strong_password;
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (validate(values)) {
      setRecruiterData(values);
      nextStep();
    } else {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12}>
          <Typography
            variant="h3"
            style={{ textAlign: "center", marginBottom: "32px" }}
          >
            <b>
              <FormattedMessage id="recruiter.signup_modal.title_step_2" />
            </b>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            type="text"
            size="medium"
            name="first_name"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            variant="outlined"
            color="secondary"
            label={<FormattedMessage id="devise.signup.first_name" />}
            {...(errors["first_name"] && {
              error: true,
              helperText: errors["first_name"],
            })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            type="text"
            size="medium"
            variant="outlined"
            name="last_name"
            color="secondary"
            onBlur={handleInputValue}
            onChange={handleInputValue}
            {...(errors["last_name"] && {
              error: true,
              helperText: errors["last_name"],
            })}
            label={<FormattedMessage id="devise.signup.last_name" />}
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneInput
            country={"be"}
            onlyCountries={["be", "lu"]}
            enableLongNumbers={true}
            containerStyle={{
              marginBottom: "8px",
            }}
            inputStyle={{ height: "56px", minWidth: "100%" }}
            value={values.phone}
            color="secondary"
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: false,
              onBlur: handleInputValue,
              onChange: handleInputValue,
            }}
          />
          {errors["phone"] && (
            <p
              className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required"
              style={{ color: "#f44336" }}
            >
              {errors["phone"]}
            </p>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type="email"
              variant="outlined"
              onBlur={handleInputValue}
              onChange={handleInputValue}
              color="secondary"
              name="email"
              size="medium"
              {...(errors["email"] && {
                error: true,
                helperText: errors["email"],
              })}
              label={<FormattedMessage id="devise.signup.email" />}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordField
              name="password"
              labelId="devise.signup.password"
              handleInputValue={handleInputValue}
              errors={errors}
              color="secondary"
              size="medium"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: "8px" }}>
        <Grid item xs={12}>
          <Button
            fullWidth
            type="submit"
            variant="rounded"
            color="secondaryContained"
            size="medium"
            disabled={loading}
          >
            <Box justifyContent="center">
              {loading ? (
                <CircularProgress size={16} color="secondary">
                  <FormattedMessage id="devise.signup.build_team" />
                </CircularProgress>
              ) : (
                <FormattedMessage id="devise.signup.build_team" />
              )}
            </Box>
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="caption" align="center">
            <FormattedMessage id="devise.signup.c_v_recruiter"
              values={{
                link1: chunks => (
                  <Link href={`/${i18nLocale}/terms-of-use`}
                    style={{ cursor: "pointer", underline: "always" }}
                  >
                    {chunks}
                  </Link>
                ),
                link2: chunks => (
                  <Link href={`/${i18nLocale}/privacy-policy`}
                    style={{ cursor: "pointer", underline: "always" }}
                  >
                    {chunks}
                  </Link>
                ),
                link3: chunks => (
                  <Link href={`/${i18nLocale}/cookies`}
                    style={{ cursor: "pointer", underline: "always" }}
                  >
                    {chunks}
                  </Link>
                ),
              }}
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ textAlign: "center" }}>
            <Typography variant="body3">
              <FormattedMessage align="center" id="devise.already_subscribed" />{" "}
              <Link
                href={`/${i18nLocale}/recruiters/sign_in`}
                color="secondary"
                underline="always"
              >
                <FormattedMessage id="signin" />
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}
