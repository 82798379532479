import React from "react";
import { Provider } from "react-redux";
import appStore from "bundles/common/store/appStore";
import CandidateContainer from "bundles/Candidate/container/CandidateContainer";
import SquarehubTheme from "bundles/Layouts/SquarehubTheme";
import { SnackbarProvider, MaterialDesignContent } from "notistack";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { styled } from "@mui/styles";
import CandidateQuestion from "bundles/common/components/CandidateQuestion";

Bugsnag.start({
  apiKey: "525ceeca3e03177d30aecd1ce3f1a299",
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["production"],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-info": {
    backgroundColor: "#2A334E",
  },
}));

// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
const CandidateApp = (props, railsContext) => {
  /* eslint-disable react/display-name */
  return (
    <ErrorBoundary>
      <Provider store={appStore(Object.assign(railsContext, props))}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          Components={{
            info: StyledMaterialDesignContent,
            candidateQuestion: CandidateQuestion,
          }}
        >
          <SquarehubTheme>
            <CandidateContainer {...props} />
          </SquarehubTheme>
        </SnackbarProvider>
      </Provider>
    </ErrorBoundary>
  );
};

export default CandidateApp;
